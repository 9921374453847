import { FC } from 'react'

const Footer: FC = () => {
	return (
		<footer className='admin__footer'>
			<span className='admin__footer-cp'>Copyright © {new Date().getFullYear()} Produced by Bellwether</span>
		</footer>
	)
}

export default Footer
