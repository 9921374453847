import { FC, useMemo, useState } from 'react'
import { Tabs } from 'antd'
import { UserOutlined as UserIco } from '@ant-design/icons'
import Clients from './tabs/Clients'
import PMs from './tabs/PMs'
import Advisors from './tabs/Advisors'
import Admins from './tabs/Admins'
import Files from './tabs/Files'
import { Role } from 'graphql/generated/schema'
import { useAuth } from 'hooks/useContexts'
import PortfolioAdmins from './tabs/PortfolioAdmins'
import { type Tab } from 'rc-tabs/lib/interface'

type TabKeys = 'Clients' | 'PMs' | 'Advisors' | 'Admins' | 'Files'

const tabsByRole = {
	Clients: [Role.Admin, Role.Pm, Role.Advisor, Role.PortfolioAdmin],
	PMs: [Role.Admin],
	Advisors: [Role.Admin, Role.Pm],
	PortfolioAdmins: [Role.Admin],
	Admins: [Role.Admin],
	Files: [Role.Admin, Role.Pm, Role.Advisor],
}

const Workspace: FC = () => {
	const [activeTab, setActiveTab] = useState<TabKeys>('Clients')
	const auth = useAuth()

	const userRole = auth.user?.role

	const memoizedTabs = useMemo(() => {
		const tabsItems: Tab[] = [
			{
				key: 'Clients',
				label: (
					<>
						<UserIco />
						<span>Clients</span>
					</>
				),
				children: <Clients />,
			},
			{
				key: 'PMs',
				label: 'PMs',
				children: <PMs />,
			},
			{
				key: 'Advisors',
				label: 'Advisors',
				children: <Advisors />,
			},
			{
				key: 'PortfolioAdmins',
				label: 'Portfolio Admins',
				children: <PortfolioAdmins />,
			},
			{
				key: 'Admins',
				label: 'Admins',
				children: <Admins />,
			},
			{
				key: 'Files',
				label: 'Files',
				children: <Files />,
			},
		].filter((tab) => {
			const roles = tabsByRole[tab.key as TabKeys]
			return userRole && roles.includes(userRole)
		})

		return tabsItems
	}, [userRole])

	return (
		<div className='admin__workspace'>
			<Tabs
				defaultActiveKey={activeTab}
				className='admin-tabs'
				onChange={(activeKey) => {
					setActiveTab(activeKey as TabKeys)
				}}
				items={memoizedTabs}
			/>
		</div>
	)
}

export default Workspace
