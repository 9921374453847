import type { FormInstance } from 'antd'
import type {
	IDataIPS,
	AssetMixBreakpoint,
	AssetMixValue,
	IReturnObjectiveFields,
	IInvestmentPolicyFields,
	IRiskToleranceFields,
	AssetMixPoint,
	IStateIPS,
} from 'types/ips'

// const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>
const getEntries = Object.entries as <T extends object>(obj: T) => Array<[keyof T, T[keyof T]]>

type FormFields = Partial<IReturnObjectiveFields & IInvestmentPolicyFields & IRiskToleranceFields>
type FormFieldValue = string | number | undefined | null | number[]

type IPointMap<T> = {
	[key in keyof FormFields]: {
		[key: string]: T
	}
}

const POINT_MIX_MAP: Record<AssetMixValue, AssetMixBreakpoint> = {
	'Fixed Income': -1,
	Conservative: 0,
	'Conservative Growth': 20,
	Balanced: 55,
	'Moderate Growth': 70,
	'Aggressive Growth': 110,
	'All Equity': 120,
}

const INITIAL_POINT = POINT_MIX_MAP['Fixed Income']
const INITIAL_BREAKPOINT = POINT_MIX_MAP['All Equity']

const POINTS_MAP: IPointMap<AssetMixPoint> = {
	// The primary goal for my investment portfolio
	investmentGoal: { '1': 0, '2': 5, '3': 15, '4': 25 },
	// An investor’s financial capacity
	financialCapacity: { '1': 0, '2': 5, '3': 10, '4': 20 },
	// If the value of your investments declined
	investmentsDeclined: { '1': 0, '2': 5, '3': 15 },
	// Did you have an investment portfolio of meaningful size
	investmentBigSize: { '1': 5, '2': 0, '3': 10, '4': 20 },
	// Investments can go up or down in value;
	downturnSum: { '1': 0, '2': 5, '3': 15, '4': 20, '5': 25 },
	// Based on the information highlighted in the graphs above,
	riskProfile: { '1': 0, '2': 0, '3': 5, '4': 10, '5': 15 },
} as const

const BREAKPOINTS_MAP: IPointMap<AssetMixBreakpoint> = {
	// Investment Objectives
	investmentObjectivesCapitalPreservation: { '1': 0 },
	investmentObjectivesIncome: { '1': 20 },
	investmentObjectivesBalance: { '1': 70 },
	// Investment Knowledge
	investmentKnowledge: { '4': 70 },
	// Risk Tolerance (Risk Profile)
	riskTolerance: { '2': 70, '3': 20 },
	// Estimated Investment Time Horizon
	investmentTimeHorizon: { '1': -1, '2': 0, '3': 55 },
	// Intended use for Portfolio
	intendedUseOfPortfolio: { '2': 70 },
	// The primary goal for my investment portfolio
	investmentGoal: { '1': 0, '2': 70, '3': 55 },
	// Are you currently making regular withdrawals
	withdrawalsPlan: { '1': 70, '2': 70, '3': 70 },
	// An investor’s financial capacity
	financialCapacity: { '1': 70, '2': 110 },
	// If the value of your investments declined by 10%
	investmentsDeclined: { '1': 0, '2': 20 },
	// Did you have an investment portfolio of meaningful size
	investmentBigSize: { '1': 70, '2': 0 },
	// Investments can go up or down in value
	downturnSum: { '1': 0, '2': 20, '3': 55, '4': 70 },
	// Based on the information highlighted
	riskProfile: { '1': -1, '2': 0, '3': 55, '4': 70 },
} as const

function getAssetMixVal(totalPoints: number, breakpoint: AssetMixBreakpoint): AssetMixValue {
	let resultValue: AssetMixValue = 'Fixed Income'

	let pointsResult = totalPoints

	// 1. Adjust the maximum point value based on the breakpoint
	if (totalPoints === 0 && breakpoint === INITIAL_BREAKPOINT) {
		pointsResult = INITIAL_POINT
	} else if (totalPoints > breakpoint) {
		pointsResult = breakpoint
	}

	// 2. Adjust the asset mix value based on the points range
	/*  Range:
		Fixed Income: Initial || -1
		Conservative: 0-19
		Conservative Growth: 20-54
		Balanced: 55-69
		Moderate Growth: 70-109
		Aggressive Growth: 110-119
		All Equity: 120 – Infinity 
	*/
	for (const assetMixValue of Object.keys(POINT_MIX_MAP) as AssetMixValue[]) {
		if (pointsResult >= POINT_MIX_MAP[assetMixValue]) {
			resultValue = assetMixValue
		} else {
			break
		}
	}

	return resultValue
}

function calcBreakpoints(
	breakpointValues: { [key in keyof typeof BREAKPOINTS_MAP]: FormFieldValue },
	formValues: IDataIPS,
) {
	const breakpoints = new Set<AssetMixBreakpoint>([])

	const { returnObjective: { isRegularWithdrawals, withdrawalsAnnuallyPercent } = {} } = formValues

	if (isRegularWithdrawals === true && typeof withdrawalsAnnuallyPercent === 'number') {
		if (withdrawalsAnnuallyPercent <= 3) {
			breakpoints.add(70)
		} else if (withdrawalsAnnuallyPercent <= 5) {
			breakpoints.add(55)
		} else if (withdrawalsAnnuallyPercent <= 10) {
			breakpoints.add(20)
		}
	}

	getEntries(breakpointValues).forEach(([key, value]) => {
		if (!value) return

		if (Array.isArray(value)) {
			value.forEach((val) => {
				const breakpoint = BREAKPOINTS_MAP?.[key]?.[val]
				if (breakpoint !== undefined) {
					breakpoints.add(breakpoint)
				}
			})
		} else {
			const breakpoint = BREAKPOINTS_MAP?.[key]?.[value]
			if (breakpoint !== undefined) {
				breakpoints.add(breakpoint)
			}
		}
	})

	const breakpoint: AssetMixBreakpoint = breakpoints.size
		? (Math.min(...Array.from(breakpoints)) as AssetMixBreakpoint)
		: INITIAL_BREAKPOINT

	return { breakpoints, breakpoint }
}

function calcPoints(pointsValues: { [key in keyof typeof POINTS_MAP]: FormFieldValue }) {
	const totalPoints = getEntries(pointsValues).reduce((acc, [key, value]) => {
		if (Array.isArray(value)) {
			return acc
		} else {
			const points = value ? POINTS_MAP?.[key]?.[value] : 0

			return acc + (points ?? 0)
		}
	}, 0)

	return { totalPoints }
}

export const getAssetMixState = (form: FormInstance<IDataIPS>) => {
	const formValues: IDataIPS = form.getFieldsValue()
	const {
		returnObjective: { investmentGoal, withdrawalsPlan } = {},
		investmentPolicy: {
			investmentObjectivesCapitalPreservation,
			investmentObjectivesIncome,
			investmentObjectivesBalance,
			investmentTimeHorizon,
			intendedUseOfPortfolio,
			riskTolerance,
			investmentKnowledge,
		} = {},
		riskTolerance: { financialCapacity, investmentsDeclined, investmentBigSize, downturnSum, riskProfile } = {},
	} = formValues

	// breakpoints to observe
	const breakpointsValues = {
		investmentObjectivesCapitalPreservation,
		investmentObjectivesIncome,
		investmentObjectivesBalance,
		investmentKnowledge,
		riskTolerance,
		investmentTimeHorizon,
		intendedUseOfPortfolio,
		investmentGoal,
		withdrawalsPlan,
		financialCapacity,
		investmentsDeclined,
		investmentBigSize,
		downturnSum,
		riskProfile,
	}

	// points to observe
	const pointsValues = {
		investmentGoal,
		financialCapacity,
		investmentsDeclined,
		investmentBigSize,
		downturnSum,
		riskProfile,
	}

	const { breakpoints, breakpoint } = calcBreakpoints(breakpointsValues, formValues)

	const { totalPoints } = calcPoints(pointsValues)

	// get assetMix value
	const assetMixValue: AssetMixValue = getAssetMixVal(totalPoints, breakpoint)

	const assetMixNext: IStateIPS['assetMix'] = {
		totalPoints: totalPoints,
		breakpoint: breakpoint,
		breakpoints: Array.from(breakpoints),
		value: assetMixValue,
	}

	return assetMixNext
}
