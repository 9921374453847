import { FC, Fragment } from 'react'
import { Col, Row, Select, Form, InputNumber, Tabs, Input, Switch } from 'antd'
import { ClientNum, OnboardingChildProps, OnboardingFormValues } from 'types/client-onboarding'
import { formItemProps, rules } from 'constants/props'
import { clientTypeMap } from './ClientOnboarding'
import { generateNumValues } from './ClientInformation'
import { ExtractWithOptional, Paths } from 'types/ips'
import {
	AdvisorDocument,
	ClientAssetsAccountType,
	EntityAssetsAccountType,
	JointAssetsAccountType,
} from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'
import { useQuery } from '@apollo/client'

type FieldKey = ExtractWithOptional<OnboardingFormValues['existingAccounts']>

const fieldPath = (keys: Paths<OnboardingFormValues['existingAccounts']>): Paths<OnboardingFormValues> => [
	'existingAccounts',
	...keys,
]

type CombinedEntityAssets = ClientAssetsAccountType | JointAssetsAccountType | EntityAssetsAccountType

const getAccountTypes = (fieldKey: FieldKey) => {
	const accountTypesMap: Record<FieldKey, CombinedEntityAssets[]> = {
		clientOne: Object.values(ClientAssetsAccountType),
		clientTwo: Object.values(ClientAssetsAccountType),
		jointAccounts: Object.values(JointAssetsAccountType),
		entityAccounts: Object.values(EntityAssetsAccountType),
	}

	return accountTypesMap[fieldKey]
}

const ExistingAccounts: FC<OnboardingChildProps> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')
	const { clientCount, form } = props

	const existingAccounts = Form.useWatch(['existingAccounts'], form)
	const advisorId = Form.useWatch(['advisorId'])

	const advisorRes = useQuery(AdvisorDocument, {
		variables: { advisorId },
		skip: !advisorId,
	})

	const isNonSolicitFormActive = advisorRes.data?.advisor?.isNonSolicitFormActive

	const getQuestions = () => {
		const questions: {
			title: string
			question: string
			fieldKey: FieldKey
		}[] = []

		;[...Array(clientCount)]
			.map((_, idx) => (idx + 1) as ClientNum)
			.forEach((clientNum) => {
				questions.push({
					title: `Client ${clientNum}`,
					question: `Does Client ${clientNum} have any existing accounts with Bellwether, or are there intentions to open accounts outside of ROBIN?`,
					fieldKey: clientTypeMap[clientNum],
				})
			})

		if (clientCount === 2) {
			questions.push({
				title: 'Joint-Accounts',
				question:
					'Do Client 1 and Client 2 have any existing joint accounts with Bellwether, or are there intentions to open such accounts outside of ROBIN?',
				fieldKey: 'jointAccounts',
			})
		}

		questions.push({
			title: 'Entity-Accounts',
			question:
				'Do Client 1 or Client 2 have any existing entity accounts with Bellwether, or are there intentions to open such accounts outside of ROBIN?',
			fieldKey: 'entityAccounts',
		})

		return questions
	}

	const getAssetsJSX = (fieldKey: FieldKey) => {
		const { accountsNum, isExistingAccounts } = existingAccounts?.[fieldKey] ?? {}

		if (!accountsNum || !isExistingAccounts) {
			return null
		}

		const accountTypes = getAccountTypes(fieldKey)

		const assetsJSX = [...Array(accountsNum)].map((_, accIndex) => {
			return (
				<Fragment key={accIndex}>
					<Col xs={24}>
						<h4 style={{ color: '#A09575' }}>Account {accIndex + 1}</h4>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Account Type'
							name={fieldPath([fieldKey, 'accounts', accIndex, 'type'])}
							rules={[rules.requiredWhen(isRequired), rules.selectedFromList(accountTypes)]}>
							<Select
								allowClear
								options={accountTypes.map((value) => ({
									value,
								}))}
							/>
						</Form.Item>
					</Col>

					{fieldKey === 'entityAccounts' && (
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Asset Name'
								name={fieldPath([fieldKey, 'accounts', accIndex, 'name'])}
								rules={[rules.requiredWhen(isRequired)]}>
								<Input />
							</Form.Item>
						</Col>
					)}

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Asset Value'
							name={fieldPath([fieldKey, 'accounts', accIndex, 'value'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<InputNumber
								type='number'
								min={0}
								max={100_000_000}
								style={{ width: `${100}%` }}
							/>
						</Form.Item>
					</Col>
				</Fragment>
			)
		})

		return assetsJSX
	}

	return (
		<>
			<Tabs
				tabPosition='left'
				items={getQuestions()
					.map((record, idx) => {
						const { fieldKey } = record
						const { isExistingAccounts } = existingAccounts?.[fieldKey] ?? {}

						return {
							key: record.title,
							label: record.title,
							forceRender: true,
							children: (
								<Col
									key={idx}
									xs={24}
									xl={12}>
									<Row
										gutter={[20, 0]}
										align={'bottom'}>
										<Col
											xs={24}
											xl={12}>
											<Form.Item
												{...formItemProps}
												label={record.question}
												name={fieldPath([fieldKey, 'isExistingAccounts'])}
												rules={[rules.requiredWhen(isRequired)]}>
												<Select
													options={[
														{ label: 'Yes', value: true },
														{ label: 'No', value: false },
													]}
												/>
											</Form.Item>
										</Col>

										{isExistingAccounts && (
											<Col
												xs={24}
												xl={12}>
												<Form.Item
													{...formItemProps}
													label='How many accounts satisfy the mentioned criteria?'
													name={fieldPath([fieldKey, 'accountsNum'])}
													rules={[rules.requiredWhen(isRequired)]}>
													<Select
														allowClear
														options={generateNumValues(5)}
													/>
												</Form.Item>
											</Col>
										)}

										{getAssetsJSX(record.fieldKey)}

										{(fieldKey === 'clientOne' || fieldKey === 'clientTwo') && isNonSolicitFormActive && (
											<Col
												xs={24}
												xl={24}>
												<Form.Item
													label='Enable Non-Solicit Form'
													name={fieldPath([fieldKey, 'isNonSolicitFormEnabled'])}
													valuePropName='checked'
													rules={[]}>
													<Switch defaultChecked />
												</Form.Item>
											</Col>
										)}
									</Row>
								</Col>
							),
						}
					})
					.concat([
						{
							key: 'advisorNotes',
							label: 'Notes',
							forceRender: true,
							children: (
								<Row align={'bottom'}>
									<Col
										xs={24}
										xl={12}>
										<Form.Item
											{...formItemProps}
											label='Notes'
											name={'advisorNotes'}
											rules={[]}>
											<Input.TextArea
												maxLength={1000}
												autoSize={{ maxRows: 10, minRows: 2 }}
												showCount
											/>
										</Form.Item>
									</Col>
								</Row>
							),
						},
					])}
			/>
		</>
	)
}

export default ExistingAccounts
