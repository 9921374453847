import { FC, Fragment } from 'react'
import {
	BeneRelationshipType,
	BeneficiaryFieldsType,
	IndividualAccountInfoType,
	JointAccountInfoType,
	OnboardingChildProps,
	OnboardingFormValues,
} from 'types/client-onboarding'
import { Col, Form, Input, InputNumber, Select } from 'antd'
import { formItemProps, rules } from 'constants/props'
import { DeepPartial, Paths } from 'types/ips'
import { BwDatePicker } from 'components/start/BwDatePicker'
import { AccountType, AccountTypeJt } from 'graphql/generated/schema'
import { checkITF } from './ClientOnboarding'
import { useMatchQuery } from 'hooks/useMatchQuery'

type PathType = IndividualAccountInfoType | JointAccountInfoType

interface Props {
	accountType?: AccountType | AccountTypeJt
	beneficiaryInfo?: DeepPartial<BeneficiaryFieldsType[]>
	beneficiaryNum?: 1 | 2 | 3 | 4
	isSuccessor?: boolean
	fieldPath(keys: Paths<PathType>): Paths<OnboardingFormValues>
}

const MAX_ALLOCATION = 100

const BeneficiaryFields: FC<OnboardingChildProps & Props> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')

	const { fieldPath, beneficiaryNum, isSuccessor, beneficiaryInfo, accountType } = props
	const isITF = checkITF(accountType)

	if (isSuccessor || !beneficiaryNum) {
		return null
	}

	const fieldsJSX = [...Array(beneficiaryNum)].map((_, idx) => {
		return (
			<Fragment key={idx}>
				<Col xs={24}>
					<h5>Beneficiary {idx + 1}</h5>
				</Col>
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='First Name'
						name={fieldPath(['beneficiary', idx, 'firstName'])}
						rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
						<Input />
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Middle Initial'
						name={fieldPath(['beneficiary', idx, 'middleInitial'])}
						rules={[rules.minmax(1, 1)]}>
						<Input />
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Last Name'
						name={fieldPath(['beneficiary', idx, 'lastName'])}
						rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
						<Input />
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						label='Date of Birth'
						name={fieldPath(['beneficiary', idx, 'dateOfBirth'])}
						rules={[rules.requiredWhen(isRequired && isITF)]}>
						<BwDatePicker />
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						validateFirst
						normalize={(value) => value?.replace(/\s+/g, '') || undefined}
						label='SIN'
						name={fieldPath(['beneficiary', idx, 'sin'])}
						rules={[rules.sin]}>
						<Input type='number' />
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Relationship'
						name={fieldPath(['beneficiary', idx, 'relationship'])}
						rules={[rules.requiredWhen(isRequired), rules.selectedFromList(Object.values(BeneRelationshipType))]}>
						<Select
							allowClear
							options={Object.values(BeneRelationshipType).map((value) => ({
								value,
							}))}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						label='Allocation'
						// normalize={(value) => Number(value) || undefined}
						name={fieldPath(['beneficiary', idx, 'allocation'])}
						dependencies={[...Array(beneficiaryNum)].map((_, idx) => fieldPath(['beneficiary', idx, 'allocation']))}
						rules={[
							rules.requiredWhen(isRequired),
							{
								validator: (_, value) => {
									const allocationSum =
										beneficiaryInfo?.reduce((prev, curr) => {
											return prev + Number(curr?.allocation || 0)
										}, 0) || 0

									if (value >= 0 && allocationSum !== 100) {
										return Promise.reject('The total allocation of beneficiaries should equal 100')
									}

									return Promise.resolve()
								},
							},
						]}>
						<InputNumber
							type='number'
							min={0}
							max={MAX_ALLOCATION}
							style={{ width: `${100}%` }}
						/>
					</Form.Item>
				</Col>
			</Fragment>
		)
	})

	return <>{fieldsJSX}</>
}

export default BeneficiaryFields
