import { FC } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { Avatar, Button, Col, Modal, Row, Typography } from 'antd'
import { UserOutlined as UserIco, LogoutOutlined as LogoutIco } from '@ant-design/icons'
import { ReactComponent as LogoSVG } from '../../../assets/images/logo.svg'
import { adminModalProps } from '../modal/ModalProps'
import { useAuth } from 'hooks/useContexts'

const Header: FC = () => {
	const navigate = useNavigate()
	const auth = useAuth()
	const isDashboard = useMatch('/admin/dashboard')

	return (
		<header className='admin__header'>
			<Row
				justify='space-between'
				align='middle'>
				<Col span={4}>
					<Link to={'/admin/dashboard'}>
						<LogoSVG className='admin__logo' />
					</Link>
				</Col>
				{isDashboard && auth.user && (
					<Col span={4}>
						<div className='admin__userpanel'>
							<Avatar
								style={{ backgroundColor: '#BFBFBF', marginRight: '8px' }}
								size={24}
								icon={<UserIco />}
							/>
							<Typography.Text className='admin__userpanel-name'>{`${auth.user?.firstName} ${auth.user?.lastName}`}</Typography.Text>
							<Button
								shape='circle'
								type='text'
								icon={<LogoutIco />}
								onClick={() => {
									Modal.confirm({
										...adminModalProps,
										title: <h2>Do you want to log out from your account?</h2>,
										okText: 'Log Out',
										okButtonProps: {
											className: 'ant-btn-primary-black',
										},
										cancelButtonProps: {
											className: 'ant-btn-default-gold',
										},
										onOk() {
											auth.logout().then(() => {
												navigate('/admin/signin')
											})
										},
									})
								}}
							/>
						</div>
					</Col>
				)}
			</Row>
		</header>
	)
}

export default Header
