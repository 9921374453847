import moment from 'moment'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type SetValue<T> = Dispatch<SetStateAction<T>>

function parseWithMoment(json: string) {
	const regexDate = /^(\d{4})-(\d{2})-(\d{2})/

	return JSON.parse(json, function (key: any, value: any) {
		if (typeof value === 'string' && regexDate.exec(value)) {
			return moment(value)
		}
		return value
	})
}

function getStorageValue(key: string, defaultValue: any) {
	try {
		const saved = window.localStorage.getItem(key)
		return saved ? parseWithMoment(saved) : defaultValue
	} catch (error) {
		console.warn(`Error reading or parse localStorage key “${key}”:`, error)
		return defaultValue
	}
}

export function useLocalStorage<T>(key: string, defaultValue: T): [T, SetValue<T>] {
	const [value, setValue] = useState<T>(() => getStorageValue(key, defaultValue))

	useEffect(() => {
		try {
			localStorage.setItem(key, JSON.stringify(value))
		} catch (error) {
			console.warn(`Error save to localStorage key “${key}”:`, error)
		}
	}, [key, value])

	return [value, setValue]
}
