import { QueryResult, useMutation } from '@apollo/client'
import { IChildFields, IDataIPS, IStateIPS } from 'types/ips'
import {
	ClientFullInfoQuery,
	ClientRelationType,
	RemoveSecondaryClientDocument,
	UpdateClientDocument,
	UpdateClientInput,
} from 'graphql/generated/schema'
import { getDateString } from 'components/admin/onboarding/mapping'

interface SaveValuesProps {
	guid: string
	formValues: IDataIPS
	state: IStateIPS
	client: QueryResult<
		ClientFullInfoQuery,
		{
			guid: string
		}
	>
	isFullSave?: boolean
}

const getNullishNum = (value: number | null | undefined) => {
	if (value === null || value === undefined) {
		return null
	}

	return value
}

export const useSaveStepValues = () => {
	const [updateClient] = useMutation(UpdateClientDocument)
	const [removeSecondaryClient] = useMutation(RemoveSecondaryClientDocument)

	const saveStepValues = async ({ guid, formValues, state, client }: SaveValuesProps) => {
		const {
			clientInfo: { clientOne = {}, clientTwo = {} },
			clientChilds = {},
			jointChildNum,
		} = formValues

		const getAddressFields = (clientType: 'clientOne' | 'clientTwo') => {
			const clientInfo = formValues.clientInfo?.[clientType] || {}

			return {
				addressType: clientInfo.addressType || null,
				civic: clientInfo.civic || null,
				streetName: clientInfo.streetName || null,
				streetType: clientInfo.streetType || null,
				directionType: clientInfo.directionType || null,
				unitType: clientInfo.unitType || null,
				apartment: clientInfo.apartment || null,
				city: clientInfo.city || null,
				provinceId: clientInfo.provinceId || null,
				countryId: clientInfo.countryId || null,
				postalCode: clientInfo.postalCode || null,
			}
		}

		const getChildsFields = (): UpdateClientInput['childs'] => {
			const result: UpdateClientInput['childs'] = []

			Object.keys(clientChilds).forEach((key) => {
				const typedKey = key as keyof typeof clientChilds
				const childs = clientChilds[typedKey] as IChildFields[]

				childs.forEach((child, idx) => {
					if (child.firstName || child.lastName) {
						result.push({
							firstName: child.firstName || null,
							lastName: child.lastName || null,
							dob: getDateString(child.dob) || null,
							sin: child.sin || null,
							order: idx + 1,
							relation: typedKey as ClientRelationType,
						})
					}
				})
			})

			return result
		}

		const clientInput: UpdateClientInput = {
			guid,
			childNum: clientOne.childNum,
			...getAddressFields('clientOne'),
			assetMix: state.assetMix.value,
			title: clientOne.title || null,
			gender: clientOne.gender || null,
			firstName: clientOne.firstName || null,
			lastName: clientOne.lastName || null,
			dateOfBirth: getDateString(clientOne.dateOfBirth) || null,
			socialInsuranceNumber: clientOne.socialInsuranceNumber,
			mobilePhone: clientOne.mobilePhone || null,
			homePhone: clientOne.homePhone || null,
			email: clientOne.email || null,
			maritalStatus: clientOne.maritalStatus || null,
			citizenshipCountryId: clientOne.citizenshipCountryId || null,
			isTaxResidentCanada: clientOne.isTaxResidentCanada,
			isTaxCitizenUS: clientOne.isTaxCitizenUS,
			isTaxResidentOther: clientOne.isTaxResidentOther,
			isTaxBenefitsClaim: clientOne.isTaxBenefitsClaim,
			residentOfBlankCountryId: clientOne.residentOfBlankCountryId || null,
			isSpecialRatesClaim: clientOne.isSpecialRatesClaim,
			employmentStatus: clientOne.employmentStatus || null,
			employer: clientOne.employer || null,
			occupation: clientOne.occupation || null,
			businessType: clientOne.businessType || null,
			employerAddress: clientOne.employerAddress || null,
			isLegalAddressSameClientOne: clientOne?.isLegalAddressSameClientOne,
			isMailingAddressSameLegal: clientOne.isMailingAddressSameLegal,
			mailingAddressType: clientOne.mailingAddressType || null,
			mailingAddressCivic: clientOne?.mailingAddressCivic || null,
			mailingAddressStreetName: clientOne?.mailingAddressStreetName || null,
			mailingAddressStreetType: clientOne?.mailingAddressStreetType || null,
			mailingAddressDirectionType: clientOne?.mailingAddressDirectionType || null,
			mailingAddressUnitType: clientOne?.mailingAddressUnitType || null,
			mailingAddressApartment: clientOne?.mailingAddressApartment || null,
			mailingAddressCity: clientOne?.mailingAddressCity || null,
			mailingAddressProvinceId: clientOne?.mailingAddressProvinceId || null,
			mailingAddressCountryId: clientOne?.mailingAddressCountryId || null,
			mailingAddressPostalCode: clientOne?.mailingAddressPostalCode || null,
			relationshipType: clientOne?.relationshipType,
			relationshipTypeOther: clientOne?.relationshipTypeOther,
			spouseFirstName: clientOne.spouseFirstName || null,
			spouseLastName: clientOne.spouseLastName || null,
			spouseDateOfBirth: getDateString(clientOne.spouseDateOfBirth) || null,
			spouseSocialInsuranceNumber: clientOne.spouseSocialInsuranceNumber || null,
			spouseEmploymentStatus: clientOne.spouseEmploymentStatus || null,
			spouseEmployer: clientOne.spouseEmployer || null,
			spouseOccupation: clientOne.spouseOccupation || null,
			spouseBusinessType: clientOne.spouseBusinessType || null,
			spouseEmployerAddress: clientOne.spouseEmployerAddress || null,
			jointChildNum: jointChildNum,
			childs: getChildsFields(),
		}

		if (state.isSecondaryClient) {
			clientInput.clientSecondary = {
				childNum: clientTwo.childNum,
				...getAddressFields(clientTwo.isLegalAddressSameClientOne ? 'clientOne' : 'clientTwo'),
				title: clientTwo.title || null,
				gender: clientTwo.gender || null,
				firstName: clientTwo.firstName || null,
				lastName: clientTwo.lastName || null,
				dateOfBirth: getDateString(clientTwo.dateOfBirth) || null,
				socialInsuranceNumber: clientTwo.socialInsuranceNumber,
				mobilePhone: clientTwo.mobilePhone || null,
				homePhone: clientTwo.homePhone || null,
				email: clientTwo.email || null,
				maritalStatus: clientTwo.maritalStatus || null,
				citizenshipCountryId: clientTwo.citizenshipCountryId || null,
				isTaxResidentCanada: clientTwo.isTaxResidentCanada,
				isTaxCitizenUS: clientTwo.isTaxCitizenUS,
				isTaxResidentOther: clientTwo.isTaxResidentOther,
				isTaxBenefitsClaim: clientTwo.isTaxBenefitsClaim,
				residentOfBlankCountryId: clientTwo.residentOfBlankCountryId || null,
				isSpecialRatesClaim: clientTwo.isSpecialRatesClaim,
				employmentStatus: clientTwo.employmentStatus || null,
				employer: clientTwo.employer || null,
				occupation: clientTwo.occupation || null,
				businessType: clientTwo.businessType || null,
				employerAddress: clientTwo.employerAddress || null,
				isLegalAddressSameClientOne: clientTwo?.isLegalAddressSameClientOne,
				isMailingAddressSameLegal: clientTwo.isMailingAddressSameLegal,
				mailingAddressType: clientTwo.mailingAddressType || null,
				mailingAddressCivic: clientTwo?.mailingAddressCivic || null,
				mailingAddressStreetName: clientTwo?.mailingAddressStreetName || null,
				mailingAddressStreetType: clientTwo?.mailingAddressStreetType || null,
				mailingAddressDirectionType: clientTwo?.mailingAddressDirectionType || null,
				mailingAddressUnitType: clientTwo?.mailingAddressUnitType || null,
				mailingAddressApartment: clientTwo?.mailingAddressApartment || null,
				mailingAddressCity: clientTwo?.mailingAddressCity || null,
				mailingAddressProvinceId: clientTwo?.mailingAddressProvinceId || null,
				mailingAddressCountryId: clientTwo?.mailingAddressCountryId || null,
				mailingAddressPostalCode: clientTwo?.mailingAddressPostalCode || null,
				spouseFirstName: clientTwo.spouseFirstName || null,
				spouseLastName: clientTwo.spouseLastName || null,
				spouseDateOfBirth: getDateString(clientTwo.spouseDateOfBirth) || null,
				spouseSocialInsuranceNumber: clientTwo.spouseSocialInsuranceNumber || null,
				spouseEmploymentStatus: clientTwo.spouseEmploymentStatus || null,
				spouseEmployer: clientTwo.spouseEmployer || null,
				spouseOccupation: clientTwo.spouseOccupation || null,
				spouseBusinessType: clientTwo.spouseBusinessType || null,
				spouseEmployerAddress: clientTwo.spouseEmployerAddress || null,
			}
		} else {
			const id = client.data?.client?.clientSecondary?.id
			if (id) {
				await removeSecondaryClient({ variables: { id } })
			}
		}

		// Banking Info
		{
			const values = formValues.bankingInfo || {}

			clientInput.bankingInfo = {
				bankName: values.bankName,
				bankNumber: values.bankNumber,
				branchNumber: values.branchNumber,
				accountNumber: values.accountNumber,
				voidCheckUrl: values.voidCheck?.[0]?.url,
				secondaryBankName: values.secondaryBankName || null,
				secondaryBankNumber: values.secondaryBankNumber || null,
				secondaryBranchNumber: values.secondaryBranchNumber || null,
				secondaryAccountNumber: values.secondaryAccountNumber || null,
				secondaryVoidCheckUrl: values.secondaryVoidCheck?.[0]?.url || null,
			}
		}

		// Trusted Person
		{
			const values = formValues.trustedPerson || {}

			clientInput.trustedPerson = {
				isTrustedPerson: values.isTrustedPerson,
				firstName: values.isTrustedPerson ? values.firstName : null,
				lastName: values.isTrustedPerson ? values.lastName : null,
				relationship: values.isTrustedPerson ? values.relationship : null,
				mobilePhone: values.isTrustedPerson ? values.mobilePhone : null,
				email: values.isTrustedPerson ? values.email : null,
				homePhone: values.isTrustedPerson ? values.homePhone || null : null,
			}
		}

		// Additional Disclosures
		{
			const values = formValues.additionalDisclosures || {}

			clientInput.additionalDisclosures = {
				isVotingRights: values.isVotingRights,
				votingRightsSymbol: values.votingRightsSymbol || null,
				votingRightsMarket: values.votingRightsMarket || null,
				isInsider: values.isInsider,
				insiderSymbol: values.insiderSymbol || null,
				insiderMarket: values.insiderMarket || null,
				isIIROC: values.isIIROC,
				IIROCDetails: values.IIROCDetails || null,
				isAccountPower: values.isAccountPower,
				isAccountGuarantee: values.isAccountGuarantee,
				isFinancialInterest: values.isFinancialInterest,
				financialInterestDetails: values.financialInterestDetails || null,
				isThirdPartyInstructions: values.isThirdPartyInstructions,
				isPEP: values.isPEP,
				pepDetails: values.pepDetails || null,
				foreignPEP: values.foreignPEP || null,
				domesticPEP: values.domesticPEP || null,
				isHeadIO: values.isHeadIO,
				headIOrganization: values.headIOrganization || null,
				// Secondary
				isVotingRightsSecondary: values.isVotingRightsSecondary,
				votingRightsSymbolSecondary: values.votingRightsSymbolSecondary || null,
				votingRightsMarketSecondary: values.votingRightsMarketSecondary || null,
				isInsiderSecondary: values.isInsiderSecondary,
				insiderSymbolSecondary: values.insiderSymbolSecondary || null,
				insiderMarketSecondary: values.insiderMarketSecondary || null,
				isIIROCSecondary: values.isIIROCSecondary,
				IIROCDetailsSecondary: values.IIROCDetailsSecondary || null,
				isFinancialInterestSecondary: values.isFinancialInterestSecondary,
				financialInterestDetailsSecondary: values.financialInterestDetailsSecondary || null,
				isThirdPartyInstructionsSecondary: values.isThirdPartyInstructionsSecondary,
				isPEPSecondary: values.isPEPSecondary,
				pepDetailsSecondary: values.pepDetailsSecondary || null,
				foreignPEPSecondary: values.foreignPEPSecondary || null,
				domesticPEPSecondary: values.domesticPEPSecondary || null,
				isHeadIOSecondary: values.isHeadIOSecondary,
				headIOrganizationSecondary: values.headIOrganizationSecondary || null,
			}
		}

		// Investment Policy
		{
			const values = formValues.investmentPolicy || {}

			clientInput.investmentPolicy = {
				investmentObjectivesCapitalPreservation: values.investmentObjectivesCapitalPreservation,
				investmentObjectivesIncome: values.investmentObjectivesIncome,
				investmentObjectivesBalance: values.investmentObjectivesBalance,
				investmentObjectivesGrowth: values.investmentObjectivesGrowth,
				investmentObjectivesSpeculation: values.investmentObjectivesSpeculation,
				investmentKnowledge: values.investmentKnowledge,
				riskTolerance: values.riskTolerance,
				clientOneIncome: values.clientOneIncome,
				clientOneNetFinancialAssets: values.clientOneNetFinancialAssets,
				clientOneNetFixedAssets: values.clientOneNetFixedAssets,
				clientTwoIncome: getNullishNum(values.clientTwoIncome),
				clientTwoNetFinancialAssets: getNullishNum(values.clientTwoNetFinancialAssets),
				clientTwoNetFixedAssets: getNullishNum(values.clientTwoNetFixedAssets),
				investmentTimeHorizon: values.investmentTimeHorizon,
				intendedUseOfPortfolio: values.intendedUseOfPortfolio,
				intendedUseOfPortfolioOther: values.intendedUseOfPortfolioOther || null,
				percentageOfInvestment: values.percentageOfInvestment || null,
				previousInvestments: values.previousInvestments,
			}
		}

		// Return Objective
		{
			const values = formValues.returnObjective || {}

			clientInput.returnObjective = {
				investmentGoal: values.investmentGoal || null,
				isRegularWithdrawals: values.isRegularWithdrawals,
				withdrawalsPlan: values.withdrawalsPlan || null,
				withdrawalsTotalValue: values.withdrawalsTotalValue || null,
				withdrawalsAnnuallyValue: values.withdrawalsAnnuallyValue || null,
				withdrawalsAnnuallyPercent: values.withdrawalsAnnuallyPercent || null,
				isMajorWithdrawalsSoon: values.isMajorWithdrawalsSoon,
				expectedWithdrawalSum: values.expectedWithdrawalSum || null,
				expectedWithdrawalYears: values.expectedWithdrawalYears || null,
				expectAverage: values.expectAverage || null,
				isExpectedIncreaseInvestments: values.isExpectedIncreaseInvestments,
				expectedInvestmentsSum: values.expectedInvestmentsSum || null,
				expectedInvestmentsYears: values.expectedInvestmentsYears || null,
				isExpectedAddCapital: values.isExpectedAddCapital,
				expectedAddCapitalSum: values.expectedAddCapitalSum || null,
				expectedAddCapitalYears: values.expectedAddCapitalYears || null,
				isTransferringSecurities: values.isTransferringSecurities,
				transferringSecuritiesSymbol: values.transferringSecuritiesSymbol || null,
				isAvoidInvesting: values.isAvoidInvesting,
				avoidInvesting: values.avoidInvesting || null,
				isCapitalLosses: values.isCapitalLosses,
				capitalLossesSum: values.capitalLossesSum || null,
			}
		}

		// Risk Tolerance (Risk Profile)
		{
			const values = formValues.riskTolerance || {}

			clientInput.riskTolerance = {
				financialCapacity: values.financialCapacity || null,
				downturnSum: values.downturnSum || null,
				investmentBigSize: values.investmentBigSize || null,
				investmentsDeclined: values.investmentsDeclined || null,
				riskProfile: values.riskProfile || null,
			}
		}

		// Investment Guidelines
		{
			const values = formValues.investmentGuidelines || {}

			clientInput.investmentGuidelines = {
				agreements: values.agreements,
			}
		}

		await updateClient({
			variables: {
				input: clientInput,
			},
		})

		await client.refetch()
	}

	return [saveStepValues]
}
