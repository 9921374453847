import { FC, useEffect } from 'react'
import { Col, Form, Input, message, Modal, Row, Select, Spin } from 'antd'
import { useMutation } from '@apollo/client'
import { MODAL } from 'types/admin'
import { adminModalProps } from '../ModalProps'
import {
	CreatePortfolioAdminDocument,
	CreatePortfolioAdminInput,
	PortfolioAdminsDocument,
	UpdatePortfolioAdminDocument,
} from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { useModal } from 'hooks/useContexts'
import { rules } from 'constants/props'
import { DataTypePortfolioAdmin } from 'components/admin/tabs/PortfolioAdmins'

interface PropsType {
	values: DataTypePortfolioAdmin | undefined
}

const ModalCreateUpdatePortfolioAdmin: FC<PropsType> = (props) => {
	const [form] = Form.useForm<CreatePortfolioAdminInput>()
	const { activeModal, setActiveModal } = useModal()
	const [createPortfolioAdmin, { loading: createPortfolioAdminLoading }] = useMutation(CreatePortfolioAdminDocument)
	const [updatePortfolioAdmin, { loading: updatePortfolioAdminLoading }] = useMutation(UpdatePortfolioAdminDocument)

	const onCloseModal = () => setActiveModal(null)

	const setKey = (key: keyof CreatePortfolioAdminInput): keyof CreatePortfolioAdminInput => key

	const actionType = activeModal === MODAL.CREATE_PORTFOLIO_ADMIN ? 'Create' : 'Update'

	useEffect(() => {
		if (actionType === 'Update') {
			form.setFieldsValue({
				...props.values,
			})
		}
	}, [actionType, props.values])

	const isLoading = createPortfolioAdminLoading || updatePortfolioAdminLoading

	return (
		<Modal
			{...adminModalProps}
			className='admin-modal admin-modal--A'
			open={activeModal === MODAL.CREATE_PORTFOLIO_ADMIN || activeModal === MODAL.UPDATE_PORTFOLIO_ADMIN}
			okText={actionType}
			okButtonProps={{ className: 'ant-btn-primary-black' }}
			cancelButtonProps={{ className: 'ant-btn-default-gold' }}
			onCancel={onCloseModal}
			confirmLoading={isLoading}
			maskClosable={false}
			onOk={() => {
				if (actionType === 'Create') {
					form
						.validateFields()
						.then((values) => {
							createPortfolioAdmin({
								variables: {
									createPortfolioAdminInput: {
										...values,
									},
								},
								refetchQueries: [PortfolioAdminsDocument],
							})
								.then(() => {
									message.success(`${values.firstName} ${values.lastName} created`)
									onCloseModal()
									form.resetFields()
								})
								.catch(printError)
						})
						.catch((info) => {
							console.warn('Create Portfolio Admin error fields:', info)
						})
				}

				if (actionType === 'Update') {
					form
						.validateFields()
						.then((values) => {
							updatePortfolioAdmin({
								variables: {
									updatePortfolioAdminInput: {
										...values,
										id: props.values?.id as number,
									},
								},
								refetchQueries: [PortfolioAdminsDocument],
							})
								.then(() => {
									message.success(`${values.firstName} ${values.lastName} updated`)
									onCloseModal()
									form.resetFields()
								})
								.catch(printError)
						})
						.catch((info) => {
							console.warn('Update Portfolio Admin error fields:', info)
						})
				}
			}}>
			<h2 className='text-center'>{actionType} Portfolio Admin</h2>
			<Spin spinning={isLoading}>
				<Form
					form={form}
					size='large'
					layout='vertical'
					validateTrigger='onBlur'
					autoComplete='off'
					requiredMark={false}>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='First Name'
								name={setKey('firstName')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Last Name'
								name={setKey('lastName')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Email'
								name={setKey('email')}
								normalize={(val?: string) => val?.toLowerCase()}
								rules={[rules.required, rules.email]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Phone'
								name={setKey('phone')}
								rules={[rules.required, rules.phone]}>
								<Input />
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Address'
								name={setKey('address')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input maxLength={50} />
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Password'
								name={setKey('password')}
								rules={[rules.requiredWhen(actionType === 'Create'), rules.minmax(8, 20)]}>
								<Input.Password autoComplete='new-password' />
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='RR Codes'
								name={setKey('rrCodes')}
								normalize={(val?: string[]) => val?.map((val: string) => val.toUpperCase())}
								validateTrigger={['onBlur', 'onChange']}
								rules={[rules.required, rules.rrCodes]}>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									tokenSeparators={[',']}
									notFoundContent={null}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>
		</Modal>
	)
}

export default ModalCreateUpdatePortfolioAdmin
