import { FC } from 'react'
import { Form, Row, Col, Radio, InputNumber, Checkbox, Select, Typography, Input, Space } from 'antd'
import { IPropsSteps, IInvestmentPolicyFields, IDataIPS } from 'types/ips'
import FormSection from 'components/ips/FormSection'
import { rules } from 'constants/props'
import { getInactiveIPS } from 'helpers/ips'
import { INACTIVE_IPS_INVESTMENT_OBJECTIVE } from 'constants/ips'
import AudioTooltip from '../AudioTooltip'
import audio2 from '../../../assets/audio/tooltips/2. Investment Policy Statement.mp3'
import audio3 from '../../../assets/audio/tooltips/3. Investment Objectives.mp3'
import audio4 from '../../../assets/audio/tooltips/4. Investment Knowledge Assessment.mp3'
import audio5 from '../../../assets/audio/tooltips/5. Risk Profile Classification.mp3'
import audio6 from '../../../assets/audio/tooltips/6. Assets.mp3'
import audio7 from '../../../assets/audio/tooltips/7. Estimated Investment Time Horizon.mp3'
import audio8 from '../../../assets/audio/tooltips/8. Intended use of Portfolio.mp3'

const Step5: FC<IPropsSteps> = (props) => {
	const keyPath = (key: keyof IInvestmentPolicyFields): [keyof IDataIPS, keyof IInvestmentPolicyFields] => [
		'investmentPolicy',
		key,
	]

	const investmentObjectivesCapitalPreservation = Form.useWatch(
		keyPath('investmentObjectivesCapitalPreservation'),
		props.form,
	) as IInvestmentPolicyFields['investmentObjectivesCapitalPreservation']
	const investmentObjectivesIncome = Form.useWatch(
		keyPath('investmentObjectivesIncome'),
		props.form,
	) as IInvestmentPolicyFields['investmentObjectivesIncome']
	const investmentObjectivesBalance = Form.useWatch(
		keyPath('investmentObjectivesBalance'),
		props.form,
	) as IInvestmentPolicyFields['investmentObjectivesBalance']
	const investmentObjectivesGrowth = Form.useWatch(
		keyPath('investmentObjectivesGrowth'),
		props.form,
	) as IInvestmentPolicyFields['investmentObjectivesGrowth']
	const investmentObjectivesSpeculation = Form.useWatch(
		keyPath('investmentObjectivesSpeculation'),
		props.form,
	) as IInvestmentPolicyFields['investmentObjectivesSpeculation']

	const clientOneIncome =
		(Form.useWatch(keyPath('clientOneIncome'), props.form) as IInvestmentPolicyFields['clientOneIncome']) || 0
	const clientTwoIncome =
		(Form.useWatch(keyPath('clientTwoIncome'), props.form) as IInvestmentPolicyFields['clientTwoIncome']) || 0

	const clientOneNetFinancialAssets =
		(Form.useWatch(
			keyPath('clientOneNetFinancialAssets'),
			props.form,
		) as IInvestmentPolicyFields['clientOneNetFinancialAssets']) || 0

	const clientOneNetFixedAssets =
		(Form.useWatch(
			keyPath('clientOneNetFixedAssets'),
			props.form,
		) as IInvestmentPolicyFields['clientOneNetFixedAssets']) || 0

	const clientTwoNetFinancialAssets =
		(Form.useWatch(
			keyPath('clientTwoNetFinancialAssets'),
			props.form,
		) as IInvestmentPolicyFields['clientTwoNetFinancialAssets']) || 0

	const clientTwoNetFixedAssets =
		(Form.useWatch(
			keyPath('clientTwoNetFixedAssets'),
			props.form,
		) as IInvestmentPolicyFields['clientTwoNetFixedAssets']) || 0

	const intendedUseOfPortfolio = Form.useWatch(
		keyPath('intendedUseOfPortfolio'),
		props.form,
	) as IInvestmentPolicyFields['intendedUseOfPortfolio']
	const riskTolerance = Form.useWatch(keyPath('riskTolerance'), props.form) as IInvestmentPolicyFields['riskTolerance']
	const investmentKnowledge = Form.useWatch(
		keyPath('investmentKnowledge'),
		props.form,
	) as IInvestmentPolicyFields['investmentKnowledge']

	const investmentObjectives = [
		investmentObjectivesCapitalPreservation,
		investmentObjectivesIncome,
		investmentObjectivesBalance,
		investmentObjectivesGrowth,
		investmentObjectivesSpeculation,
	]

	const investmentObjectivesVars: {
		label: string
		key: keyof IInvestmentPolicyFields
	}[] = [
		{ label: 'Capital Preservation', key: 'investmentObjectivesCapitalPreservation' },
		{ label: 'Income', key: 'investmentObjectivesIncome' },
		{ label: 'Balance (Income and Growth)', key: 'investmentObjectivesBalance' },
		{ label: 'Growth', key: 'investmentObjectivesGrowth' },
		{ label: 'Speculation', key: 'investmentObjectivesSpeculation' },
	]

	const riskToleranceText = () => {
		if (riskTolerance === '1') {
			return 'The high-risk rating applies to investors who are growth-oriented and are willing to accept significant short-term fluctuations in portfolio value in exchange for potentially higher long-term returns. Investments under the high risk rating include investments with high volatility and may include investment funds that concentrate their investments predominantly in specific regions or in specific sectors of the economy where there is a substantial risk of loss (i.e., emerging markets and precious metals); labour-sponsored venture capital funds, or funds that engage in speculative trading strategies including hedge funds that invest in derivatives, short sell or use leverage.'
		}
		if (riskTolerance === '2') {
			return 'The medium risk rating applies to investors who are seeking moderate growth over a longer period of time. Investments under the medium risk rating include investments with medium volatility and may include equity portfolios that are well diversified across a broad number of Canadian and/or international equity securities and across a number of economic sectors.'
		}
		if (riskTolerance === '3') {
			return 'The low-risk rating applies to investors who are willing to accept lower returns to preserve their principal. Investments under the low-risk rating include investments with low volatility such as money market funds and/or Canadian fixed income funds.'
		}
	}

	const investmentKnowledgeText = () => {
		if (investmentKnowledge === '1') {
			return 'If you complete your own analysis on investment products, this suggests that you may be "sophisticated".'
		}
		if (investmentKnowledge === '2') {
			return 'If you are involved in your investment decisions but not completely conversant in them, this may indicate that your knowledge is "good."'
		}
		if (investmentKnowledge === '3') {
			return 'If you read the papers and follow the news but aren\'t directly involved in your investments, you may have "limited" investment knowledge.'
		}
		if (investmentKnowledge === '4') {
			return 'If you are not involved in your investments and do not keep up-to-date on financial news, you may have "poor" investment knowledge.'
		}
	}

	const getInactive = (key: keyof IInvestmentPolicyFields) => {
		const [, fieldKey] = keyPath(key)
		const isInactive = Boolean(props.inactiveFields.investmentPolicy[fieldKey])

		return getInactiveIPS(isInactive)
	}

	return (
		<>
			<div className='mb-20'>
				<AudioTooltip
					audioSrc={audio2}
					title='Play overview'
				/>
			</div>
			<FormSection>
				<div className='bw-form__block'>
					<p>
						The following information is required under securities legislation to open discretionary investment accounts
						with Bellwether Investment Management Inc. (“Bellwether”). All information shall remain strictly
						confidential.
					</p>
					<p>TO BE COMPLETED BY THE DECISION MAKER ON BEHALF OF THE INDIVIDUAL(S) AS APPLICABLE:</p>
				</div>
				<div className='bw-form__block'>
					<div
						className='bw-form__block-head'
						style={{ fontSize: '1rem' }}></div>
					<Space>
						<AudioTooltip audioSrc={audio3} />
						<b>Investment Objectives</b>
					</Space>
					<div>
						<Typography.Text type='secondary'>(1- Most important to 5- Least important.)</Typography.Text>
					</div>
					{investmentObjectivesVars.map((field) => (
						<Row
							gutter={[15, 0]}
							style={{ alignItems: 'baseline' }}
							className={getInactive(field.key).isInactive ? 'ant-row--active' : 'ant-row--inactive'}
							key={field.key}>
							<Col
								xs={24}
								xl={17}>
								{field.label}
							</Col>
							<Col
								xs={24}
								xl={7}>
								<Form.Item
									name={keyPath(field.key)}
									className={getInactive(field.key).className}
									style={{ marginBottom: 5 }}
									rules={[rules.required]}>
									<Select
										allowClear
										options={[1, 2, 3, 4, 5].map((num) => ({
											label: num,
											value: num,
											disabled: investmentObjectives.includes(num),
										}))}
									/>
								</Form.Item>
							</Col>

							{getInactive(field.key).message(INACTIVE_IPS_INVESTMENT_OBJECTIVE)}
						</Row>
					))}
				</div>
			</FormSection>
			<FormSection
				help={{
					text: investmentKnowledgeText(),
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio4} />
										<span>Investment Knowledge</span>
									</Space>
								}
								name={keyPath('investmentKnowledge')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title: `
									This is a relative term. It does not ask you to compare your knowledge to that of a financial professional but rather to indicate how much experience and knowledge you have in investing.
									`,
								}}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{ value: '1', label: 'Sophisticated' },
										{ value: '2', label: 'Good' },
										{ value: '3', label: 'Limited' },
										{ value: '4', label: 'Poor/None' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
			<FormSection
				help={{
					text: riskToleranceText(),
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio5} />
										<span>Risk Profile</span>
									</Space>
								}
								name={keyPath('riskTolerance')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{ value: '1', label: 'High' },
										{ value: '2', label: 'Medium' },
										{ value: '3', label: 'Low' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Client 1 Income'
								name={keyPath('clientOneIncome')}
								rules={[rules.required]}>
								<InputNumber
									type='number'
									controls={false}
									min={0}
									style={{ width: `${100}%` }}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio6} />
										<span>Net Financial Assets</span>
									</Space>
								}
								name={keyPath('clientOneNetFinancialAssets')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										'Net financial assets include (i) cash, (ii) securities, and (iii) a contract of insurance, deposit or an evidence of a deposit that is not a security for the purposes of securities legislation, minus any related debt. The value of an investor’s personal residence or other real estate is not included in the calculation of financial assets.',
								}}
								rules={[rules.required]}>
								<InputNumber
									type='number'
									min={0}
									max={999_999_999_999}
									style={{ width: `${100}%` }}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Net Fixed Assets'
								name={keyPath('clientOneNetFixedAssets')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										'Net fixed assets refer to the total value of your tangible long-term assets, including items like real estate and equipment, after subtracting any accumulated depreciation and outstanding liabilities. The value attributed to net fixed assets should reasonably represent your estimated fair market value. In the context of investments, income tax obligations should be recognized as liabilities if they are outstanding at the time of a security’s distribution or trade.',
								}}
								rules={[rules.required]}>
								<InputNumber
									type='number'
									min={0}
									max={999_999_999_999}
									style={{ width: `${100}%` }}
								/>
							</Form.Item>
						</Col>
						{props.stateIPS.isSecondaryClient && (
							<>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										label='Client 2 Income'
										name={keyPath('clientTwoIncome')}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											controls={false}
											min={0}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='Net Financial Assets'
										name={keyPath('clientTwoNetFinancialAssets')}
										tooltip={{
											overlayStyle: { maxWidth: '400px' },
											title:
												'Net financial assets include (i) cash, (ii) securities, and (iii) a contract of insurance, deposit or an evidence of a deposit that is not a security for the purposes of securities legislation, minus any related debt. The value of an investor’s personal residence or other real estate is not included in the calculation of financial assets.',
										}}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											min={0}
											max={999_999_999_999}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='Net Fixed Assets'
										name={keyPath('clientTwoNetFixedAssets')}
										tooltip={{
											overlayStyle: { maxWidth: '400px' },
											title:
												'Net fixed assets refer to the total value of your tangible long-term assets, including items like real estate and equipment, after subtracting any accumulated depreciation and outstanding liabilities. The value attributed to net fixed assets should reasonably represent your estimated fair market value. In the context of investments, income tax obligations should be recognized as liabilities if they are outstanding at the time of a security’s distribution or trade.',
										}}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											min={0}
											max={999_999_999_999}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
							</>
						)}
					</Row>
					<Row gutter={[20, 0]}>
						{props.stateIPS.isSecondaryClient && (
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Total Annual Income'
									extra='calculated automatically'>
									<InputNumber
										disabled
										type='number'
										controls={false}
										value={clientOneIncome + clientTwoIncome}
										style={{ width: `${100}%` }}
									/>
								</Form.Item>
							</Col>
						)}

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Net Worth'
								extra='calculated automatically'>
								<InputNumber
									disabled
									type='number'
									controls={false}
									value={
										clientOneNetFinancialAssets +
										clientOneNetFixedAssets +
										clientTwoNetFinancialAssets +
										clientTwoNetFixedAssets
									}
									style={{ width: `${100}%` }}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio7} />
										<span>Estimated Investment Time Horizon</span>
									</Space>
								}
								name={keyPath('investmentTimeHorizon')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										'Your estimated time horizon is best thought of as the amount of time until you plan to withdraw your portfolio. For example, even though you may plan to retire in 3 years, you still intend to keep your portfolio invested and have it supplement your income in retirement for the foreseeable future. In this case, your time horizon would be longer than the three years to retirement.',
								}}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{ value: '1', label: 'Under 2 years' },
										{ value: '2', label: '2 - 5 years' },
										{ value: '3', label: '5 - 10 years' },
										{ value: '4', label: '10+ years' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio8} />
										<span>Intended use of Portfolio</span>
									</Space>
								}
								name={keyPath('intendedUseOfPortfolio')}
								rules={[rules.required]}>
								<Checkbox.Group
									options={[
										{ value: 1, label: 'Saving for retirement' },
										{ value: 2, label: 'Supplementing income' },
										{ value: 3, label: 'Saving for real estate/education' },
										{ value: 4, label: 'Other' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					{Array.isArray(intendedUseOfPortfolio) && intendedUseOfPortfolio.includes(4) && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Enter your choice'
									name={keyPath('intendedUseOfPortfolioOther')}
									rules={[rules.required, rules.minmax(1, 20)]}>
									<Input
										showCount
										maxLength={20}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Percentage of investments managed by Bellwether'
								name={keyPath('percentageOfInvestment')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{ value: '1', label: '<10%' },
										{ value: '2', label: '10-25%' },
										{ value: '3', label: '25-50%' },
										{ value: '4', label: '50-75%' },
										{ value: '5', label: '>75%' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Previous Investments that you have made'
								name={keyPath('previousInvestments')}>
								<Checkbox.Group
									className='ant-group-col2'
									options={[
										{ value: 1, label: 'Bonds' },
										{ value: 2, label: 'Mutual Funds' },
										{ value: 3, label: 'Mortgages' },
										{ value: 4, label: 'Term Deposits' },
										{ value: 5, label: 'Stocks' },
										{ value: 6, label: 'Real Estate' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
		</>
	)
}

export default Step5
