import { FC, Suspense, lazy, useEffect } from 'react'
import { Form, Row, Col, Radio, Skeleton, Button } from 'antd'
import FormSection from 'components/ips/FormSection'
import { rules } from 'constants/props'
import { downturnSumDisabled, OptionsDependency, riskProfileDisabled } from 'helpers/ips-block-values'
import type {
	DeepPartial,
	IDataIPS,
	IPropsSteps,
	IReturnObjectiveFields,
	IRiskToleranceFields,
	StepFields,
} from 'types/ips'
const LongTermReturnChart = lazy(() => import('components/ips/charts/LongTermReturn'))
const OneYearReturnChart = lazy(() => import('components/ips/charts/OneYearReturn'))

const Step7: FC<IPropsSteps> = (props) => {
	const keyPath = (key: keyof IRiskToleranceFields): [keyof IDataIPS, string] => ['riskTolerance', key]
	const keyPath6 = (key: keyof IReturnObjectiveFields): [keyof IDataIPS, string] => ['returnObjective', key]
	const setFields = (values: DeepPartial<IDataIPS>) => props.form.setFieldsValue(values as any)

	const downturnSum = (Form.useWatch(keyPath('downturnSum'), props.form) as IRiskToleranceFields['downturnSum']) || ''
	const riskProfile = (Form.useWatch(keyPath('riskProfile'), props.form) as IRiskToleranceFields['riskProfile']) || ''
	const expectAverage =
		(Form.useWatch(keyPath6('expectAverage'), props.form) as IReturnObjectiveFields['expectAverage']) || ''

	const stepWays: {
		[key in keyof StepFields]: {
			step: number
			path: string[]
		}
	} = {
		riskProfile: {
			step: 7,
			path: keyPath('riskProfile'),
		},
		downturnSum: {
			step: 7,
			path: keyPath('downturnSum'),
		},
		expectAverage: {
			step: 6,
			path: keyPath6('expectAverage'),
		},
	}

	const depDownturnSum = new OptionsDependency(downturnSumDisabled({ expectAverage, riskProfile }))
	const depRiskProfile = new OptionsDependency(riskProfileDisabled({ expectAverage, downturnSum }))

	const getDisabledDownturnSum = (value: IRiskToleranceFields['downturnSum']) =>
		depDownturnSum.getDisabledOption(value as string).disabled
	const getDisabledRiskProfile = (value: IRiskToleranceFields['riskProfile']) =>
		depRiskProfile.getDisabledOption(value as string).disabled

	useEffect(() => {
		// reset values (prevent to be selected & disabled)
		// https://docs.google.com/spreadsheets/d/1dsE1icqhGueeDUwrhRiNboyaATrjSNXeI18phRUpigE/edit#gid=0&range=E12
		if (getDisabledDownturnSum(downturnSum)) {
			setFields({
				riskTolerance: {
					downturnSum: '',
				},
			})
		}

		if (getDisabledRiskProfile(riskProfile)) {
			setFields({
				riskTolerance: {
					riskProfile: '',
				},
			})
		}
	}, [riskProfile, expectAverage, downturnSum])

	const dependencyExtra = (instanceClass: typeof depDownturnSum) => {
		if (instanceClass.disabledOptions.length) {
			return (
				<>
					<div className='mb-10'>
						Based on your previous answers, your investment requirements and restrictions are not consistent with these
						unavailable options (options in grey text). Please review your previous answers to make these options
						available to you if you believe they are more appropriate.
					</div>
					<Button
						style={{ fontWeight: '500' }}
						className='ant-btn-primary-black'
						size='middle'
						onClick={() => {
							const firstReason = instanceClass.blockReasons[0] as keyof StepFields
							const targetWay = stepWays[firstReason]

							if (targetWay) {
								props.stepController.setStep(targetWay.step)
								setTimeout(() => {
									props.form.scrollToField(targetWay.path, {
										behavior: 'smooth',
										block: 'center',
									})
								}, 100)
							}
						}}>
						REVIEW
					</Button>{' '}
				</>
			)
		}

		return null
	}

	return (
		<>
			<FormSection
				help={{
					text: 'For most individual investors, risk, or volatility, can be defined by the magnitude of changes (both increases and decreases) in the value of their investments over time. Clearly, the potential downward movement in portfolio value is what may cause concern. The extent of any short-term decline in the value of your investment portfolio that you can accept is a function of your financial situation as well as your emotional comfort level with market fluctuations.',
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='An investor’s financial capacity to endure a material decline in the value of their investment portfolio is a critical aspect of risk profile. The degree to which one’s sources of income exceed their expenses is an important consideration:'
								name={keyPath('financialCapacity')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label:
												'I plan on withdrawing from my portfolio in the next 5 years, or I am retired and depend on my investments to help support my current lifestyle.',
										},
										{
											value: '2',
											label:
												'I am retired, but I do not anticipate drawing income from my investments for the next 5 years.',
										},
										{
											value: '3',
											label:
												'I am employed, unemployed, or a student, but find it difficult to save much annually from my cash flow.',
										},
										{
											value: '4',
											label: 'I am employed and in a financial position to add to my investments each year.',
										},
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='If the value of your investments declined by 10% and stayed at that level for the next 2 years, how would your current lifestyle be impacted?'
								name={keyPath('investmentsDeclined')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{ value: '1', label: 'I would be concerned about my ability to meet basic living needs.' },
										{ value: '2', label: 'I would need to make adjustments to my standard of living.' },
										{ value: '3', label: 'I would not need to change anything in my current lifestyle.' },
									]}></Radio.Group>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
			<FormSection
				help={{
					text: (
						<>
							<p>
								The other aspect of risk profile is an investor’s potential emotional reaction to a significant market
								downturn, i.e., how much of a decline in portfolio value can one tolerate and still sleep at night? The
								goal is to minimize the risk of an investor selling their investments after a significant decline in
								value due to emotional distress and anxiety, which can result in a permanent loss of capital.
							</p>
							<p>
								Past investment experience is a good indicator of how an investor might react to challenging market
								conditions, i.e., how did they handle previous declines in the value of their investments?
							</p>
						</>
					),
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Did you have an investment portfolio of meaningful size invested in stocks or mutual funds during the Technology Crash in 2000/2001, the 2008/2009 Financial Crisis, or the COVID pandemic in 2020?'
								name={keyPath('investmentBigSize')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label:
												'No, I have only started investment in more recent years and have not experienced a major (+10%) decline in the value of my portfolio.',
										},
										{
											value: '2',
											label:
												'Yes, and I sold all (or a good portion) of my investments as it was too stressful for me.',
										},
										{
											value: '3',
											label:
												'Yes, I did not enjoy that period of time, but I did not sell my investments and waited for them to recover.',
										},
										{
											value: '4',
											label:
												'Yes, and I understand that investments can fluctuate in value. I was confident they would eventually recover and I saw this as an opportunity to buy stocks.',
										},
									]}></Radio.Group>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
			<FormSection>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Investments can go up or down in value; investors must be prepared to weather a market downturn. If your portfolio was valued at $500,000, at what point would a decline in market value begin to make you feel very uncomfortable?'
								tooltip='I understand that there is a trade-off between long-term returns and the chance of an investment portfolio declining in value during the short-term.'
								name={keyPath('downturnSum')}
								extra={dependencyExtra(depDownturnSum)}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label: '$475,000 ($25,000, or 5% decline)',
											disabled: getDisabledDownturnSum('1'),
										},
										{
											value: '2',
											label: '$450,000 ($50,000, or 10% decline)',
											disabled: getDisabledDownturnSum('2'),
										},
										{
											value: '3',
											label: '$425,000 ($75,000, or 15% decline)',
											disabled: getDisabledDownturnSum('3'),
										},
										{
											value: '4',
											label: '$400,000 ($100,000, or 20% decline)',
											disabled: getDisabledDownturnSum('4'),
										},
										{
											value: '5',
											label: 'I would not be concerned about short-term changes in the value of my investments',
											disabled: getDisabledDownturnSum('5'),
										},
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<p
								className='text-bold'
								style={{ marginBottom: '35px' }}>
								The graphs below illustrate the potential range of outcomes for a $500,000 investment portfolio after a
								one-year period as well as the potential longer-term returns that might be earned.
							</p>
							<h4>Range of 1 Year Returns</h4>
							<Suspense fallback={<Skeleton active />}>
								<OneYearReturnChart {...props} />
							</Suspense>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<h4>Impact of long term return</h4>
							<Suspense fallback={<Skeleton active />}>
								<LongTermReturnChart {...props} />
							</Suspense>
						</Col>
					</Row>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Based on the information highlighted in the graphs above, I would be most comfortable with the risk and return profile associated with:'
								name={keyPath('riskProfile')}
								extra={dependencyExtra(depRiskProfile)}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label: 'Scenario 1 (100% Fixed Income/ 0% Equities)',
											disabled: getDisabledRiskProfile('1'),
										},
										{
											value: '2',
											label: 'Scenario 2 (80% Fixed Income / 20% Equities)',
											disabled: getDisabledRiskProfile('2'),
										},
										{
											value: '3',
											label: 'Scenario 3 (60% Fixed Income / 40% Equities)',
											disabled: getDisabledRiskProfile('3'),
										},
										{
											value: '4',
											label: 'Scenario 4 (40% Fixed Income / 60% Equities)',
											disabled: getDisabledRiskProfile('4'),
										},
										{
											value: '5',
											label: 'Scenario 5 (20% Fixed Income / 80% Equities)',
											disabled: getDisabledRiskProfile('5'),
										},
									]}></Radio.Group>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
		</>
	)
}

export default Step7
