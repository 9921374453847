import { Alert } from 'antd'
import { INACTIVE_IPS_MESSAGE } from 'constants/ips'

export const getInactiveIPS = (isInactive: boolean) => {
	return {
		isInactive,
		message: (msg?: string) =>
			isInactive ? (
				<Alert
					type='error'
					message={msg ?? INACTIVE_IPS_MESSAGE}
				/>
			) : null,
		className: isInactive ? 'bw-form__item--active' : '',
	}
}
