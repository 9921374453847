import { ReactNode } from 'react'
import { Button } from 'antd'
import { PlayCircleOutlined as IconPlay, PauseCircleOutlined as IconPause } from '@ant-design/icons'
import { useAudioPlayer } from 'context/audioHintContext'

const AudioTooltip: React.FC<{ audioSrc: string; title?: ReactNode }> = ({ audioSrc, title }) => {
	const { currentAudio, isPlaying, playAudio, pauseAudio } = useAudioPlayer()

	const isTargetAudio = currentAudio === audioSrc
	const isPlayState = isTargetAudio && isPlaying

	const toggleAudio = () => {
		if (isPlayState) {
			pauseAudio()
		} else {
			playAudio(audioSrc)
		}
	}

	return (
		<div>
			<Button
				size='large'
				className='audio-tooltip-btn'
				icon={isPlayState ? <IconPause /> : <IconPlay />}
				onClick={toggleAudio}>
				{title}
			</Button>
		</div>
	)
}

export default AudioTooltip
