import { FC, useEffect } from 'react'
import { Col, Form, Input, message, Modal, Row, Select, Spin, Switch } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { MODAL } from 'types/admin'
import { adminModalProps } from '../ModalProps'
import {
	AdvisorsDocument,
	CanadaStatesDocument,
	ClientTableInfoDocument,
	CountersDocument,
	CountriesDocument,
	CreateAdvisorDocument,
	CreateAdvisorInput,
	FindFormsOfAdvisorsDocument,
	PmsDocument,
	Role,
	UpdateAdvisorDocument,
} from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { useAuth, useModal } from 'hooks/useContexts'
import { rules } from 'constants/props'
import { config } from 'config'
import { EyeOutlined as IconEye } from '@ant-design/icons'
import { DataTypeAdvisor } from 'components/admin/tabs/Advisors'

interface PropsType {
	values: DataTypeAdvisor | undefined
}

const ModalCreateUpdateAdvisor: FC<PropsType> = (props) => {
	const [form] = Form.useForm<CreateAdvisorInput>()
	const { user } = useAuth()
	const { activeModal, setActiveModal } = useModal()
	const [createAdvisor, { loading: createAdvisorLoading }] = useMutation(CreateAdvisorDocument)
	const [updateAdvisor, { loading: updateAdvisorLoading }] = useMutation(UpdateAdvisorDocument)

	const onCloseModal = () => setActiveModal(null)

	const setKey = (key: keyof CreateAdvisorInput): keyof CreateAdvisorInput => key

	const actionType = activeModal === MODAL.CREATE_ADVISOR ? 'Create' : 'Update'

	const pmsRes = useQuery(PmsDocument)
	const pmsOpts = pmsRes.data?.pms.nodes?.map((pm) => ({
		label: `${pm.firstName} ${pm.lastName}`,
		value: pm.id,
	}))

	const countriesRes = useQuery(CountriesDocument)
	const countriesOpts = countriesRes.data?.countries?.map((country) => ({
		label: country.name,
		value: country.id,
	}))

	const canadaStatesRes = useQuery(CanadaStatesDocument)
	const canadaStatesOpts = canadaStatesRes.data?.states?.map((state) => ({
		label: state.name,
		value: state.id,
	}))

	const advisorFormsRes = useQuery(FindFormsOfAdvisorsDocument)
	const advisorFormsArr = advisorFormsRes.data?.findFormsOfAdvisors ?? []
	const advisorFormsOpts = [
		{ label: 'Default', value: null },
		...advisorFormsArr.map((form) => ({
			label: form.name,
			value: form.id,
		})),
	]

	const onPreviewIMA = () => {
		const { formId } = form.getFieldsValue()

		window.open(`${config.apiUrl}/pdf/ima/${formId ?? 0}`, '_blank')
	}

	useEffect(() => {
		if (actionType === 'Update') {
			form.setFieldsValue({
				...props.values,
			})
		}
	}, [actionType, props.values])

	useEffect(() => {
		if (user?.role === Role.Pm) {
			form.setFieldsValue({ pmId: user.id })
		}
	}, [user])

	const isLoading = createAdvisorLoading || updateAdvisorLoading

	return (
		<Modal
			{...adminModalProps}
			className='admin-modal admin-modal--A'
			open={activeModal === MODAL.CREATE_ADVISOR || activeModal === MODAL.UPDATE_ADVISOR}
			okText={actionType}
			okButtonProps={{ className: 'ant-btn-primary-black' }}
			cancelButtonProps={{ className: 'ant-btn-default-gold' }}
			onCancel={onCloseModal}
			confirmLoading={isLoading}
			maskClosable={false}
			onOk={() => {
				if (actionType === 'Create') {
					form
						.validateFields()
						.then((values) => {
							createAdvisor({
								variables: {
									createAdvisorInput: {
										...values,
									},
								},
								refetchQueries: [CountersDocument, AdvisorsDocument],
							})
								.then(() => {
									message.success(`${values.firstName} ${values.lastName} created`)
									onCloseModal()
									form.resetFields()
								})
								.catch(printError)
						})
						.catch((info) => {
							console.warn('Create Advisor error fields:', info)
						})
				}

				if (actionType === 'Update') {
					form
						.validateFields()
						.then((values) => {
							updateAdvisor({
								variables: {
									updateAdvisorInput: {
										id: props.values?.id as number,
										...values,
									},
								},
								refetchQueries: [ClientTableInfoDocument, AdvisorsDocument],
							})
								.then(() => {
									message.success(`${values.firstName} ${values.lastName} updated`)
									onCloseModal()
									form.resetFields()
								})
								.catch(printError)
						})
						.catch((info) => {
							console.warn('Update Advisor error fields:', info)
						})
				}
			}}>
			<h2 className='text-center'>{actionType} Advisor</h2>

			<Spin spinning={isLoading}>
				<Form
					form={form}
					size='large'
					layout='vertical'
					validateTrigger='onBlur'
					autoComplete='off'
					initialValues={{ formId: null }}
					requiredMark={false}>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='First Name'
								name={setKey('firstName')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Last Name'
								name={setKey('lastName')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Email'
								name={setKey('email')}
								normalize={(val?: string) => val?.toLowerCase()}
								rules={[rules.required, rules.email]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Phone'
								name={setKey('phone')}
								rules={[rules.required, rules.phone]}>
								<Input />
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Address'
								name={setKey('address')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input maxLength={50} />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Province'
								name={setKey('stateId')}
								rules={[rules.required]}>
								<Select
									showSearch
									options={canadaStatesOpts}
									loading={canadaStatesRes.loading}
									filterOption={(input, option) => {
										return (option?.label as string).toLowerCase().includes(input.toLowerCase())
									}}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='City'
								name={setKey('city')}
								rules={[rules.required, rules.minmax(2, 50)]}>
								<Input maxLength={50} />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Postal Code'
								normalize={(val?: string) => val?.toUpperCase()}
								name={setKey('postalCode')}
								rules={[rules.required, rules.postalCA]}>
								<Input maxLength={7} />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Country'
								name={setKey('countryId')}
								rules={[rules.required]}>
								<Select
									options={countriesOpts?.filter((country) => country.value === 1)}
									loading={countriesRes.loading}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Password'
								name={setKey('password')}
								rules={[rules.requiredWhen(actionType === 'Create'), rules.minmax(8, 20)]}>
								<Input.Password autoComplete='new-password' />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}
							hidden={user?.role && [Role.Pm, Role.Advisor].includes(user?.role)}>
							<Form.Item
								label='Portfolio Manager'
								name={setKey('pmId')}
								rules={[rules.required]}>
								<Select
									showSearch
									filterOption={(input, option) => {
										return (option?.label as string).toLowerCase().includes(input.toLowerCase())
									}}
									options={pmsOpts}
									loading={pmsRes.loading}
								/>
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='RR Code'
								normalize={(val?: string) => val?.toUpperCase()}
								name={setKey('rrCode')}
								rules={[rules.required, rules.rrCode]}>
								<Input maxLength={4} />
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label={
									<div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
										<span>IMA Form Type</span>
										<IconEye
											onClick={onPreviewIMA}
											style={{ fontSize: 16 }}
										/>
									</div>
								}
								style={{ flex: 1, maxWidth: '100%' }}
								name={setKey('formId')}
								rules={[]}>
								<Select
									showSearch
									options={advisorFormsOpts}
									loading={advisorFormsRes.loading}
									filterOption={(input, option) => {
										return (option?.label as string).toLowerCase().includes(input.toLowerCase())
									}}
								/>
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Enable Non-Solicit Form'
								name={setKey('isNonSolicitFormActive')}
								valuePropName='checked'
								rules={[]}>
								<Switch />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>
		</Modal>
	)
}

export { ModalCreateUpdateAdvisor }
