import { CSSProperties, FC, ReactNode } from 'react'
import { Col, Form, Row } from 'antd'
import { ClientKeyType, OnboardingChildProps } from 'types/client-onboarding'
import { AccountTypeJt, JtwrosOrTicType } from 'graphql/generated/schema'
import { getDateString } from './mapping'
import { isMarriedOrCommonLaw } from './ClientInformation'

interface Props {
	form: OnboardingChildProps['form']
}

const joinValues = (values: (string | undefined)[]) => values.filter(Boolean).join(' ').trim()

const twoColStyle: CSSProperties = {
	display: 'grid',
	gridTemplateColumns: 'max-content max-content',
	gap: '0 50px',
}

const resetListStyle: CSSProperties = {
	listStyle: 'none',
	paddingLeft: 10,
}

const ViewSummary: FC<Props> = (props) => {
	const formValues = Form.useWatch([], props.form) ?? {}
	const { clientCount = 0 } = formValues
	const { existingAccounts: { entityAccounts = {} } = {} } = formValues

	const individualClientJSX = (clientKey: ClientKeyType, clientNumber: 1 | 2) => {
		const { clientInfo, individualAccounts } = formValues?.[clientKey] ?? {}

		const existingAccounts = formValues.existingAccounts?.[clientKey] ?? {}
		const clientData = {
			clientInfo: {
				'Full Name': joinValues([clientInfo?.firstName, clientInfo?.lastName]),
				Email: clientInfo?.email,
				'Date of Birth': getDateString(clientInfo?.dateOfBirth),
				SIN: clientInfo?.socialInsuranceNumber,
				'Number of New Accounts': clientInfo?.accountsNum,
			},
			spouseInfo: {
				...(clientCount > 1 && clientNumber === 1 && { Relationship: clientInfo?.relationshipType }),
				'Spouse First Name': clientInfo?.spouseFirstName,
				'Spouse Last Name': clientInfo?.spouseLastName,
				'Spouse Date of Birth': getDateString(clientInfo?.spouseDateOfBirth),
				'Spouse SIN': clientInfo?.spouseSocialInsuranceNumber,
			},
			individualAccounts: individualAccounts?.map((acc = {}) => ({
				'Account Type': acc.accountType,
				'Account Currency': acc.accountCurrency,
				...(acc.isSuccessor && { Successor: acc.isSuccessor ? 'Yes' : 'No' }),
				...(acc.isBeneficiary && { 'Number of Beneficiary': acc.beneficiaryNum }),
				...(acc.isBeneficiary && {
					Beneficiaries: acc.beneficiary?.map((bene) => joinValues([bene?.firstName, bene?.lastName])).join(', '),
				}),
				...(Number(acc.transferNum) >= 1 && { 'Transfer 1: Institution Name': acc.transfers?.[0]?.institutionName }),
				...(Number(acc.transferNum) >= 2 && { 'Transfer 2: Institution Name': acc.transfers?.[1]?.institutionName }),
				...(acc.isPacSwp && { 'PAC/SWP Information': acc.pacSwp?.type }),
				...(acc.riffPayments?.age && { 'RIFF Payments': acc.riffPayments?.age ? 'Included' : 'Not Included' }),
			})),
			existingAccounts: existingAccounts?.accounts?.map((acc) => ({
				'Account Type': acc?.type,
				...(acc?.name && { 'Asset Name': acc?.name }),
				'Asset Value': acc?.value,
			})),
		}

		return (
			<SectionBlock title={`Client ${clientNumber}`}>
				<SectionSubBlock title='Client Information:'>
					<div style={{ ...twoColStyle }}>
						<ul style={{ ...resetListStyle }}>
							{Object.entries(clientData.clientInfo).map(([key, value]) => (
								<li key={key}>
									<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{key}</span>: {value ? String(value) : '-'}
								</li>
							))}
						</ul>
						{isMarriedOrCommonLaw(clientInfo?.maritalStatus) && (
							<ul style={{ ...resetListStyle }}>
								{Object.entries(clientData.spouseInfo).map(([key, value]) => (
									<li key={key}>
										<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{key}</span>: {value ? String(value) : '-'}
									</li>
								))}
							</ul>
						)}
					</div>
				</SectionSubBlock>

				{Number(clientInfo?.accountsNum) > 0 && (
					<SectionSubBlock title='Individual Accounts:'>
						<Row
							gutter={[10, 10]}
							align={'stretch'}>
							{clientData?.individualAccounts?.map((acc, accIndex) => (
								<Col
									key={accIndex}
									xs={24}
									xl={8}>
									<AccountCard
										title={`Account ${accIndex + 1}`}
										items={acc}
									/>
								</Col>
							))}
						</Row>
					</SectionSubBlock>
				)}

				{existingAccounts.accountsNum && (
					<SectionSubBlock title='BIM Existing and Non-ROBIN:'>
						<Row
							gutter={[10, 10]}
							align={'stretch'}>
							{clientData?.existingAccounts?.map((acc, accIndex) => (
								<Col
									key={accIndex}
									xs={24}
									xl={8}>
									<AccountCard
										title={`Account ${accIndex + 1}`}
										items={acc}
									/>
								</Col>
							))}
						</Row>
					</SectionSubBlock>
				)}
			</SectionBlock>
		)
	}

	const jointAccountsJSX = () => {
		const { jointAccounts, jointAccountsNum, existingAccounts } = formValues

		if (!jointAccountsNum) {
			return null
		}

		const jointAccountsData = {
			accountsInfo: {
				'Number of Joint-Accounts': jointAccountsNum,
			},
			jointAccounts: jointAccounts?.map((acc = {}) => ({
				'Account Type': acc.accountType,
				'Account Currency': acc.accountCurrency,
				'Account Ownership Structure': acc.ownershipStructure,
				...(acc.ownershipStructure === JtwrosOrTicType.Jtic && {
					'TIC Percentage': acc.ticPercentage,
				}),
				...(acc.accountType === AccountTypeJt.JointItf && {
					Beneficiaries: acc.beneficiary?.map((bene) => joinValues([bene?.firstName, bene?.lastName])).join(', '),
				}),
				...(Number(acc.transferNum) >= 1 && { 'Transfer 1: Institution Name': acc.transfers?.[0]?.institutionName }),
				...(Number(acc.transferNum) >= 2 && { 'Transfer 2: Institution Name': acc.transfers?.[1]?.institutionName }),
				...(acc.isPacSwp && { 'PAC/SWP Information': acc.pacSwp?.type }),
			})),
			existingAccounts: existingAccounts?.jointAccounts?.accounts?.map((acc = {}) => ({
				'Account Type': acc.type,
				'Asset Value': acc.value,
			})),
		}

		return (
			<SectionBlock title='Joint-Accounts'>
				<ul
					style={{ ...twoColStyle, ...resetListStyle }}
					className='ant-group-col2'>
					{Object.entries(jointAccountsData.accountsInfo).map(([key, value], idx) => (
						<li key={idx}>
							<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{key}</span>: {value ? String(value) : '-'}
						</li>
					))}
				</ul>

				{jointAccountsNum && (
					<SectionSubBlock title='Joint-Accounts:'>
						<Row
							gutter={[10, 10]}
							align={'stretch'}>
							{jointAccountsData?.jointAccounts?.map((acc, accIndex) => (
								<Col
									key={accIndex}
									xs={24}
									xl={8}>
									<AccountCard
										title={`Account ${accIndex + 1}`}
										items={acc}
									/>
								</Col>
							))}
						</Row>
					</SectionSubBlock>
				)}

				{existingAccounts?.jointAccounts?.accountsNum && (
					<SectionSubBlock title='BIM Existing and Non-ROBIN:'>
						<Row
							gutter={[10, 10]}
							align={'stretch'}>
							{existingAccounts?.jointAccounts?.accounts?.map((acc, accIndex) => (
								<Col
									key={accIndex}
									xs={24}
									xl={8}>
									<AccountCard
										title={`Account ${accIndex + 1}`}
										items={acc}
									/>
								</Col>
							))}
						</Row>
					</SectionSubBlock>
				)}
			</SectionBlock>
		)
	}

	const entityAccountsJSX = () => {
		const { existingAccounts: { entityAccounts = {} } = {} } = formValues

		const { accountsNum, accounts } = entityAccounts

		if (!accountsNum) {
			return null
		}

		const entityAccountsData = {
			accounts: accounts?.map((acc) => ({
				'Account Type': acc?.type,
				'Asset Name': acc?.name,
				'Asset Value': acc?.value,
			})),
		}

		return (
			<SectionBlock title='Entity-Accounts'>
				{accountsNum && (
					<SectionSubBlock title=''>
						<Row
							gutter={[10, 10]}
							align={'stretch'}>
							{entityAccountsData.accounts?.map((acc, accIndex) => (
								<Col
									key={accIndex}
									xs={24}
									xl={8}>
									<AccountCard
										title={`Account ${accIndex + 1}`}
										items={acc}
									/>
								</Col>
							))}
						</Row>
					</SectionSubBlock>
				)}
			</SectionBlock>
		)
	}

	return (
		<div>
			{clientCount >= 1 && individualClientJSX('clientOne', 1)}
			{clientCount >= 2 && individualClientJSX('clientTwo', 2)}
			{clientCount >= 2 && jointAccountsJSX()}
			{entityAccounts?.accountsNum && entityAccountsJSX()}
		</div>
	)
}

const SectionBlock: FC<{ title: string; children: ReactNode }> = (props) => {
	return (
		<div style={{ marginLeft: 10, marginBottom: 30 }}>
			<h3 style={{ marginBottom: 10 }}>{props.title}</h3>
			{props.children}
		</div>
	)
}

const SectionSubBlock: FC<{ title: string; children: ReactNode }> = (props) => {
	return (
		<div style={{ marginLeft: 10, marginBottom: 20 }}>
			<h4 style={{ marginBottom: 10 }}>{props.title}</h4>
			{props.children}
		</div>
	)
}

const AccountCard: FC<{ title: string; items: Record<string, unknown> | undefined }> = (props) => {
	const { items = {} } = props
	return (
		<div
			style={{
				height: '100%',
				background: '#fafafa',
				fontSize: 12,
				padding: 10,
			}}>
			<h5 style={{ color: '#A09575', marginBottom: 5 }}>{props.title}</h5>
			<ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
				{Object.entries(items).map(([key, value]) => (
					<li key={key}>
						<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{key}</span>: {value ? String(value) : '-'}
					</li>
				))}
			</ul>
		</div>
	)
}

export default ViewSummary
