import { FC } from 'react'
import { Col, Row } from 'antd'
import { ReactComponent as LogoSVG } from '../assets/images/logo.svg'
import { ReactComponent as NotFoundSVG } from '../../src/assets/images/404.svg'
import { Link } from 'react-router-dom'

const NotFoundPage: FC = (props) => {
	return (
		<div className='admin'>
			<div className='admin__inner'>
				<header className='admin__header'>
					<Row
						justify='space-between'
						align='middle'>
						<Col span={4}>
							<Link to={'/admin/dashboard'}>
								<LogoSVG className='admin__logo' />
							</Link>
						</Col>
					</Row>
				</header>
				<main className='admin__main'>
					<div className='notfound-page'>
						<NotFoundSVG />
						<h1>404</h1>
						<div>Page not found</div>
					</div>
				</main>
				<footer className='admin__footer'>
					<span className='admin__footer-cp'>Copyright © {new Date().getFullYear()} Produced by Bellwether</span>
				</footer>
			</div>
		</div>
	)
}

export default NotFoundPage
