import { FC } from 'react'
import { Col, Form, InputNumber, Row, Select, Tabs } from 'antd'
import { formItemProps, rules } from 'constants/props'
import BeneficiaryFields from './BeneficiaryFields'
import TransferFields from './TransferFields'
import PacSwpFields from './PacSwpFields'
import type { Paths } from 'types/ips'
import { JointAccountInfoType, OnboardingChildProps, OnboardingFormValues, CurrencyType } from 'types/client-onboarding'
import { AccountTypeJt, JtwrosOrTicType } from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'
// import { CheckCircleOutlined as IconCheck } from '@ant-design/icons'

interface Props {
	jointAccounts: OnboardingFormValues['jointAccounts']
	jointAccountsNum: OnboardingFormValues['jointAccountsNum']
}

const JointAccounts: FC<OnboardingChildProps & Props> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')

	const { jointAccountsNum, jointAccounts } = props

	if (!jointAccountsNum) {
		return null
	}

	const accountFieldsJSX = [...Array(jointAccountsNum)].map((_, accIndex) => {
		const currentAccount = jointAccounts?.[accIndex] ?? {}

		const fieldPath = (keys: Paths<JointAccountInfoType>): Paths<OnboardingFormValues> => [
			'jointAccounts',
			accIndex,
			...keys,
		]

		return (
			<Row
				gutter={[20, 0]}
				style={{ marginBottom: 30 }}
				align={'bottom'}
				key={accIndex}>
				<Col xs={24}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: 35,
							marginTop: 5,
						}}>
						<h4 style={{ color: '#A09575', margin: 0 }}>Account {accIndex + 1}</h4>
						{/* <IconCheck style={{ color: '#A09575', fontSize: 20 }} /> */}
					</div>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Account Type'
						name={fieldPath(['accountType'])}
						rules={[rules.requiredWhen(isRequired)]}>
						<Select
							allowClear
							listHeight={300}
							options={[
								{ label: 'Joint', value: AccountTypeJt.Joint },
								{ label: 'Joint ITF', value: AccountTypeJt.JointItf },
							]}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Account Currency'
						name={fieldPath(['accountCurrency'])}
						rules={[rules.requiredWhen(isRequired)]}>
						<Select
							allowClear
							options={[
								{ label: 'CAD', value: CurrencyType.Cad },
								{ label: 'CAD & USD', value: CurrencyType.CadUsd },
							]}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Account Ownership Structure'
						name={fieldPath(['ownershipStructure'])}
						rules={[rules.requiredWhen(isRequired)]}>
						<Select
							allowClear
							options={[
								{ label: 'TIC', value: JtwrosOrTicType.Jtic },
								{ label: 'JTWROS', value: JtwrosOrTicType.Jtwros },
							]}
						/>
					</Form.Item>
				</Col>

				{currentAccount.ownershipStructure === JtwrosOrTicType.Jtic && (
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							label='TIC Percentage'
							// normalize={(value) => Number(value) || undefined}
							name={fieldPath(['ticPercentage'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<InputNumber
								type='number'
								min={0}
								max={100}
								style={{ width: `${100}%` }}
							/>
						</Form.Item>
					</Col>
				)}

				{/* Beneficiary */}
				{currentAccount.accountType === AccountTypeJt.JointItf && (
					<Col
						xs={24}
						style={{ marginTop: 20 }}>
						<Row
							gutter={[20, 0]}
							style={{ marginBottom: 1 }}>
							<Col xs={24}>
								<h4>Beneficiary Information</h4>
							</Col>

							<BeneficiaryFields
								{...props}
								beneficiaryInfo={currentAccount.beneficiary}
								accountType={currentAccount.accountType}
								beneficiaryNum={1}
								fieldPath={fieldPath}
							/>
						</Row>
					</Col>
				)}

				{/* Transfer */}
				<TransferFields
					{...props}
					accountCurrency={currentAccount.accountCurrency}
					isTransfer={currentAccount.isTransfer}
					transferNum={currentAccount.transferNum}
					fieldPath={fieldPath}
				/>

				{/* PAC/SWP */}
				{currentAccount.accountType &&
					[AccountTypeJt.Joint, AccountTypeJt.JointItf].includes(currentAccount.accountType) && (
						<PacSwpFields
							{...props}
							isPacSwp={currentAccount.isPacSwp}
							pacSwp={currentAccount.pacSwp}
							accountType={currentAccount.accountType}
							fieldPath={fieldPath}
							isJointAccount={true}
						/>
					)}
			</Row>
		)
	})

	return (
		<Row>
			<Col xl={12}>
				<h3>Joint-Accounts</h3>
				<Tabs
					tabPosition='left'
					className='ant-tabs-bar-sticky'
					items={accountFieldsJSX.map((jsx, idx) => ({
						key: String(idx),
						label: idx + 1,
						children: jsx,
						forceRender: true,
					}))}
				/>
			</Col>
		</Row>
	)
}

export default JointAccounts
