import { ApolloError } from '@apollo/client'
import { message, notification } from 'antd'

export const printError = (error: any): string[] => {
	console.dir(error)

	const errorMessages: string[] = []

	// Check if the error is an instance of ApolloError
	if (error instanceof ApolloError) {
		error.graphQLErrors.forEach((graphQLError) => {
			if (graphQLError.extensions && graphQLError.extensions.originalError) {
				const originalError = graphQLError.extensions.originalError as Record<string, any>

				const { message } = originalError

				if (Array.isArray(message)) {
					message.forEach((msg) => {
						if (Array.isArray(msg.messages)) {
							errorMessages.push(...msg.messages)
						} else {
							errorMessages.push(msg)
						}
					})
				} else if (typeof message === 'string') {
					errorMessages.push(message)
				}
			}
		})

		if (error.networkError instanceof Error && 'result' in error.networkError) {
			const errorResult = error.networkError.result
			if (typeof errorResult === 'object' && Array.isArray(errorResult.errors)) {
				const messages = errorResult.errors.map((error) => error.message)
				errorMessages.push(...messages)
			}
		}
	}

	if (!errorMessages.length && error.message) {
		const message = error.message.replace('Failed to fetch', 'Server error. Please try again later')
		errorMessages.push(message)
	}

	if (!errorMessages.length) {
		errorMessages.push('Unexpected error')
	}

	if (errorMessages.length > 1) {
		const errorsJSX = errorMessages.map((error, idx) => (
			<div
				key={idx}
				style={{
					background: 'rgba(255, 125, 125, 0.1)',
					marginBottom: 5,
					fontSize: 13,
					lineHeight: 1.2,
					padding: 5,
					borderRadius: 5,
				}}>
				{error}
			</div>
		))
		notification.error({
			message: errorsJSX,
			duration: 0,
			placement: 'top',
			style: {
				width: 500,
				overflow: 'auto',
			},
		})
	} else {
		message.error(errorMessages[0])
	}

	return errorMessages
}
