import { FC } from 'react'
import { IndividualAccountInfoType, OnboardingChildProps, OnboardingFormValues } from 'types/client-onboarding'
import { Col, Form, InputNumber, Select } from 'antd'
import { formItemProps, rules } from 'constants/props'
import { DeepPartial, Paths } from 'types/ips'
import { BwDatePicker } from 'components/start/BwDatePicker'
import moment from 'moment'
import {
	AccPaymentByType,
	AccPaymentFrequency,
	AccPaymentType,
	AccRrifAgeType,
	AccWtOnType,
	ConfirmationType,
} from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'

type PathType = IndividualAccountInfoType
interface Props {
	fieldPath(keys: Paths<PathType>): Paths<OnboardingFormValues>
	riffPayments?: DeepPartial<IndividualAccountInfoType['riffPayments']>
}

const RrifPaymentsFields: FC<OnboardingChildProps & Props> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')
	const { fieldPath, riffPayments } = props

	return (
		<>
			<Col xs={24}>
				<h4>RIFF Payments</h4>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Age'
					name={fieldPath(['riffPayments', 'age'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'Annuitant', value: AccRrifAgeType.Annuitant },
							{ label: 'Spouse', value: AccRrifAgeType.Spouse },
						]}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Type'
					name={fieldPath(['riffPayments', 'paymentType'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'Min', value: AccPaymentType.Min },
							{ label: 'Max', value: AccPaymentType.Max },
							{ label: 'Gross', value: AccPaymentType.Gross },
							{ label: 'Net', value: AccPaymentType.Net },
						]}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Frequency'
					name={fieldPath(['riffPayments', 'paymentFrequency'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'Monthly', value: AccPaymentFrequency.Monthly },
							{ label: 'Quarterly', value: AccPaymentFrequency.Quarterly },
							{ label: 'Semi Annually', value: AccPaymentFrequency.SemiAnnually },
							{ label: 'Annually', value: AccPaymentFrequency.Annually },
						]}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Start Date'
					name={fieldPath(['riffPayments', 'paymentStartDate'])}
					rules={[rules.requiredWhen(isRequired), rules.futureDate]}>
					<BwDatePicker disabledDate={(inputDate) => inputDate && inputDate < moment().endOf('day')} />
				</Form.Item>
			</Col>
			{riffPayments?.paymentType && [AccPaymentType.Gross, AccPaymentType.Net].includes(riffPayments?.paymentType) && (
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='RIFF Payment Amount'
						name={fieldPath(['riffPayments', 'paymentAmount'])}
						rules={[rules.requiredWhen(isRequired)]}>
						<InputNumber
							type='number'
							min={0}
							max={100_000_000}
							style={{ width: `${100}%` }}
						/>
					</Form.Item>
				</Col>
			)}

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Blended'
					name={fieldPath(['riffPayments', 'paymentBlended'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'Yes', value: ConfirmationType.Yes },
							{ label: 'No', value: ConfirmationType.No },
						]}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Withholding Tax'
					name={fieldPath(['riffPayments', 'paymentWithholdingTax'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'Full', value: AccWtOnType.Full },
							{ label: 'Excess', value: AccWtOnType.Excess },
						]}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Withholding Tax %'
					name={fieldPath(['riffPayments', 'paymentWithholdingTaxPercent'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<InputNumber
						type='number'
						min={0}
						max={99}
						style={{ width: `${100}%` }}
					/>
				</Form.Item>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='RIFF Payment Destination'
					name={fieldPath(['riffPayments', 'paymentDestination'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						options={[
							{ label: 'EFT', value: AccPaymentByType.Eft },
							{ label: 'Non-reg', value: AccPaymentByType.NonReg },
						]}
					/>
				</Form.Item>
			</Col>
		</>
	)
}

export default RrifPaymentsFields
