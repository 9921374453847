import type { AssetMixData } from 'types/ips'

export const DATE_FORMAT = 'YYYY-MM-DD'

export const ASSET_MIX_DATA_MAP: AssetMixData = {
	'Fixed Income': [
		{ range: '0-20', benchmark: '10' },
		{ range: '80-98', benchmark: '90' },
		{ range: '0-0', benchmark: '0' },
		{ range: '', benchmark: '0' },
		{ range: '', benchmark: '0' },
	],
	Conservative: [
		{ range: '0-20', benchmark: '5' },
		{ range: '65-85', benchmark: '75' },
		{ range: '10-30', benchmark: '20' },
		{ range: '', benchmark: '10' },
		{ range: '', benchmark: '10' },
	],
	'Conservative Growth': [
		{ range: '0-20', benchmark: '5' },
		{ range: '45-75', benchmark: '60' },
		{ range: '20-50', benchmark: '35' },
		{ range: '', benchmark: '17.5' },
		{ range: '', benchmark: '17.5' },
	],
	Balanced: [
		{ range: '0-20', benchmark: '5' },
		{ range: '30-60', benchmark: '45' },
		{ range: '35-65', benchmark: '50' },
		{ range: '', benchmark: '25' },
		{ range: '', benchmark: '25' },
	],
	'Moderate Growth': [
		{ range: '0-20', benchmark: '5' },
		{ range: '10-50', benchmark: '30' },
		{ range: '45-85', benchmark: '65' },
		{ range: '', benchmark: '32.5' },
		{ range: '', benchmark: '32.5' },
	],
	'Aggressive Growth': [
		{ range: '0-20', benchmark: '5' },
		{ range: '0-25', benchmark: '10' },
		{ range: '70-100', benchmark: '85' },
		{ range: '', benchmark: '42.5' },
		{ range: '', benchmark: '42.5' },
	],
	'All Equity': [
		{ range: '0-20', benchmark: '5' },
		{ range: '0-0', benchmark: '0' },
		{ range: '90-100', benchmark: '95' },
		{ range: '', benchmark: '47.5' },
		{ range: '', benchmark: '47.5' },
	],
}

export const INACTIVE_IPS_MESSAGE =
	'Circumstance not currently supported by this online system, please reachout to your advisor to complete your application'

export const INACTIVE_IPS_INVESTMENT_OBJECTIVE =
	'The investment objective is not in line with the Bellwether Investment approach. Kindly avoid entering a value higher than 3 in the designated box'

export const streetTypesCanada = [
	'ABBEY',
	'ACCESS',
	'ACRES',
	'AIRE',
	'ALLEY',
	'ALLÉE',
	'AUT',
	'AV',
	'AVE',
	'BAY',
	'BEACH',
	'BEND',
	'BLOC',
	'BLOCK',
	'BLVD',
	'BOUL',
	'BOURG',
	'BRGE',
	'BROOK',
	'BYPASS',
	'BYWAY',
	'C',
	'CAMPUS',
	'CAPE',
	'CAR',
	'CARREF',
	'CDS',
	'CERCLE',
	'CH',
	'CHASE',
	'CIR',
	'CIRCT',
	'CLOSE',
	'COMMON',
	'CONC',
	'CÔTE',
	'COUR',
	'COURS',
	'COVE',
	'CRES',
	'CREST',
	'CRNRS',
	'CROFT',
	'CROIS',
	'CROSS',
	'CRSSRD',
	'CRT',
	'CTR',
	'DALE',
	'DELL',
	'DESSTE',
	'DIVERS',
	'DOWNS',
	'DR',
	'DRPASS',
	'ÉCH',
	'END',
	'ESPL',
	'ESTATE',
	'EXPY',
	'EXTEN',
	'FARM',
	'FIELD',
	'FOREST',
	'FRONT',
	'FWY',
	'GATE',
	'GDNS',
	'GLADE',
	'GLEN',
	'GREEN',
	'GRNDS',
	'GROVE',
	'HARBR',
	'HAVEN',
	'HEATH',
	'HGHLDS',
	'HILL',
	'HOLLOW',
	'HTS',
	'HWY',
	'ÎLE',
	'IMP',
	'INLET',
	'ISLAND',
	'KEY',
	'KNOLL',
	'LANDNG',
	'LANE',
	'LANEWY',
	'LINE',
	'LINK',
	'LKOUT',
	'LMTS',
	'LOOP',
	'MALL',
	'MANOR',
	'MAZE',
	'MEADOW',
	'MEWS',
	'MONTÉE',
	'MOOR',
	'MOUNT',
	'MTN',
	'ORCH',
	'PARADE',
	'PARC',
	'PASS',
	'PATH',
	'PEAK',
	'PINES',
	'PK',
	'PKY',
	'PL',
	'PLACE',
	'PLAT',
	'PLAZA',
	'POINTE',
	'PORT',
	'PROM',
	'PT',
	'PTWAY',
	'PVT',
	'QUAI',
	'QUAY',
	'RAMP',
	'RANG',
	'RD',
	'RDPT',
	'REACH',
	'RG',
	'RIDGE',
	'RISE',
	'RLE',
	'ROUTE',
	'ROW',
	'RTE',
	'RTOFWY',
	'RUE',
	'RUIS',
	'RUN',
	'SECTN',
	'SENT',
	'SIDERD',
	'SQ',
	'ST',
	'STROLL',
	'SUBDIV',
	'TERR',
	'THICK',
	'TLINE',
	'TOWERS',
	'TRACE',
	'TRAIL',
	'TRNABT',
	'TRUNK',
	'TSSE',
	'VALE',
	'VIA',
	'VIEW',
	'VILLAS',
	'VILLGE',
	'VISTA',
	'VOIE',
	'WALK',
	'WAY',
	'WHARF',
	'WOOD',
	'WYND',
]
