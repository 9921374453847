import { Dispatch, FC, SetStateAction } from 'react'
import { message, Upload } from 'antd'
import { InboxOutlined as IconInbox, LoadingOutlined as IconLoading } from '@ant-design/icons'
import type { UploadFile as UploadFileType } from 'antd/lib/upload/interface'

interface Props {
	setFileList: Dispatch<SetStateAction<UploadFileType[]>>
	fileList: UploadFileType[]
	loading: boolean
}

const ALLOWED_TYPES = [
	'text/csv',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const UploadFile: FC<Props> = (props) => {
	return (
		<>
			<div style={{ marginBottom: 5 }}>Add your excel file with client base</div>
			<Upload.Dragger
				name='file'
				multiple={false}
				maxCount={1}
				listType='picture'
				fileList={props.fileList}
				disabled={props.loading}
				customRequest={() => {}}
				beforeUpload={(file) => {
					const isAllowedType = ALLOWED_TYPES.includes(file.type)
					const isAllowedSize = file.size / 1024 / 1024 < 10

					if (!isAllowedType) {
						message.error('Allowed only .xlsx, .xls, .csv file formats')
						return Upload.LIST_IGNORE
					}

					if (!isAllowedSize) {
						message.error('File must be smaller than 10MB!')
						return Upload.LIST_IGNORE
					}
				}}
				accept={ALLOWED_TYPES.join()}
				onChange={(info) => {
					if (info.file.status === 'removed') {
						props.setFileList([])
					} else if (info.file.status === 'uploading') {
						props.setFileList([{ ...info.file, status: 'success' }])
					}
				}}>
				<p className='ant-upload-drag-icon'>{props.loading ? <IconLoading /> : <IconInbox />}</p>
				<p className='ant-upload-text'>Click or drag file to this area to upload</p>
			</Upload.Dragger>
		</>
	)
}

export default UploadFile
