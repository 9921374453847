import { FC, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Form, Row, Col, Input, Select, Radio, Typography, InputNumber } from 'antd'
import { formItemProps, rules } from 'constants/props'
import {
	CountriesDocument,
	CanadaStatesDocument,
	RelationshipType,
	ClientRelationType,
	ClientGender,
} from 'graphql/generated/schema'
import { IClientAccountsFields, IClientFields, IDataIPS, IPropsSteps } from 'types/ips'
import { getInactiveIPS } from 'helpers/ips'
import { BwDatePicker } from 'components/start/BwDatePicker'
import ClientChilds from '../ClientChilds'
import { streetTypesCanada } from 'constants/ips'
import { ClientTitle } from 'components/admin/onboarding/ClientInformation'

const isMarriedOrCommonLaw = (maritalStatus?: string) => {
	return Boolean(maritalStatus && ['Married', 'Common law'].includes(maritalStatus))
}

enum AddressType {
	Civic = 'Civic',
	Rural = 'Rural',
	International = 'International',
}

enum DirectionType {
	N = 'N',
	E = 'E',
	S = 'S',
	W = 'W',
	O = 'O',
	NE = 'NE',
	SE = 'SE',
	NW = 'NW',
	SW = 'SW',
}

enum UnitType {
	APT = 'APT',
	UNIT = 'UNIT',
	SUITE = 'SUITE',
	PH = 'PH',
}

enum EmploymentStatus {
	EMPLOYED = 'Employed',
	SELF_EMPLOYED = 'Self-Employed',
	UNEMPLOYED = 'Unemployed',
	RETIRED = 'Retired',
	STUDENT = 'Student',
}

interface Props {
	clientKey: 'clientOne' | 'clientTwo'
	form: IPropsSteps['form']
	inactiveFields: IPropsSteps['inactiveFields']
	stateIPS: IPropsSteps['stateIPS']
}

const NON_EMPLOYMENT = ['Unemployed', 'Retired']

const ClientInfo: FC<Props> = (props) => {
	const keyPath = (key: keyof IClientFields): [keyof IDataIPS, Props['clientKey'], keyof IClientFields] => [
		'clientInfo',
		props.clientKey,
		key,
	]

	const isMailingAddressSameLegal = Form.useWatch(
		keyPath('isMailingAddressSameLegal'),
		props.form,
	) as IClientFields['isMailingAddressSameLegal']

	const isLegalAddressSameClientOne = Form.useWatch(
		keyPath('isLegalAddressSameClientOne'),
		props.form,
	) as IClientFields['isLegalAddressSameClientOne']

	const employmentStatus = Form.useWatch(keyPath('employmentStatus'), props.form) as IClientFields['employmentStatus']
	const spouseEmploymentStatus = Form.useWatch(
		keyPath('spouseEmploymentStatus'),
		props.form,
	) as IClientFields['spouseEmploymentStatus']

	const maritalStatus = Form.useWatch(keyPath('maritalStatus'), props.form)
	const maritalStatusClientOne = Form.useWatch(['clientInfo', 'clientOne', 'maritalStatus'])
	const relationshipType = Form.useWatch(keyPath('relationshipType'), props.form) as IClientFields['relationshipType']
	const relationshipTypeClientOne = Form.useWatch(['clientInfo', 'clientOne', 'relationshipType'], props.form)

	const childNum = Form.useWatch(keyPath('childNum'), props.form)

	const clientsAccounts: IClientAccountsFields[] = Form.useWatch(['clientAccounts'])

	const { isSecondaryClient } = props.stateIPS

	const countriesRes = useQuery(CountriesDocument)

	const countriesOpts = countriesRes.data?.countries?.map((country) => ({
		label: country.name,
		value: country.id,
	}))

	const canadaStatesRes = useQuery(CanadaStatesDocument)
	const canadaStatesOpts = canadaStatesRes.data?.states?.map((state) => ({
		label: state.name,
		value: state.id,
	}))

	const getInactive = (field: keyof IClientFields) => {
		const isInactive = Boolean(props.inactiveFields.clientInfo[props.clientKey][field])
		return getInactiveIPS(isInactive)
	}

	const getEmploymentLabel = (status: IClientFields['employmentStatus']) => {
		if (status === 'Student') {
			return {
				employer: 'School',
				address: 'School Address',
			}
		}

		return {
			employer: 'Employer',
			address: 'Employer Address',
		}
	}

	const isSameAddressClient2 = Boolean(props.clientKey === 'clientTwo' && isLegalAddressSameClientOne)

	const isSpouseClient2 =
		isMarriedOrCommonLaw(maritalStatusClientOne) && relationshipTypeClientOne === RelationshipType.Spouse

	const isNeedSpouseInfo = () => {
		if (isSpouseClient2) {
			return false
		}

		if (isMarriedOrCommonLaw(maritalStatus)) {
			return true
		}

		const clientTypeMap: Record<Props['clientKey'], IClientAccountsFields['clientType']> = {
			clientOne: 'Client 1',
			clientTwo: 'Client 2',
		}

		const currentClientAccounts = clientsAccounts?.filter((acc) => acc.clientType === clientTypeMap[props.clientKey])

		const isSpousalAccountExists = currentClientAccounts?.some((acc) =>
			// 5: 'Spousal RRSP',
			// 6: 'Spousal RRIF',
			acc.accountTypes?.some((num) => num === 5 || num === 6),
		)

		if (isSpousalAccountExists) {
			return true
		}

		return false
	}

	useEffect(() => {
		// When the spouse of 'Client 1' is 'Client 2', their Marital Status should match"
		if (isSpouseClient2) {
			props.form.setFieldsValue({
				clientInfo: {
					clientTwo: {
						maritalStatus: maritalStatusClientOne,
					},
				},
			})
		}
	}, [maritalStatusClientOne, relationshipTypeClientOne])

	return (
		<>
			<div className='bw-form__block'>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Title'
							name={keyPath('title')}
							rules={[rules.required, rules.selectedFromList(Object.values(ClientTitle))]}>
							<Select
								allowClear
								options={Object.values(ClientTitle).map((value) => ({
									value,
								}))}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Gender'
							name={keyPath('gender')}
							rules={[rules.required, rules.selectedFromList(Object.values(ClientGender))]}>
							<Select
								allowClear
								options={Object.values(ClientGender).map((value) => ({
									value,
								}))}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='First Name'
							name={keyPath('firstName')}
							rules={[rules.required, rules.minmax(1, 20)]}>
							<Input />
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Last Name'
							name={keyPath('lastName')}
							rules={[rules.required, rules.minmax(1, 20)]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							label='Date of Birth'
							name={keyPath('dateOfBirth')}
							rules={[rules.required, rules.minAge(18)]}>
							<BwDatePicker />
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							label='SIN'
							name={keyPath('socialInsuranceNumber')}
							validateFirst
							rules={[rules.required, rules.sin]}>
							<Input
								placeholder='XXXXXXXXX'
								type='number'
							/>
						</Form.Item>
					</Col>
				</Row>
			</div>
			{props.clientKey === 'clientTwo' && (
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Do you have the same legal address as Client 1?'
							name={keyPath('isLegalAddressSameClientOne')}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
			)}
			{/* Do not show address fields for client 2 if their address is the same as client 1.
				We need to retrieve these fields from client 1 before saving them in the useSaveStepValues() method. 
			*/}
			{!isSameAddressClient2 && (
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Address Type'
								rules={[rules.required, rules.selectedFromList(Object.values(AddressType))]}
								name={keyPath('addressType')}>
								<Select
									options={Object.values(AddressType).map((value) => ({
										value,
									}))}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Civic # / RR #'
								name={keyPath('civic')}
								rules={[rules.required, rules.minmax(1, 8)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Street Name / RR'
								name={keyPath('streetName')}
								rules={[rules.required, rules.minmax(1, 30)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Street Type'
								name={keyPath('streetType')}
								rules={[rules.required, rules.minmax(1, 8), rules.selectedFromList(streetTypesCanada)]}>
								<Select
									allowClear
									options={streetTypesCanada.map((value) => ({ value }))}
									showSearch
									filterOption={(input, option) => {
										return (option?.value as string).toLowerCase().includes(input.toLowerCase())
									}}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Direction Type'
								name={keyPath('directionType')}
								rules={[rules.selectedFromList(Object.values(DirectionType))]}>
								<Select
									allowClear
									options={Object.values(DirectionType).map((value) => ({
										value,
									}))}
								/>
							</Form.Item>
						</Col>

						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Unit Type'
								name={keyPath('unitType')}
								rules={[rules.selectedFromList(Object.values(UnitType))]}>
								<Select
									allowClear
									options={Object.values(UnitType).map((value) => ({
										value,
									}))}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Unit # / Apartment # / Floor # '
								name={keyPath('apartment')}
								rules={[rules.minmax(1, 6)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='City'
								name={keyPath('city')}
								rules={[rules.required, rules.minmax(1, 30)]}>
								<Input />
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Province'
								name={keyPath('provinceId')}
								rules={[rules.required]}>
								<Select
									showSearch
									options={canadaStatesOpts}
									loading={canadaStatesRes.loading}
									filterOption={(input, option) => {
										return (option?.label as string).toLowerCase().includes(input.toLowerCase())
									}}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								label='Country'
								name={keyPath('countryId')}
								rules={[rules.required]}>
								<Select
									options={countriesOpts?.filter((country) => country.value === 1)}
									loading={countriesRes.loading}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Postal Code'
								normalize={(val?: string) => val?.toUpperCase()}
								name={keyPath('postalCode')}
								validateFirst
								rules={[rules.required, rules.postalCA]}>
								<Input maxLength={7} />
							</Form.Item>
						</Col>
					</Row>
				</div>
			)}
			<div className='bw-form__block'>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Do your mailing address and legal address match?'
							name={keyPath('isMailingAddressSameLegal')}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
				{isMailingAddressSameLegal === false && (
					<div className='bw-form__block'>
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Address Type'
									name={keyPath('mailingAddressType')}
									rules={[rules.required, rules.selectedFromList(Object.values(AddressType))]}>
									<Select
										options={Object.values(AddressType).map((value) => ({
											value,
										}))}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Civic # / RR #'
									name={keyPath('mailingAddressCivic')}
									rules={[rules.required, rules.minmax(1, 8)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Street Name / RR'
									name={keyPath('mailingAddressStreetName')}
									rules={[rules.required, rules.minmax(1, 30)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Street Type'
									name={keyPath('mailingAddressStreetType')}
									rules={[rules.required, rules.minmax(1, 8), rules.selectedFromList(streetTypesCanada)]}>
									<Select
										allowClear
										options={streetTypesCanada.map((value) => ({ value }))}
										showSearch
										filterOption={(input, option) => {
											return (option?.value as string).toLowerCase().includes(input.toLowerCase())
										}}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Direction Type'
									name={keyPath('mailingAddressDirectionType')}
									rules={[rules.selectedFromList(Object.values(DirectionType))]}>
									<Select
										allowClear
										options={Object.values(DirectionType).map((value) => ({
											value,
										}))}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Unit Type'
									name={keyPath('mailingAddressUnitType')}
									rules={[rules.selectedFromList(Object.values(UnitType))]}>
									<Select
										allowClear
										options={Object.values(UnitType).map((value) => ({
											value,
										}))}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Unit # / Apartment # / Floor # '
									name={keyPath('mailingAddressApartment')}
									rules={[rules.minmax(1, 6)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='City'
									name={keyPath('mailingAddressCity')}
									rules={[rules.required, rules.minmax(1, 30)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Province'
									name={keyPath('mailingAddressProvinceId')}
									rules={[rules.required]}>
									<Select
										showSearch
										options={canadaStatesOpts}
										loading={canadaStatesRes.loading}
										filterOption={(input, option) => {
											return (option?.label as string).toLowerCase().includes(input.toLowerCase())
										}}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Country'
									name={keyPath('mailingAddressCountryId')}
									rules={[rules.required]}>
									<Select
										options={countriesOpts?.filter((country) => country.value === 1)}
										loading={countriesRes.loading}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Postal Code'
									normalize={(val?: string) => val?.toUpperCase()}
									name={keyPath('mailingAddressPostalCode')}
									validateFirst
									rules={[rules.required, rules.postalCA]}>
									<Input maxLength={7} />
								</Form.Item>
							</Col>
						</Row>
					</div>
				)}
			</div>
			<div className='bw-form__block'>
				<Row
					gutter={[20, 0]}
					align='bottom'>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Mobile Phone'
							name={keyPath('mobilePhone')}
							rules={[rules.required, rules.mobilePhone]}>
							<Input />
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label={
								<>
									Home Phone <Typography.Text type='secondary'>&nbsp;(optional)</Typography.Text>
								</>
							}
							name={keyPath('homePhone')}
							rules={[rules.numbers, rules.minmax(10, 10)]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col xs={24}>
						<Form.Item
							{...formItemProps}
							label='Email'
							name={keyPath('email')}
							rules={[rules.required, rules.email]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Citizenship'
							name={keyPath('citizenshipCountryId')}
							rules={[rules.required]}
							extra={getInactive('citizenshipCountryId').message()}
							className={getInactive('citizenshipCountryId').className}>
							<Select
								showSearch
								options={countriesOpts}
								loading={countriesRes.loading}
								filterOption={(input, option) => {
									return (option?.label as string).toLowerCase().includes(input.toLowerCase())
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col xs={24}>
						<Form.Item
							{...formItemProps}
							label='Employment Status'
							name={keyPath('employmentStatus')}
							rules={[rules.required, rules.selectedFromList(Object.values(EmploymentStatus))]}>
							<Select
								showSearch
								options={Object.values(EmploymentStatus).map((value) => ({
									value,
								}))}
							/>
						</Form.Item>
					</Col>

					{employmentStatus && NON_EMPLOYMENT.includes(employmentStatus) === false && (
						<>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									{...formItemProps}
									label={getEmploymentLabel(employmentStatus).employer}
									name={keyPath('employer')}
									rules={[rules.required, rules.minmax(1, 20)]}>
									<Input />
								</Form.Item>
							</Col>
							{employmentStatus !== 'Student' && (
								<>
									<Col
										xs={24}
										xl={24}>
										<Form.Item
											{...formItemProps}
											label='Occupation'
											name={keyPath('occupation')}
											rules={[rules.required, rules.minmax(1, 20)]}>
											<Input />
										</Form.Item>
									</Col>

									<Col xs={24}>
										<Form.Item
											{...formItemProps}
											label='Type of Business'
											name={keyPath('businessType')}
											rules={[rules.required, rules.minmax(1, 20)]}>
											<Input />
										</Form.Item>
									</Col>
								</>
							)}

							<Col xs={24}>
								<Form.Item
									{...formItemProps}
									label={getEmploymentLabel(employmentStatus).address}
									name={keyPath('employerAddress')}
									rules={[rules.required, rules.minmax(1, 50) /*1-20 "Fidelity" sliced */]}>
									<Input />
								</Form.Item>
							</Col>
						</>
					)}
				</Row>

				{relationshipTypeClientOne !== RelationshipType.Spouse && (
					<>
						<Row gutter={[20, 0]}>
							<Col xs={24}>
								<Form.Item
									label={'Number of children'}
									name={keyPath('childNum')}
									rules={[rules.required]}>
									<InputNumber
										type='number'
										min={0}
										max={10}
										style={{ width: `${100}%` }}
									/>
								</Form.Item>
							</Col>
						</Row>
						<ClientChilds
							form={props.form}
							childNum={childNum}
							relation={props.clientKey === 'clientOne' ? ClientRelationType.ClientOne : ClientRelationType.ClientTwo}
						/>
					</>
				)}
			</div>
			<div className='bw-form__block'>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Marital Status'
							name={keyPath('maritalStatus')}
							rules={[rules.required]}>
							<Radio.Group
								options={[
									{ value: 'Single', label: 'Single' },
									{ value: 'Married', label: 'Married' },
									{ value: 'Widowed', label: 'Widowed' },
									{ value: 'Divorced', label: 'Divorced' },
									{ value: 'Separated', label: 'Separated' },
									{ value: 'Common law', label: 'Common law' },
								].map((option) => {
									// When the spouse of 'Client 1' is 'Client 2', their Marital Status should match"
									if (isSpouseClient2 && props.clientKey === 'clientTwo') {
										return { ...option, disabled: option.value !== maritalStatusClientOne }
									} else {
										return option
									}
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
			</div>
			<div className='bw-form__block'>
				{isSecondaryClient && props.clientKey === 'clientOne' && (
					<Row
						gutter={[20, 0]}
						align={'bottom'}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								{...formItemProps}
								label={'Relationship of Client 2 to Client 1'}
								name={keyPath('relationshipType')}
								dependencies={[keyPath('maritalStatus')]}
								rules={[rules.required, rules.selectedFromList(Object.values(RelationshipType))]}>
								<Select
									allowClear
									options={Object.values(RelationshipType).map((value) => {
										const disabled = !isMarriedOrCommonLaw(maritalStatus) && value === RelationshipType.Spouse

										return {
											value,
											disabled,
										}
									})}
								/>
							</Form.Item>
						</Col>

						{relationshipType === RelationshipType.Other && (
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									{...formItemProps}
									label='Other: Specify'
									name={keyPath('relationshipTypeOther')}
									rules={[rules.required, rules.minmax(1, 20)]}>
									<Input />
								</Form.Item>
							</Col>
						)}
					</Row>
				)}

				{isNeedSpouseInfo() && (
					<>
						<div className='bw-form__section-head'>
							<h3 className='bw-form__section-subtitle'>Spouse Information</h3>
						</div>
						<Row gutter={[20, 0]}>
							<Col xs={12}>
								<Form.Item
									{...formItemProps}
									label='First Name'
									name={keyPath('spouseFirstName')}
									rules={[rules.required, rules.minmax(1, 20)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									{...formItemProps}
									label='Last Name'
									name={keyPath('spouseLastName')}
									rules={[rules.required, rules.minmax(1, 20)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									label='Date of Birth'
									name={keyPath('spouseDateOfBirth')}
									rules={[rules.required]}>
									<BwDatePicker />
								</Form.Item>
							</Col>
							<Col xs={12}>
								<Form.Item
									label='SIN'
									name={keyPath('spouseSocialInsuranceNumber')}
									validateFirst
									rules={[rules.sin]}>
									<Input
										placeholder='XXXXXXXXX'
										type='number'
									/>
								</Form.Item>
							</Col>
							{maritalStatus && ['Widowed', 'Divorced'].includes(maritalStatus) === false && (
								<Col xs={24}>
									<Form.Item
										{...formItemProps}
										label='Employment Status'
										name={keyPath('spouseEmploymentStatus')}
										rules={[rules.required, rules.selectedFromList(Object.values(EmploymentStatus))]}>
										<Select
											showSearch
											options={Object.values(EmploymentStatus).map((value) => ({
												value,
											}))}
										/>
									</Form.Item>
								</Col>
							)}
							{spouseEmploymentStatus && NON_EMPLOYMENT.includes(spouseEmploymentStatus) === false && (
								<>
									<Col xs={24}>
										<Form.Item
											{...formItemProps}
											label={getEmploymentLabel(spouseEmploymentStatus).employer}
											name={keyPath('spouseEmployer')}
											rules={[rules.required, rules.minmax(1, 20)]}>
											<Input />
										</Form.Item>
									</Col>

									{spouseEmploymentStatus !== 'Student' && (
										<>
											<Col xs={24}>
												<Form.Item
													{...formItemProps}
													label='Occupation'
													name={keyPath('spouseOccupation')}
													rules={[rules.required, rules.minmax(1, 20)]}>
													<Input />
												</Form.Item>
											</Col>

											<Col xs={24}>
												<Form.Item
													{...formItemProps}
													label='Type of Business'
													name={keyPath('spouseBusinessType')}
													rules={[rules.required, rules.minmax(1, 20)]}>
													<Input />
												</Form.Item>
											</Col>
										</>
									)}
									<Col xs={24}>
										<Form.Item
											{...formItemProps}
											label={getEmploymentLabel(spouseEmploymentStatus).address}
											name={keyPath('spouseEmployerAddress')}
											rules={[rules.required, rules.minmax(1, 20)]}>
											<Input />
										</Form.Item>
									</Col>
								</>
							)}
						</Row>
					</>
				)}
			</div>

			<div className='bw-form__block'>
				<Row gutter={[20, 0]}>
					<Col xs={24}>
						<Form.Item
							label='Are you a Tax Resident of Canada?'
							name={keyPath('isTaxResidentCanada')}
							extra={getInactive('isTaxResidentCanada').message()}
							className={getInactive('isTaxResidentCanada').className}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Are you a Tax Resident of the United States?'
							name={keyPath('isTaxCitizenUS')}
							extra={getInactive('isTaxCitizenUS').message()}
							className={getInactive('isTaxCitizenUS').className}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item
							label='Are you a Tax Resident of a jurisdiction other than Canada or the United States?'
							name={keyPath('isTaxResidentOther')}
							extra={getInactive('isTaxResidentOther').message()}
							className={getInactive('isTaxResidentOther').className}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={[20, 0]}>
					<Col xs={24}>
						<Form.Item
							label='Do you wish to make a claim for Tax Treaty Benefits?'
							tooltip='Please reach out to your portfolio manager if you are unsure.'
							name={keyPath('isTaxBenefitsClaim')}
							extra={getInactive('isTaxBenefitsClaim').message()}
							className={getInactive('isTaxBenefitsClaim').className}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item
							label='I certify that I am a Resident of "(The Below Country)" within the meaning of the income tax treaty between the United States and that country.'
							name={keyPath('residentOfBlankCountryId')}
							rules={[rules.required]}
							extra={getInactive('residentOfBlankCountryId').message()}
							className={getInactive('residentOfBlankCountryId').className}>
							<Select
								showSearch
								options={countriesOpts}
								loading={countriesRes.loading}
								filterOption={(input, option) => {
									return (option?.label as string).toLowerCase().includes(input.toLowerCase())
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Do you wish to claim Special rates and conditions?'
							name={keyPath('isSpecialRatesClaim')}
							tooltip='You should only claim special rates and conditions if you have been instructed to do so by a tax professional.'
							extra={getInactive('isSpecialRatesClaim').message()}
							className={getInactive('isSpecialRatesClaim').className}
							rules={[rules.required]}>
							<Radio.Group
								className='ant-group-col2'
								options={[
									{ value: true, label: 'Yes' },
									{ value: false, label: 'No' },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default ClientInfo
