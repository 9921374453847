import { FC } from 'react'
import { IDataIPS, IPropsSteps, IReturnObjectiveFields, DeepPartial } from 'types/ips'
import { Form, Row, Col, Radio, InputNumber, Input, Space } from 'antd'
import {
	DollarOutlined as IconDollar,
	PercentageOutlined as IconPercent,
	LinkOutlined as IconLink,
} from '@ant-design/icons'
import FormSection from 'components/ips/FormSection'
import { rules } from 'constants/props'
import AudioTooltip from '../AudioTooltip'
import audio9 from '../../../assets/audio/tooltips/9. 5-year term deposit.mp3'

const Step6: FC<IPropsSteps> = (props) => {
	const keyPath = (key: keyof IReturnObjectiveFields): [keyof IDataIPS, string] => ['returnObjective', key]
	const setFields = (values: DeepPartial<IDataIPS>) => props.form.setFieldsValue(values as any)
	const normalizeNum = (val: string) => (+val > 0 ? +val : 0)

	const withdrawalsTotalValue =
		(Form.useWatch(keyPath('withdrawalsTotalValue'), props.form) as IReturnObjectiveFields['withdrawalsTotalValue']) ??
		0
	const isRegularWithdrawals = Form.useWatch(
		keyPath('isRegularWithdrawals'),
		props.form,
	) as IReturnObjectiveFields['isRegularWithdrawals']
	const isMajorWithdrawalsSoon = Form.useWatch(
		keyPath('isMajorWithdrawalsSoon'),
		props.form,
	) as IReturnObjectiveFields['isMajorWithdrawalsSoon']
	const isExpectedIncreaseInvestments = Form.useWatch(
		keyPath('isExpectedIncreaseInvestments'),
		props.form,
	) as IReturnObjectiveFields['isExpectedIncreaseInvestments']
	const isExpectedAddCapital = Form.useWatch(
		keyPath('isExpectedAddCapital'),
		props.form,
	) as IReturnObjectiveFields['isExpectedAddCapital']
	const isCapitalLosses = Form.useWatch(
		keyPath('isCapitalLosses'),
		props.form,
	) as IReturnObjectiveFields['isCapitalLosses']

	const isAvoidInvesting = Form.useWatch(
		keyPath('isAvoidInvesting'),
		props.form,
	) as IReturnObjectiveFields['isAvoidInvesting']

	const isTransferringSecurities = Form.useWatch(
		keyPath('isTransferringSecurities'),
		props.form,
	) as IReturnObjectiveFields['isTransferringSecurities']

	return (
		<>
			<FormSection
				help={{
					text: 'This section will provide your Portfolio Manager with the necessary information to ensure your investments are structured in a way that supports your financial goals.',
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='The primary goal for my investment portfolio is to:'
								name={keyPath('investmentGoal')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label: `Earn a higher long-term return than is expected from guaranteed investment securities, while
									minimizing fluctuations in the value of my portfolio and the frequency of negative returns.`,
										},
										{
											value: '2',
											label: `Generate as much income as possible from my portfolio, while preserving the long-term value of my
											capital.`,
										},
										{
											value: '3',
											label:
												'Achieve a balance between long-term growth (+10 years), income, and short-term stability of capital.',
										},
										{
											value: '4',
											label:
												'Maximize total returns and long-term growth (+10 years), regardless of the degree of fluctuations in the value of my portfolio in the short-term.',
										},
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>

				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Are you currently making regular withdrawals from your portfolio?'
								name={keyPath('isRegularWithdrawals')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isRegularWithdrawals && (
						<>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										label='What is the total current value of your investments?'
										name={keyPath('withdrawalsTotalValue')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<Input
											type='number'
											suffix={<IconDollar style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.25)' }} />}
											onChange={() => {
												setFields({
													returnObjective: {
														withdrawalsAnnuallyValue: 0,
														withdrawalsAnnuallyPercent: 0,
													},
												})
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row
								gutter={[20, 0]}
								align='middle'>
								<Col
									xs={24}
									xl={24}>
									How much are you drawing from your portfolio annually?
								</Col>
								<Col
									xs={24}
									xl={11}>
									<Form.Item
										label='Amount value'
										className='ant-label-grey'
										name={keyPath('withdrawalsAnnuallyValue')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<Input
											type='number'
											onChange={({ target: { value } }) => {
												const nextValue = +value > withdrawalsTotalValue ? withdrawalsTotalValue : +value

												const nextPercent =
													withdrawalsTotalValue === 0 ? 0 : Math.round((nextValue / withdrawalsTotalValue) * 100)

												setFields({
													returnObjective: {
														withdrawalsAnnuallyValue: nextValue,
														withdrawalsAnnuallyPercent: nextPercent,
													},
												})
											}}
											suffix={<IconDollar style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.25)' }} />}
										/>
									</Form.Item>
								</Col>
								<Col>
									<IconLink style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.25)' }} />
								</Col>
								<Col
									xs={24}
									xl={11}>
									<Form.Item
										label='Percentage value'
										className='ant-label-grey'
										name={keyPath('withdrawalsAnnuallyPercent')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<Input
											type='number'
											onChange={({ target: { value } }) => {
												const nextValue = +value > 100 ? 100 : +value < 0 ? 0 : +value

												setFields({
													returnObjective: {
														withdrawalsAnnuallyValue: Math.round((nextValue / 100) * withdrawalsTotalValue),
														withdrawalsAnnuallyPercent: nextValue,
													},
												})
											}}
											suffix={<IconPercent style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.25)' }} />}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					{isRegularWithdrawals === false && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='When do you plan on making regular withdrawals from your portfolio?'
									name={keyPath('withdrawalsPlan')}
									rules={[rules.required]}>
									<Radio.Group
										options={[
											{ value: '1', label: 'Less than 1 year' },
											{ value: '2', label: '1 year from now' },
											{ value: '3', label: '2 years from now' },
											{ value: '4', label: '3 years from now' },
											{ value: '5', label: '4 years from now' },
											{ value: '6', label: '5 or more years' },
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>

				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you plan on making a major withdrawal from your portfolio any time soon? These may include purchasing property, funding education, etc.'
								name={keyPath('isMajorWithdrawalsSoon')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										"As a discretionary investment manager, your Portfolio Manager has the ability to monitor your investments and sell assets when it's most beneficial for you. Because of this, the more information you can provide about capital needs, the more effectively Bellwether can manage your portfolio.",
								}}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isMajorWithdrawalsSoon && (
						<>
							<Row
								gutter={[20, 0]}
								align='bottom'>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='I expect to make a withdrawal'
										name={keyPath('expectedWithdrawalSum')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<Input
											type='number'
											suffix={<IconDollar style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.25)' }} />}
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='in approximately'
										name={keyPath('expectedWithdrawalYears')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='Years'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio9} />
										<span>
											If a 5-year term deposit currently pays 3%, what would you expect the average annual return to be
											on your investment portfolio over the next 10 years?
										</span>
									</Space>
								}
								name={keyPath('expectAverage')}
								rules={[rules.required]}>
								<Radio.Group
									options={[
										{
											value: '1',
											label: 'About the same',
										},
										{
											value: '2',
											label: 'About one and a half times the rate of a term deposit',
										},
										{
											value: '3',
											label: 'About double the rate of a term deposit',
										},
										{
											value: '4',
											label: 'About triple the rate of a term deposit',
										},
										{
											value: '5',
											label: 'More than three times the rate of a term deposit',
										},
									]}></Radio.Group>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
			<FormSection title='Considerations'>
				<div className='bw-form__block'>
					<p>
						Bellwether has taken the following additional factors into consideration when recommending the mix of fixed
						income and equity investments for the portfolio that they are managing:
					</p>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you expect to downsize your primary residence and add to your investments in the future?'
								name={keyPath('isExpectedIncreaseInvestments')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isExpectedIncreaseInvestments && (
						<>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='How much'
										name={keyPath('expectedInvestmentsSum')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='$'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='How many years'
										name={keyPath('expectedInvestmentsYears')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='Years'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you expect to add capital to your investment portfolio from other sources (e.g. inheritance).'
								name={keyPath('isExpectedAddCapital')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isExpectedAddCapital && (
						<>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='How much'
										name={keyPath('expectedAddCapitalSum')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='$'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='How many years'
										name={keyPath('expectedAddCapitalYears')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='Years'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</div>
			</FormSection>
			<FormSection title='Client-Directed Securities'>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Will you be transferring‐in securities that are not to be held in a managed account?'
								name={keyPath('isTransferringSecurities')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isTransferringSecurities && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Security Name/Symbol'
									name={keyPath('transferringSecuritiesSymbol')}
									rules={[rules.required, rules.minmax(1, 10)]}>
									<Input maxLength={10} />
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
			</FormSection>
			<FormSection title='Investor Preferences and/or Constraints'>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you have any special investment preferences and/or constraints?'
								name={keyPath('isAvoidInvesting')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isAvoidInvesting && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='I would like Bellwether to make reasonable efforts to avoid investing in the following industry sectors or individual stocks:'
									name={keyPath('avoidInvesting')}
									rules={[rules.required]}>
									<Input.TextArea
										showCount
										maxLength={320}
										placeholder='example'
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
			</FormSection>
			<FormSection
				title='Tax Considerations'
				help={{
					text: "All of the accounts are to be managed according to the investment objectives and restrictions as indicated in this agreement. If no restriction is stated, I agree that this account is to be managed under Bellwether's discretion in accordance with their investment policies and guidelines.",
				}}>
				<div className='bw-form__block'>
					<p>
						If one of the accounts that Bellwether manages is taxable, we will do our best to minimize the annual tax
						liability, although it will not take precedence over prudent investment management practices.
					</p>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you have capital losses from previous years that could offset capital gains from your investment portfolio with Bellwether?'
								name={keyPath('isCapitalLosses')}
								rules={[rules.required]}>
								<Radio.Group className='ant-group-col2'>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{isCapitalLosses && (
						<>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										label='How much'
										name={keyPath('capitalLossesSum')}
										normalize={normalizeNum}
										rules={[rules.required]}>
										<InputNumber
											type='number'
											placeholder='$'
											controls={false}
											style={{ width: `${100}%` }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</div>
			</FormSection>
		</>
	)
}

export default Step6
