import { FC } from 'react'
import { Col, Form, Row, Input, Tag } from 'antd'
import { IClientAccountsFields, IDataIPS, IPropsSteps } from 'types/ips'
import FormSection from 'components/ips/FormSection'
import { formItemProps } from 'constants/props'

const ACCOUNT_MAP = {
	1: 'Individual',
	2: 'Joint',
	3: 'RRSP',
	4: 'RRIF',
	5: 'Spousal RRSP',
	6: 'Spousal RRIF',
	7: 'LIRA/LRSP',
	8: 'LIF',
	9: 'TFSA',
	10: 'RESP',
	11: 'RDSP',
	12: 'Corporate',
	13: 'IPP',
	14: 'RCA',
	15: 'ITF',
	16: 'Joint ITF',
	17: 'Trust',
	18: 'Estate',
	19: 'Cash',
	20: 'FHSA',
}

const Step9: FC<IPropsSteps> = (props) => {
	const accKey: keyof IDataIPS = 'clientAccounts'

	return (
		<FormSection>
			<Form.List name={accKey}>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ name }, idx) => {
							const keyPath = (fieldKey: keyof IClientAccountsFields): [number, keyof IClientAccountsFields] => [
								name,
								fieldKey,
							]
							const accTypesPath = keyPath('accountTypes')
							const accTypes = props.form.getFieldValue([
								accKey,
								...accTypesPath,
							]) as IClientAccountsFields['accountTypes']

							return (
								<div
									className='bw-form__block'
									key={name}>
									<div className='bw-form__block-head'>
										<b>Account Holder #{idx + 1}</b>
									</div>
									<Row gutter={[20, 0]}>
										<Col
											xs={24}
											xl={24}>
											<Form.Item
												{...formItemProps}
												label='Name of Account'
												name={keyPath('accountName')}>
												<Input disabled />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[20, 0]}>
										<Col
											xs={24}
											xl={24}>
											<Form.Item
												{...formItemProps}
												label='Account Types'
												// name={keyPath('accountTypes')}
											>
												{accTypes?.map((type, idx) => (
													<Tag
														style={{ padding: '5px 10px', marginBottom: 8, fontSize: 'inherit' }}
														key={idx}>
														{ACCOUNT_MAP[type as keyof typeof ACCOUNT_MAP]}
													</Tag>
												))}
											</Form.Item>
										</Col>
									</Row>
								</div>
							)
						})}
					</>
				)}
			</Form.List>
		</FormSection>
	)
}

export default Step9
