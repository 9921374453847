import { FC } from 'react'
import { IPropsSteps } from 'types/ips'
import ClientInfo from './info/ClientInfo'
import FormSection from 'components/ips/FormSection'
import { InputNumber, Form } from 'antd'
import { rules } from 'constants/props'
import ClientChilds from './ClientChilds'
import { ClientRelationType, RelationshipType } from 'graphql/generated/schema'

const Step1: FC<IPropsSteps> = (props) => {
	const {
		stateIPS: { isSecondaryClient },
	} = props

	const jointChildNum = Form.useWatch(['jointChildNum'], props.form)
	const clientOneRelationshipType = Form.useWatch(['clientInfo', 'clientOne', 'relationshipType'], props.form)

	return (
		<>
			<FormSection
				title='Client 1'
				help={{
					text: 'Please review and complete the following',
				}}>
				<ClientInfo
					clientKey='clientOne'
					form={props.form}
					inactiveFields={props.inactiveFields}
					stateIPS={props.stateIPS}
				/>
			</FormSection>
			{isSecondaryClient && (
				<FormSection title='Client 2'>
					<ClientInfo
						clientKey='clientTwo'
						form={props.form}
						inactiveFields={props.inactiveFields}
						stateIPS={props.stateIPS}
					/>
				</FormSection>
			)}
			{clientOneRelationshipType === RelationshipType.Spouse && (
				<FormSection title='Children'>
					<Form.Item
						label='Number of children'
						name={['jointChildNum']}
						rules={[rules.required]}>
						<InputNumber
							type='number'
							min={0}
							max={10}
							style={{ width: `${100}%` }}
						/>
					</Form.Item>

					<ClientChilds
						form={props.form}
						childNum={jointChildNum}
						relation={ClientRelationType.Joint}
					/>
				</FormSection>
			)}
		</>
	)
}

export default Step1
