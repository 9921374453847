import { FC, useEffect, useState } from 'react'
import { Button, Space, Table, Dropdown, Tooltip, Modal, Typography, message } from 'antd'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import { EllipsisOutlined as EllipsisIco } from '@ant-design/icons'
import { EditableCell, EditableRow, getColumnSearchProps } from 'helpers/table'
import Initials from '../common/Initials'
import StatusTag from '../common/StatusTag'
import type { TableProps } from 'antd/lib/table'
import { ColumnTypeEditable, FILTER_INPUT_TYPE, MODAL, SORT_MAP } from 'types/admin'
import { adminModalProps } from '../modal/ModalProps'
import {
	ClientStatusType,
	ClientTableInfoDocument,
	ClientTableInfoQuery,
	ClientTableInfoQueryVariables,
	CountersDocument,
	FilterClientInput,
	RemoveClientsDocument,
	Role,
	SendDocusignEnvelopeDocument,
	Sort,
	SortingClientInput,
	UpdateClientDocument,
} from 'graphql/generated/schema'
import EditClientDrawer from '../EditClientDrawer'
import TabsActions from './TabsActions'
import axios from 'axios'
import jszip from 'jszip'
import { saveAs } from 'file-saver'
import { config } from 'config'
import rfdc from 'rfdc'
import { printError } from 'helpers/error'
import ClientLogDrawer from '../ClientLogDrawer'
import AccountsStatus from '../modal/Client/AccountsStatus'
import { useDashboardTableFilters } from 'hooks/useDashboardTableFilters'
import { useAuth, useModal } from 'hooks/useContexts'
import { useNavigate } from 'react-router-dom'
import SignatureStatus from '../modal/Client/SignatureStatus'

const deepClone = rfdc()

export type DataTypeClient = NonNullable<ClientTableInfoQuery['clients']['nodes']>[0]

interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterClientInput
	sorting: SortingClientInput
}

interface DownloadedZip {
	clientName: string
	clientId: number
	fileBlob: Blob
}

const Clients: FC = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const { activeModal, setActiveModal } = useModal()
	const [selectedRows, setSelectedRows] = useState<DataTypeClient[]>([])
	const [showEditClient, setShowEditClient] = useState(false)
	const [showClientLog, setShowClientLog] = useState(false)
	const [clientRecord, setClientRecord] = useState<DataTypeClient>()
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})
	const { getFilters } = useDashboardTableFilters()

	const [updateClient] = useMutation(UpdateClientDocument)
	const [removeClients] = useMutation(RemoveClientsDocument)
	const [sendDocusignEnvelope] = useMutation(SendDocusignEnvelopeDocument)

	const getSearchVariables = (): ClientTableInfoQueryVariables => {
		const { pagination } = searchParams
		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const clientsRes = useQuery(ClientTableInfoDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onCompleted: (data) => {},
		onError: (err) => {
			printError(err)
		},
	})

	const clients = clientsRes.data?.clients.nodes || []

	useEffect(() => {
		clientsRes.refetch()
	}, [searchParams])

	// Update the client record with the latest data after refetching
	useEffect(() => {
		const client = clientRecord && clientsRes.data?.clients.nodes?.find((client) => client.id === clientRecord?.id)

		if (client) {
			setClientRecord(client)
		}
	}, [clientsRes])

	const columns: ColumnTypeEditable<DataTypeClient>[] = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: '7%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeClient>(FILTER_INPUT_TYPE.NUMBER),
			render: (value, record) => (
				<Tooltip
					title={<>GUID: {record.guid} </>}
					placement='right'
					overlayStyle={{ maxWidth: 360 }}>
					{record.id}
				</Tooltip>
			),
		},
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '15%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeClient>(),
			render: (value, record) => record.fullName,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '13%',
			ellipsis: true,
			editable: true,
			className: 'editable-cell-ellipsis',
			render: (value) => <Tooltip title={value}>{value}</Tooltip>,
			...getColumnSearchProps<DataTypeClient>(),
		},
		{
			title: 'Portfolio Manager',
			dataIndex: 'pm',
			key: 'pm',
			width: '15%',
			hidden: [Role.Pm, Role.Advisor].includes(auth.user?.role as Role),
			filterSearch: true,
			filters: getFilters('pm'),
			filteredValue: searchParams.filters.pm,
			render: (value, record) => (
				<Initials
					name={record.portfolioManager ? `${record.portfolioManager.fullName}` : '-'}
					color='blue'
				/>
			),
		},
		{
			title: 'Advisor',
			dataIndex: ['advisor', 'fullName'],
			key: 'advisor',
			width: '15%',
			hidden: [Role.Advisor].includes(auth.user?.role as Role),
			filterSearch: true,
			filters: getFilters('advisor'),
			filteredValue: searchParams.filters.advisor,
			render: (value, record) => (
				<Initials
					name={record.advisor ? `${record.advisor.fullName}` : '-'}
					color='orange'
				/>
			),
		},
		{
			title: 'File',
			dataIndex: 'file',
			key: 'file',
			width: '8%',
			ellipsis: true,
			render: (value, record) =>
				record.file?.name ? <Tooltip title={record.file?.name}>{record.file?.name}</Tooltip> : '-',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '8%',
			filters: getFilters('clientStatus'),
			filteredValue: searchParams.filters.status,
			render: (value, record) => <StatusTag status={record.status} />,
		},
		{
			title: 'Updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			align: 'center',
			width: '9%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeClient>(FILTER_INPUT_TYPE.DATE_RANGE),
			render: (value, record) => moment(record.updatedAt).fromNow(),
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: '6%',
			render: (_, record) => (
				<Space size='small'>
					<Button
						type='link'
						onClick={() => {
							setClientRecord(record)
							setShowEditClient(true)
						}}
						style={{ padding: 0 }}>
						Edit
					</Button>
					<Dropdown
						menu={{
							items: [
								...([ClientStatusType.Creation, ClientStatusType.Uploaded, ClientStatusType.Client].includes(
									record.status,
								)
									? [
											{
												key: 1,
												label: 'Set Up Onboarding',
												onClick: () => navigate(`/admin/client-onboarding/${record.guid}`),
											},
										]
									: []),
								...([
									ClientStatusType.Error,
									ClientStatusType.Sent,
									ClientStatusType.Client,
									ClientStatusType.Creation,
									ClientStatusType.Uploaded,
								].includes(record.status)
									? [
											{
												key: 2,
												label: 'Send Onboarding Email',
												onClick: () => onRowResendInvite(record),
											},
										]
									: []),
								...([ClientStatusType.Error, ClientStatusType.Signature, ClientStatusType.Fidelity].includes(
									record.status,
								)
									? [
											{
												key: 3,
												label: 'Send DocuSign Envelope',
												onClick: () => onRowResendDocusignEnvelope(record),
											},
										]
									: []),
								...([ClientStatusType.Fidelity, ClientStatusType.Completed].includes(record.status)
									? [
											{
												key: 4,
												label: 'Download Documents',
												onClick: () => onRowDownloadZip(record),
											},
										]
									: []),
								...([ClientStatusType.Completed].includes(record.status)
									? [
											{
												key: 5,
												label: 'Export User Data',
												onClick: () => exportClients([record.id], record.fullName),
											},
										]
									: []),

								...([ClientStatusType.Signature].includes(record.status)
									? [
											{
												key: 6,
												label: 'Signature Status',
												onClick: () => {
													setClientRecord(record)
													setActiveModal(MODAL.SIGNATURE_STATUS)
												},
											},
										]
									: []),
								{
									key: 7,
									label: 'Request Status',
									onClick: () => {
										setClientRecord(record)
										setActiveModal(MODAL.ACCOUNTS_STATUS)
									},
								},

								{
									key: 8,
									label: 'View Log',
									onClick: () => {
										setClientRecord(record)
										setShowClientLog(true)
									},
								},
								{
									key: 9,
									label: 'Delete',
									style: { color: '#FF4D4F' },
									onClick: () => onRowDelete(record),
								},
							],
						}}
						placement='bottomRight'>
						<EllipsisIco />
					</Dropdown>
				</Space>
			),
		},
	]

	const onTableChange: TableProps<DataTypeClient>['onChange'] = (pagination, filters, sorter, extra) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingClientInput
				const sortOrder = sorter.order

				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	const onRowResendInvite = async (record: DataTypeClient) => {
		navigate(`/admin/client-onboarding/${record.guid}?required=1`)
	}

	const onRowResendDocusignEnvelope = (record: DataTypeClient) => {
		sendDocusignEnvelope({
			variables: {
				guid: record.guid,
			},
			refetchQueries: [ClientTableInfoDocument],
		}).catch(printError)

		message.info({ content: `Sending DocuSign envelope for ${record.fullName}` })
	}

	const onRowDownloadZip = (record: DataTypeClient) => {
		if (!record.docusign?.completedDocumentUrl) {
			return message.warn('There is no completed documents for this client yet')
		}

		axios({
			method: 'POST',
			url: `${config.apiUrl}/generate/documents`,
			responseType: 'blob',
			data: { url: record.docusign.completedDocumentUrl },
			withCredentials: true,
		})
			.then((res) => {
				const fileBlob: Blob = res.data
				const fileName = `[${record.id}] ${record.fullName} docs.zip`

				saveAs(fileBlob, fileName)
			})
			.catch(printError)
	}

	const onRowDelete = (record: DataTypeClient) => {
		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure that you want to delete this client?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph
						strong
						style={{ margin: 0 }}>
						{record.fullName}
					</Typography.Paragraph>
					<Typography.Paragraph type='secondary'>will be deleted forever from the system</Typography.Paragraph>
				</div>
			),
			okText: 'Delete',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeClients({
					variables: {
						ids: [record.id],
					},
					refetchQueries: [CountersDocument],
				})
				clientsRes
					.refetch()
					.then(() => {
						message.success(`${record.fullName} has been deleted`)
						clientsRes.refetch()
					})
					.catch(printError)
			},
		})
	}

	const exportClients = async (ids: number[], clientName?: string) => {
		const res = await axios({
			method: 'POST',
			url: `${config.apiUrl}/generate/clients`,
			responseType: 'blob',
			data: { ids },
			withCredentials: true,
		})

		const currentDateTime = moment().format('YYYY-MM-DD_HH-mm-ss')

		const fileName = clientName ? clientName : 'Export clients'

		const blob: Blob = res.data
		saveAs(blob, `${fileName} (${currentDateTime}).xlsx`)
	}

	// const onBulkResendInvites = () => {
	// 	const allowedStatuses = [
	// 		ClientStatusType.Sent,
	// 		ClientStatusType.Error,
	// 		ClientStatusType.Client,
	// 		ClientStatusType.Uploaded,
	// 		ClientStatusType.Signature,
	// 	]
	// 	const clientsToResend: SendInviteInput[] = selectedRows
	// 		.filter((row) => allowedStatuses.includes(row.status))
	// 		.map((row) => ({
	// 			clientName: row.fullName,
	// 			clientEmail: row.email,
	// 			advisorName: row.advisor.fullName,
	// 			actionUrl: `${window.location.origin}/start/${row.guid}`,
	// 		}))

	// 	sendInvites({
	// 		variables: {
	// 			sendInvitesInput: clientsToResend,
	// 		},
	// 	})
	// 		.then(() => {
	// 			message.success(`Invitation for ${clientsToResend.length} client(s) sent successfully`)
	// 		})
	// 		.catch(printError)
	// }

	const onBulkDownloadZip = async () => {
		const promisesZips: Promise<DownloadedZip>[] = []

		for await (const client of selectedRows) {
			if (!client?.docusign?.completedDocumentUrl) {
				continue
			}

			promisesZips.push(
				axios({
					method: 'POST',
					url: `${config.apiUrl}/generate/documents`,
					responseType: 'blob',
					data: { url: client.docusign.completedDocumentUrl },
					withCredentials: true,
				}).then((res) => ({
					fileBlob: res.data,
					clientName: client.fullName,
					clientId: client.id,
				})),
			)
		}

		const downloadedZips = await Promise.all(promisesZips)

		const zip = new jszip()

		downloadedZips.forEach((dzip: DownloadedZip) => {
			const fileName = `[${dzip.clientId}] ${dzip.clientName} docs.zip`

			zip.file(fileName, dzip.fileBlob)
		})

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, 'Documents of clients.zip')
		})
	}

	const onBulkExportClients = () => {
		const clientsIds = selectedRows.map((row) => row.id)
		exportClients(clientsIds)
	}
	const onBulkDelete = () => {
		const idsToDelete = selectedRows.map((row) => row.id)

		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure that you want to delete selected clients?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph type='secondary'>
						{idsToDelete.length} client(s) will be deleted forever from the system
					</Typography.Paragraph>
				</div>
			),
			okText: 'Delete selected',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeClients({
					variables: {
						ids: idsToDelete,
					},
					refetchQueries: [CountersDocument],
				})
					.then(() => {
						message.success('Clients have been deleted')
						clientsRes.refetch()
						setSelectedRows([])
					})
					.catch(printError)
			},
		})
	}

	const handleSave = async (row: DataTypeClient, col: ColumnTypeEditable<DataTypeClient>) => {
		const colKey = col.dataIndex as keyof DataTypeClient

		updateClient({
			variables: {
				input: {
					guid: row.guid,
					[colKey]: row[colKey],
				},
			},
		})
			.then(() => {
				message.success(`${col.title} changed`)
				clientsRes.refetch()
			})
			.catch(printError)
	}

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataTypeClient) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave: (row: DataTypeClient) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const totalClients = clientsRes.data?.clients?.totalCount ?? clientsRes.previousData?.clients?.totalCount

	return (
		<>
			<TabsActions>
				{selectedRows.length ? (
					<>
						<Button
							size='large'
							onClick={onBulkDownloadZip}
							className='ant-btn-default-gold'>
							Download Documents ({selectedRows.length})
						</Button>
						<Button
							size='large'
							onClick={onBulkExportClients}
							className='ant-btn-default-gold'>
							Export Users Data ({selectedRows.length})
						</Button>
						<Button
							danger
							onClick={onBulkDelete}
							size='large'>
							Delete ({selectedRows.length})
						</Button>
					</>
				) : (
					<Button
						size='large'
						onClick={() => exportClients([])}
						className='ant-btn-default-gold'>
						Export Users Data
					</Button>
				)}

				<Button
					size='large'
					onClick={() => navigate('/admin/client-onboarding')}
					className='ant-btn-default-gold'>
					Onboarding
				</Button>
			</TabsActions>
			<Table
				columns={columnsEditable as ColumnTypeEditable<DataTypeClient>[]}
				components={{
					body: {
						row: EditableRow,
						cell: EditableCell,
					},
				}}
				rowClassName='editable-row'
				rowKey={(record) => record.id}
				dataSource={clients}
				pagination={{
					...searchParams.pagination,
					total: totalClients,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
				}}
				loading={clientsRes.loading}
				onChange={onTableChange}
				rowSelection={{
					type: 'checkbox',
					columnWidth: '4%',
					selectedRowKeys: selectedRows.map((row) => row.id),
					onChange: (selectedRowKeys: React.Key[], selectedRows: DataTypeClient[]) => {
						setSelectedRows(selectedRows)
					},
					getCheckboxProps: (record: DataTypeClient) => ({
						// disabled: record.name === 'Disabled User', // Column configuration not to be checked
						// name: record.name,
					}),
				}}
			/>
			{clientRecord?.guid && showEditClient && (
				<EditClientDrawer
					clientRecord={clientRecord}
					showEditClient={showEditClient}
					setShowEditClient={setShowEditClient}
				/>
			)}
			{clientRecord?.guid && showClientLog && (
				<ClientLogDrawer
					clientRecord={clientRecord}
					showClientLog={showClientLog}
					setShowClientLog={setShowClientLog}
				/>
			)}
			{clientRecord?.guid && activeModal === MODAL.ACCOUNTS_STATUS && <AccountsStatus clientRecord={clientRecord} />}
			{clientRecord?.guid && activeModal === MODAL.SIGNATURE_STATUS && <SignatureStatus clientRecord={clientRecord} />}
		</>
	)
}

export default Clients
