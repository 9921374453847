import { FC } from 'react'
import { Button, Form, Input, Row, Col, message } from 'antd'
import { MailOutlined as MailIco, LockOutlined as LockIco } from '@ant-design/icons'
import { rules } from 'constants/props'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/useContexts'
import Layout from '../layout/Layout'

interface SignInValues {
	email: string
	password: string
}

const SignIn: FC = () => {
	const auth = useAuth()
	const navigate = useNavigate()

	const onFinish = (values: SignInValues) => {
		auth
			.login(values)
			.then(() => navigate('/admin/dashboard'))
			.catch((err) => {
				if (err.message === 'Unauthorized') {
					message.error('The credentials provided are incorrect')
				} else {
					message.error(err.message)
				}
			})
	}

	return (
		<Layout>
			<div className='admin-auth'>
				<div className='admin-auth__inner'>
					<Form
						size='large'
						layout='vertical'
						onFinish={onFinish}
						requiredMark={false}>
						<Row justify='center'>
							<Col>
								<h2>Sign In</h2>
							</Col>
						</Row>
						<Form.Item
							name='email'
							rules={[rules.required, rules.email]}>
							<Input
								prefix={<MailIco />}
								placeholder='Email'
							/>
						</Form.Item>
						<Form.Item
							className='mb-10'
							name='password'
							rules={[rules.required]}>
							<Input.Password
								prefix={<LockIco />}
								placeholder='Password'
							/>
						</Form.Item>

						<Row
							justify='end'
							className='mb-10'>
							<Col>
								<Link to={'/admin/forgot-password'}>Forgot your password?</Link>
							</Col>
						</Row>

						<Form.Item className='mt-50'>
							<Button
								block
								loading={auth.loading}
								type='primary'
								htmlType='submit'
								className='ant-btn-primary-gold'>
								Sign In
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</Layout>
	)
}

export default SignIn
