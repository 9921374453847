import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'context/authContext'
import './assets/styles/common.sass'
import App from './components/App'
import { createUploadLink } from 'apollo-upload-client'
import { config } from './config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const apiUri = `${config.apiUrl}/graphql`

const apolloClient = new ApolloClient({
	uri: apiUri,
	credentials: 'include',
	cache: new InMemoryCache(),
	link: createUploadLink({
		uri: apiUri,
		credentials: 'include',
		headers: {
			'Apollo-Require-Preflight': 'true',
		},
	}),
})

root.render(
	<ApolloProvider client={apolloClient}>
		<AuthProvider>
			<BrowserRouter>
				<React.StrictMode>
					<App />
				</React.StrictMode>
			</BrowserRouter>
		</AuthProvider>
	</ApolloProvider>,
)
