import { FC } from 'react'
import { Card, Row, Col, Statistic, StatisticProps } from 'antd'
import { PlusOutlined as IconPlus } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { CountersDocument } from 'graphql/generated/schema'

const getTitle = (title: string, action?: () => any) => {
	return (
		<div className='admin-counters__title'>
			<span>{title}</span>
			{action && (
				<span
					className='admin-counters__action'
					onClick={action}>
					<IconPlus />
				</span>
			)}
		</div>
	)
}

const Counters: FC = () => {
	const { data } = useQuery(CountersDocument)

	const countersData: {
		title: StatisticProps['title']
		value: StatisticProps['value']
	}[] = [
		{ title: getTitle('Clients'), value: data?.clients?.totalCount },
		{ title: getTitle('PMs'), value: data?.pms?.totalCount },
		{ title: getTitle('Advisors'), value: data?.advisors?.totalCount },
		{ title: getTitle('Admins'), value: data?.admins?.totalCount },
	]

	return (
		<Row
			gutter={20}
			className='admin-counters'>
			{countersData.map((counter, idx) => (
				<Col
					key={idx}
					xs={24}
					md={24 / countersData.length}>
					<Card>
						<Statistic
							title={counter.title}
							value={counter.value}
							valueStyle={{ fontWeight: 500 }}
							groupSeparator={' '}
						/>
					</Card>
				</Col>
			))}
		</Row>
	)
}

export default Counters
