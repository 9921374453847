import { FC } from 'react'
import { Form, Row, Col, Input, Upload, message, UploadFile } from 'antd'
import { FileCheckType, IBankingFields, IDataIPS, IPropsSteps } from 'types/ips'
import { InboxOutlined as IconInbox } from '@ant-design/icons'
import FormSection from 'components/ips/FormSection'
import { formItemProps, rules } from 'constants/props'
import { useMutation } from '@apollo/client'
import { GetSignedFileUrlDocument, UploadClientFileDocument, UploadClientFileMutation } from 'graphql/generated/schema'
import { UploadRequestOption } from 'rc-upload/lib/interface'

const ALLOWED_UPLOAD_TYPES = ['image/jpeg', 'image/heic', 'image/png', 'application/pdf']

const Step2: FC<IPropsSteps> = (props) => {
	const {
		stateIPS: { isSecondaryClient },
	} = props

	const [uploadClientFile] = useMutation(UploadClientFileDocument)
	const [getSignedFileUrlDocument] = useMutation(GetSignedFileUrlDocument)

	const handleUpload = ({ onSuccess, onError, file }: UploadRequestOption) => {
		uploadClientFile({
			variables: {
				file: file,
				guid: props.guid,
			},
		})
			.then((res) => onSuccess && onSuccess(res))
			.catch((err) => onError && onError(err))
	}

	const onPreview = async (file: UploadFile<FileCheckType>) => {
		if (file.url) {
			const result = await getSignedFileUrlDocument({
				variables: {
					url: file.url,
				},
			})

			if (result.data?.getSignedFileUrl) {
				window.open(result.data.getSignedFileUrl, '_blank')
			}
		}
	}

	const onUploadValueEvent = (args: any): FileCheckType[] => {
		const file: UploadFile = args.file

		if (file.status === 'uploading') {
			return [
				{
					uid: file.uid,
					name: file.name,
					status: file.status,
					url: file.url ?? '',
				},
			]
		} else if (file.status === 'done') {
			const data: UploadClientFileMutation = file.response?.data
			const url = data.uploadClientFile.Location

			return [
				{
					uid: file.uid,
					name: file.name,
					status: file.status,
					url: url,
				},
			]
		}

		return []
	}

	const bankingFieldsJSX = (type: 'clientOne' | 'clientTwo') => {
		const setKey = (key: keyof IBankingFields): [keyof IDataIPS, keyof IBankingFields] => ['bankingInfo', key]

		const voidCheck = props.form.getFieldValue(setKey(type === 'clientTwo' ? 'secondaryVoidCheck' : 'voidCheck'))

		return (
			<div
				className='bw-form__block'
				key={type}>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							{...formItemProps}
							label='Financial Institution Name'
							name={setKey(type === 'clientTwo' ? 'secondaryBankName' : 'bankName')}
							rules={[rules.required, rules.minmax(1, 30)]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Institution Number'
							name={setKey(type === 'clientTwo' ? 'secondaryBankNumber' : 'bankNumber')}
							validateFirst
							rules={[rules.required, rules.minmax(3, 3), rules.numbers]}>
							<Input />
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Branch Transit Number'
							name={setKey(type === 'clientTwo' ? 'secondaryBranchNumber' : 'branchNumber')}
							rules={[rules.required, rules.minmax(5, 5), rules.numbers]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							{...formItemProps}
							label='Institution Account Number'
							name={setKey(type === 'clientTwo' ? 'secondaryAccountNumber' : 'accountNumber')}
							rules={[rules.required, rules.minmax(1, 12)]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[20, 0]}>
					<Col
						xs={24}
						xl={24}>
						<Form.Item
							label='Image of Void Cheque'
							name={setKey(type === 'clientTwo' ? 'secondaryVoidCheck' : 'voidCheck')}
							valuePropName='file'
							getValueFromEvent={onUploadValueEvent}
							rules={[{ required: true, message: 'Please upload!' }]}>
							<Upload.Dragger
								name='file'
								multiple={false}
								maxCount={1}
								fileList={voidCheck}
								customRequest={handleUpload}
								onPreview={onPreview}
								showUploadList={{
									showRemoveIcon: false,
								}}
								beforeUpload={(file) => {
									const allowedSizeInMB = 10
									const isAllowedType = ALLOWED_UPLOAD_TYPES.includes(file.type)
									const isAllowedSize = file.size / 1024 / 1024 < allowedSizeInMB

									if (!isAllowedType) {
										message.error(`Allowed only ${ALLOWED_UPLOAD_TYPES.join()} file formats`)
										return Upload.LIST_IGNORE
									}

									if (!isAllowedSize) {
										message.error(`File must be smaller than ${allowedSizeInMB}MB!`)
										return Upload.LIST_IGNORE
									}
								}}
								accept={ALLOWED_UPLOAD_TYPES.join()}>
								<p className='ant-upload-drag-icon'>
									<IconInbox />
								</p>
								<p className='ant-upload-text'>Click or drag file to this area to upload</p>
								<p className='ant-upload-hint'>
									Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Col>
				</Row>
			</div>
		)
	}

	return (
		<>
			<FormSection
				title='Client 1'
				help={{
					text: (
						<>
							Please enter your banking information and upload a void cheque or direct deposit slip. If you would like
							to attach multiple bank accounts, select 'Add Banking Information'
						</>
					),
				}}>
				{bankingFieldsJSX('clientOne')}
			</FormSection>

			{isSecondaryClient && <FormSection title='Client 2'>{bankingFieldsJSX('clientTwo')}</FormSection>}
		</>
	)
}

export default Step2
