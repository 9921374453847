import { FC } from 'react'
import { Drawer, Button, Space, Typography } from 'antd'
import { LinkOutlined as IconLink } from '@ant-design/icons'
import { DataTypeClient } from './tabs/Clients'
import IPSPage from 'components/ips/IPSPage'
import moment from 'moment'
import { CLICK_ACTIONS } from 'types/common'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { CacheKey } from 'helpers/localCache'
import AudioPlayerProvider from 'context/audioHintContext'

interface Props {
	clientRecord: DataTypeClient
	showEditClient: boolean
	setShowEditClient: (showEditClient: boolean) => void
}

const EditClientDrawer: FC<Props> = (props) => {
	const [, setIsValidBirthday] = useLocalStorage(CacheKey.BW_VALID_BIRTHDAY, false)

	const client = props.clientRecord

	const onClose = () => {
		props.setShowEditClient(false)
	}

	const goToIps = () => {
		setIsValidBirthday(true)
		window.open(`/ips/${client?.guid}?from=admin`)
	}

	return (
		<Drawer
			placement='right'
			className='bw-ips--drawer'
			width={950}
			onClose={onClose}
			open={props.showEditClient}
			destroyOnClose={true}
			closeIcon={false}
			title={
				<>
					<Space style={{ display: 'flex' }}>
						<Typography.Text
							strong
							style={{ fontSize: 16 }}>
							{client?.fullName}
						</Typography.Text>
						<Typography.Text type='secondary'>
							id: {client?.id} <IconLink onClick={goToIps} />
						</Typography.Text>
					</Space>
					<Space style={{ display: 'flex' }}>
						<Typography.Text type='secondary'>Updated: {moment(client?.updatedAt).fromNow()}</Typography.Text> |
						<Typography.Text type='secondary'>
							Created: {moment(client?.createdAt).format('YYYY-MM-DD HH:mm')}
						</Typography.Text>
					</Space>
				</>
			}
			extra={
				<Space size='large'>
					<Button
						className='ant-btn-default-gold'
						size='large'
						onClick={onClose}>
						Cancel
					</Button>
					<Button
						className='ant-btn-primary-black'
						data-action={CLICK_ACTIONS.ADMIN_ON_SAVE_FORM}
						size='large'>
						Save
					</Button>
				</Space>
			}>
			<AudioPlayerProvider>
				<IPSPage
					isAdmin
					guid={client?.guid}
				/>
			</AudioPlayerProvider>
		</Drawer>
	)
}

export default EditClientDrawer
