import { FC } from 'react'
import { Form, Row, Col, Radio, Checkbox, Input, Select } from 'antd'
import FormSection from 'components/ips/FormSection'
import { formItemProps, rules } from 'constants/props'
import { getInactiveIPS } from 'helpers/ips'
import type { IDataIPS, IDisclosuresFields, IPropsSteps } from 'types/ips'

interface IDisclosuresProps extends IPropsSteps {
	clientKey: 'clientOne' | 'clientTwo'
}

const Step4: FC<IPropsSteps> = (props) => {
	return (
		<>
			<AdditionalDisclosures
				clientKey='clientOne'
				{...props}
			/>
			{props.stateIPS.isSecondaryClient && (
				<AdditionalDisclosures
					clientKey='clientTwo'
					{...props}
				/>
			)}
		</>
	)
}

const AdditionalDisclosures: FC<IDisclosuresProps> = (props) => {
	const getFieldKey = (key: keyof IDisclosuresFields) =>
		(props.clientKey === 'clientTwo' ? key + 'Secondary' : key) as keyof IDisclosuresFields

	const keyPath = (key: keyof IDisclosuresFields): [keyof IDataIPS, keyof IDisclosuresFields] => {
		const fieldKey = getFieldKey(key)
		return ['additionalDisclosures', fieldKey]
	}

	const getInactive = (key: keyof IDisclosuresFields) => {
		const fieldKey = getFieldKey(key)
		const isInactive = Boolean(props.inactiveFields.additionalDisclosures[fieldKey])
		return getInactiveIPS(isInactive)
	}

	const isVotingRights = Form.useWatch(keyPath('isVotingRights'), props.form) as IDisclosuresFields['isVotingRights']
	const isInsider = Form.useWatch(keyPath('isInsider'), props.form) as IDisclosuresFields['isInsider']
	const isIIROC = Form.useWatch(keyPath('isIIROC'), props.form) as IDisclosuresFields['isIIROC']
	const isFinancialInterest = Form.useWatch(
		keyPath('isFinancialInterest'),
		props.form,
	) as IDisclosuresFields['isFinancialInterest']
	const isHeadIO = Form.useWatch(keyPath('isHeadIO'), props.form) as IDisclosuresFields['isHeadIO']
	const isPEP = Form.useWatch(keyPath('isPEP'), props.form) as IDisclosuresFields['isPEP']
	const foreignPEP = Form.useWatch(keyPath('foreignPEP'), props.form) as IDisclosuresFields['foreignPEP']
	const domesticPEP = Form.useWatch(keyPath('domesticPEP'), props.form) as IDisclosuresFields['domesticPEP']

	const atLeastOnePEPRequired = !foreignPEP?.length && !domesticPEP?.length

	return (
		<>
			<FormSection
				title={props.clientKey === 'clientOne' ? 'Client 1' : 'Client 2'}
				help={{
					text:
						props.clientKey === 'clientOne' &&
						'These questions provide Bellwether with an overview of any potential conflicts of interest.',
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Are you an “insider”, “reporting insider,” or “affiliate” of any publicly traded company (exchange or over-the-counter) for purposes of applicable securities regulations or individually or as part of a group, own or control 10% or more of the voting rights of a publicly traded company? You are responsible for knowing the securities rules that apply to you. If the response is yes, indicate the security symbol.'
								name={keyPath('isInsider')}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					{isInsider && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Security Symbol'
									name={keyPath('insiderSymbol')}
									rules={[rules.required, rules.minmax(1, 10)]}>
									<Input maxLength={10} />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Market of Security'
									name={keyPath('insiderMarket')}
									extra={getInactive('insiderMarket').message()}
									className={getInactive('insiderMarket').className}
									rules={[rules.required]}>
									<Select
										options={[
											{ label: 'TSX C$', value: 'T' },
											{ label: 'TSX U$', value: 'TF' },
											{ label: 'TSX VENTURE C$', value: 'V' },
											{ label: 'TSX VENTURE U$', value: 'VF' },
											{ value: 'Other' },
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Do you either individually or as part of a group, own 20% or more of the voting rights of a publicly traded company? If yes, please indicate security symbol.'
								name={keyPath('isVotingRights')}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					{isVotingRights && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Security Symbol'
									name={keyPath('votingRightsSymbol')}
									rules={[rules.required, rules.minmax(1, 10)]}>
									<Input maxLength={10} />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									label='Market of Security'
									name={keyPath('votingRightsMarket')}
									extra={getInactive('votingRightsMarket').message()}
									className={getInactive('votingRightsMarket').className}
									rules={[rules.required]}>
									<Select
										options={[
											{ label: 'TSX C$', value: 'T' },
											{ label: 'TSX U$', value: 'TF' },
											{ label: 'TSX VENTURE C$', value: 'V' },
											{ label: 'TSX VENTURE U$', value: 'VF' },
											{ value: 'Other' },
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Are you or anyone you live with an employee, officer, director or partner of an IIROC member firm? If yes, please provide name of firm.'
								name={keyPath('isIIROC')}
								extra={getInactive('isIIROC').message()}
								className={getInactive('isIIROC').className}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					{isIIROC && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Firm Name'
									name={keyPath('IIROCDetails')}
									rules={[rules.required]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
				{/* Separate questions (for the Client 1) */}
				{props.clientKey === 'clientOne' && (
					<div className='bw-form__block'>
						<Row gutter={[20, 50]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Will any person(s) other than the person(s) named on the account have power of attorney over the account?'
									name={keyPath('isAccountPower')}
									extra={getInactive('isAccountPower').message()}
									className={getInactive('isAccountPower').className}
									rules={[rules.required]}>
									<Radio.Group
										className='ant-group-col2'
										options={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
									/>
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Will any person(s) other than the person(s) named on the account guarantee this account?'
									name={keyPath('isAccountGuarantee')}
									extra={getInactive('isAccountGuarantee').message()}
									className={getInactive('isAccountGuarantee').className}
									rules={[rules.required]}>
									<Radio.Group
										className='ant-group-col2'
										options={[
											{ value: true, label: 'Yes' },
											{ value: false, label: 'No' },
										]}
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
				)}
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Will any person(s) other than the person(s) named on the account have any financial interest in the account? If yes, please indicate below the name of the individual(s) who have financial interest.'
								name={keyPath('isFinancialInterest')}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
					{isFinancialInterest && (
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									label='Individual(s) who have financial interest'
									name={keyPath('financialInterestDetails')}
									rules={[rules.required]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					)}
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Will a third party be providing instructions on how to handle the account?
								If yes, please provide the third party’s name, address, primary business/occupation,
								relationship, and date of birth (if an individual), or incorporation number
								and jurisdiction of incorporation (if a corporation).'
								name={keyPath('isThirdPartyInstructions')}
								extra={getInactive('isThirdPartyInstructions').message()}
								className={getInactive('isThirdPartyInstructions').className}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
			<FormSection
				help={{
					text:
						props.clientKey === 'clientOne' &&
						`If a person is a Foreign or Domestic PEP or The Head of an International Organization (HIO), certain family members or close associates may be regarded as one. Family members include: their spouse or common-law partner; their biological or adoptive child(ren); their mother(s) or father(s); the mother(s) or father(s) of their spouse or common-law partner (mother-in-law or father-in-law); and a child(ren) of their mother or father (sibling(s)); ex-spouse or partner.
					`,
				}}>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Are you, or a family member a Foreign or Domestic Politically Exposed Person (PEP),
								pursuant to the Proceeds of Crime (Money Laundering) and Terrorist Financing Act?
								If yes, please indicate how.'
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										'A Foreign PEP/Domestic PEP means a person who holds or has held one of the following offices or positions in or on behalf of a Foreign or Domestic state (a person ceases to be a Domestic PEP 5 years after they have left office or 5 years after they are deceased).',
								}}
								name={keyPath('isPEP')}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>

					{isPEP && (
						<>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										name={keyPath('pepDetails')}
										rules={[rules.required]}>
										<Input.TextArea
											showCount
											maxLength={100}
											placeholder='Add details here'
										/>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										label='Foreign PEP'
										name={keyPath('foreignPEP')}
										dependencies={[keyPath('foreignPEP'), keyPath('domesticPEP')]}
										rules={[rules.requiredWhen(atLeastOnePEPRequired)]}>
										<Checkbox.Group
											options={[
												{ value: 1, label: 'The head of state or head of government' },
												{ value: 2, label: 'Member of executive council of government or member of legislature' },
												{ value: 3, label: 'Deputy minister or equivalent rank' },
												{ value: 4, label: 'Ambassador or attaché or counsellor of an ambassador' },
												{ value: 5, label: 'Military officer with a rank of general or above' },
												{ value: 6, label: 'President of a state-owned company or state-owned bank' },
												{ value: 7, label: 'Head of a government agency' },
												{
													value: 8,
													label: 'Judge of a supreme court, constitutional court or other court of last resort',
												},
												{ value: 9, label: 'Leader or president of a political party represented in a legislature' },
												{ value: 10, label: 'Holder of any prescribed office or position' },
											]}
										/>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={24}>
									<Form.Item
										label='Domestic PEP'
										name={keyPath('domesticPEP')}
										dependencies={[keyPath('foreignPEP'), keyPath('domesticPEP')]}
										rules={[rules.requiredWhen(atLeastOnePEPRequired)]}>
										<Checkbox.Group
											options={[
												{
													value: 1,
													label: 'Governor General, lieutenant governor or head of government',
												},
												{
													value: 2,
													label: 'Member member of Senate or House of Commons or member of legislature',
												},
												{
													value: 3,
													label: 'Deputy minister or equivalent rank;',
												},
												{
													value: 4,
													label: 'Ambassador or attaché or counsellor of an ambassador',
												},
												{
													value: 5,
													label: 'Military officer with a rank of general or above',
												},
												{
													value: 6,
													label:
														'President of a corporation that is wholly owned directly by His Majesty in right of Canada or a province;',
												},
												{
													value: 7,
													label: 'Head of a government agency',
												},
												{
													value: 8,
													label:
														'Judge of an appellate court in a province, the Federal Court of Appeal or the Supreme Court of Canada;',
												},
												{
													value: 9,
													label: 'Leader or president of a political party represented in a legislature',
												},
												{
													value: 10,
													label: 'Mayor, reeve or other similar chief officer of a municipal or local government',
												},
												{ value: 11, label: 'Holder of any prescribed office or position' },
											]}
										/>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
				</div>

				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label='Are you or a family member The Head of an International Organization?'
								name={keyPath('isHeadIO')}
								tooltip={{
									overlayStyle: { maxWidth: '400px' },
									title:
										'A HIO is a person who currently holds or has held within the last 5 years the specific office or position of head of an international organization and the international organization that they head or were head of is either: (1) an international organization established by the governments of states; or (2) an institution of an organization referred to in 1 above; or (3) an international sports organization.',
								}}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
						{isHeadIO && (
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									{...formItemProps}
									label='Organization name'
									name={keyPath('headIOrganization')}
									rules={[rules.required, rules.minmax(2, 50)]}>
									<Input />
								</Form.Item>
							</Col>
						)}
					</Row>
				</div>
			</FormSection>
		</>
	)
}

export default Step4
