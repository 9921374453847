import { QueryResult } from '@apollo/client'
import { getDate } from 'components/admin/onboarding/mapping'
import { ClientFullInfoQuery } from 'graphql/generated/schema'
import { FileCheckType, IDataIPS } from 'types/ips'

const getFile = (url: string | undefined | null) => {
	if (url && url.length) {
		const fileName = url.split('/').pop() as string
		const result: FileCheckType[] = [
			{
				uid: `uid-${fileName}`,
				status: 'done',
				name: fileName,
				url: url,
			},
		]

		return result
	}
}

export function getIPSValues(
	client: QueryResult<
		ClientFullInfoQuery,
		{
			guid: string
		}
	>,
): IDataIPS {
	const fields = client?.data?.client

	const clientSecondary = fields?.clientSecondary

	const getChildsFields = () => {
		const result: IDataIPS['clientChilds'] = {}

		fields?.childs
			?.sort((a, b) => a.order - b.order)
			?.forEach((child) => {
				if (!result[child.relation]) {
					result[child.relation] = []
				}

				result[child.relation]?.push({
					...child,
					dob: getDate(child.dob),
				})
			})

		return result
	}

	return {
		clientInfo: {
			clientOne: {
				title: fields?.title,
				gender: fields?.gender,
				firstName: fields?.firstName,
				lastName: fields?.lastName,
				dateOfBirth: getDate(fields?.dateOfBirth),
				socialInsuranceNumber: fields?.socialInsuranceNumber,
				civic: fields?.civic,
				streetName: fields?.streetName,
				streetType: fields?.streetType,
				addressType: fields?.addressType,
				directionType: fields?.directionType,
				unitType: fields?.unitType,
				apartment: fields?.apartment,
				city: fields?.city,
				provinceId: fields?.province?.id,
				countryId: fields?.country?.id === 1 ? 1 : null,
				postalCode: fields?.postalCode,
				mobilePhone: fields?.mobilePhone,
				homePhone: fields?.homePhone,
				email: fields?.email,
				maritalStatus: fields?.maritalStatus,
				citizenshipCountryId: fields?.citizenshipCountry?.id,
				isTaxResidentCanada: fields?.isTaxResidentCanada,
				isTaxCitizenUS: fields?.isTaxCitizenUS,
				isTaxResidentOther: fields?.isTaxResidentOther,
				isSpecialRatesClaim: fields?.isSpecialRatesClaim,
				isTaxBenefitsClaim: fields?.isTaxBenefitsClaim,
				residentOfBlankCountryId: fields?.residentOfBlankCountry?.id,
				employmentStatus: fields?.employmentStatus,
				employer: fields?.employer,
				occupation: fields?.occupation,
				businessType: fields?.businessType,
				employerAddress: fields?.employerAddress,
				isLegalAddressSameClientOne: fields?.isLegalAddressSameClientOne,
				isMailingAddressSameLegal: fields?.isMailingAddressSameLegal,
				mailingAddressType: fields?.mailingAddressType,
				mailingAddressCivic: fields?.mailingAddressCivic,
				mailingAddressStreetName: fields?.mailingAddressStreetName,
				mailingAddressStreetType: fields?.mailingAddressStreetType,
				mailingAddressDirectionType: fields?.mailingAddressDirectionType,
				mailingAddressUnitType: fields?.mailingAddressUnitType,
				mailingAddressApartment: fields?.mailingAddressApartment,
				mailingAddressCity: fields?.mailingAddressCity,
				mailingAddressProvinceId: fields?.mailingAddressProvince?.id,
				mailingAddressCountryId: fields?.mailingAddressCountry?.id,
				mailingAddressPostalCode: fields?.mailingAddressPostalCode,
				childNum: fields?.childNum,
				relationshipType: fields?.relationshipType,
				relationshipTypeOther: fields?.relationshipTypeOther,
				spouseFirstName: fields?.spouseFirstName,
				spouseLastName: fields?.spouseLastName,
				spouseDateOfBirth: getDate(fields?.spouseDateOfBirth),
				spouseSocialInsuranceNumber: fields?.spouseSocialInsuranceNumber,
				spouseEmploymentStatus: fields?.spouseEmploymentStatus,
				spouseEmployer: fields?.spouseEmployer,
				spouseOccupation: fields?.spouseOccupation,
				spouseBusinessType: fields?.spouseBusinessType,
				spouseEmployerAddress: fields?.spouseEmployerAddress,
			},
			...(clientSecondary && {
				clientTwo: {
					...clientSecondary,
					dateOfBirth: getDate(clientSecondary?.dateOfBirth),
					spouseDateOfBirth: getDate(clientSecondary?.spouseDateOfBirth),
					provinceId: clientSecondary?.province?.id,
					countryId: clientSecondary?.country?.id === 1 ? 1 : null,
					citizenshipCountryId: clientSecondary?.citizenshipCountry?.id,
					mailingAddressProvinceId: clientSecondary?.mailingAddressProvince?.id,
					mailingAddressCountryId: clientSecondary?.mailingAddressCountry?.id,
					residentOfBlankCountryId: clientSecondary?.residentOfBlankCountry?.id,
				},
			}),
		},
		jointChildNum: fields?.jointChildNum,
		clientChilds: getChildsFields(),
		bankingInfo: {
			...fields?.bankingInfo,
			voidCheck: getFile(fields?.bankingInfo?.voidCheckUrl),
			secondaryVoidCheck: getFile(fields?.bankingInfo?.secondaryVoidCheckUrl),
		},
		trustedPerson: {
			...fields?.trustedPerson,
		},
		additionalDisclosures: {
			...fields?.additionalDisclosures,
		},
		investmentPolicy: {
			...fields?.investmentPolicy,
		},
		returnObjective: {
			...fields?.returnObjective,
		},
		riskTolerance: {
			...fields?.riskTolerance,
		},
		investmentGuidelines: {
			agreements: fields?.investmentGuidelines?.agreements || [],
		},
		clientAccounts: fields?.clientAccounts || [],
	}
}
