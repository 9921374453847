import { DATE_FORMAT } from 'constants/ips'
import {
	ClientAdditionalFields,
	AccountType,
	ConfirmationType,
	TransferType,
	PacOrSwpType,
	AccPaymentFrequency,
	AccRrifAgeType,
	AccPaymentType,
	AccWtOnType,
	AccountTypeJt,
	JtwrosOrTicType,
	AccPaymentByType,
	ClientAssetsAccountType,
	JointAssetsAccountType,
	EntityAssetsAccountType,
} from 'graphql/generated/schema'
import moment from 'moment'
import {
	CurrencyType,
	BeneRelationshipType,
	JurisdictionType,
	OnboardingFormValues,
	TransferProvinceType,
} from 'types/client-onboarding'

export const getValue = <T>(value: T | null | undefined): T | undefined => {
	return value === null ? undefined : value
}

export const getDate = (value: string | null | undefined) => {
	return value ? moment(value) : undefined
}

export const getDateString = (date: object | null | undefined) => {
	return date ? moment(date).format(DATE_FORMAT) : undefined
}

export const getNumber = (value: string | number | null | undefined) => {
	if (value === null || value === undefined) {
		return undefined
	}

	return Number(value)
}

export const getBoolean = (value: string | null | boolean | undefined) => {
	if (value === null || value === undefined) {
		return undefined
	}

	return Boolean(value)
}

const accountTypeMap = (accountType: string) =>
	({
		Cash: AccountType.Cash,
		RRSP: AccountType.Rrsp,
		'Spousal RRSP': AccountType.SpousalRrsp,
		'LIRA/LRSP': AccountType.LiraLrsp,
		RRIF: AccountType.Rrif,
		'Spousal RRIF': AccountType.SpousalRrif,
		LIF: AccountType.Lif,
		ITF: AccountType.Itf,
		TFSA: AccountType.Tfsa,
		FHSA: AccountType.Fhsa,
	})[accountType]

const currencyMap = (currency: string) =>
	({
		CAD: CurrencyType.Cad,
		USD: CurrencyType.Usd,
		'CAD & USD': CurrencyType.CadUsd,
	})[currency]

const accountTypeJtMap = (accountType: string) =>
	({
		Joint: AccountTypeJt.Joint,
		'Joint ITF': AccountTypeJt.JointItf,
	})[accountType]

const clientAssetsAccountMap = (accountType: string) =>
	({
		TFSA: ClientAssetsAccountType.Tfsa,
		Cash: ClientAssetsAccountType.Cash,
		RRSP: ClientAssetsAccountType.Rrsp,
		'Spousal RRSP': ClientAssetsAccountType.SpousalRrsp,
		'LIRA/LRSP': ClientAssetsAccountType.LiraLrsp,
		RRIF: ClientAssetsAccountType.Rrif,
		'Spousal RRIF': ClientAssetsAccountType.SpousalRrif,
		LIF: ClientAssetsAccountType.Lif,
		ITF: ClientAssetsAccountType.Itf,
		RDSP: ClientAssetsAccountType.Rdsp,
		RESP: ClientAssetsAccountType.Resp,
		FHSA: ClientAssetsAccountType.Fhsa,
	})[accountType]

const jointAssetsAccountMap = (accountType: string) =>
	({
		Joint: JointAssetsAccountType.Joint,
		'Joint ITF': JointAssetsAccountType.JointItf,
		RESP: JointAssetsAccountType.Resp,
	})[accountType]

const entityAssetsAccountMap = (accountType: string) =>
	({
		Corp: EntityAssetsAccountType.Corp,
		Trust: EntityAssetsAccountType.Trust,
		RCA: EntityAssetsAccountType.Rca,
		IPP: EntityAssetsAccountType.Ipp,
		Estate: EntityAssetsAccountType.Estate,
	})[accountType]

const booleanTypeMap = (confirm: string) =>
	({
		Yes: true,
		No: false,
	})[confirm]

const beneTypeMap = (number: any) => {
	const map: Record<number, 1 | 2 | 3 | 4> = {
		'1': 1,
		'2': 2,
		'3': 3,
		'4': 4,
	}
	return map[number]
}

const transferTypeMap = (transferType: string) =>
	({
		'All In Kind': TransferType.AllInKind,
		'All In Cash': TransferType.AllInCash,
	})[transferType]

const rrifAgeMap = (age: string) =>
	({
		Annuitant: AccRrifAgeType.Annuitant,
		Spouse: AccRrifAgeType.Spouse,
	})[age]

const paymentFrequencyMap = (frequency: string) =>
	({
		Biweekly: AccPaymentFrequency.Biweekly,
		'Semimonthly 1 & 15': AccPaymentFrequency.Semimonthly_1_15,
		'Semimonthly 15 & Last': AccPaymentFrequency.Semimonthly_15Last,
		Monthly: AccPaymentFrequency.Monthly,
		Quarterly: AccPaymentFrequency.Quarterly,
		'Semi Annually': AccPaymentFrequency.SemiAnnually,
		Annually: AccPaymentFrequency.Annually,
	})[frequency]

const rrifPaymentTypeMap = (type: string) =>
	({
		Min: AccPaymentType.Min,
		Max: AccPaymentType.Max,
		Gross: AccPaymentType.Gross,
		Net: AccPaymentType.Net,
	})[type]

const rrifWithholdingTaxMap = (type: string) =>
	({
		Full: AccWtOnType.Full,
		Excess: AccWtOnType.Excess,
	})[type]

const getPacSwpClientType = (type: string | undefined | null) => {
	if (type === 'Client 1' || type === 'Client 2') {
		return type
	}
	return undefined
}

const confirmTypeMap = (type: string) =>
	({
		Yes: ConfirmationType.Yes,
		No: ConfirmationType.No,
	})[type]

const paymentByMap = (type: string) =>
	({
		EFT: AccPaymentByType.Eft,
		'Non-reg': AccPaymentByType.NonReg,
	})[type]

export const additionalFieldsMapper = (fields: ClientAdditionalFields): OnboardingFormValues => {
	const result: OnboardingFormValues = {
		clientOne: {
			individualAccounts: [
				{
					accountType: accountTypeMap(fields.primary_account_1 || ''),
					accountCurrency: currencyMap(fields.primary_account_1_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_1_successor || ''),
					jurisdiction: getValue(fields.primary_account_1_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_1_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_1_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_1_bene_1_first_name),
							lastName: getValue(fields.primary_account_1_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_1_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_1_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_1_bene_1_dob),
							relationship: getValue(fields.primary_account_1_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_1_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_1_bene_2_first_name),
							lastName: getValue(fields.primary_account_1_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_1_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_1_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_1_bene_2_dob),
							relationship: getValue(fields.primary_account_1_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_1_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_1_bene_3_first_name),
							lastName: getValue(fields.primary_account_1_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_1_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_1_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_1_bene_3_dob),
							relationship: getValue(fields.primary_account_1_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_1_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_1_bene_4_first_name),
							lastName: getValue(fields.primary_account_1_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_1_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_1_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_1_bene_4_dob),
							relationship: getValue(fields.primary_account_1_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_1_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_1_transfer_1_institution_name,
							fields.primary_account_1_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_1_transfer_1_institution_name,
							fields.primary_account_1_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_1_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_1_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_1_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_1_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_1_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_1_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_1_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_1_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_1_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_1_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_1_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_1_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_1_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_1_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_1_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_1_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_1_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_1_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_1_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_1_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_1_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_1_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_1_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_1_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_1_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_1_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_1_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_1_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_1_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_1_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_1_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_1_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_2 || ''),
					accountCurrency: currencyMap(fields.primary_account_2_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_2_successor || ''),
					jurisdiction: getValue(fields.primary_account_2_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_2_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_2_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_2_bene_1_first_name),
							lastName: getValue(fields.primary_account_2_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_2_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_2_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_2_bene_1_dob),
							relationship: getValue(fields.primary_account_2_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_2_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_2_bene_2_first_name),
							lastName: getValue(fields.primary_account_2_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_2_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_2_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_2_bene_2_dob),
							relationship: getValue(fields.primary_account_2_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_2_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_2_bene_3_first_name),
							lastName: getValue(fields.primary_account_2_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_2_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_2_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_2_bene_3_dob),
							relationship: getValue(fields.primary_account_2_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_2_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_2_bene_4_first_name),
							lastName: getValue(fields.primary_account_2_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_2_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_2_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_2_bene_4_dob),
							relationship: getValue(fields.primary_account_2_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_2_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_2_transfer_1_institution_name,
							fields.primary_account_2_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_2_transfer_1_institution_name,
							fields.primary_account_2_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_2_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_2_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_2_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_2_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_2_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_2_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_2_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_2_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_2_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_2_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_2_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_2_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_2_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_2_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_2_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_2_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_2_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_2_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_2_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_2_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_2_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_2_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_2_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_2_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_2_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_2_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_2_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_2_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_2_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_2_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_2_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_2_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_3 || ''),
					accountCurrency: currencyMap(fields.primary_account_3_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_3_successor || ''),
					jurisdiction: getValue(fields.primary_account_3_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_3_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_3_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_3_bene_1_first_name),
							lastName: getValue(fields.primary_account_3_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_3_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_3_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_3_bene_1_dob),
							relationship: getValue(fields.primary_account_3_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_3_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_3_bene_2_first_name),
							lastName: getValue(fields.primary_account_3_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_3_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_3_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_3_bene_2_dob),
							relationship: getValue(fields.primary_account_3_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_3_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_3_bene_3_first_name),
							lastName: getValue(fields.primary_account_3_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_3_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_3_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_3_bene_3_dob),
							relationship: getValue(fields.primary_account_3_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_3_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_3_bene_4_first_name),
							lastName: getValue(fields.primary_account_3_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_3_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_3_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_3_bene_4_dob),
							relationship: getValue(fields.primary_account_3_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_3_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_3_transfer_1_institution_name,
							fields.primary_account_3_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_3_transfer_1_institution_name,
							fields.primary_account_3_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_3_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_3_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_3_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_3_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_3_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_3_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_3_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_3_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_3_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_3_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_3_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_3_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_3_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_3_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_3_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_3_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_3_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_3_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_3_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_3_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_3_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_3_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_3_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_3_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_3_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_3_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_3_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_3_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_3_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_3_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_3_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_3_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_4 || ''),
					accountCurrency: currencyMap(fields.primary_account_4_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_4_successor || ''),
					jurisdiction: getValue(fields.primary_account_4_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_4_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_4_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_4_bene_1_first_name),
							lastName: getValue(fields.primary_account_4_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_4_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_4_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_4_bene_1_dob),
							relationship: getValue(fields.primary_account_4_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_4_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_4_bene_2_first_name),
							lastName: getValue(fields.primary_account_4_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_4_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_4_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_4_bene_2_dob),
							relationship: getValue(fields.primary_account_4_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_4_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_4_bene_3_first_name),
							lastName: getValue(fields.primary_account_4_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_4_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_4_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_4_bene_3_dob),
							relationship: getValue(fields.primary_account_4_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_4_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_4_bene_4_first_name),
							lastName: getValue(fields.primary_account_4_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_4_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_4_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_4_bene_4_dob),
							relationship: getValue(fields.primary_account_4_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_4_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_4_transfer_1_institution_name,
							fields.primary_account_4_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_4_transfer_1_institution_name,
							fields.primary_account_4_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_4_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_4_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_4_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_4_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_4_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_4_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_4_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_4_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_4_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_4_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_4_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_4_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_4_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_4_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_4_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_4_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_4_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_4_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_4_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_4_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_4_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_4_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_4_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_4_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_4_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_4_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_4_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_4_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_4_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_4_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_4_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_4_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_5 || ''),
					accountCurrency: currencyMap(fields.primary_account_5_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_5_successor || ''),
					jurisdiction: getValue(fields.primary_account_5_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_5_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_5_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_5_bene_1_first_name),
							lastName: getValue(fields.primary_account_5_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_5_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_5_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_5_bene_1_dob),
							relationship: getValue(fields.primary_account_5_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_5_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_5_bene_2_first_name),
							lastName: getValue(fields.primary_account_5_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_5_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_5_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_5_bene_2_dob),
							relationship: getValue(fields.primary_account_5_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_5_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_5_bene_3_first_name),
							lastName: getValue(fields.primary_account_5_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_5_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_5_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_5_bene_3_dob),
							relationship: getValue(fields.primary_account_5_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_5_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_5_bene_4_first_name),
							lastName: getValue(fields.primary_account_5_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_5_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_5_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_5_bene_4_dob),
							relationship: getValue(fields.primary_account_5_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_5_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_5_transfer_1_institution_name,
							fields.primary_account_5_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_5_transfer_1_institution_name,
							fields.primary_account_5_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_5_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_5_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_5_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_5_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_5_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_5_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_5_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_5_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_5_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_5_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_5_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_5_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_5_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_5_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_5_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_5_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_5_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_5_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_5_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_5_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_5_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_5_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_5_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_5_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_5_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_5_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_5_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_5_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_5_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_5_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_5_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_5_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_7 || ''),
					accountCurrency: currencyMap(fields.primary_account_7_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_7_successor || ''),
					jurisdiction: getValue(fields.primary_account_7_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_7_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_7_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_7_bene_1_first_name),
							lastName: getValue(fields.primary_account_7_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_7_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_7_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_7_bene_1_dob),
							relationship: getValue(fields.primary_account_7_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_7_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_7_bene_2_first_name),
							lastName: getValue(fields.primary_account_7_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_7_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_7_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_7_bene_2_dob),
							relationship: getValue(fields.primary_account_7_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_7_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_7_bene_3_first_name),
							lastName: getValue(fields.primary_account_7_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_7_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_7_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_7_bene_3_dob),
							relationship: getValue(fields.primary_account_7_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_7_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_7_bene_4_first_name),
							lastName: getValue(fields.primary_account_7_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_7_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_7_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_7_bene_4_dob),
							relationship: getValue(fields.primary_account_7_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_7_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_7_transfer_1_institution_name,
							fields.primary_account_7_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_7_transfer_1_institution_name,
							fields.primary_account_7_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_7_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_7_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_7_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_7_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_7_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_7_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_7_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_7_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_7_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_7_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_7_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_7_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_7_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_7_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_7_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_7_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_7_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_7_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_7_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_7_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_7_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_7_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_7_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_7_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_7_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_7_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_7_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_7_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_7_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_7_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_7_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_7_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.primary_account_8 || ''),
					accountCurrency: currencyMap(fields.primary_account_8_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_8_successor || ''),
					jurisdiction: getValue(fields.primary_account_8_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.primary_account_8_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_8_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_8_bene_1_first_name),
							lastName: getValue(fields.primary_account_8_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_8_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_8_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_8_bene_1_dob),
							relationship: getValue(fields.primary_account_8_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_8_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_8_bene_2_first_name),
							lastName: getValue(fields.primary_account_8_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_8_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_8_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_8_bene_2_dob),
							relationship: getValue(fields.primary_account_8_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_8_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_8_bene_3_first_name),
							lastName: getValue(fields.primary_account_8_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_8_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_8_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_8_bene_3_dob),
							relationship: getValue(fields.primary_account_8_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_8_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_8_bene_4_first_name),
							lastName: getValue(fields.primary_account_8_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_8_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_8_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_8_bene_4_dob),
							relationship: getValue(fields.primary_account_8_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_8_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_8_transfer_1_institution_name,
							fields.primary_account_8_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_8_transfer_1_institution_name,
							fields.primary_account_8_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_8_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_8_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_8_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_8_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_8_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_8_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_8_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_8_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_8_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_8_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_8_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_8_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_8_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_8_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_8_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_8_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_8_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_8_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_8_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_8_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_8_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_8_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_8_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.primary_account_8_rrif_age || ''),
						paymentAmount: getNumber(fields.primary_account_8_payment_amount),
						paymentBlended: confirmTypeMap(fields.primary_account_8_payment_blended || ''),
						paymentDestination: paymentByMap(fields.primary_account_8_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.primary_account_8_payment_frequency || ''),
						paymentStartDate: getDate(fields.primary_account_8_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.primary_account_8_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.primary_account_8_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.primary_account_8_wt_percentage),
					},
				},
				{
					accountType: fields.primary_account_tfsa === 'Yes' ? AccountType.Tfsa : undefined,
					accountCurrency: currencyMap(fields.primary_account_tfsa_currency || ''),
					isSuccessor: booleanTypeMap(fields.primary_account_tfsa_successor || ''),
					isBeneficiary: Boolean(fields.primary_account_tfsa_bene_number),
					beneficiaryNum: beneTypeMap(fields.primary_account_tfsa_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.primary_account_tfsa_bene_1_first_name),
							lastName: getValue(fields.primary_account_tfsa_bene_1_last_name),
							middleInitial: getValue(fields.primary_account_tfsa_bene_1_middle_initial),
							allocation: getNumber(fields.primary_account_tfsa_bene_1_allocation),
							dateOfBirth: getDate(fields.primary_account_tfsa_bene_1_dob),
							relationship: getValue(fields.primary_account_tfsa_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_tfsa_bene_1_sin),
						},
						{
							firstName: getValue(fields.primary_account_tfsa_bene_2_first_name),
							lastName: getValue(fields.primary_account_tfsa_bene_2_last_name),
							middleInitial: getValue(fields.primary_account_tfsa_bene_2_middle_initial),
							allocation: getNumber(fields.primary_account_tfsa_bene_2_allocation),
							dateOfBirth: getDate(fields.primary_account_tfsa_bene_2_dob),
							relationship: getValue(fields.primary_account_tfsa_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_tfsa_bene_2_sin),
						},
						{
							firstName: getValue(fields.primary_account_tfsa_bene_3_first_name),
							lastName: getValue(fields.primary_account_tfsa_bene_3_last_name),
							middleInitial: getValue(fields.primary_account_tfsa_bene_3_middle_initial),
							allocation: getNumber(fields.primary_account_tfsa_bene_3_allocation),
							dateOfBirth: getDate(fields.primary_account_tfsa_bene_3_dob),
							relationship: getValue(fields.primary_account_tfsa_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_tfsa_bene_3_sin),
						},
						{
							firstName: getValue(fields.primary_account_tfsa_bene_4_first_name),
							lastName: getValue(fields.primary_account_tfsa_bene_4_last_name),
							middleInitial: getValue(fields.primary_account_tfsa_bene_4_middle_initial),
							allocation: getNumber(fields.primary_account_tfsa_bene_4_allocation),
							dateOfBirth: getDate(fields.primary_account_tfsa_bene_4_dob),
							relationship: getValue(fields.primary_account_tfsa_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.primary_account_tfsa_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.primary_account_tfsa_transfer_1_institution_name,
							fields.primary_account_tfsa_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.primary_account_tfsa_transfer_1_institution_name,
							fields.primary_account_tfsa_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.primary_account_tfsa_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_tfsa_transfer_1_currency || ''),
							transferAmount: getNumber(fields.primary_account_tfsa_transfer_1_amount),
							deliveryNumber: getValue(fields.primary_account_tfsa_transfer_1_delivering_number),
							institutionAddress: getValue(fields.primary_account_tfsa_transfer_1_institution_address),
							institutionCity: getValue(fields.primary_account_tfsa_transfer_1_institution_city),
							institutionName: getValue(fields.primary_account_tfsa_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.primary_account_tfsa_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_tfsa_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.primary_account_tfsa_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.primary_account_tfsa_transfer_2_currency || ''),
							transferAmount: getNumber(fields.primary_account_tfsa_transfer_2_amount),
							deliveryNumber: getValue(fields.primary_account_tfsa_transfer_2_delivering_number),
							institutionAddress: getValue(fields.primary_account_tfsa_transfer_2_institution_address),
							institutionCity: getValue(fields.primary_account_tfsa_transfer_2_institution_city),
							institutionName: getValue(fields.primary_account_tfsa_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.primary_account_tfsa_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.primary_account_tfsa_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.primary_account_tfsa_pac_swp),
					pacSwp: {
						type: getValue(fields.primary_account_tfsa_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.primary_account_tfsa_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.primary_account_tfsa_pac_swp_frequency || ''),
						startDate: getDate(fields.primary_account_tfsa_pac_swp_date),
					},
				},
			],
		},
		clientTwo: {
			individualAccounts: [
				{
					accountType: accountTypeMap(fields.jt_account_1 || ''),
					accountCurrency: currencyMap(fields.jt_account_1_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_1_successor || ''),
					jurisdiction: getValue(fields.jt_account_1_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_1_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_1_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_1_bene_1_first_name),
							lastName: getValue(fields.jt_account_1_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_1_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_1_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_1_bene_1_dob),
							relationship: getValue(fields.jt_account_1_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_1_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_1_bene_2_first_name),
							lastName: getValue(fields.jt_account_1_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_1_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_1_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_1_bene_2_dob),
							relationship: getValue(fields.jt_account_1_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_1_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_1_bene_3_first_name),
							lastName: getValue(fields.jt_account_1_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_1_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_1_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_1_bene_3_dob),
							relationship: getValue(fields.jt_account_1_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_1_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_1_bene_4_first_name),
							lastName: getValue(fields.jt_account_1_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_1_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_1_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_1_bene_4_dob),
							relationship: getValue(fields.jt_account_1_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_1_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_1_transfer_1_institution_name, fields.jt_account_1_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_1_transfer_1_institution_name, fields.jt_account_1_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_1_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_1_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_1_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_1_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_1_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_1_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_1_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_1_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_1_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_1_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_1_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_1_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_1_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_1_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_1_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_1_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_1_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_1_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_1_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_1_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_1_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_1_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_1_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_1_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_1_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_1_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_1_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_1_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_1_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_1_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_1_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_1_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_2 || ''),
					accountCurrency: currencyMap(fields.jt_account_2_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_2_successor || ''),
					jurisdiction: getValue(fields.jt_account_2_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_2_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_2_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_2_bene_1_first_name),
							lastName: getValue(fields.jt_account_2_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_2_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_2_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_2_bene_1_dob),
							relationship: getValue(fields.jt_account_2_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_2_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_2_bene_2_first_name),
							lastName: getValue(fields.jt_account_2_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_2_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_2_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_2_bene_2_dob),
							relationship: getValue(fields.jt_account_2_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_2_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_2_bene_3_first_name),
							lastName: getValue(fields.jt_account_2_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_2_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_2_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_2_bene_3_dob),
							relationship: getValue(fields.jt_account_2_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_2_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_2_bene_4_first_name),
							lastName: getValue(fields.jt_account_2_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_2_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_2_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_2_bene_4_dob),
							relationship: getValue(fields.jt_account_2_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_2_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_2_transfer_1_institution_name, fields.jt_account_2_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_2_transfer_1_institution_name, fields.jt_account_2_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_2_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_2_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_2_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_2_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_2_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_2_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_2_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_2_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_2_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_2_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_2_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_2_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_2_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_2_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_2_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_2_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_2_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_2_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_2_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_2_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_2_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_2_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_2_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_2_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_2_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_2_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_2_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_2_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_2_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_2_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_2_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_2_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_3 || ''),
					accountCurrency: currencyMap(fields.jt_account_3_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_3_successor || ''),
					jurisdiction: getValue(fields.jt_account_3_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_3_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_3_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_3_bene_1_first_name),
							lastName: getValue(fields.jt_account_3_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_3_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_3_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_3_bene_1_dob),
							relationship: getValue(fields.jt_account_3_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_3_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_3_bene_2_first_name),
							lastName: getValue(fields.jt_account_3_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_3_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_3_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_3_bene_2_dob),
							relationship: getValue(fields.jt_account_3_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_3_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_3_bene_3_first_name),
							lastName: getValue(fields.jt_account_3_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_3_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_3_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_3_bene_3_dob),
							relationship: getValue(fields.jt_account_3_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_3_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_3_bene_4_first_name),
							lastName: getValue(fields.jt_account_3_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_3_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_3_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_3_bene_4_dob),
							relationship: getValue(fields.jt_account_3_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_3_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_3_transfer_1_institution_name, fields.jt_account_3_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_3_transfer_1_institution_name, fields.jt_account_3_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_3_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_3_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_3_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_3_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_3_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_3_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_3_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_3_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_3_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_3_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_3_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_3_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_3_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_3_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_3_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_3_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_3_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_3_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_3_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_3_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_3_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_3_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_3_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_3_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_3_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_3_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_3_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_3_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_3_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_3_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_3_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_3_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_4 || ''),
					accountCurrency: currencyMap(fields.jt_account_4_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_4_successor || ''),
					jurisdiction: getValue(fields.jt_account_4_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_4_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_4_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_4_bene_1_first_name),
							lastName: getValue(fields.jt_account_4_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_4_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_4_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_4_bene_1_dob),
							relationship: getValue(fields.jt_account_4_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_4_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_4_bene_2_first_name),
							lastName: getValue(fields.jt_account_4_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_4_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_4_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_4_bene_2_dob),
							relationship: getValue(fields.jt_account_4_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_4_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_4_bene_3_first_name),
							lastName: getValue(fields.jt_account_4_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_4_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_4_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_4_bene_3_dob),
							relationship: getValue(fields.jt_account_4_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_4_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_4_bene_4_first_name),
							lastName: getValue(fields.jt_account_4_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_4_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_4_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_4_bene_4_dob),
							relationship: getValue(fields.jt_account_4_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_4_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_4_transfer_1_institution_name, fields.jt_account_4_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_4_transfer_1_institution_name, fields.jt_account_4_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_4_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_4_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_4_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_4_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_4_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_4_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_4_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_4_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_4_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_4_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_4_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_4_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_4_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_4_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_4_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_4_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_4_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_4_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_4_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_4_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_4_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_4_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_4_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_4_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_4_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_4_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_4_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_4_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_4_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_4_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_4_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_4_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_5 || ''),
					accountCurrency: currencyMap(fields.jt_account_5_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_5_successor || ''),
					jurisdiction: getValue(fields.jt_account_5_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_5_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_5_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_5_bene_1_first_name),
							lastName: getValue(fields.jt_account_5_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_5_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_5_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_5_bene_1_dob),
							relationship: getValue(fields.jt_account_5_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_5_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_5_bene_2_first_name),
							lastName: getValue(fields.jt_account_5_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_5_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_5_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_5_bene_2_dob),
							relationship: getValue(fields.jt_account_5_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_5_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_5_bene_3_first_name),
							lastName: getValue(fields.jt_account_5_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_5_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_5_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_5_bene_3_dob),
							relationship: getValue(fields.jt_account_5_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_5_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_5_bene_4_first_name),
							lastName: getValue(fields.jt_account_5_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_5_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_5_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_5_bene_4_dob),
							relationship: getValue(fields.jt_account_5_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_5_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_5_transfer_1_institution_name, fields.jt_account_5_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_5_transfer_1_institution_name, fields.jt_account_5_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_5_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_5_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_5_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_5_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_5_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_5_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_5_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_5_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_5_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_5_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_5_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_5_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_5_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_5_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_5_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_5_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_5_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_5_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_5_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_5_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_5_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_5_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_5_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_5_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_5_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_5_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_5_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_5_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_5_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_5_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_5_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_5_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_7 || ''),
					accountCurrency: currencyMap(fields.jt_account_7_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_7_successor || ''),
					jurisdiction: getValue(fields.jt_account_7_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_7_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_7_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_7_bene_1_first_name),
							lastName: getValue(fields.jt_account_7_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_7_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_7_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_7_bene_1_dob),
							relationship: getValue(fields.jt_account_7_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_7_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_7_bene_2_first_name),
							lastName: getValue(fields.jt_account_7_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_7_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_7_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_7_bene_2_dob),
							relationship: getValue(fields.jt_account_7_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_7_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_7_bene_3_first_name),
							lastName: getValue(fields.jt_account_7_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_7_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_7_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_7_bene_3_dob),
							relationship: getValue(fields.jt_account_7_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_7_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_7_bene_4_first_name),
							lastName: getValue(fields.jt_account_7_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_7_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_7_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_7_bene_4_dob),
							relationship: getValue(fields.jt_account_7_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_7_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_7_transfer_1_institution_name, fields.jt_account_7_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_7_transfer_1_institution_name, fields.jt_account_7_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_7_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_7_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_7_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_7_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_7_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_7_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_7_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_7_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_7_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_7_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_7_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_7_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_7_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_7_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_7_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_7_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_7_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_7_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_7_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_7_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_7_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_7_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_7_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_7_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_7_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_7_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_7_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_7_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_7_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_7_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_7_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_7_wt_percentage),
					},
				},
				{
					accountType: accountTypeMap(fields.jt_account_8 || ''),
					accountCurrency: currencyMap(fields.jt_account_8_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_8_successor || ''),
					jurisdiction: getValue(fields.jt_account_8_jurisdiction) as JurisdictionType,
					isBeneficiary: Boolean(fields.jt_account_8_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_8_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_8_bene_1_first_name),
							lastName: getValue(fields.jt_account_8_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_8_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_8_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_8_bene_1_dob),
							relationship: getValue(fields.jt_account_8_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_8_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_8_bene_2_first_name),
							lastName: getValue(fields.jt_account_8_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_8_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_8_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_8_bene_2_dob),
							relationship: getValue(fields.jt_account_8_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_8_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_8_bene_3_first_name),
							lastName: getValue(fields.jt_account_8_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_8_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_8_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_8_bene_3_dob),
							relationship: getValue(fields.jt_account_8_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_8_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_8_bene_4_first_name),
							lastName: getValue(fields.jt_account_8_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_8_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_8_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_8_bene_4_dob),
							relationship: getValue(fields.jt_account_8_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_8_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[fields.jt_account_8_transfer_1_institution_name, fields.jt_account_8_transfer_2_institution_name].filter(
							Boolean,
						).length,
					),
					transferNum:
						([fields.jt_account_8_transfer_1_institution_name, fields.jt_account_8_transfer_2_institution_name].filter(
							Boolean,
						).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_8_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_8_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_8_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_8_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_8_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_8_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_8_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_8_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_8_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_8_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_8_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_8_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_8_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_8_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_8_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_8_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_8_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_8_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_8_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_8_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_8_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_8_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_8_pac_swp_date),
					},
					riffPayments: {
						age: rrifAgeMap(fields.jt_account_8_rrif_age || ''),
						paymentAmount: getNumber(fields.jt_account_8_payment_amount),
						paymentBlended: confirmTypeMap(fields.jt_account_8_payment_blended || ''),
						paymentDestination: paymentByMap(fields.jt_account_8_payment_by || ''),
						paymentFrequency: paymentFrequencyMap(fields.jt_account_8_payment_frequency || ''),
						paymentStartDate: getDate(fields.jt_account_8_payment_startdate),
						paymentType: rrifPaymentTypeMap(fields.jt_account_8_payment_type || ''),
						paymentWithholdingTax: rrifWithholdingTaxMap(fields.jt_account_8_wt_on || ''),
						paymentWithholdingTaxPercent: getNumber(fields.jt_account_8_wt_percentage),
					},
				},
				{
					accountType: fields.jt_account_tfsa === 'Yes' ? AccountType.Tfsa : undefined,
					accountCurrency: currencyMap(fields.jt_account_tfsa_currency || ''),
					isSuccessor: booleanTypeMap(fields.jt_account_tfsa_successor || ''),
					isBeneficiary: Boolean(fields.jt_account_tfsa_bene_number),
					beneficiaryNum: beneTypeMap(fields.jt_account_tfsa_bene_number),
					beneficiary: [
						{
							firstName: getValue(fields.jt_account_tfsa_bene_1_first_name),
							lastName: getValue(fields.jt_account_tfsa_bene_1_last_name),
							middleInitial: getValue(fields.jt_account_tfsa_bene_1_middle_initial),
							allocation: getNumber(fields.jt_account_tfsa_bene_1_allocation),
							dateOfBirth: getDate(fields.jt_account_tfsa_bene_1_dob),
							relationship: getValue(fields.jt_account_tfsa_bene_1_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_tfsa_bene_1_sin),
						},
						{
							firstName: getValue(fields.jt_account_tfsa_bene_2_first_name),
							lastName: getValue(fields.jt_account_tfsa_bene_2_last_name),
							middleInitial: getValue(fields.jt_account_tfsa_bene_2_middle_initial),
							allocation: getNumber(fields.jt_account_tfsa_bene_2_allocation),
							dateOfBirth: getDate(fields.jt_account_tfsa_bene_2_dob),
							relationship: getValue(fields.jt_account_tfsa_bene_2_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_tfsa_bene_2_sin),
						},
						{
							firstName: getValue(fields.jt_account_tfsa_bene_3_first_name),
							lastName: getValue(fields.jt_account_tfsa_bene_3_last_name),
							middleInitial: getValue(fields.jt_account_tfsa_bene_3_middle_initial),
							allocation: getNumber(fields.jt_account_tfsa_bene_3_allocation),
							dateOfBirth: getDate(fields.jt_account_tfsa_bene_3_dob),
							relationship: getValue(fields.jt_account_tfsa_bene_3_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_tfsa_bene_3_sin),
						},
						{
							firstName: getValue(fields.jt_account_tfsa_bene_4_first_name),
							lastName: getValue(fields.jt_account_tfsa_bene_4_last_name),
							middleInitial: getValue(fields.jt_account_tfsa_bene_4_middle_initial),
							allocation: getNumber(fields.jt_account_tfsa_bene_4_allocation),
							dateOfBirth: getDate(fields.jt_account_tfsa_bene_4_dob),
							relationship: getValue(fields.jt_account_tfsa_bene_4_relationship) as BeneRelationshipType,
							sin: getNumber(fields.jt_account_tfsa_bene_4_sin),
						},
					],
					isTransfer: Boolean(
						[
							fields.jt_account_tfsa_transfer_1_institution_name,
							fields.jt_account_tfsa_transfer_2_institution_name,
						].filter(Boolean).length,
					),
					transferNum:
						([
							fields.jt_account_tfsa_transfer_1_institution_name,
							fields.jt_account_tfsa_transfer_2_institution_name,
						].filter(Boolean).length as 1 | 2) || undefined,
					transfers: [
						{
							transferType: transferTypeMap(fields.jt_account_tfsa_transfer_1_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_tfsa_transfer_1_currency || ''),
							transferAmount: getNumber(fields.jt_account_tfsa_transfer_1_amount),
							deliveryNumber: getValue(fields.jt_account_tfsa_transfer_1_delivering_number),
							institutionAddress: getValue(fields.jt_account_tfsa_transfer_1_institution_address),
							institutionCity: getValue(fields.jt_account_tfsa_transfer_1_institution_city),
							institutionName: getValue(fields.jt_account_tfsa_transfer_1_institution_name),
							institutionPostalCode: getValue(fields.jt_account_tfsa_transfer_1_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_tfsa_transfer_1_institution_province,
							) as TransferProvinceType,
						},
						{
							transferType: transferTypeMap(fields.jt_account_tfsa_transfer_2_transfer_type || ''),
							transferCurrency: currencyMap(fields.jt_account_tfsa_transfer_2_currency || ''),
							transferAmount: getNumber(fields.jt_account_tfsa_transfer_2_amount),
							deliveryNumber: getValue(fields.jt_account_tfsa_transfer_2_delivering_number),
							institutionAddress: getValue(fields.jt_account_tfsa_transfer_2_institution_address),
							institutionCity: getValue(fields.jt_account_tfsa_transfer_2_institution_city),
							institutionName: getValue(fields.jt_account_tfsa_transfer_2_institution_name),
							institutionPostalCode: getValue(fields.jt_account_tfsa_transfer_2_institution_postal),
							institutionProvince: getValue(
								fields.jt_account_tfsa_transfer_2_institution_province,
							) as TransferProvinceType,
						},
					],
					isPacSwp: Boolean(fields.jt_account_tfsa_pac_swp),
					pacSwp: {
						type: getValue(fields.jt_account_tfsa_pac_swp) as PacOrSwpType,
						amount: getNumber(fields.jt_account_tfsa_pac_swp_amount),
						frequency: paymentFrequencyMap(fields.jt_account_tfsa_pac_swp_frequency || ''),
						startDate: getDate(fields.jt_account_tfsa_pac_swp_date),
					},
				},
			],
		},
		jointAccounts: [
			{
				accountType: accountTypeJtMap(fields.account_jt_1_account_type || ''),
				accountCurrency: currencyMap(fields.account_jt_1_currency || ''),
				ownershipStructure: fields.jtwros_or_tic_1 as JtwrosOrTicType,
				ticPercentage: getNumber(fields.tic_percentage_1),
				beneficiary: [
					{
						firstName: getValue(fields.account_jt_1_ITF_bene_1_first_name),
						lastName: getValue(fields.account_jt_1_ITF_bene_1_last_name),
						middleInitial: getValue(fields.account_jt_1_ITF_bene_1_middle_initial),
						allocation: getNumber(fields.account_jt_1_ITF_bene_1_allocation),
						dateOfBirth: getDate(fields.account_jt_1_ITF_bene_1_dob),
						relationship: getValue(fields.account_jt_1_ITF_bene_1_relationship) as BeneRelationshipType,
						sin: getNumber(fields.account_jt_1_ITF_bene_1_sin),
					},
				],
				isTransfer: Boolean(
					[fields.account_jt_1_transfer_1_institution_name, fields.account_jt_1_transfer_2_institution_name].filter(
						Boolean,
					).length,
				),
				transferNum:
					([fields.account_jt_1_transfer_1_institution_name, fields.account_jt_1_transfer_2_institution_name].filter(
						Boolean,
					).length as 1 | 2) || undefined,
				transfers: [
					{
						transferType: transferTypeMap(fields.account_jt_1_transfer_1_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_1_transfer_1_currency || ''),
						transferAmount: getNumber(fields.account_jt_1_transfer_1_amount),
						deliveryNumber: getValue(fields.account_jt_1_transfer_1_delivering_number),
						institutionAddress: getValue(fields.account_jt_1_transfer_1_institution_address),
						institutionCity: getValue(fields.account_jt_1_transfer_1_institution_city),
						institutionName: getValue(fields.account_jt_1_transfer_1_institution_name),
						institutionPostalCode: getValue(fields.account_jt_1_transfer_1_institution_postal),
						institutionProvince: getValue(fields.account_jt_1_transfer_1_institution_province) as TransferProvinceType,
					},
					{
						transferType: transferTypeMap(fields.account_jt_1_transfer_2_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_1_transfer_2_currency || ''),
						transferAmount: getNumber(fields.account_jt_1_transfer_2_amount),
						deliveryNumber: getValue(fields.account_jt_1_transfer_2_delivering_number),
						institutionAddress: getValue(fields.account_jt_1_transfer_2_institution_address),
						institutionCity: getValue(fields.account_jt_1_transfer_2_institution_city),
						institutionName: getValue(fields.account_jt_1_transfer_2_institution_name),
						institutionPostalCode: getValue(fields.account_jt_1_transfer_2_institution_postal),
						institutionProvince: getValue(fields.account_jt_1_transfer_2_institution_province) as TransferProvinceType,
					},
				],
				isPacSwp: Boolean(fields.account_jt_1_pac_swp),
				pacSwp: {
					type: getValue(fields.account_jt_1_pac_swp) as PacOrSwpType,
					amount: getNumber(fields.account_jt_1_pac_swp_amount),
					frequency: paymentFrequencyMap(fields.account_jt_1_pac_swp_frequency || ''),
					startDate: getDate(fields.account_jt_1_pac_swp_date),
					clientType: getPacSwpClientType(fields.account_jt_1_pac_swp_bank),
				},
			},
			{
				accountType: accountTypeJtMap(fields.account_jt_2_account_type || ''),
				accountCurrency: currencyMap(fields.account_jt_2_currency || ''),
				ownershipStructure: fields.jtwros_or_tic_2 as JtwrosOrTicType,
				ticPercentage: getNumber(fields.tic_percentage_2),
				beneficiary: [
					{
						firstName: getValue(fields.account_jt_2_ITF_bene_1_first_name),
						lastName: getValue(fields.account_jt_2_ITF_bene_1_last_name),
						middleInitial: getValue(fields.account_jt_2_ITF_bene_1_middle_initial),
						allocation: getNumber(fields.account_jt_2_ITF_bene_1_allocation),
						dateOfBirth: getDate(fields.account_jt_2_ITF_bene_1_dob),
						relationship: getValue(fields.account_jt_2_ITF_bene_1_relationship) as BeneRelationshipType,
						sin: getNumber(fields.account_jt_2_ITF_bene_1_sin),
					},
				],
				isTransfer: Boolean(
					[fields.account_jt_2_transfer_1_institution_name, fields.account_jt_2_transfer_2_institution_name].filter(
						Boolean,
					).length,
				),
				transferNum:
					([fields.account_jt_2_transfer_1_institution_name, fields.account_jt_2_transfer_2_institution_name].filter(
						Boolean,
					).length as 1 | 2) || undefined,
				transfers: [
					{
						transferType: transferTypeMap(fields.account_jt_2_transfer_1_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_2_transfer_1_currency || ''),
						transferAmount: getNumber(fields.account_jt_2_transfer_1_amount),
						deliveryNumber: getValue(fields.account_jt_2_transfer_1_delivering_number),
						institutionAddress: getValue(fields.account_jt_2_transfer_1_institution_address),
						institutionCity: getValue(fields.account_jt_2_transfer_1_institution_city),
						institutionName: getValue(fields.account_jt_2_transfer_1_institution_name),
						institutionPostalCode: getValue(fields.account_jt_2_transfer_1_institution_postal),
						institutionProvince: getValue(fields.account_jt_2_transfer_1_institution_province) as TransferProvinceType,
					},
					{
						transferType: transferTypeMap(fields.account_jt_2_transfer_2_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_2_transfer_2_currency || ''),
						transferAmount: getNumber(fields.account_jt_2_transfer_2_amount),
						deliveryNumber: getValue(fields.account_jt_2_transfer_2_delivering_number),
						institutionAddress: getValue(fields.account_jt_2_transfer_2_institution_address),
						institutionCity: getValue(fields.account_jt_2_transfer_2_institution_city),
						institutionName: getValue(fields.account_jt_2_transfer_2_institution_name),
						institutionPostalCode: getValue(fields.account_jt_2_transfer_2_institution_postal),
						institutionProvince: getValue(fields.account_jt_2_transfer_2_institution_province) as TransferProvinceType,
					},
				],
				isPacSwp: Boolean(fields.account_jt_2_pac_swp),
				pacSwp: {
					type: getValue(fields.account_jt_2_pac_swp) as PacOrSwpType,
					amount: getNumber(fields.account_jt_2_pac_swp_amount),
					frequency: paymentFrequencyMap(fields.account_jt_2_pac_swp_frequency || ''),
					startDate: getDate(fields.account_jt_2_pac_swp_date),
					clientType: getPacSwpClientType(fields.account_jt_2_pac_swp_bank),
				},
			},
			{
				accountType: accountTypeJtMap(fields.account_jt_3_account_type || ''),
				accountCurrency: currencyMap(fields.account_jt_3_currency || ''),
				ownershipStructure: fields.jtwros_or_tic_3 as JtwrosOrTicType,
				ticPercentage: getNumber(fields.tic_percentage_3),
				beneficiary: [
					{
						firstName: getValue(fields.account_jt_3_ITF_bene_1_first_name),
						lastName: getValue(fields.account_jt_3_ITF_bene_1_last_name),
						middleInitial: getValue(fields.account_jt_3_ITF_bene_1_middle_initial),
						allocation: getNumber(fields.account_jt_3_ITF_bene_1_allocation),
						dateOfBirth: getDate(fields.account_jt_3_ITF_bene_1_dob),
						relationship: getValue(fields.account_jt_3_ITF_bene_1_relationship) as BeneRelationshipType,
						sin: getNumber(fields.account_jt_3_ITF_bene_1_sin),
					},
				],
				isTransfer: Boolean(
					[fields.account_jt_3_transfer_1_institution_name, fields.account_jt_3_transfer_2_institution_name].filter(
						Boolean,
					).length,
				),
				transferNum:
					([fields.account_jt_3_transfer_1_institution_name, fields.account_jt_3_transfer_2_institution_name].filter(
						Boolean,
					).length as 1 | 2) || undefined,
				transfers: [
					{
						transferType: transferTypeMap(fields.account_jt_3_transfer_1_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_3_transfer_1_currency || ''),
						transferAmount: getNumber(fields.account_jt_3_transfer_1_amount),
						deliveryNumber: getValue(fields.account_jt_3_transfer_1_delivering_number),
						institutionAddress: getValue(fields.account_jt_3_transfer_1_institution_address),
						institutionCity: getValue(fields.account_jt_3_transfer_1_institution_city),
						institutionName: getValue(fields.account_jt_3_transfer_1_institution_name),
						institutionPostalCode: getValue(fields.account_jt_3_transfer_1_institution_postal),
						institutionProvince: getValue(fields.account_jt_3_transfer_1_institution_province) as TransferProvinceType,
					},
					{
						transferType: transferTypeMap(fields.account_jt_3_transfer_2_transfer_type || ''),
						transferCurrency: currencyMap(fields.account_jt_3_transfer_2_currency || ''),
						transferAmount: getNumber(fields.account_jt_3_transfer_2_amount),
						deliveryNumber: getValue(fields.account_jt_3_transfer_2_delivering_number),
						institutionAddress: getValue(fields.account_jt_3_transfer_2_institution_address),
						institutionCity: getValue(fields.account_jt_3_transfer_2_institution_city),
						institutionName: getValue(fields.account_jt_3_transfer_2_institution_name),
						institutionPostalCode: getValue(fields.account_jt_3_transfer_2_institution_postal),
						institutionProvince: getValue(fields.account_jt_3_transfer_2_institution_province) as TransferProvinceType,
					},
				],
				isPacSwp: Boolean(fields.account_jt_3_pac_swp),
				pacSwp: {
					type: getValue(fields.account_jt_3_pac_swp) as PacOrSwpType,
					amount: getNumber(fields.account_jt_3_pac_swp_amount),
					frequency: paymentFrequencyMap(fields.account_jt_3_pac_swp_frequency || ''),
					startDate: getDate(fields.account_jt_3_pac_swp_date),
					clientType: getPacSwpClientType(fields.account_jt_3_pac_swp_bank),
				},
			},
		],
		existingAccounts: {
			clientOne: {
				accounts: [
					{
						type: clientAssetsAccountMap(fields.client1_assets_1_account || ''),
						value: getNumber(fields.client1_assets_1_value),
					},
					{
						type: clientAssetsAccountMap(fields.client1_assets_2_account || ''),
						value: getNumber(fields.client1_assets_2_value),
					},
					{
						type: clientAssetsAccountMap(fields.client1_assets_3_account || ''),
						value: getNumber(fields.client1_assets_3_value),
					},
					{
						type: clientAssetsAccountMap(fields.client1_assets_4_account || ''),
						value: getNumber(fields.client1_assets_4_value),
					},
					{
						type: clientAssetsAccountMap(fields.client1_assets_5_account || ''),
						value: getNumber(fields.client1_assets_5_value),
					},
				],
				//null or undefined = enabled
				isNonSolicitFormEnabled: fields.client1_isNonSolicitFormEnabled === false ? false : true,
			},
			clientTwo: {
				accounts: [
					{
						type: clientAssetsAccountMap(fields.client2_assets_1_account || ''),
						value: getNumber(fields.client2_assets_1_value),
					},
					{
						type: clientAssetsAccountMap(fields.client2_assets_2_account || ''),
						value: getNumber(fields.client2_assets_2_value),
					},
					{
						type: clientAssetsAccountMap(fields.client2_assets_3_account || ''),
						value: getNumber(fields.client2_assets_3_value),
					},
					{
						type: clientAssetsAccountMap(fields.client2_assets_4_account || ''),
						value: getNumber(fields.client2_assets_4_value),
					},
					{
						type: clientAssetsAccountMap(fields.client2_assets_5_account || ''),
						value: getNumber(fields.client2_assets_5_value),
					},
				],
				//null or undefined = enabled
				isNonSolicitFormEnabled: fields.client2_isNonSolicitFormEnabled === false ? false : true,
			},
			jointAccounts: {
				accounts: [
					{
						type: jointAssetsAccountMap(fields.joint_assets_1_account || ''),
						value: getNumber(fields.joint_assets_1_value),
					},
					{
						type: jointAssetsAccountMap(fields.joint_assets_2_account || ''),
						value: getNumber(fields.joint_assets_2_value),
					},
					{
						type: jointAssetsAccountMap(fields.joint_assets_3_account || ''),
						value: getNumber(fields.joint_assets_3_value),
					},
					{
						type: jointAssetsAccountMap(fields.joint_assets_4_account || ''),
						value: getNumber(fields.joint_assets_4_value),
					},
					{
						type: jointAssetsAccountMap(fields.joint_assets_5_account || ''),
						value: getNumber(fields.joint_assets_5_value),
					},
				],
			},
			entityAccounts: {
				accounts: [
					{
						type: entityAssetsAccountMap(fields.entity_assets_1_account || ''),
						value: getNumber(fields.entity_assets_1_value),
						name: getValue(fields.entity_assets_1_name),
					},
					{
						type: entityAssetsAccountMap(fields.entity_assets_2_account || ''),
						value: getNumber(fields.entity_assets_2_value),
						name: getValue(fields.entity_assets_2_name),
					},
					{
						type: entityAssetsAccountMap(fields.entity_assets_3_account || ''),
						value: getNumber(fields.entity_assets_3_value),
						name: getValue(fields.entity_assets_3_name),
					},
					{
						type: entityAssetsAccountMap(fields.entity_assets_4_account || ''),
						value: getNumber(fields.entity_assets_4_value),
						name: getValue(fields.entity_assets_4_name),
					},
					{
						type: entityAssetsAccountMap(fields.entity_assets_5_account || ''),
						value: getNumber(fields.entity_assets_5_value),
						name: getValue(fields.entity_assets_5_name),
					},
				],
			},
		},
	}

	if (result.clientOne?.individualAccounts) {
		result.clientOne.individualAccounts = result.clientOne.individualAccounts.filter((acc) => acc?.accountType)
	}

	if (result.clientTwo?.individualAccounts) {
		result.clientTwo.individualAccounts = result.clientTwo.individualAccounts.filter((acc) => acc?.accountType)
	}

	if (result.jointAccounts) {
		result.jointAccounts = result.jointAccounts.filter((acc) => acc?.accountType)
	}

	const existingAccounts = result.existingAccounts

	if (existingAccounts?.clientOne?.accounts) {
		existingAccounts.clientOne.accounts = existingAccounts?.clientOne?.accounts.filter((acc) => acc?.type)
		existingAccounts.clientOne.accountsNum = existingAccounts.clientOne.accounts.length || (undefined as any)
		existingAccounts.clientOne.isExistingAccounts = Boolean(existingAccounts.clientOne.accountsNum)
	}

	if (existingAccounts?.clientTwo?.accounts) {
		existingAccounts.clientTwo.accounts = existingAccounts?.clientTwo?.accounts.filter((acc) => acc?.type)
		existingAccounts.clientTwo.accountsNum = existingAccounts.clientTwo.accounts.length || (undefined as any)
		existingAccounts.clientTwo.isExistingAccounts = Boolean(existingAccounts.clientTwo.accountsNum)
	}

	if (existingAccounts?.jointAccounts?.accounts) {
		existingAccounts.jointAccounts.accounts = existingAccounts?.jointAccounts?.accounts.filter((acc) => acc?.type)
		existingAccounts.jointAccounts.accountsNum = existingAccounts.jointAccounts.accounts.length || (undefined as any)
		existingAccounts.jointAccounts.isExistingAccounts = Boolean(existingAccounts.jointAccounts.accountsNum)
	}

	if (existingAccounts?.entityAccounts?.accounts) {
		existingAccounts.entityAccounts.accounts = existingAccounts?.entityAccounts?.accounts.filter((acc) => acc?.type)
		existingAccounts.entityAccounts.accountsNum = existingAccounts.entityAccounts.accounts.length || (undefined as any)
		existingAccounts.entityAccounts.isExistingAccounts = Boolean(existingAccounts.entityAccounts.accountsNum)
	}

	return result
}
