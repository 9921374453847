import React, { FC, useEffect, useState } from 'react'
import { Button, Space, Table, Dropdown, message, Modal, Typography, Tooltip, Tag } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { EllipsisOutlined as EllipsisIco } from '@ant-design/icons'
import Initials from '../common/Initials'
import { EditableCell, EditableRow, getColumnSearchProps } from 'helpers/table'
import { ColumnTypeEditable, SORT_MAP } from 'types/admin'
import { adminModalProps } from '../modal/ModalProps'
import { MODAL } from 'types/admin'
import {
	FilterPortfolioAdminInput,
	PortfolioAdminsDocument,
	PortfolioAdminsQueryVariables,
	RemovePortfolioAdminDocument,
	Sort,
	SortingPortfolioAdminInput,
	UpdatePortfolioAdminDocument,
} from 'graphql/generated/schema'
import type { TableProps } from 'antd/lib/table'
import TabsActions from './TabsActions'
import { printError } from 'helpers/error'
import rfdc from 'rfdc'
import { useModal } from 'hooks/useContexts'
import ModalCreateUpdatePortfolioAdmin from '../modal/PortfolioAdmin/CreateUpdatePortfolioAdmin'

const deepClone = rfdc()
export interface DataTypePortfolioAdmin {
	id: number
	firstName: string
	lastName: string
	fullName: string
	email: string
	address: string
	phone: string
	rrCodes: string[]
}

interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterPortfolioAdminInput
	sorting: SortingPortfolioAdminInput
}

const PortfolioAdmins: FC = () => {
	const { activeModal, setActiveModal } = useModal()
	const [tableData, setTableData] = useState<DataTypePortfolioAdmin[]>([])
	const [activeRecord, setActiveRecord] = useState<DataTypePortfolioAdmin>()
	const [updatePortfolioAdmin] = useMutation(UpdatePortfolioAdminDocument)
	const [removePortfolioAdmin] = useMutation(RemovePortfolioAdminDocument)
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})

	const getSearchVariables = (): PortfolioAdminsQueryVariables => {
		const { pagination } = searchParams

		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const portfolioAdminsRes = useQuery(PortfolioAdminsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onError: (err) => {
			setTableData([])
			printError(err)
		},
		onCompleted: (data) => {
			const preparedData =
				data?.portfolioAdmins?.nodes?.map((pa): DataTypePortfolioAdmin => {
					return {
						id: pa.id,
						rrCodes: pa.rrCodes,
						email: pa.user.email,
						fullName: `${pa.user.firstName} ${pa.user.lastName}`,
						firstName: pa.user.firstName,
						lastName: pa.user.lastName,
						address: pa.user.address ?? '',
						phone: pa.user.phone ?? '',
					}
				}) ?? []

			setTableData(preparedData)
		},
	})

	useEffect(() => {
		portfolioAdminsRes.refetch()
	}, [searchParams])

	const columns: ColumnTypeEditable<DataTypePortfolioAdmin>[] = [
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '25%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypePortfolioAdmin>(),
			render: (name) => (
				<Initials
					name={name}
					color='orange'
				/>
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			width: '15%',
			render: (address) => <Tooltip title={address}>{address}</Tooltip>,
			...getColumnSearchProps<DataTypePortfolioAdmin>(),
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: '15%',
			...getColumnSearchProps<DataTypePortfolioAdmin>(),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			editable: true,
			className: 'editable-cell-ellipsis',
			width: '20%',
			...getColumnSearchProps<DataTypePortfolioAdmin>(),
		},
		{
			title: 'RR-Codes',
			dataIndex: 'rrCodes',
			key: 'rrCodes',
			ellipsis: true,
			render: (rrCodes: string[]) => (
				<Tooltip title={rrCodes.join(', ')}>
					{rrCodes.map((rrCode) => (
						<Tag key={rrCode}>{rrCode}</Tag>
					))}
				</Tooltip>
			),
			width: '17%',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: '10%',
			render: (_, record) => (
				<Space size='small'>
					<Button
						type='link'
						onClick={() => {
							setActiveRecord(record)
							setActiveModal(MODAL.UPDATE_PORTFOLIO_ADMIN)
						}}>
						Edit
					</Button>
					<Dropdown
						menu={{
							items: [
								{
									key: 1,
									label: (
										<div
											style={{ color: '#FF4D4F' }}
											onClick={() => onRowDelete(record)}>
											Delete
										</div>
									),
								},
							],
						}}
						placement='bottomRight'>
						<EllipsisIco />
					</Dropdown>
				</Space>
			),
		},
	]

	const onTableChange: TableProps<DataTypePortfolioAdmin>['onChange'] = (pagination, filters, sorter, extra) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingPortfolioAdminInput
				const sortOrder = sorter.order

				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	const handleSave = async (row: DataTypePortfolioAdmin, col: ColumnTypeEditable<DataTypePortfolioAdmin>) => {
		const colKey = col.dataIndex as keyof DataTypePortfolioAdmin

		updatePortfolioAdmin({
			variables: {
				updatePortfolioAdminInput: {
					id: row.id,
					[colKey]: row[colKey],
				},
			},
		})
			.then(() => {
				message.success(`${col.title} changed`)
				portfolioAdminsRes.refetch()
			})
			.catch(printError)
	}

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataTypePortfolioAdmin) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave: (row: DataTypePortfolioAdmin) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const onRowDelete = (record: DataTypePortfolioAdmin) => {
		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure that you want to delete this Portfolio Administrator?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph
						strong
						style={{ margin: 0 }}>
						{record.fullName}
					</Typography.Paragraph>
					<Typography.Paragraph type='secondary'>will be deleted forever from the system</Typography.Paragraph>
				</div>
			),
			okText: 'Delete',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removePortfolioAdmin({
					variables: {
						ids: [record.id],
					},
				})
					.then(() => {
						message.success(`${record.fullName} has been deleted`)
						portfolioAdminsRes.refetch()
					})
					.catch(printError)
			},
		})
	}

	const totalCount =
		portfolioAdminsRes.data?.portfolioAdmins?.totalCount ?? portfolioAdminsRes.previousData?.portfolioAdmins?.totalCount

	return (
		<>
			<TabsActions>
				<Button
					size='large'
					className='ant-btn-primary-black'
					onClick={() => setActiveModal(MODAL.CREATE_PORTFOLIO_ADMIN)}>
					Create New
				</Button>
			</TabsActions>
			<Table
				columns={columnsEditable as ColumnTypeEditable<DataTypePortfolioAdmin>[]}
				components={{
					body: {
						row: EditableRow,
						cell: EditableCell,
					},
				}}
				dataSource={tableData}
				pagination={{
					...searchParams.pagination,
					total: totalCount,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
				}}
				loading={portfolioAdminsRes.loading}
				onChange={onTableChange}
				rowClassName='editable-row'
				rowKey={(record) => record.id}
			/>

			{activeModal && [MODAL.CREATE_PORTFOLIO_ADMIN, MODAL.UPDATE_PORTFOLIO_ADMIN].includes(activeModal) && (
				<ModalCreateUpdatePortfolioAdmin values={activeRecord} />
			)}
		</>
	)
}

export default PortfolioAdmins
