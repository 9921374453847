import React from 'react'
import { Spin } from 'antd'
import { useQuery } from '@apollo/client'
import { useParams, Navigate } from 'react-router-dom'
import { CacheKey, localCache } from 'helpers/localCache'
import NotFoundPage from 'components/NotFoundPage'
import { ClientBaseInfoDocument, ClientStatusType } from 'graphql/generated/schema'
import AudioPlayerProvider from 'context/audioHintContext'

interface IProps {
	children: React.ReactElement
	checkBirthday?: boolean
}

export function PrivateRouteIPS({ children, checkBirthday }: IProps): React.ReactElement {
	const { guid = '' } = useParams()
	const client = useQuery(ClientBaseInfoDocument, {
		variables: { guid },
	})

	if (!checkBirthday) {
		localCache.remove(CacheKey.BW_VALID_BIRTHDAY)
	}

	const { guid: clientGuid, status } = client.data?.client || {}

	const isGuidMatch = clientGuid === guid
	const isValidBirthday = localCache.get(CacheKey.BW_VALID_BIRTHDAY) === true

	if (client.loading) {
		return (
			<Spin
				size='large'
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
				}}
			/>
		)
	}

	if (
		status &&
		[ClientStatusType.Uploaded, ClientStatusType.Creation, ClientStatusType.Sent, ClientStatusType.Client].includes(
			status,
		) === false
	) {
		return (
			<Navigate
				replace
				to={`/sent/${guid}`}
			/>
		)
	}

	if (isGuidMatch && checkBirthday && !isValidBirthday) {
		return (
			<Navigate
				replace
				to={`/start/${guid}`}
			/>
		)
	}

	if (!isGuidMatch) {
		return <NotFoundPage />
	}

	return <AudioPlayerProvider>{children}</AudioPlayerProvider>
}
