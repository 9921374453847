import { FC, createContext, useState, ReactNode } from 'react'
import { MODAL } from 'types/admin'

type ContextType = {
	setActiveModal: (name: MODAL | null) => void
	activeModal: MODAL | null
}

export const ModalContext = createContext<ContextType>({
	setActiveModal: () => {},
	activeModal: null,
})

interface Props {
	children: ReactNode
}

const ModalContextProvider: FC<Props> = ({ children }) => {
	const [activeModal, setActiveModal] = useState<ContextType['activeModal']>(null)

	return <ModalContext.Provider value={{ activeModal, setActiveModal }}>{children}</ModalContext.Provider>
}

export default ModalContextProvider
