import { useQuery } from '@apollo/client'
import { AdvisorsDocument, ClientStatusType, FileStatus, PmsDocument } from 'graphql/generated/schema'
import type { ColumnFilterItem } from 'antd/lib/table/interface'

type FilterKey = 'pm' | 'advisor' | 'fileStatus' | 'clientStatus'

export const useDashboardTableFilters = () => {
	const pmsRes = useQuery(PmsDocument)
	const advisorsRes = useQuery(AdvisorsDocument)

	const getFilters = (key: FilterKey): ColumnFilterItem[] => {
		if (key === 'pm') {
			return (
				pmsRes.data?.pms?.nodes?.map((pm) => {
					const filter: ColumnFilterItem = {
						text: `${pm.firstName} ${pm.lastName}`,
						value: pm.id,
					}
					return filter
				}) ?? []
			)
		}
		if (key === 'advisor') {
			return (
				advisorsRes.data?.advisors?.nodes?.map((advisor) => {
					const filter: ColumnFilterItem = {
						text: `${advisor.firstName} ${advisor.lastName}`,
						value: advisor.id,
					}
					return filter
				}) ?? []
			)
		}

		if (key === 'fileStatus') {
			return (
				Object.values(FileStatus).map((key) => {
					const filter: ColumnFilterItem = {
						text: key,
						value: key,
					}

					return filter
				}) ?? []
			)
		}

		if (key === 'clientStatus') {
			return (
				Object.values(ClientStatusType).map((key) => {
					const filter: ColumnFilterItem = {
						text: key,
						value: key,
					}

					return filter
				}) ?? []
			)
		}

		return []
	}

	return { getFilters }
}
