import { useState } from 'react'
import { isObjectMatchTrue } from 'helpers/common'
import type {
	DeepBoolean,
	IClient,
	IClientFields,
	IDataIPS,
	IDisclosuresFields,
	IInvestmentPolicyFields,
} from 'types/ips'

export type InactiveFields = {
	clientInfo: {
		[key in keyof Required<IClient>]: DeepBoolean<IClientFields>
	}
	additionalDisclosures: DeepBoolean<IDisclosuresFields>
	investmentPolicy: DeepBoolean<IInvestmentPolicyFields>
}

type InactiveReturnType = [InactiveFields, boolean, (values: IDataIPS) => void]

export const useInactiveIPS = (): InactiveReturnType => {
	const [inactiveFields, setInactiveFields] = useState<InactiveFields>({
		clientInfo: {
			clientOne: {},
			clientTwo: {},
		},
		additionalDisclosures: {},
		investmentPolicy: {},
	})
	const [isInactive, setIsInactive] = useState(false)

	const updateValues = (values: IDataIPS) => {
		const { clientInfo } = values
		const { clientOne, clientTwo } = clientInfo

		const inactiveFieldsNext: InactiveFields = {
			clientInfo: {
				clientOne: {
					isTaxBenefitsClaim: clientOne?.isTaxBenefitsClaim === false,
					isSpecialRatesClaim: clientOne?.isSpecialRatesClaim === true,
					citizenshipCountryId: clientOne?.citizenshipCountryId ? clientOne?.citizenshipCountryId !== 1 : false,
					isTaxResidentCanada: clientOne?.isTaxResidentCanada === false,
					isTaxCitizenUS: clientOne?.isTaxCitizenUS === true,
					isTaxResidentOther: clientOne?.isTaxResidentOther === true,
					residentOfBlankCountryId: clientOne?.residentOfBlankCountryId
						? clientOne?.residentOfBlankCountryId !== 1
						: false,
				},
				clientTwo: {
					isTaxBenefitsClaim: clientTwo?.isTaxBenefitsClaim === false,
					isSpecialRatesClaim: clientTwo?.isSpecialRatesClaim === true,
					citizenshipCountryId: clientTwo?.citizenshipCountryId ? clientTwo?.citizenshipCountryId !== 1 : false,
					isTaxResidentCanada: clientTwo?.isTaxResidentCanada === false,
					isTaxCitizenUS: clientTwo?.isTaxCitizenUS === true,
					isTaxResidentOther: clientTwo?.isTaxResidentOther === true,
					residentOfBlankCountryId: clientTwo?.residentOfBlankCountryId
						? clientTwo?.residentOfBlankCountryId !== 1
						: false,
				},
			},
			additionalDisclosures: {
				isThirdPartyInstructions: values.additionalDisclosures?.isThirdPartyInstructions === true,
				isThirdPartyInstructionsSecondary: values.additionalDisclosures?.isThirdPartyInstructionsSecondary === true,
				isIIROC: values.additionalDisclosures?.isIIROC === true,
				isIIROCSecondary: values.additionalDisclosures?.isIIROCSecondary === true,
				isAccountPower: values.additionalDisclosures?.isAccountPower === true,
				isAccountGuarantee: values.additionalDisclosures?.isAccountGuarantee === true,
				insiderMarket: values.additionalDisclosures?.insiderMarket === 'Other',
				insiderMarketSecondary: values.additionalDisclosures?.insiderMarketSecondary === 'Other',
				votingRightsMarket: values.additionalDisclosures?.votingRightsMarket === 'Other',
				votingRightsMarketSecondary: values.additionalDisclosures?.votingRightsMarketSecondary === 'Other',
			},
			investmentPolicy: {
				investmentObjectivesCapitalPreservation:
					values.investmentPolicy.investmentObjectivesCapitalPreservation === 1 ||
					values.investmentPolicy.investmentObjectivesCapitalPreservation === 2,
				investmentObjectivesSpeculation:
					values.investmentPolicy.investmentObjectivesSpeculation === 1 ||
					values.investmentPolicy.investmentObjectivesSpeculation === 2,
			},
		}

		if (JSON.stringify(inactiveFieldsNext) !== JSON.stringify(inactiveFields)) {
			setInactiveFields(inactiveFieldsNext)
		}

		const isInactiveNext = isObjectMatchTrue(inactiveFieldsNext)
		if (isInactiveNext !== isInactive) {
			setIsInactive(isInactiveNext)
		}
	}

	return [inactiveFields, isInactive, updateValues]
}
