import { FC } from 'react'
import { Form, Col, Input, Typography, FormInstance, Row, Space } from 'antd'
import { IChildFields, IDataIPS } from 'types/ips'
import { formItemProps, rules } from 'constants/props'
import { BwDatePicker } from 'components/start/BwDatePicker'
import { ClientRelationType } from 'graphql/generated/schema'

interface Props {
	relation: ClientRelationType
	childNum?: number | null
	form: FormInstance
}

const ClientChilds: FC<Props> = (props) => {
	const { childNum } = props

	if (!childNum) {
		return null
	}

	const setKey = (
		idx: number,
		key: keyof IChildFields,
	): [keyof IDataIPS, keyof IDataIPS['clientChilds'], number, keyof IChildFields] => [
		'clientChilds',
		props.relation,
		idx,
		key,
	]

	const fieldsJSX = [...Array(childNum)].map((_, idx) => {
		return (
			<Row
				key={idx}
				gutter={[20, 0]}
				style={{ marginBottom: 20 }}>
				<Col xs={24}>
					<Space align='start'>
						<h4>Child {idx + 1}</h4>
						<Typography.Text type='secondary'>(optional)</Typography.Text>
					</Space>
				</Col>
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='First Name'
						name={setKey(idx, 'firstName')}
						rules={[rules.minmax(1, 20)]}>
						<Input />
					</Form.Item>
				</Col>
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						{...formItemProps}
						label='Last Name'
						name={setKey(idx, 'lastName')}
						rules={[rules.minmax(1, 20)]}>
						<Input />
					</Form.Item>
				</Col>
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						label='Date of Birthday'
						name={setKey(idx, 'dob')}
						rules={[]}>
						<BwDatePicker />
					</Form.Item>
				</Col>
				<Col
					xs={24}
					xl={12}>
					<Form.Item
						label='SIN'
						name={setKey(idx, 'sin')}
						validateFirst
						rules={[rules.sin]}>
						<Input
							placeholder='XXXXXXXXX'
							type='number'
						/>
					</Form.Item>
				</Col>
			</Row>
		)
	})

	return <>{fieldsJSX}</>
}

export default ClientChilds
