import { FC, useEffect, useRef, useState } from 'react'
import IMask from 'imask'
import { DatePicker, Select } from 'antd'
import moment, { Moment } from 'moment'
import { DATE_FORMAT } from 'constants/ips'
import type { PickerDateProps } from 'antd/lib/date-picker/generatePicker'
import { SelectProps } from 'antd/lib/select'
import { useOutsideClick } from 'hooks/useOutsideClick'

const iMaskDate = IMask.createMask({
	blocks: {
		YYYY: { from: 1900, mask: IMask.MaskedRange, to: 2050 },
		MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
		DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
	},
	format: (date: Moment) => moment(date).format(DATE_FORMAT),
	mask: Date,
	parse: (date: Moment) => moment(date, DATE_FORMAT),
	pattern: DATE_FORMAT,
	//   lazy: false
})

type Props = PickerDateProps<Moment>

const BwDatePicker: FC<Props> = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedMonth, setSelectedMonth] = useState(moment().month())
	const [selectedYear, setSelectedYear] = useState(moment().year())

	const { ...restProps } = props

	const panelRef = useRef<HTMLDivElement>(null)

	const pickerWrapRef = useOutsideClick<HTMLDivElement>(() => {
		setTimeout(() => {
			setIsOpen(false)
		}, 150)
	})

	const updateDateOnChange = (year: number, month: number, day: number) => {
		setSelectedMonth(month)
		setSelectedYear(year)

		const date = moment().year(year).month(month).date(day)

		if (props.onChange) {
			props.onChange(date, date.toString())
		}
	}

	const monthOptions = (): SelectProps['options'] => {
		return moment.months().map((month, index) => ({
			label: month,
			value: index,
			disabled: false,
		}))
	}

	const yearOptions = (): SelectProps['options'] => {
		const startYear = 1900
		const endYear = 2100
		const yearOptions: SelectProps['options'] = []

		for (let year = startYear; year <= endYear; year++) {
			yearOptions.push({ label: year, value: year, disabled: false })
		}

		return yearOptions
	}

	const CustomHeader = () => {
		return (
			<div
				className='ant-picker-dropdown-bw__header'
				onMouseDown={(e) => {
					e.stopPropagation()
					e.preventDefault()
				}}>
				<Select
					value={selectedMonth}
					size='middle'
					options={monthOptions()}
					onSelect={(value) => {
						const day = moment(props.value, DATE_FORMAT).format('D')
						updateDateOnChange(selectedYear, value, +day)
					}}
				/>

				<Select
					value={selectedYear}
					size='middle'
					options={yearOptions()}
					onSelect={(value) => {
						const day = moment(props.value, DATE_FORMAT).format('D')
						updateDateOnChange(value, selectedMonth, +day)
					}}
				/>
			</div>
		)
	}

	useEffect(() => {
		if (props.value) {
			const year = moment(props.value).year()
			const month = moment(props.value).month()

			if (year > 0) {
				setSelectedYear(year)
			}
			if (month >= 0) {
				setSelectedMonth(month)
			}
		}
	}, [props.value])

	// Effect that helps to keep datePicker input focused
	useEffect(() => {
		const input = pickerWrapRef?.current?.querySelector('.ant-picker-input input') as HTMLInputElement | null

		const handleBlur = (e: FocusEvent) => {
			if (panelRef.current && panelRef.current.contains(e.relatedTarget as Node)) {
				input?.focus()
			}
		}

		input?.addEventListener('blur', handleBlur)

		return () => {
			input?.removeEventListener('blur', handleBlur)
		}
	}, [pickerWrapRef, panelRef])

	return (
		<div ref={pickerWrapRef}>
			<DatePicker
				disabledDate={(current) => current > moment().endOf('day')}
				format={DATE_FORMAT}
				placeholder={DATE_FORMAT}
				picker='date'
				popupClassName='ant-picker-dropdown-bw'
				showToday={false}
				open={isOpen}
				onFocus={() => setIsOpen(true)}
				onClick={() => setIsOpen(true)}
				onSelect={() => setIsOpen(false)}
				panelRender={(origin) => {
					return (
						<div ref={panelRef}>
							{CustomHeader()}
							{origin}
						</div>
					)
				}}
				onKeyDown={(event) => {
					const input = event.currentTarget

					setTimeout(() => {
						const enteredDate = moment(input.value, DATE_FORMAT, true)

						if (enteredDate.isValid()) {
							const day = enteredDate.format('D')
							updateDateOnChange(enteredDate.year(), enteredDate.month(), +day)
						}
					}, 100)

					input.value = iMaskDate.resolve(input.value)

					if (!isOpen) {
						setIsOpen(true)
					}

					if (event.key === 'Enter') {
						setIsOpen(false)
					}
				}}
				{...restProps}
			/>
		</div>
	)
}

export { BwDatePicker }
