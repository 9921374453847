import React, { FC, useEffect, useState } from 'react'
import { Button, Space, Table, Dropdown, message, Modal, Typography, Divider, Tooltip } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { EllipsisOutlined as EllipsisIco } from '@ant-design/icons'
import Initials from '../common/Initials'
import { EditableCell, EditableRow, getColumnSearchProps } from 'helpers/table'
import { ColumnTypeEditable, SORT_MAP } from 'types/admin'
import { adminModalProps } from '../modal/ModalProps'
import { MODAL } from 'types/admin'
import {
	AdvisorsDocument,
	AdvisorsQueryVariables,
	CountersDocument,
	FilterAdvisorsInput,
	RemoveAdvisorsDocument,
	Sort,
	SortingAdvisorsInput,
	UpdateAdvisorDocument,
} from 'graphql/generated/schema'
import type { TableProps } from 'antd/lib/table'
import TabsActions from './TabsActions'
import { printError } from 'helpers/error'
import rfdc from 'rfdc'
import { useDashboardTableFilters } from 'hooks/useDashboardTableFilters'
import { useModal } from 'hooks/useContexts'
import ModalUploadIMA from '../modal/Advisor/UploadIMA'
import { ModalCreateUpdateAdvisor } from '../modal/Advisor/CreateUpdateAdvisor'

const deepClone = rfdc()
export interface DataTypeAdvisor {
	id: number
	fullName: string
	firstName: string
	lastName: string
	address: string
	phone: string
	email: string
	city: string
	stateId: number
	countryId: number
	postalCode: string
	rrCode: string
	isNonSolicitFormActive?: boolean | null
	pm: string
	pmId: number
	formName: string
	formId: number | null
}

interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterAdvisorsInput
	sorting: SortingAdvisorsInput
}

const Advisors: FC = () => {
	const { activeModal, setActiveModal } = useModal()
	const [tableData, setTableData] = useState<DataTypeAdvisor[]>([])
	const [selectedRows, setSelectedRows] = useState<DataTypeAdvisor[]>([])
	const [activeRecord, setActiveRecord] = useState<DataTypeAdvisor>()
	const [updateAdvisor] = useMutation(UpdateAdvisorDocument)
	const [removeAdvisors] = useMutation(RemoveAdvisorsDocument)
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})

	const { getFilters } = useDashboardTableFilters()

	const getSearchVariables = (): AdvisorsQueryVariables => {
		const { pagination } = searchParams

		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const advisorsRes = useQuery(AdvisorsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onError: (err) => {
			setTableData([])
			printError(err)
		},
		onCompleted: (data) => {
			const preparedData =
				data?.advisors?.nodes?.map((advisor): DataTypeAdvisor => {
					return {
						id: advisor.id,
						fullName: `${advisor.firstName} ${advisor.lastName}`,
						firstName: advisor.firstName,
						lastName: advisor.lastName,
						address: advisor.address,
						phone: advisor.phone,
						email: advisor.email,
						countryId: advisor.country.id,
						stateId: advisor.state.id,
						city: advisor.city,
						postalCode: advisor.postalCode,
						rrCode: advisor.rrCode ?? '',
						pm: `${advisor.pm?.firstName} ${advisor.pm?.lastName}`,
						pmId: advisor.pm.id,
						formName: advisor.form?.name ?? 'Default',
						formId: advisor.form?.id ?? null,
						isNonSolicitFormActive: advisor.isNonSolicitFormActive,
					}
				}) ?? []

			setTableData(preparedData)
		},
	})

	useEffect(() => {
		advisorsRes.refetch()
	}, [searchParams])

	const columns: ColumnTypeEditable<DataTypeAdvisor>[] = [
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '15%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeAdvisor>(),
			render: (name) => (
				<Initials
					name={name}
					color='orange'
				/>
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			width: '15%',
			render: (address) => <Tooltip title={address}>{address}</Tooltip>,
			...getColumnSearchProps<DataTypeAdvisor>(),
		},
		{
			title: 'Phone',
			dataIndex: 'phone',
			key: 'phone',
			width: '10%',
			...getColumnSearchProps<DataTypeAdvisor>(),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			editable: true,
			className: 'editable-cell-ellipsis',
			width: '15%',
			...getColumnSearchProps<DataTypeAdvisor>(),
		},
		{
			title: 'IMA Form',
			dataIndex: 'formName',
			key: 'formName',
			ellipsis: true,
			render: (name) => <Tooltip title={name}>{name}</Tooltip>,
			width: '10%',
		},
		{
			title: 'RR-Code',
			dataIndex: 'rrCode',
			key: 'rrCode',
			ellipsis: true,
			render: (name) => <Tooltip title={name}>{name}</Tooltip>,
			width: '10%',
		},
		{
			title: 'Portfolio Manager',
			dataIndex: 'pm',
			key: 'pm',
			width: '15%',
			filterSearch: true,
			filters: getFilters('pm'),
			filteredValue: searchParams.filters.pm as unknown as [],
			render: (name) => (
				<Initials
					name={name}
					color='blue'
				/>
			),
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: '10%',
			render: (_, record) => (
				<Space size='small'>
					<Button
						type='link'
						onClick={() => {
							setActiveRecord(record)
							setActiveModal(MODAL.UPDATE_ADVISOR)
						}}>
						Edit
					</Button>
					<Dropdown
						menu={{
							items: [
								{
									key: 1,
									label: (
										<div
											style={{ color: '#FF4D4F' }}
											onClick={() => onRowDelete(record)}>
											Delete
										</div>
									),
								},
							],
						}}
						placement='bottomRight'>
						<EllipsisIco />
					</Dropdown>
				</Space>
			),
		},
	]

	const onTableChange: TableProps<DataTypeAdvisor>['onChange'] = (pagination, filters, sorter, extra) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingAdvisorsInput
				const sortOrder = sorter.order

				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	const handleSave = async (row: DataTypeAdvisor, col: ColumnTypeEditable<DataTypeAdvisor>) => {
		const colKey = col.dataIndex as keyof DataTypeAdvisor

		updateAdvisor({
			variables: {
				updateAdvisorInput: {
					id: row.id,
					[colKey]: row[colKey],
				},
			},
		})
			.then(() => {
				message.success(`${col.title} changed`)
				advisorsRes.refetch()
			})
			.catch(printError)
	}

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataTypeAdvisor) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave: (row: DataTypeAdvisor) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const onRowDelete = (record: DataTypeAdvisor) => {
		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure that you want to delete this advisor?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph
						strong
						style={{ margin: 0 }}>
						{record.fullName}
					</Typography.Paragraph>
					<Typography.Paragraph type='secondary'>will be deleted forever from the system</Typography.Paragraph>
				</div>
			),
			okText: 'Delete',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeAdvisors({
					variables: {
						ids: [record.id],
					},
					refetchQueries: [CountersDocument],
				})
					.then(() => {
						message.success(`${record.fullName} has been deleted`)
						advisorsRes.refetch()
					})
					.catch(printError)
			},
		})
	}

	const onBulkDelete = () => {
		const idsToDelete = selectedRows.map((row) => row.id)

		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure you want to delete selected advisors?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph type='secondary'>
						{idsToDelete.length} advisor(s) will be deleted forever from the system
					</Typography.Paragraph>
				</div>
			),
			okText: 'Delete selected',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeAdvisors({
					variables: {
						ids: idsToDelete,
					},
					refetchQueries: [CountersDocument],
				})
					.then(() => {
						message.success('Advisors have been deleted')
						advisorsRes.refetch()
						setSelectedRows([])
					})
					.catch(printError)
			},
		})
	}

	const totalAdvisors = advisorsRes.data?.advisors?.totalCount ?? advisorsRes.previousData?.advisors?.totalCount

	return (
		<>
			<TabsActions>
				{!!selectedRows.length && (
					<>
						<Button
							danger
							onClick={onBulkDelete}
							size='large'>
							Delete ({selectedRows.length})
						</Button>
						<Divider
							style={{ height: '35px' }}
							type='vertical'
						/>
					</>
				)}
				<Button
					size='large'
					className='ant-btn-primary-black'
					onClick={() => setActiveModal(MODAL.UPLOAD_IMA)}>
					Upload IMA
				</Button>
				<Button
					size='large'
					className='ant-btn-primary-black'
					onClick={() => setActiveModal(MODAL.CREATE_ADVISOR)}>
					Create Advisor
				</Button>
			</TabsActions>
			<Table
				columns={columnsEditable as ColumnTypeEditable<DataTypeAdvisor>[]}
				components={{
					body: {
						row: EditableRow,
						cell: EditableCell,
					},
				}}
				dataSource={tableData}
				pagination={{
					...searchParams.pagination,
					total: totalAdvisors,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
				}}
				loading={advisorsRes.loading}
				onChange={onTableChange}
				rowClassName='editable-row'
				rowKey={(record) => record.id}
				rowSelection={{
					type: 'checkbox',
					columnWidth: '4%',
					selectedRowKeys: selectedRows.map((row) => row.id),
					onChange: (selectedRowKeys: React.Key[], selectedRows: DataTypeAdvisor[]) => {
						setSelectedRows(selectedRows)
					},
					getCheckboxProps: (record: DataTypeAdvisor) => ({
						// disabled: record.name === 'Disabled User', // Column configuration not to be checked
						// name: record.name,
					}),
				}}
			/>

			{activeModal && [MODAL.CREATE_ADVISOR, MODAL.UPDATE_ADVISOR].includes(activeModal) && (
				<ModalCreateUpdateAdvisor values={activeRecord} />
			)}

			<ModalUploadIMA />
		</>
	)
}

export default Advisors
