import { FC, useEffect, useState } from 'react'
import { Button, message, Modal, notification, Space, Typography } from 'antd'
import { MODAL } from 'types/admin'
import { adminModalProps } from '../ModalProps'
import ReviewTable from './ReviewTable'
import UploadFile from './UploadFile'
import BindPM from './BindPM'
import {
	ClientTableInfoDocument,
	CountersDocument,
	FilesDocument,
	FindAdvisorByUserDocument,
	Role,
	UploadClientsDocument,
} from 'graphql/generated/schema'
import { useMutation, useQuery } from '@apollo/client'
import { useStep } from 'hooks/useSteps'
import { printError } from 'helpers/error'
import { useAuth, useModal } from 'hooks/useContexts'
import type { UploadFile as UploadFileType } from 'antd/lib/upload/interface'

export type SelectUserOptionType = {
	label: string
	value: number
}

type FileStepKey = 'uploadFile' | 'bindPm' | 'reviewTable'

const DEFAULT_USER_OPTION = {
	label: '',
	value: 0,
}

const ModalAddFile: FC = () => {
	const { user } = useAuth()
	const { activeModal, setActiveModal } = useModal()
	const [loading, setLoading] = useState(false)
	const [fileList, setFileList] = useState<UploadFileType[]>([])
	const [selectedAdvisor, setSelectedAdvisor] = useState<SelectUserOptionType>(DEFAULT_USER_OPTION)
	const [selectedPm, setSelectedPm] = useState<SelectUserOptionType>(DEFAULT_USER_OPTION)
	const [uploadClients] = useMutation(UploadClientsDocument)
	const advisorByUser = useQuery(FindAdvisorByUserDocument, { variables: { userId: user?.id ?? 0 } })

	const [file] = fileList
	const isAdvisor = user?.role === Role.Advisor

	let steps: {
		key: FileStepKey
		title: string
		content: React.ReactNode
	}[] = [
		{
			key: 'uploadFile',
			title: 'Add excel',
			content: (
				<UploadFile
					setFileList={setFileList}
					fileList={fileList}
					loading={loading}
				/>
			),
		},
		{
			key: 'bindPm',
			title: `Add ${user?.role === Role.Admin ? 'Portfolio Manager & ' : ''} Advisor`,
			content: (
				<BindPM
					selectedAdvisor={selectedAdvisor}
					setSelectedAdvisor={setSelectedAdvisor}
					selectedPm={selectedPm}
					setSelectedPm={setSelectedPm}
				/>
			),
		},
		{
			key: 'reviewTable',
			title: 'Review and Confirm',
			content: (
				<ReviewTable
					fileList={fileList}
					setFileList={setFileList}
					selectedAdvisor={selectedAdvisor}
					selectedPm={selectedPm}
					setLoading={setLoading}
				/>
			),
		},
	]

	// Skip step for Advisors
	if (isAdvisor) {
		steps = steps.filter(({ key }) => key !== 'bindPm')
	}

	const [activeStep, stepController] = useStep(steps.length)

	useEffect(() => {
		if (user?.role === Role.Pm) {
			setSelectedPm({ label: `${user.firstName} ${user.lastName}`, value: user.id || 0 })
		}

		if (user?.role === Role.Advisor) {
			const advisorRes = advisorByUser.data?.findAdvisorByUser

			setSelectedAdvisor({ label: `${advisorRes?.firstName} ${advisorRes?.lastName}`, value: advisorRes?.id || 0 })
			setSelectedPm({ label: `${advisorRes?.pm.firstName} ${advisorRes?.pm.lastName}`, value: advisorRes?.pm.id || 0 })
		}
	}, [user, advisorByUser])

	const stepData = steps[activeStep - 1]

	const stepsJSX = steps.map((step) => {
		return (
			<div
				key={step.key}
				style={{ display: stepData.key === step.key ? 'block' : 'none' }}>
				{step.content}
			</div>
		)
	})

	const closeModal = () => {
		setActiveModal(null)
		setFileList([])
		setSelectedAdvisor(DEFAULT_USER_OPTION)
		setSelectedPm(DEFAULT_USER_OPTION)
		stepController.setStep(1)
		notification.destroy()
	}

	const onUploadClients = async () => {
		setLoading(true)

		try {
			await uploadClients({
				variables: {
					file: file.originFileObj,
					portfolioManagerId: selectedPm.value,
					advisorId: selectedAdvisor.value,
				},
				refetchQueries: [CountersDocument, ClientTableInfoDocument, FilesDocument],
			})

			closeModal()
			message.success('Clients have been uploaded into the system')
		} catch (err: any) {
			printError(err)
		} finally {
			setLoading(false)
		}
	}

	const modalFooterJSX = () => {
		const controls = {
			cancel: (
				<Button
					key='1'
					className='ant-btn-default-gold'
					onClick={() => closeModal()}>
					Cancel
				</Button>
			),
			prev: (
				<Button
					key='2'
					className='ant-btn-default-gold'
					loading={loading}
					onClick={() => stepController.prevStep()}>
					Prev
				</Button>
			),
			next: (
				<Button
					key='3'
					className='ant-btn-primary-black'
					loading={loading}
					onClick={() => {
						if (activeStep === 1 && file?.status !== 'success') {
							return message.warning('Please upload the file')
						}

						if (activeStep === 2) {
							if (!selectedPm.value) {
								return message.warning('Please choose a Portfolio Manager')
							}
							if (!selectedAdvisor.value) {
								return message.warning('Please choose an Advisor')
							}
						}

						stepController.nextStep()
					}}>
					Next
				</Button>
			),
			finish: (
				<Space key='6'>
					<Button
						className='ant-btn-primary-gold'
						loading={loading}
						disabled={!fileList.length}
						onClick={() => onUploadClients()}>
						Upload Clients
					</Button>
				</Space>
			),
		}

		let controlsMap: {
			[key in 1 | 2 | 3]?: JSX.Element[]
		} = {
			1: [controls.cancel, controls.next],
			2: [controls.prev, controls.next],
			3: [controls.prev, controls.finish],
		}

		if (isAdvisor) {
			controlsMap = {
				1: [controls.cancel, controls.next],
				2: [controls.prev, controls.finish],
			}
		}

		return controlsMap[activeStep as keyof typeof controlsMap] || null
	}

	return (
		<Modal
			{...adminModalProps}
			width={stepData.key === 'reviewTable' ? 1050 : 600}
			className='admin-modal admin-modal--A'
			open={activeModal === MODAL.ADD_FILE}
			footer={modalFooterJSX()}
			onCancel={() => closeModal()}>
			<div style={{ marginBottom: 30 }}>
				<Typography.Paragraph type='secondary'>
					Step {activeStep} of {steps.length}
				</Typography.Paragraph>
				<h2>{stepData.title}</h2>
				<div>{stepsJSX}</div>
			</div>
		</Modal>
	)
}

export default ModalAddFile
