import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Spin } from 'antd'
import StartPage from './start/StartPage'
import IPSPage from './ips/IPSPage'
import SignIn from './admin/auth/SignIn'
import Dashboard from './admin/Dashboard'
import Layout from './admin/layout/Layout'
import NotFoundPage from './NotFoundPage'
import { PrivateRouteIPS } from 'hoc/privateRouteIPS'
import FormSentPage from './sent/FormSentPage'
import ClientOnboarding from './admin/onboarding/ClientOnboarding'
import ModalContextProvider from 'context/modalContext'
import { useAuth } from 'hooks/useContexts'
import ForgotPassword from './admin/auth/ForgotPassword'
import ResetPassword from './admin/auth/ResetPassword'

const ProtectedAdminRoute: FC = () => {
	const auth = useAuth()

	if (auth.loading) {
		return (
			<Spin
				size='large'
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
				}}
			/>
		)
	}

	if (!auth.user) {
		return (
			<Navigate
				replace
				to='/admin/signin'
			/>
		)
	}

	return (
		<ModalContextProvider>
			<Layout />
		</ModalContextProvider>
	)
}

const App: FC = () => {
	return (
		<Routes>
			<Route
				path='start/:guid'
				element={
					<PrivateRouteIPS>
						<StartPage />
					</PrivateRouteIPS>
				}
			/>
			<Route
				path='ips/:guid'
				element={
					<PrivateRouteIPS checkBirthday>
						<IPSPage />
					</PrivateRouteIPS>
				}
			/>
			<Route
				path='sent/:guid'
				element={<FormSentPage />}
			/>

			{/* Protected admin routes */}
			<Route
				path='/admin'
				element={<ProtectedAdminRoute />}>
				<Route
					path='dashboard'
					element={<Dashboard />}
				/>
				<Route
					path='client-onboarding'
					element={<ClientOnboarding />}
				/>
				<Route
					path='client-onboarding/:guid'
					element={<ClientOnboarding />}
				/>
			</Route>

			{/* Public admin routes */}
			<Route path='/admin'>
				<Route
					path='signin'
					element={<SignIn />}
				/>
				<Route
					path='forgot-password'
					element={<ForgotPassword />}
				/>
				<Route
					path='reset-password/:token'
					element={<ResetPassword />}
				/>
			</Route>

			<Route
				path='*'
				element={<NotFoundPage />}
			/>
		</Routes>
	)
}

export default App
