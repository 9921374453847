import { FC } from 'react'
import { Col, Row, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { AdvisorsDocument, PmsDocument, Role } from 'graphql/generated/schema'
import { SelectUserOptionType } from '../AddFile'
import { useAuth } from 'hooks/useContexts'

interface Props {
	selectedPm: SelectUserOptionType
	setSelectedPm: (pm: SelectUserOptionType) => void
	selectedAdvisor: SelectUserOptionType
	setSelectedAdvisor: (advisor: SelectUserOptionType) => void
}

const BindPM: FC<Props> = (props) => {
	const { user } = useAuth()
	const { selectedAdvisor, selectedPm } = props

	const pmsRes = useQuery(PmsDocument)
	const advisorsRes = useQuery(AdvisorsDocument)

	const pmsOpts: {
		label: string
		value: number
	}[] =
		pmsRes.data?.pms?.nodes?.map((pm) => ({
			label: `${pm.firstName} ${pm.lastName}`,
			value: pm.id,
		})) ?? []

	const pmsAdvisorsOpts =
		advisorsRes.data?.advisors?.nodes
			?.filter((advisor) => advisor.pm.id === selectedPm.value)
			?.map((advisor) => ({
				label: `${advisor.firstName} ${advisor.lastName}`,
				value: advisor.id,
			})) ?? []

	return (
		<Row gutter={[20, 20]}>
			{user?.role === Role.Admin && (
				<Col xs={24}>
					<div style={{ marginBottom: 5 }}>Portfolio Manager</div>
					<Select
						style={{ width: '100%' }}
						size='large'
						showSearch
						value={selectedPm.label}
						onChange={(value: string) => {
							props.setSelectedPm({
								label: value,
								value: 0,
							})
						}}
						onSelect={(_: any, option: any) => {
							props.setSelectedPm({
								label: option.label,
								value: option.value,
							})

							props.setSelectedAdvisor({
								label: '',
								value: 0,
							})
						}}
						filterOption={(input, option) => {
							return (option?.label as string).toLowerCase().includes(input.toLowerCase())
						}}
						options={pmsOpts}
						loading={pmsRes.loading}
					/>
				</Col>
			)}

			<Col xs={24}>
				<div style={{ marginBottom: 5 }}>Advisor </div>
				<Select
					style={{ width: '100%' }}
					size='large'
					showSearch
					value={selectedAdvisor.label}
					loading={advisorsRes.loading || pmsRes.loading}
					onChange={(value: string) => {
						props.setSelectedAdvisor({
							label: value,
							value: 0,
						})
					}}
					onSelect={(_: any, option: any) => {
						props.setSelectedAdvisor({
							label: option.label,
							value: option.value,
						})
					}}
					filterOption={(input, option) => {
						if (typeof option?.label === 'string') {
							return option?.label.toLowerCase().includes(input.toLowerCase())
						}
						return false
					}}
					options={pmsAdvisorsOpts}
				/>
			</Col>
		</Row>
	)
}

export default BindPM
