import { FC, useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'antd'
import { useMutation } from '@apollo/client'
import {
	ClientTableInfoDocument,
	type FidelityRequest,
	RequestStatus,
	ResendFidelityRequestsDocument,
} from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { DataTypeClient } from 'components/admin/tabs/Clients'
import { adminModalProps } from '../ModalProps'
import { ColumnTypeEditable, MODAL } from 'types/admin'
import RequestStatusBadge from 'components/admin/common/RequestStatusBadge'
import { useModal } from 'hooks/useContexts'

interface Props {
	clientRecord: DataTypeClient
}

const AccountsStatus: FC<Props> = (props) => {
	const { activeModal, setActiveModal } = useModal()
	const [loading, setLoading] = useState(false)
	const [selectedRows, setSelectedRows] = useState<FidelityRequest[]>([])

	const [resendFidelityRequests] = useMutation(ResendFidelityRequestsDocument)

	const onResend = async () => {
		setLoading(true)

		await resendFidelityRequests({
			variables: {
				guid: props.clientRecord.guid,
				ids: selectedRows.map((row) => row.id),
			},
			refetchQueries: [ClientTableInfoDocument],
		})
			.then(() => setSelectedRows([]))
			.catch(printError)
			.finally(() => setLoading(false))
	}

	const columns: ColumnTypeEditable<FidelityRequest>[] = [
		{
			title: 'Account numbers',
			dataIndex: 'accountNumbers',
			width: '40%',
			render: (accountNumbers: string[]) => <b>{accountNumbers?.join(', ')}</b>,
		},
		{
			title: 'Request id',
			dataIndex: 'requestId',
			width: '15%',
		},
		{
			title: 'Type',
			dataIndex: 'requestType',
			width: '15%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: '15%',
			render: (status) => <RequestStatusBadge status={status} />,
		},
	]

	return (
		<Modal
			{...adminModalProps}
			width={920}
			className='admin-modal'
			open={activeModal === MODAL.ACCOUNTS_STATUS}
			footer={null}
			onCancel={() => setActiveModal(null)}>
			<>
				<Table
					bordered
					columns={columns}
					dataSource={props.clientRecord.fidelityRequests}
					loading={loading}
					rowKey={(record) => record.id}
					pagination={false}
					rowSelection={{
						type: 'checkbox',
						columnWidth: '4%',
						selectedRowKeys: selectedRows.map((row) => row.id),
						onChange: (selectedRowKeys: React.Key[], selectedRows: FidelityRequest[]) => {
							setSelectedRows(selectedRows)
						},
						getCheckboxProps: (record) => ({
							disabled: record.status !== RequestStatus.Error,
						}),
					}}
				/>

				{selectedRows.length > 0 && (
					<Row
						justify={'end'}
						className='mt-25'>
						<Col>
							<Button
								className='ant-btn-primary-black'
								loading={loading}
								size='large'
								onClick={() => onResend()}>
								Resend requests ({selectedRows.length})
							</Button>
						</Col>
					</Row>
				)}
			</>
		</Modal>
	)
}

export default AccountsStatus
