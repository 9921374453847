import { FC } from 'react'

interface IFormSection {
	title?: string
	subtitle?: string
	children: React.ReactNode
	help?: {
		title?: string
		text?: React.ReactNode
		link?: string
	}
}

const FormSection: FC<IFormSection> = ({ title, subtitle, children, help }) => {
	return (
		<div className='bw-form__section'>
			{(title || subtitle) && (
				<div className='bw-form__section-head'>
					{title && <h3 className='bw-form__section-title'>{title}</h3>}
					{subtitle && <h5 className='bw-form__section-subtitle'>{subtitle}</h5>}
				</div>
			)}
			<div className='bw-form__blocks'>{children}</div>
			{help && (
				<div className='bw-form__help'>
					<div className='bw-help'>
						{help.title && <div className='bw-help__title'>{help.title}</div>}
						{help.text && <div className='bw-help__text'>{help.text}</div>}
						{help.link && (
							<a
								className='bw-help__link'
								href={help.link}>
								read more
							</a>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default FormSection
