import { FC } from 'react'
import { Button, Col, Modal, Row, Table } from 'antd'
import { useQuery } from '@apollo/client'
import { DocusignRecipientStatus, GetDocusignRecipientsDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { DataTypeClient } from 'components/admin/tabs/Clients'
import { adminModalProps } from '../ModalProps'
import { ColumnTypeEditable, MODAL } from 'types/admin'
import { useModal } from 'hooks/useContexts'

interface Props {
	clientRecord: DataTypeClient
}

const SignatureStatus: FC<Props> = (props) => {
	const { activeModal, setActiveModal } = useModal()

	const recipients = useQuery(GetDocusignRecipientsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: {
			envelopeId: props.clientRecord?.docusign?.envelopeId as string,
		},
		onError: printError,
	})

	const columns: ColumnTypeEditable<DocusignRecipientStatus>[] = [
		{
			title: 'Full Name',
			dataIndex: 'name',
			width: '25%',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '25%',
		},
		{
			title: 'Role',
			dataIndex: 'roleName',
			width: '15%',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			width: '15%',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: '15%',
		},
	]

	return (
		<Modal
			{...adminModalProps}
			width={920}
			className='admin-modal'
			open={activeModal === MODAL.SIGNATURE_STATUS}
			footer={null}
			onCancel={() => setActiveModal(null)}>
			<>
				<Table
					bordered
					columns={columns}
					dataSource={recipients?.data?.getDocusignRecipients}
					loading={recipients.loading}
					pagination={false}
				/>

				<Row
					justify={'end'}
					className='mt-25'>
					<Col>
						<Button
							className='ant-btn-primary-black'
							loading={recipients.loading}
							size='large'
							onClick={() => recipients.refetch()}>
							Refresh
						</Button>
					</Col>
				</Row>
			</>
		</Modal>
	)
}

export default SignatureStatus
