import { FC } from 'react'
import { Form, Row, Col, Input, Typography, Radio, Space } from 'antd'
import { IDataIPS, IPropsSteps, ITrustedPersonFields } from 'types/ips'
import FormSection from 'components/ips/FormSection'
import { formItemProps, rules } from 'constants/props'
import AudioTooltip from '../AudioTooltip'
import audio10 from '../../../assets/audio/tooltips/10. Trusted Contact Person.mp3'

const Step3: FC<IPropsSteps> = (props) => {
	const setKey = (key: keyof ITrustedPersonFields): [keyof IDataIPS, keyof ITrustedPersonFields] => [
		'trustedPerson',
		key,
	]

	const isTrustedPerson = Form.useWatch(
		setKey('isTrustedPerson'),
		props.form,
	) as ITrustedPersonFields['isTrustedPerson']

	return (
		<>
			<FormSection
				subtitle='Authorization:'
				help={{
					text: (
						<>
							By choosing to provide information about a Trusted Contact Person (TCP), you authorize Bellwether
							Investment Management (BIM) to contact the TCP listed below and disclose information about your account to
							that person in the following circumstances: to address possible financial exploitation; to address
							concerns regarding your health status; or to ascertain the identity of any legal guardian, executor,
							trustee or power of attorney.
						</>
					),
				}}>
				<div className='bw-form__block'>
					<p>
						I hereby authorize BIM, to communicate, verbally or in writing with the Contact Person(s) listed below. This
						contact may be initiated by BIM or the Contact Person(s). I understand and agree that any communication with
						the Contact Person(s) may include information related to me and my accounts. I further understand and agree
						that:
					</p>
					<ul style={{ listStyle: 'auto' }}>
						<li>
							this Authorization is not a power of attorney or trade authorization and does not authorize the Contact
							Person(s) to make any investment decisions or transact any business with BIM on my behalf;
						</li>
						<li>this Authorization may be changed at any time by notifying BIM in writing;</li>
						<li>
							this Authorization does not require BIM to share my personal or account information with the Contact
							Person(s); and
						</li>
						<li>
							this Authorization may become null and void if BIM receives legal documents appointing an alternative
							third party (e.g. legal guardian) to manage my financial affairs.
						</li>
					</ul>
				</div>
				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={
									<Space>
										<AudioTooltip audioSrc={audio10} />
										<span>Do you wish to elect a Trusted Contact Person?</span>
									</Space>
								}
								name={setKey('isTrustedPerson')}
								rules={[rules.required]}>
								<Radio.Group
									className='ant-group-col2'
									options={[
										{ value: true, label: 'Yes' },
										{ value: false, label: 'No' },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
				{isTrustedPerson === true && (
					<div className='bw-form__block'>
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='First Name'
									name={setKey('firstName')}
									rules={[rules.required, rules.minmax(2, 50)]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Last Name'
									name={setKey('lastName')}
									rules={[rules.required, rules.minmax(2, 50)]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[20, 0]}>
							<Col
								xs={24}
								xl={24}>
								<Form.Item
									{...formItemProps}
									label='Relationship'
									name={setKey('relationship')}
									rules={[rules.required, rules.minmax(2, 50)]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row
							gutter={[20, 0]}
							align='bottom'>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Mobile Phone'
									name={setKey('mobilePhone')}
									rules={[rules.required, rules.mobilePhone]}>
									<Input />
								</Form.Item>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label={
										<>
											Home Phone <Typography.Text type='secondary'>&nbsp;(optional)</Typography.Text>
										</>
									}
									name={setKey('homePhone')}
									rules={[rules.numbers, rules.minmax(10, 10)]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[20, 0]}>
							<Col xs={24}>
								<Form.Item
									{...formItemProps}
									label='Email'
									name={setKey('email')}
									rules={[rules.email]}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</div>
				)}
			</FormSection>
		</>
	)
}

export default Step3
