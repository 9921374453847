export enum CacheKey {
	BW_VALID_BIRTHDAY = 'BW_VALID_BIRTHDAY',
}

interface CacheValues {
	[CacheKey.BW_VALID_BIRTHDAY]: true | null
}

interface LocalCache {
	set: <T extends CacheKey>(key: T, object: CacheValues[T]) => void
	get: <T extends CacheKey>(key: T) => CacheValues[T] | null
	remove: (key: CacheKey) => void
	removeAll: () => void
}

export const localCache: LocalCache = {
	set: (key, object) => {
		localStorage?.setItem(key, JSON.stringify(object))
	},
	get: (key) => JSON.parse(localStorage?.getItem(key) as string),
	remove: (key) => localStorage?.removeItem(key),
	removeAll: () => localStorage?.clear(),
}
