import type { ColumnType } from 'antd/lib/table'

export type ColumnTypeEditable<T> = ColumnType<T> & {
	editable?: boolean | (() => boolean)
	hidden?: boolean | (() => boolean)
}

export enum SORT_MAP {
	ascend = 'ASC',
	descend = 'DESC',
	ASC = 'descend',
	DESC = 'ascend',
}

export enum MODAL {
	CREATE_ADVISOR = 'CREATE_ADVISOR',
	UPDATE_ADVISOR = 'UPDATE_ADVISOR',
	CREATE_PORTFOLIO_ADMIN = 'CREATE_PORTFOLIO_ADMIN',
	UPDATE_PORTFOLIO_ADMIN = 'UPDATE_PORTFOLIO_ADMIN',
	ADD_FILE = 'ADD_FILE',
	ACCOUNTS_STATUS = 'ACCOUNTS_STATUS',
	SIGNATURE_STATUS = 'SIGNATURE_STATUS',
	UPLOAD_IMA = 'UPLOAD_IMA',
	ONBOARDING_SAVE = 'ONBOARDING_SAVE',
}

export enum FILTER_INPUT_TYPE {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	DATE_RANGE = 'DATE_RANGE',
}
