import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, message, Space, Spin, Typography } from 'antd'
import moment from 'moment'
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg'
import { CacheKey } from 'helpers/localCache'
import { BwDatePicker } from './BwDatePicker'
import { useQuery } from '@apollo/client'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { ClientBaseInfoDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { DATE_FORMAT } from 'constants/ips'
import AudioTooltip from 'components/ips/AudioTooltip'
import audio1 from '../../assets/audio/tooltips/1. Overview.mp3'

export interface IDate {
	status: '' | 'success' | 'error'
	value: moment.Moment | null
}

const StartPage: FC = () => {
	const navigate = useNavigate()
	const { guid = '' } = useParams()
	const [isValidBirthday, setIsValidBirthday] = useLocalStorage(CacheKey.BW_VALID_BIRTHDAY, false)
	const [date, setDate] = useState<IDate>({
		status: '',
		value: null,
	})

	const client = useQuery(ClientBaseInfoDocument, { variables: { guid } })

	const { dateOfBirth, firstName, lastName, portfolioManager, advisor } = client?.data?.client || {}
	const clientFullName = firstName ? `${firstName} ${lastName}` : 'Client'

	const onChangeDate = (date: moment.Moment | null) => {
		const status = moment(dateOfBirth, DATE_FORMAT).isSame(date, 'day') ? 'success' : 'error'
		setDate({ status, value: date })
	}

	const onStart = () => {
		if (date.status === 'success') {
			setIsValidBirthday(true)
			return
		}
		if (date.status === 'error') {
			message.error('Please select valid Date of Birth')
		}
		if (date.status === '') {
			message.warning('Please select your Date of Birth')
		}
	}

	useEffect(() => {
		if (client.error) {
			printError(client.error)
		}
	}, [client])

	const managerJSX = (
		<div className='bw-manager'>
			{advisor && (
				<div className='bw-manager__item'>
					<div>Your Family Wealth Advisor</div>
					<div className='bw-manager__name'>
						{advisor?.firstName} {advisor?.lastName}
					</div>
					<address>
						<div>{advisor?.address}</div>
						<div>Tel: {advisor?.phone}</div>
					</address>
				</div>
			)}
			{portfolioManager && (
				<div className='bw-manager__item'>
					<div>Your Portfolio Manager</div>
					<div className='bw-manager__name'>
						{portfolioManager?.firstName} {portfolioManager?.lastName}
					</div>
					<address>
						<div>{portfolioManager?.address}</div>
						<div>Tel: {portfolioManager?.phone}</div>
					</address>
				</div>
			)}
		</div>
	)

	return (
		<div className='bw-start'>
			<header className='bw-start__header'>
				<div className='bw-start__logo'>
					<LogoSVG />
				</div>
				<div className='bw-start__head'>
					<div className='bw-start__contacts'>
						<div>Bellwether Investment Management Inc.</div>
						<div>1295 Cornwall Road, Unit A3 Oakville ON L6J 7T5</div>
						<div>Tel: 905.337.2227 Fax: 905.337.3552</div>
					</div>
				</div>
			</header>
			<main className='bw-start__main'>
				{isValidBirthday ? (
					<>
						<div className='bw-start__info'>
							<div>
								<div className='bw-start__client'>Welcome, {clientFullName}</div>
								<h2 className='bw-start__title'>
									Client Information and <br /> Investment Policy Statement
								</h2>
								<div className='bw-start__stages'>
									<div>You will go through the following three stages:</div>
									<ul>
										<li>
											<span>Client Information</span>
										</li>
										<li>
											<span>Investment Policy Information</span>
										</li>
										<li>
											<span>Account Information</span>
										</li>
									</ul>
								</div>
								<Button
									className='ant-btn-primary-black ant-btn-primary-black--lg'
									onClick={() => navigate(`/ips/${guid}`)}>
									Start
								</Button>
							</div>
							{managerJSX}
						</div>
					</>
				) : (
					<div className='bw-start__info'>
						<div>
							<div className='bw-start__client'>Welcome, {clientFullName}</div>
							<h2 className='bw-start__title'>To begin the process, kindly provide your date of birth</h2>
							<div className='bw-start__date'>
								<div>Date of Birth</div>
								<Spin spinning={client.loading}>
									<BwDatePicker
										onChange={onChangeDate}
										value={date.value}
										size='large'
									/>
								</Spin>
								<div>
									<Typography.Text type='secondary'>Enter Your DOB in format {DATE_FORMAT}</Typography.Text>
								</div>
							</div>
							<Space>
								<Button
									className='ant-btn-primary-black ant-btn-primary-black--lg'
									onClick={onStart}>
									Start
								</Button>
								<AudioTooltip
									audioSrc={audio1}
									title='Play overview'
								/>
							</Space>
						</div>
						{managerJSX}
					</div>
				)}
			</main>
		</div>
	)
}
export default StartPage
