import { FC, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

type Props = {
	children?: ReactNode
}

const Layout: FC<Props> = (props) => {
	return (
		<div className='admin'>
			<div className='admin__inner'>
				<Header />
				<main className='admin__main'>{props.children || <Outlet />}</main>
				<Footer />
			</div>
		</div>
	)
}

export default Layout
