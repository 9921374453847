import moment from 'moment'
import { isMobilePhone, isNumberString, isPhoneNumber, isPostalCode } from 'class-validator'
import { type FormItemProps } from 'antd'
import { type Rule } from 'antd/lib/form'

export const formItemProps: FormItemProps = {
	normalize: (value: string) => {
		if (typeof value === 'string') {
			return value.trimStart().replace(/\s+/g, ' ')
		}

		return value
	},
	validateFirst: true,
}

type FuncRule = (...args: any) => Rule

const asType = <T extends { [key: string]: Rule | FuncRule }>(arg: T): T => arg

export const rules = asType({
	required: { required: true, message: 'Required Field' },
	requiredWhen: (isRequired: boolean, message = 'Required Field') => ({ required: isRequired, message }),
	email: { type: 'email', message: 'Is not valid email' },
	minmax: (min: number, max: number) => ({
		min: min,
		max: max,
		message: `Should be ${min === max ? max : `${min} to ${max}`} characters long`,
	}),
	numbers: { pattern: new RegExp(/^[0-9]*$/), message: 'Only numbers allowed' },
	latin: { pattern: new RegExp(/^[a-zA-Z ]*$/), message: 'Latin letters only' },
	sin: {
		validator(_, sin) {
			const multiplyEveryOtherBy2 = (val: number, index: number) => val * ((index % 2) + 1)
			const sumValues = (acc: number, val: string) => acc + parseInt(val)

			if (!sin) {
				return Promise.resolve()
			}
			if (typeof sin === 'number') {
				sin = sin.toString()
			}

			if (sin.length !== 9) {
				return Promise.reject('Please enter 9 digit number')
			}

			// convert to an array & pop off the check digit
			const sinArr: any = sin.split('')
			const check = parseInt(sinArr.pop())
			const sum =
				(sinArr
					.map(multiplyEveryOtherBy2)
					//To individual digits
					.join('')
					.split('')
					.reduce(sumValues, 0) *
					9) %
				10

			if (check === sum) {
				return Promise.resolve()
			} else {
				return Promise.reject('Wrong SIN')
			}
		},
	},
	mobilePhone: {
		validator(_, phone) {
			if (!phone) {
				return Promise.resolve()
			}

			if (!isNumberString(phone)) {
				return Promise.reject('Only numbers allowed')
			}

			const isValid = isMobilePhone(phone)

			return isValid ? Promise.resolve() : Promise.reject('Must be a valid CA (Canadian) mobile phone number')
		},
	},
	phone: {
		validator(_, phone) {
			if (!phone) {
				return Promise.resolve()
			}

			if (!isNumberString(phone)) {
				return Promise.reject('Only numbers allowed')
			}

			const isValid = isPhoneNumber(phone, 'CA')
			return isValid ? Promise.resolve() : Promise.reject('Must be a valid CA (Canadian) phone number')
		},
	},
	postalCA: {
		validator(_, value) {
			if (!value) {
				return Promise.resolve()
			}

			const isValid = isPostalCode(value, 'CA')

			return isValid ? Promise.resolve() : Promise.reject('Must be a valid CA (Canadian) postal code')
		},
	},
	minAge: (minAge: number) => ({
		validator(_: any, inputAge: moment.MomentInput) {
			const isValid = moment().diff(moment(inputAge), 'years') >= minAge

			if (isValid || !inputAge) {
				return Promise.resolve()
			} else {
				return Promise.reject(`The minimum age allowed is ${minAge}`)
			}
		},
	}),
	maxAge: (maxAge: number) => ({
		validator(_: any, inputAge: moment.MomentInput) {
			const isValid = moment().diff(moment(inputAge), 'years') <= maxAge

			if (isValid || !inputAge) {
				return Promise.resolve()
			} else {
				return Promise.reject(`The maximum age allowed is ${maxAge}`)
			}
		},
	}),
	futureDate: {
		validator(_: any, inputDate: moment.MomentInput) {
			const isDisabled = inputDate && inputDate < moment().endOf('day')

			if (!isDisabled || !inputDate) {
				return Promise.resolve()
			} else {
				return Promise.reject('Please choose a date in the future')
			}
		},
	},
	rrCode: {
		pattern: new RegExp(/^[A-Z0-9]{4,4}$/),
		message: 'Invalid code',
	},
	rrCodes: {
		validator(_: any, value: string[] | undefined) {
			const pattern = /^[A-Z0-9]{4,4}$/

			if (value && Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					if (!pattern.test(value[i])) {
						return Promise.reject(`Invalid code: ${value[i]}`)
					}
				}
			}

			return Promise.resolve()
		},
	},

	selectedFromList: (validOptions: string[]) => ({
		validator(_: any, value: string | undefined) {
			if (!value) {
				return Promise.resolve()
			}

			const isValid = validOptions.some((option) => option === value)

			return isValid ? Promise.resolve() : Promise.reject('Please select a value from the list')
		},
	}),
})
