import { FC, useEffect, useState } from 'react'
import { Table, message } from 'antd'
import Initials from '../common/Initials'
import { EditableCell, EditableRow, getColumnSearchProps } from 'helpers/table'
import {
	FilterPmsInput,
	SortingPmsInput,
	PmsDocument,
	PmsQueryVariables,
	UpdatePmDocument,
	Sort,
} from 'graphql/generated/schema'
import { ColumnTypeEditable, SORT_MAP } from 'types/admin'
import type { TableProps } from 'antd/lib/table'
import rfdc from 'rfdc'
import { useMutation, useQuery } from '@apollo/client'
import { printError } from 'helpers/error'
const deepClone = rfdc()

interface DataTypePM {
	id: number
	fullName: string
	address: string
	email: string
	password: string
}

interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterPmsInput
	sorting: SortingPmsInput
}

const PMs: FC = () => {
	const [tableData, setTableData] = useState<DataTypePM[]>([])
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})

	const [updatePM] = useMutation(UpdatePmDocument)

	const getSearchVariables = (): PmsQueryVariables => {
		const { pagination } = searchParams

		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const pmsRes = useQuery(PmsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onError: (err) => {
			setTableData([])
			printError(err)
		},
		onCompleted: (data) => {
			const preparedData =
				data?.pms?.nodes?.map((pm): DataTypePM => {
					return {
						id: pm.id,
						fullName: `${pm.firstName} ${pm.lastName}`,
						email: pm.email,
						address: pm.address ?? '',
						password: '********',
					}
				}) ?? []

			setTableData(preparedData)
		},
	})

	useEffect(() => {
		pmsRes.refetch()
	}, [searchParams])

	const columns: ColumnTypeEditable<DataTypePM>[] = [
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '25%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypePM>(),
			render: (name) => (
				<Initials
					name={name}
					color='blue'
				/>
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			filterSearch: true,
			width: '35%',
			...getColumnSearchProps<DataTypePM>(),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '25%',
			editable: true,
			...getColumnSearchProps<DataTypePM>(),
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			width: '15%',
			editable: true,
		},
	]

	const onTableChange: TableProps<DataTypePM>['onChange'] = (pagination, filters, sorter) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingPmsInput
				const sortOrder = sorter.order
				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	const handleSave = async (row: DataTypePM, col: ColumnTypeEditable<DataTypePM>) => {
		const colKey = col.dataIndex as keyof DataTypePM

		updatePM({
			variables: {
				updatePMInput: {
					id: row.id,
					[colKey]: row[colKey],
				},
			},
		})
			.then(() => {
				message.success(`${col.title} changed`)
				pmsRes.refetch()
			})
			.catch(printError)
	}

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataTypePM) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave: (row: DataTypePM) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const totalPms = pmsRes.data?.pms?.totalCount ?? pmsRes.previousData?.pms?.totalCount

	return (
		<Table
			columns={columnsEditable as ColumnTypeEditable<DataTypePM>[]}
			components={{
				body: {
					row: EditableRow,
					cell: EditableCell,
				},
			}}
			rowClassName='editable-row'
			rowKey={(record) => record.id}
			dataSource={tableData}
			pagination={{
				...searchParams.pagination,
				total: totalPms,
				showSizeChanger: true,
				showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
			}}
			loading={pmsRes.loading}
			onChange={onTableChange}
		/>
	)
}

export default PMs
