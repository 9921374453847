import { FC, useState } from 'react'
import { Button, Form, Input, Row, Col } from 'antd'
import { LockOutlined as LockIco } from '@ant-design/icons'
import { rules } from 'constants/props'
import Layout from '../layout/Layout'
import { useMutation } from '@apollo/client'
import { ResetPasswordDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { useParams } from 'react-router-dom'
import { useAuth } from 'hooks/useContexts'

interface FormValues {
	newPassword: string
	newPasswordRepeat: string
}

const ResetPassword: FC = () => {
	const auth = useAuth()
	const params = useParams<{ token: string }>()
	const [resetPassword, { loading }] = useMutation(ResetPasswordDocument)
	const [isPasswordChanged, setIsPasswordChanged] = useState(false)

	const onFinish = (values: FormValues) => {
		resetPassword({
			variables: {
				resetPasswordInput: {
					newPassword: values.newPassword,
					token: String(params.token),
				},
			},
		})
			.then((res) => {
				setIsPasswordChanged(true)

				auth.login({
					email: String(res.data?.resetPassword?.email),
					password: values.newPassword,
				})
			})

			.catch(printError)
	}

	return (
		<Layout>
			<div className='admin-auth'>
				<div className='admin-auth__inner'>
					<Row justify='center'>
						<Col>
							<h2>Reset password</h2>
						</Col>
					</Row>

					{isPasswordChanged ? (
						<Row justify='center'>
							<Col>
								<h3 className='text-center'>Password has been changed successfully.</h3>
							</Col>
							<Col>
								<Button
									block
									size='large'
									loading={auth.loading}
									type='primary'
									href='/admin/dashboard'
									className='ant-btn-primary-gold'>
									Go to dashboard
								</Button>
							</Col>
						</Row>
					) : (
						<Form
							size='large'
							layout='vertical'
							onFinish={onFinish}
							requiredMark={false}>
							<Form.Item
								className='mb-10'
								name='newPassword'
								dependencies={['newPasswordRepeat']}
								rules={[rules.required, rules.minmax(8, 20)]}>
								<Input.Password
									prefix={<LockIco />}
									placeholder='New password'
								/>
							</Form.Item>

							<Form.Item
								className='mb-10'
								name='newPasswordRepeat'
								dependencies={['newPassword']}
								rules={[
									rules.required,
									rules.minmax(8, 20),
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('newPassword') === value) {
												return Promise.resolve()
											}

											return Promise.reject('Passwords do not match')
										},
									}),
								]}>
								<Input.Password
									prefix={<LockIco />}
									placeholder='Confirm new password'
								/>
							</Form.Item>

							<Form.Item>
								<Button
									block
									loading={loading}
									type='primary'
									htmlType='submit'
									className='ant-btn-primary-gold mt-25'>
									Reset password
								</Button>
							</Form.Item>
						</Form>
					)}
				</div>
			</div>
		</Layout>
	)
}

export default ResetPassword
