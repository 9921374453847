import { FC, useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Select, Space, Spin, Tabs, TabsProps, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import ClientInformation from './ClientInformation'
import IndividualAccounts from './IndividualAccounts'
import {
	OnboardingFormValues,
	OnboardingChildProps,
	ClientNum,
	ClientKeyType,
	ClientDataType,
	ExistingAccountsKeys,
	AccountsNum,
	ExistingAccountsFieldsType,
} from 'types/client-onboarding'
import JointAccounts from './JointAccounts'
import ExistingAccounts from './ExistingAccounts'
import { adminModalProps } from '../modal/ModalProps'
import { MODAL } from 'types/admin'
import { useModal } from 'hooks/useContexts'
import ViewSummary from './ViewSummary'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { DeepPartial, Paths } from 'types/ips'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
	AccountType,
	AccountTypeJt,
	ClientOnboardingDocument,
	ClientStatusType,
	ClientTableInfoDocument,
	ConfirmationType,
	CreateClientOnboardDocument,
	CreateClientOnboardInput,
	InputMaybe,
	MaritalStatusType,
	SendInviteInput,
	SendInvitesDocument,
	UpdateClientDocument,
	UpdateClientOnboardDocument,
	UpdateClientOnboardInput,
	UploadClientAdditionalFieldsInput,
} from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { additionalFieldsMapper, getDateString, getValue, getDate } from './mapping'
import { useMatchQuery } from 'hooks/useMatchQuery'

export const clientTypeMap: Record<ClientNum, ClientKeyType> = {
	1: 'clientOne',
	2: 'clientTwo',
}

export const checkITF = (accountType: AccountType | AccountTypeJt | undefined) =>
	Boolean(accountType && [AccountType.Itf, AccountTypeJt.JointItf].includes(accountType))

const formValidateErrors = (err: unknown): err is ValidateErrorEntity => {
	return err !== null && typeof err === 'object' && 'errorFields' in err
}

const findErrorTabKeys = (
	path: Paths<OnboardingFormValues>,
): {
	mainTabKey: ActiveTabKeyType
} => {
	for (let i = 0; i < path.length; i++) {
		if (path[0] === 'clientOne' || path[0] === 'clientTwo') {
			if (path[1] === 'clientInfo') {
				return { mainTabKey: '1' }
			}
			if (path[1] === 'individualAccounts') {
				return { mainTabKey: '2' }
			}
		}
		if (path[0] === 'jointAccounts') {
			return { mainTabKey: '3' }
		}
		if (path[0] === 'existingAccounts' || path[0] === 'advisorNotes') {
			return { mainTabKey: '4' }
		}
	}

	return { mainTabKey: '1' }
}

type ActiveTabKeyType = '1' | '2' | '3' | '4'

const ClientOnboarding: FC = () => {
	const location = useLocation()
	const { guid = '' } = useParams<{ guid: string }>()
	const navigate = useNavigate()
	const inviteLoading = useRef(false)
	const { activeModal, setActiveModal } = useModal()
	const [isRequired] = useMatchQuery('required', '1')

	const [activeTabKey, setActiveTabKey] = useState<ActiveTabKeyType>('1')

	const [createClientOnboard] = useMutation(CreateClientOnboardDocument)
	const [updateClientOnboard] = useMutation(UpdateClientOnboardDocument)
	const [updateClient] = useMutation(UpdateClientDocument)
	const [sendInvites] = useMutation(SendInvitesDocument)

	const clientRes = useQuery(ClientOnboardingDocument, {
		notifyOnNetworkStatusChange: true,
		variables: { guid },
		skip: !guid,
		onError: printError,
	})

	const [form] = useForm<OnboardingFormValues>()
	const jointAccounts = Form.useWatch(['jointAccounts'], form)
	const jointAccountsNum = Form.useWatch(['jointAccountsNum'], form)
	const clientCount = Form.useWatch(['clientCount'], form)
	const clientOne = Form.useWatch(['clientOne'], form)
	const clientTwo = Form.useWatch(['clientTwo'], form)

	const clientsData = [...Array(clientCount)]
		.map((_, idx) => (idx + 1) as ClientNum)
		.map((clientNum) => {
			const clientPropsMap: Record<ClientNum, ClientDataType> = {
				1: {
					...clientOne,
					clientNum: 1,
					clientKey: 'clientOne',
				},
				2: {
					...clientTwo,
					clientNum: 2,
					clientKey: 'clientTwo',
				},
			}

			return clientPropsMap[clientNum]
		})

	const childProps: OnboardingChildProps = {
		guid,
		form,
		clientCount,
		clientsData,
	}

	const additionalValidation = async () => {
		const values = form.getFieldsValue()

		const clientKeys: ClientKeyType[] = ['clientOne', 'clientTwo']

		clientKeys.forEach((clientKey, clientIdx) => {
			const clientType = `Client ${clientIdx + 1}`
			const clientValue = values?.[clientKey]
			// 1. If individual accounts count = 9, at least one should include TFSA
			if (Number(clientValue?.clientInfo?.accountsNum) === 9) {
				const tfsaExist = clientValue?.individualAccounts?.some((acc) => acc?.accountType === AccountType.Tfsa)

				if (!tfsaExist) {
					setActiveTabKey('2')
					throw new Error(`At least one individual account for ${clientType} should include a TFSA`)
				}
			}
		})

		return true
	}

	const initialValues: DeepPartial<OnboardingFormValues> = {
		clientCount: 1,
	}

	const accountsCount = clientOne?.clientInfo?.accountsNum ?? 0 + (clientTwo?.clientInfo?.accountsNum ?? 0)

	const saveClientInfo = async (redirect?: boolean) => {
		const formValues = form.getFieldsValue()

		const { clientOne = {}, clientTwo = {}, advisorId, pmId } = formValues

		const getConfirmType = (confirm: boolean | undefined) => {
			if (confirm === undefined) {
				return undefined
			}
			return confirm ? ConfirmationType.Yes : ConfirmationType.No
		}

		let tfsaAccountNumber = 0

		const getAccountPrefix = (accountIdx: number, accountType?: AccountType) => {
			if (accountType === AccountType.Tfsa) {
				tfsaAccountNumber = accountIdx + 1
				return 'tfsa'
			} else {
				let accNumber = accountIdx + 1

				if (accNumber === 9) {
					accNumber = tfsaAccountNumber
				}

				return accNumber.toString() as '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'
			}
		}

		const fields: InputMaybe<UploadClientAdditionalFieldsInput> = {}

		const clientKeys: ClientKeyType[] = ['clientOne', 'clientTwo']

		// 1. Individual accounts
		for (const clientKey of clientKeys) {
			const clientValues = formValues[clientKey] ?? {}

			const clientPrefix = clientKey === 'clientTwo' ? 'jt' : 'primary'

			if (clientValues.individualAccounts?.length) {
				clientValues.individualAccounts.forEach((acc, accIndex) => {
					if (!acc) {
						return
					}

					const accPrefix = getAccountPrefix(accIndex, acc.accountType)
					const isITF = checkITF(acc.accountType)

					if (isITF) {
						acc.beneficiaryNum = 1
					}

					// common fields
					fields[`${clientPrefix}_account_${accPrefix}_currency`] = acc.accountCurrency
					fields[`${clientPrefix}_account_${accPrefix}_successor`] = getConfirmType(acc.isSuccessor)

					fields[`${clientPrefix}_account_${accPrefix}_pac_swp`] = acc.pacSwp?.type
					fields[`${clientPrefix}_account_${accPrefix}_pac_swp_amount`] = acc.pacSwp?.amount?.toString()
					fields[`${clientPrefix}_account_${accPrefix}_pac_swp_date`] = acc.pacSwp?.startDate
					fields[`${clientPrefix}_account_${accPrefix}_pac_swp_frequency`] = acc.pacSwp?.frequency
					fields[`${clientPrefix}_account_${accPrefix}_bene_number`] = acc.beneficiaryNum?.toString()

					if (accPrefix === 'tfsa') {
						fields[`${clientPrefix}_account_tfsa`] = ConfirmationType.Yes
					} else {
						fields[`${clientPrefix}_account_${accPrefix}`] = acc.accountType
						fields[`${clientPrefix}_account_${accPrefix}_jurisdiction`] = acc.jurisdiction
						fields[`${clientPrefix}_account_${accPrefix}_payment_amount`] = acc.riffPayments?.paymentAmount?.toString()
						fields[`${clientPrefix}_account_${accPrefix}_payment_blended`] = acc.riffPayments?.paymentBlended
						fields[`${clientPrefix}_account_${accPrefix}_payment_by`] = acc.riffPayments?.paymentDestination
						fields[`${clientPrefix}_account_${accPrefix}_payment_frequency`] = acc.riffPayments?.paymentFrequency
						fields[`${clientPrefix}_account_${accPrefix}_payment_startdate`] = acc.riffPayments?.paymentStartDate
						fields[`${clientPrefix}_account_${accPrefix}_payment_type`] = acc.riffPayments?.paymentType
						fields[`${clientPrefix}_account_${accPrefix}_rrif_age`] = acc.riffPayments?.age
						fields[`${clientPrefix}_account_${accPrefix}_wt_on`] = acc.riffPayments?.paymentWithholdingTax
						fields[`${clientPrefix}_account_${accPrefix}_wt_percentage`] =
							acc.riffPayments?.paymentWithholdingTaxPercent
					}

					acc.beneficiary?.forEach((bene = {}, beneIdx) => {
						const beneNum = String(beneIdx + 1) as '1' | '2' | '3' | '4'

						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_allocation`] = bene.allocation?.toString()
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_dob`] = bene.dateOfBirth
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_first_name`] = bene.firstName
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_last_name`] = bene.lastName
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_middle_initial`] = bene.middleInitial
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_relationship`] = bene.relationship
						fields[`${clientPrefix}_account_${accPrefix}_bene_${beneNum}_sin`] = bene.sin?.toString()
					})

					acc.transfers?.forEach((transfer = {}, transferIdx) => {
						const transferNum = String(transferIdx + 1) as '1' | '2'

						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_transfer_type`] = transfer.transferType
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_amount`] =
							transfer.transferAmount?.toString()
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_currency`] = transfer.transferCurrency
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_delivering_number`] =
							transfer.deliveryNumber
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_institution_address`] =
							transfer.institutionAddress
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_institution_city`] =
							transfer.institutionCity
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_institution_name`] =
							transfer.institutionName
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_institution_postal`] =
							transfer.institutionPostalCode
						fields[`${clientPrefix}_account_${accPrefix}_transfer_${transferNum}_institution_province`] =
							transfer.institutionProvince
					})
				})
			}
		}

		// 2. Joint accounts
		const { jointAccounts, jointAccountsNum } = formValues

		if (jointAccountsNum) {
			jointAccounts?.forEach((acc, accIdx) => {
				if (!acc) {
					return
				}

				const accNumber = String(accIdx + 1) as '1' | '2' | '3'

				fields[`account_jt_${accNumber}_account_type`] = acc.accountType
				fields[`account_jt_${accNumber}_currency`] = acc.accountCurrency as any
				fields[`jtwros_or_tic_${accNumber}`] = acc.ownershipStructure
				fields[`tic_percentage_${accNumber}`] = acc.ticPercentage

				const bene = acc.beneficiary?.[0]

				fields[`account_jt_${accNumber}_ITF_bene_1_first_name`] = bene?.firstName
				fields[`account_jt_${accNumber}_ITF_bene_1_middle_initial`] = bene?.middleInitial
				fields[`account_jt_${accNumber}_ITF_bene_1_last_name`] = bene?.lastName
				fields[`account_jt_${accNumber}_ITF_bene_1_sin`] = bene?.sin?.toString()
				fields[`account_jt_${accNumber}_ITF_bene_1_dob`] = bene?.dateOfBirth
				fields[`account_jt_${accNumber}_ITF_bene_1_relationship`] = bene?.relationship
				fields[`account_jt_${accNumber}_ITF_bene_1_allocation`] = bene?.allocation?.toString()

				fields[`account_jt_${accNumber}_pac_swp`] = acc.pacSwp?.type
				fields[`account_jt_${accNumber}_pac_swp_amount`] = acc.pacSwp?.amount?.toString()
				fields[`account_jt_${accNumber}_pac_swp_date`] = acc.pacSwp?.startDate
				fields[`account_jt_${accNumber}_pac_swp_frequency`] = acc.pacSwp?.frequency
				fields[`account_jt_${accNumber}_pac_swp_bank`] = acc.pacSwp?.clientType

				acc.transfers?.forEach((transfer = {}, transferIdx) => {
					const transferNum = String(transferIdx + 1) as '1' | '2'

					fields[`account_jt_${accNumber}_transfer_${transferNum}_transfer_type`] = transfer.transferType
					fields[`account_jt_${accNumber}_transfer_${transferNum}_amount`] = transfer.transferAmount?.toString()
					fields[`account_jt_${accNumber}_transfer_${transferNum}_currency`] = transfer.transferCurrency as any
					fields[`account_jt_${accNumber}_transfer_${transferNum}_delivering_number`] = transfer.deliveryNumber
					fields[`account_jt_${accNumber}_transfer_${transferNum}_institution_address`] = transfer.institutionAddress
					fields[`account_jt_${accNumber}_transfer_${transferNum}_institution_city`] = transfer.institutionCity
					fields[`account_jt_${accNumber}_transfer_${transferNum}_institution_name`] = transfer.institutionName
					fields[`account_jt_${accNumber}_transfer_${transferNum}_institution_postal`] = transfer.institutionPostalCode
					fields[`account_jt_${accNumber}_transfer_${transferNum}_institution_province`] = transfer.institutionProvince
				})
			})
		}

		// 3. Existing Accounts
		const { existingAccounts } = formValues

		for (const existingAccount in existingAccounts) {
			const key = existingAccount as ExistingAccountsKeys

			const accountData = existingAccounts[key]

			const prefixMap: Record<ExistingAccountsKeys, 'joint' | 'client1' | 'client2' | 'entity'> = {
				clientOne: 'client1',
				clientTwo: 'client2',
				jointAccounts: 'joint',
				entityAccounts: 'entity',
			}

			if (accountData?.accountsNum) {
				accountData?.accounts?.forEach((acc, accIdx) => {
					if (!acc) {
						return
					}
					const accNum = (accIdx + 1) as ExistingAccountsFieldsType<any>['accountsNum']
					const accPrefix = prefixMap[key]

					fields[`${accPrefix}_assets_${accNum}_account`] = acc.type as any
					fields[`${accPrefix}_assets_${accNum}_value`] = acc.value?.toString()

					if (accPrefix === 'entity') {
						fields[`${accPrefix}_assets_${accNum}_name`] = acc.name?.toString()
					}
				})
			}

			// Advisor Notes
			fields.advisorNotes = formValues.advisorNotes

			// Non-Solicit form
			fields.client1_isNonSolicitFormEnabled = formValues.existingAccounts?.clientOne?.isNonSolicitFormEnabled
			fields.client2_isNonSolicitFormEnabled = formValues.existingAccounts?.clientTwo?.isNonSolicitFormEnabled
		}

		const clientOneInfo = clientOne?.clientInfo ?? {}
		const clientTwoInfo = clientTwo?.clientInfo ?? {}

		const onboardInput: CreateClientOnboardInput | UpdateClientOnboardInput = {
			status: ClientStatusType.Creation,
			advisorId: advisorId,
			portfolioManagerId: pmId,
			clientOne: {
				firstName: clientOneInfo.firstName as string,
				lastName: clientOneInfo.lastName as string,
				email: clientOneInfo.email as string,
				title: clientOneInfo.title,
				dateOfBirth: getDateString(clientOneInfo.dateOfBirth),
				socialInsuranceNumber: clientOneInfo.socialInsuranceNumber,
				maritalStatus: clientOneInfo.maritalStatus,
				relationshipType: clientOneInfo.relationshipType || null,
				relationshipTypeOther: clientOneInfo.relationshipTypeOther || null,
				spouseDateOfBirth: getDateString(clientOneInfo.spouseDateOfBirth) || null,
				spouseFirstName: clientOneInfo.spouseFirstName || null,
				spouseLastName: clientOneInfo.spouseLastName || null,
				spouseSocialInsuranceNumber: clientOneInfo.spouseSocialInsuranceNumber || null,
			},
			clientTwo: clientTwo?.clientInfo
				? {
						firstName: clientTwoInfo.firstName,
						lastName: clientTwoInfo.lastName,
						email: clientTwoInfo.email,
						title: clientTwoInfo.title,
						dateOfBirth: getDateString(clientTwoInfo.dateOfBirth),
						socialInsuranceNumber: clientTwoInfo.socialInsuranceNumber,
						maritalStatus: clientTwoInfo.maritalStatus,
						spouseDateOfBirth: getDateString(clientTwoInfo.spouseDateOfBirth) || null,
						spouseFirstName: clientTwoInfo.spouseFirstName || null,
						spouseLastName: clientTwoInfo.spouseLastName || null,
						spouseSocialInsuranceNumber: clientTwoInfo.spouseSocialInsuranceNumber || null,
					}
				: null,
			additionalFields: fields,
		}

		if (guid) {
			await updateClientOnboard({
				variables: {
					updateClientOnboardInput: {
						guid,
						...onboardInput,
					},
				},
			})
				.then(() => {
					if (!isRequired) {
						message.success('Saved')
					}
					if (redirect) {
						navigate('/admin/dashboard')
					}
				})
				.catch((err) => {
					printError(err)
				})
		} else {
			await createClientOnboard({
				variables: {
					createClientOnboardInput: onboardInput as CreateClientOnboardInput,
				},
			})
				.then((res) => {
					if (redirect) {
						navigate('/admin/dashboard')
					} else {
						const guid = res.data?.createClientOnboard?.guid
						navigate(`/admin/client-onboarding/${guid}`)
						setActiveTabKey('1')
					}
					message.success('Saved')
				})
				.catch((err) => {
					printError(err)
				})
		}
	}

	useEffect(() => {
		if (!clientRes.loading && guid) {
			const maritalStatusMap = (status: string) =>
				({
					Married: MaritalStatusType.Married,
					'Common law': MaritalStatusType.CommonLaw,
					Divorced: MaritalStatusType.Divorced,
					Separated: MaritalStatusType.Separated,
					Widowed: MaritalStatusType.Widowed,
					Single: MaritalStatusType.Single,
				})[status]

			const clientInfo = clientRes?.data?.clientOnboarding
			const clientTwoInfo = clientInfo?.clientSecondary
			const additionalFields = clientInfo?.additionalFields

			const additionalFieldsMap = additionalFieldsMapper(additionalFields as any)

			const valuesMapped: DeepPartial<OnboardingFormValues> = {
				pmId: clientInfo?.portfolioManager?.id,
				advisorId: clientInfo?.advisor?.id,
				advisorNotes: getValue(additionalFields?.advisorNotes),
				clientCount: clientInfo?.clientSecondary ? 2 : 1,
				clientOne: {
					clientInfo: {
						accountsNum: additionalFieldsMap.clientOne?.individualAccounts?.length as AccountsNum,
						firstName: getValue(clientInfo?.firstName),
						lastName: getValue(clientInfo?.lastName),
						email: getValue(clientInfo?.email),
						title: getValue(clientInfo?.title),
						dateOfBirth: getDate(clientInfo?.dateOfBirth),
						socialInsuranceNumber: getValue(clientInfo?.socialInsuranceNumber),
						maritalStatus: maritalStatusMap(clientInfo?.maritalStatus || ''),
						relationshipType: getValue(clientInfo?.relationshipType),
						relationshipTypeOther: getValue(clientInfo?.relationshipTypeOther),
						spouseDateOfBirth: getDate(clientInfo?.spouseDateOfBirth),
						spouseFirstName: getValue(clientInfo?.spouseFirstName),
						spouseLastName: getValue(clientInfo?.spouseLastName),
						spouseSocialInsuranceNumber: getValue(clientInfo?.spouseSocialInsuranceNumber),
					},
					individualAccounts: additionalFieldsMap.clientOne?.individualAccounts,
				},
				clientTwo: clientTwoInfo
					? {
							clientInfo: {
								accountsNum: additionalFieldsMap.clientTwo?.individualAccounts?.length as AccountsNum,
								firstName: getValue(clientTwoInfo?.firstName),
								lastName: getValue(clientTwoInfo?.lastName),
								email: getValue(clientTwoInfo?.email),
								title: getValue(clientTwoInfo?.title),
								dateOfBirth: getDate(clientTwoInfo?.dateOfBirth),
								socialInsuranceNumber: getValue(clientTwoInfo?.socialInsuranceNumber),
								maritalStatus: maritalStatusMap(clientTwoInfo?.maritalStatus || ''),
								spouseDateOfBirth: getDate(clientTwoInfo?.spouseDateOfBirth),
								spouseFirstName: getValue(clientTwoInfo?.spouseFirstName),
								spouseLastName: getValue(clientTwoInfo?.spouseLastName),
								spouseSocialInsuranceNumber: getValue(clientTwoInfo?.spouseSocialInsuranceNumber),
							},
							individualAccounts: additionalFieldsMap.clientTwo?.individualAccounts,
						}
					: undefined,
				jointAccountsNum: additionalFieldsMap.jointAccounts?.length as OnboardingFormValues['jointAccountsNum'],
				jointAccounts: additionalFieldsMap.jointAccounts,
				existingAccounts: additionalFieldsMap.existingAccounts,
			}

			form.setFieldsValue(valuesMapped)
		}
	}, [clientRes])

	useEffect(() => {
		if (guid) {
			clientRes.refetch()
		}
	}, [location])

	if (clientRes.loading && !isRequired) {
		return (
			<Spin
				size='large'
				style={{
					position: 'absolute',
					left: '50%',
					top: '50%',
				}}
			/>
		)
	}

	if (clientRes.error) {
		return null
	}

	const tabItems: TabsProps['items'] = [
		{
			key: '1',
			label: 'Client Info',
			children: <ClientInformation {...childProps} />,
			forceRender: true,
		},
		{
			key: '2',
			label: 'Individual Accounts',
			children: <IndividualAccounts {...childProps} />,
			disabled: !accountsCount,
			forceRender: true,
		},
		{
			key: '3',
			label: 'Joint-Accounts',
			children: (
				<JointAccounts
					{...childProps}
					jointAccountsNum={jointAccountsNum}
					jointAccounts={jointAccounts}
				/>
			),
			disabled: !jointAccountsNum,
			forceRender: true,
		},
		{
			key: '4',
			label: 'BIM Existing and Non-ROBIN',
			children: <ExistingAccounts {...childProps} />,
			forceRender: true,
		},
	]

	const validateCurrentAndPreviousSteps = () => {
		const currentTabFields = form
			.getFieldsError()
			.filter((el) => {
				const { mainTabKey } = findErrorTabKeys(el.name as Paths<OnboardingFormValues>)
				return mainTabKey <= activeTabKey /* When Tab with error <= our active tab  */
			})
			.map((el) => el.name)

		return form.validateFields([...currentTabFields])
	}

	const goToTabWithError = (err: any) => {
		if (formValidateErrors(err)) {
			const firstError = err.errorFields[0].name

			// 1. Open the main tab
			const { mainTabKey } = findErrorTabKeys(firstError as Paths<OnboardingFormValues>)

			setActiveTabKey(mainTabKey)

			// 2. Open the sub tab (if exist)
			const fieldId = firstError.join('_')
			const panelPrefix = '-panel-'

			const fieldNode = document.getElementById(fieldId)

			const closestPanelId = fieldNode?.closest(`[id*="${panelPrefix}"]`)?.id
			const subTabKey = closestPanelId?.split(panelPrefix)?.[1]

			const subTabButton = fieldNode?.closest('.ant-tabs')?.querySelector(`[data-node-key="${subTabKey}"]`)

			if (subTabButton) {
				subTabButton.dispatchEvent(
					new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: window,
					}),
				)
			}

			// 3. Scroll to the first field with error
			setTimeout(() => {
				form.scrollToField(firstError, {
					behavior: 'smooth',
					block: 'center',
				})
			}, 100)

			message.warning('Please fill in the required fields correctly')
		} else if (err.message) {
			message.warning(err?.message)
		}
	}

	const onNext = async () => {
		try {
			await validateCurrentAndPreviousSteps()
			await additionalValidation()

			// Go to the next tab
			let nextTabKey = Number(activeTabKey) + 1

			// Setup the next first not disabled tab
			while (nextTabKey <= tabItems.length && tabItems[nextTabKey - 1].disabled) {
				nextTabKey++
			}

			if (nextTabKey <= tabItems.length) {
				setActiveTabKey(String(nextTabKey) as ActiveTabKeyType)
			} else {
				await form.validateFields()
				setActiveModal(MODAL.ONBOARDING_SAVE)
			}
		} catch (error: any) {
			goToTabWithError(error)
		}
	}

	const onSave = async () => {
		try {
			await form.validateFields()
			await additionalValidation()

			setActiveModal(MODAL.ONBOARDING_SAVE)
		} catch (error: any) {
			goToTabWithError(error)
		}
	}

	const onSendInvite = async () => {
		// Double click protection
		if (inviteLoading.current) {
			return false
		}

		inviteLoading.current = true

		// 1. Validate form fields.
		// 2. Save client info
		// 3. On validation error - go to the tab with error and exit
		try {
			await form.validateFields()
			await additionalValidation()

			await saveClientInfo()
		} catch (error: any) {
			inviteLoading.current = false
			goToTabWithError(error)
			return false
		}

		try {
			const clientRefetch = await clientRes.refetch()
			const clientInfo = clientRefetch?.data?.clientOnboarding

			const clientFullName = `${clientInfo?.firstName} ${clientInfo?.lastName}`

			const sendInviteToClient = async (advisorName: string) => {
				const clientsToResend: SendInviteInput[] = [
					{
						clientName: clientFullName,
						clientEmail: clientInfo?.email as string,
						advisorName: advisorName,
						actionUrl: `${window.location.origin}/start/${guid}`,
					},
				]

				await sendInvites({
					variables: {
						sendInvitesInput: clientsToResend,
					},
				})
					.then(() => {
						navigate('/admin/dashboard')
						message.success(`Invitation for ${clientFullName} sent successfully. Advisor: ${advisorName}.`)
					})
					.catch(printError)
			}

			let selectedAdvisorId = clientInfo?.advisor?.id
			let selectedAdvisorName = `${clientInfo?.advisor?.firstName} ${clientInfo?.advisor?.lastName}`

			const containsGroupAdvisor = clientInfo?.advisorGroup.some((advisor) => advisor?.id !== clientInfo?.advisor?.id)

			if (containsGroupAdvisor) {
				Modal.confirm({
					...adminModalProps,
					title: <h2>Confirm advisor</h2>,
					content: (
						<div className='text-center'>
							<Select
								style={{ width: 255 }}
								size='large'
								defaultValue={clientInfo?.advisor?.id}
								onChange={(_, option) => {
									if (!Array.isArray(option)) {
										selectedAdvisorId = option.value
										selectedAdvisorName = option.label
									}
								}}
								options={clientInfo?.advisorGroup.map((advisor) => ({
									label: `${advisor.firstName} ${advisor.lastName}`,
									value: advisor.id,
								}))}
							/>
						</div>
					),
					okText: 'Send',
					okButtonProps: {
						className: 'ant-btn-primary-black',
					},
					cancelButtonProps: {
						className: 'ant-btn-default-gold',
					},
					async onOk() {
						try {
							if (clientInfo?.advisor?.id !== selectedAdvisorId) {
								// UpdateClient instead updateClientOnboard
								// bcz it removes client 2 if populated in form
								await updateClient({
									variables: {
										input: {
											guid: guid,
											advisorId: selectedAdvisorId,
										},
									},
									refetchQueries: [ClientTableInfoDocument],
								})
							}

							await sendInviteToClient(selectedAdvisorName)
						} catch (error: any) {
							printError(error)
						}
					},
				})
			} else {
				await sendInviteToClient(selectedAdvisorName)
			}
		} catch (error: any) {
			printError(error)
		} finally {
			inviteLoading.current = false
		}
	}

	return (
		<div style={{ background: '#fff', padding: 50 }}>
			<Form
				form={form}
				initialValues={initialValues}
				validateTrigger='onBlur'
				scrollToFirstError
				size='large'
				layout='vertical'
				autoComplete='off'
				requiredMark={false}>
				<Tabs
					onChange={(activeKey) => setActiveTabKey(activeKey as ActiveTabKeyType)}
					activeKey={activeTabKey}
					tabBarStyle={{ marginBottom: 30, position: 'sticky', top: 0, background: '#fff', zIndex: 2 }}
					items={tabItems}
					tabBarExtraContent={
						<Space>
							<Button
								className='ant-btn-primary-black'
								onClick={() => onNext()}
								size='large'>
								Next
							</Button>

							{isRequired && guid ? (
								<Button
									className='ant-btn-primary-gold'
									loading={inviteLoading.current}
									onClick={() => onSendInvite()}
									size='large'>
									Send Onboarding Email
								</Button>
							) : (
								<Button
									className='ant-btn-primary-gold'
									onClick={() => onSave()}
									size='large'>
									Save
								</Button>
							)}
						</Space>
					}
				/>
			</Form>

			<Modal
				{...adminModalProps}
				width={920}
				className='admin-modal'
				open={activeModal === MODAL.ONBOARDING_SAVE}
				destroyOnClose
				onCancel={() => {
					setActiveModal(null)
				}}
				footer={
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button
							className='ant-btn-default-gold'
							onClick={() => {
								setActiveModal(null)
							}}
							size='large'>
							Cancel
						</Button>

						<div>
							<Button
								className='ant-btn-primary-black'
								onClick={async () => {
									setActiveModal(null)
									saveClientInfo()
								}}
								size='large'>
								Confirm
							</Button>
							<Button
								className='ant-btn-primary-gold'
								onClick={async () => {
									setActiveModal(null)
									saveClientInfo(true)
								}}
								size='large'>
								Confirm & Return to Dashboard
							</Button>
						</div>
					</div>
				}>
				<ViewSummary form={form} />
			</Modal>
		</div>
	)
}

export default ClientOnboarding
