import { FC, useEffect, useState } from 'react'
import { Button, Space, Table, Dropdown, Typography, Divider, message, Modal } from 'antd'
import moment from 'moment'
import { EllipsisOutlined as EllipsisIco } from '@ant-design/icons'
import { getColumnSearchProps } from 'helpers/table'
import Initials from '../common/Initials'
import FileStatusTag from '../common/FileStatusTag'
import { ColumnTypeEditable, FILTER_INPUT_TYPE, MODAL, SORT_MAP } from 'types/admin'
import ModalAddFile from '../modal/AddFile'
import { useMutation, useQuery } from '@apollo/client'
import {
	FileStatus,
	FilesDocument,
	FilesQueryVariables,
	FilterFilesInput,
	SortingFilesInput,
	Role,
	Sort,
	GetSignedFileUrlDocument,
	RemoveFilesDocument,
	ClientTableInfoDocument,
} from '../../../graphql/generated/schema'
import rfdc from 'rfdc'
import TabsActions from './TabsActions'
import { printError } from 'helpers/error'
import { useDashboardTableFilters } from 'hooks/useDashboardTableFilters'
import type { TableProps } from 'antd/lib/table'
import { useAuth, useModal } from 'hooks/useContexts'
import { adminModalProps } from '../modal/ModalProps'

const deepClone = rfdc()

interface DataTypeFile {
	id: number
	fileName: string
	createdAt: string
	pm: string
	advisor: string
	url: string
	status: FileStatus
	clients: number
}
interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterFilesInput
	sorting: SortingFilesInput
}

const Files: FC = () => {
	const auth = useAuth()
	const { activeModal, setActiveModal } = useModal()
	const [tableData, setTableData] = useState<DataTypeFile[]>([])
	const [selectedRows, setSelectedRows] = useState<DataTypeFile[]>([])
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})
	const { getFilters } = useDashboardTableFilters()

	const [getSignedFileUrlDocument] = useMutation(GetSignedFileUrlDocument)
	const [removeFiles] = useMutation(RemoveFilesDocument)

	const getSearchVariables = (): FilesQueryVariables => {
		const { pagination } = searchParams

		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const filesRes = useQuery(FilesDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onCompleted: (data) => {
			const preparedData =
				data?.files?.nodes?.map((file): DataTypeFile => {
					return {
						id: file.id,
						fileName: file.name,
						createdAt: file.createdAt,
						pm: `${file?.pm?.firstName} ${file?.pm?.lastName}`,
						advisor: `${file?.advisor?.firstName} ${file?.advisor?.lastName}`,
						status: file?.status as FileStatus,
						url: file?.url,
						clients: file?.totalClients,
					}
				}) ?? []

			setTableData(preparedData)
		},
		onError: (err) => {
			setTableData([])
			printError(err)
		},
	})

	useEffect(() => {
		filesRes.refetch()
	}, [searchParams])

	const onRowDownloadFile = async (record: DataTypeFile) => {
		const { data } = await getSignedFileUrlDocument({
			variables: {
				url: record.url,
			},
		})

		window?.open(data?.getSignedFileUrl)
	}

	const columns: ColumnTypeEditable<DataTypeFile>[] = [
		{
			title: 'File Name',
			dataIndex: 'fileName',
			key: 'name',
			width: '20%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeFile>(),
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '20%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataTypeFile>(FILTER_INPUT_TYPE.DATE_RANGE),
			render: (date: string) => moment(date).format('YYYY-MM-DD HH:mm'),
		},
		{
			title: 'Portfolio Manager',
			dataIndex: 'pm',
			key: 'pm',
			render: (name) => (
				<Initials
					name={name}
					color='blue'
				/>
			),
			width: '20%',
			hidden: [Role.Pm, Role.Advisor].includes(auth.user?.role as Role),
			filterSearch: true,
			filters: getFilters('pm'),
			filteredValue: searchParams.filters.pm as unknown as [],
		},
		{
			title: 'Advisor',
			dataIndex: 'advisor',
			key: 'advisor',
			width: '20%',
			hidden: [Role.Pm, Role.Advisor].includes(auth.user?.role as Role),
			filterSearch: true,
			filters: getFilters('advisor'),
			filteredValue: searchParams.filters.advisor as unknown as [],
			render: (name) => (
				<Initials
					name={name}
					color='orange'
				/>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '7%',
			filters: getFilters('fileStatus'),
			filteredValue: searchParams.filters.status as unknown as [],
			render: (status) => <FileStatusTag status={status} />,
		},
		{
			title: 'Clients',
			dataIndex: 'clients',
			key: 'clients',
			width: '5%',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: '8%',
			render: (_, record) => (
				<Space size='small'>
					<Button
						type='link'
						style={{ padding: 0 }}
						onClick={() => onRowDownloadFile(record)}>
						Download
					</Button>
					<Dropdown
						placement='bottomRight'
						menu={{
							items: [
								{
									key: 1,
									style: { color: '#FF4D4F' },
									label: <div onClick={() => onRowDelete(record)}>Delete</div>,
								},
							],
						}}>
						<EllipsisIco />
					</Dropdown>
				</Space>
			),
		},
	]

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataTypeFile) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						// handleSave: (row: DataTypeFile) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const onRowDelete = (record: DataTypeFile) => {
		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure that you want to delete this file?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph
						strong
						style={{ margin: 0 }}>
						{record.fileName}
					</Typography.Paragraph>
					<Typography.Paragraph type='secondary'>will be deleted forever from the system</Typography.Paragraph>
				</div>
			),
			okText: 'Delete',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeFiles({
					variables: {
						ids: [record.id],
					},
					refetchQueries: [FilesDocument, ClientTableInfoDocument],
				})
					.then(() => {
						message.success('File has been deleted')
					})
					.catch(printError)
			},
		})
	}

	const onBulkDelete = () => {
		const idsToDelete = selectedRows.map((row) => row.id)

		Modal.confirm({
			...adminModalProps,
			title: <h2>Are you sure you want to delete selected files?</h2>,
			content: (
				<div className='text-center'>
					<Typography.Paragraph type='secondary'>
						{idsToDelete.length} file(s) will be deleted forever from the system
					</Typography.Paragraph>
				</div>
			),
			okText: 'Delete selected',
			okButtonProps: {
				danger: true,
				type: 'primary',
			},
			cancelButtonProps: {
				className: 'ant-btn-default-gold',
			},
			onOk() {
				removeFiles({
					variables: {
						ids: idsToDelete,
					},
					refetchQueries: [FilesDocument, ClientTableInfoDocument],
				})
					.then(() => {
						message.success('Files have been deleted')
						setSelectedRows([])
					})
					.catch(printError)
			},
		})
	}

	const onTableChange: TableProps<DataTypeFile>['onChange'] = (pagination, filters, sorter) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingFilesInput
				const sortOrder = sorter.order

				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	const totalFiles = filesRes.data?.files?.totalCount ?? filesRes.previousData?.files?.totalCount

	return (
		<>
			<TabsActions>
				{!!selectedRows.length && (
					<>
						<Button
							danger
							size='large'
							onClick={onBulkDelete}>
							Delete ({selectedRows.length})
						</Button>
						<Divider
							style={{ height: '35px' }}
							type='vertical'
						/>
					</>
				)}
				<Button
					size='large'
					className='ant-btn-primary-black'
					onClick={() => setActiveModal(MODAL.ADD_FILE)}>
					Add File
				</Button>
			</TabsActions>

			<Table
				columns={columnsEditable as ColumnTypeEditable<DataTypeFile>[]}
				rowKey={(record) => record.id}
				dataSource={tableData}
				pagination={{
					pageSize: searchParams?.pagination?.pageSize,
					total: totalFiles,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
				}}
				loading={filesRes.loading}
				onChange={onTableChange}
				rowSelection={{
					type: 'checkbox',
					columnWidth: '4%',
					selectedRowKeys: selectedRows.map((row) => row.id),
					onChange: (selectedRowKeys: React.Key[], selectedRows: DataTypeFile[]) => {
						setSelectedRows(selectedRows)
					},
					getCheckboxProps: (record: DataTypeFile) => ({
						// disabled: record.name === 'Disabled User', // Column configuration not to be checked
						// name: record.name,
					}),
				}}
			/>

			{activeModal === MODAL.ADD_FILE && <ModalAddFile />}
		</>
	)
}

export default Files
