import { FC, useEffect, useRef, useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { formItemProps, rules } from 'constants/props'
import { BwDatePicker } from 'components/start/BwDatePicker'
import { OnboardingChildProps, ClientInfoType, OnboardingFormValues, ClientKeyType } from 'types/client-onboarding'
import type { Paths } from 'types/ips'
import PmAndAdvisor from './PmAndAdvisor'
import {
	AccountType,
	CheckClientUniquenessDocument,
	ClientUniqResponse,
	MaritalStatusType,
	RelationshipType,
} from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'
import { useMutation } from '@apollo/client'
import { debounce } from 'throttle-debounce'

export const generateNumValues = (size: number) => Array.from({ length: size }, (_, index) => ({ value: index + 1 }))

export const isMarriedOrCommonLaw = (maritalStatus?: MaritalStatusType): boolean => {
	return Boolean(maritalStatus && [MaritalStatusType.Married, MaritalStatusType.CommonLaw].includes(maritalStatus))
}

export const isSpousalAccountExists = (accountTypes?: (AccountType | undefined)[]): boolean => {
	const isExist = accountTypes?.some(
		(accountType) => accountType === AccountType.SpousalRrif || accountType === AccountType.SpousalRrsp,
	)

	return Boolean(isExist)
}

export enum ClientTitle {
	MR = 'Mr.',
	MRS = 'Mrs.',
	MS = 'Ms.',
	MISS = 'Miss',
	DR = 'Dr.',
}

const clientPath = (clientKey: ClientKeyType, keys: Paths<ClientInfoType>): Paths<OnboardingFormValues> => [
	clientKey,
	'clientInfo',
	...keys,
]

export const ClientInformation: FC<OnboardingChildProps> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')
	const { form, guid } = props

	const clientCount = Form.useWatch(['clientCount'], form)

	const clientOneEmail = Form.useWatch(clientPath('clientOne', ['email']), form)
	const clientOneSin = Form.useWatch(clientPath('clientOne', ['socialInsuranceNumber']), form)
	const clientTwoEmail = Form.useWatch(clientPath('clientTwo', ['email']), form)
	const clientTwoSin = Form.useWatch(clientPath('clientTwo', ['socialInsuranceNumber']), form)

	const clientOneData = props.clientsData[0]
	const clientOneMaritalStatus = clientOneData?.clientInfo?.maritalStatus

	const isSpouseClient2 =
		isMarriedOrCommonLaw(clientOneData?.clientInfo?.maritalStatus) &&
		clientOneData?.clientInfo?.relationshipType === RelationshipType.Spouse

	const [checkClientUniq] = useMutation(CheckClientUniquenessDocument)

	const [clientUniq, setClientUniq] = useState<ClientUniqResponse | null>(null)

	useEffect(() => {
		// When the spouse of 'Client 1' is 'Client 2', their Marital Status should match"
		if (isSpouseClient2) {
			form.setFieldsValue({
				clientTwo: {
					clientInfo: {
						maritalStatus: clientOneMaritalStatus,
					},
				},
			})
		}
	}, [clientOneData])

	const fetchClientUniq = useRef(
		debounce(1000, async ({ clientOneEmail, clientOneSin, clientTwoEmail, clientTwoSin }) => {
			try {
				if (!clientOneEmail && !clientOneSin && !clientTwoEmail && !clientTwoSin) {
					return
				}

				const result = await checkClientUniq({
					variables: {
						clientUniqInput: {
							guid: guid,
							email: clientOneEmail,
							sin: clientOneSin,
							emailSecondary: clientTwoEmail,
							sinSecondary: clientTwoSin,
						},
					},
				})

				if (result?.data?.checkClientUniqueness) {
					setClientUniq({
						...result?.data?.checkClientUniqueness,
					})
				}
				// eslint-disable-next-line no-empty
			} catch (error) {}
		}),
	)

	useEffect(() => {
		fetchClientUniq.current({ clientOneEmail, clientOneSin, clientTwoEmail, clientTwoSin })
	}, [guid, clientOneEmail, clientOneSin, clientTwoEmail, clientTwoSin])

	const getUniqMessages = (clientKey: ClientKeyType) => {
		const sinMessage = 'SIN already exists'
		const emailMessage = 'Email already exists'

		if (clientKey === 'clientOne') {
			return {
				email: clientUniq?.emailCount ? emailMessage : null,
				sin: clientUniq?.sinCount ? sinMessage : null,
			}
		} else {
			return {
				email: clientUniq?.emailSecondaryCount ? emailMessage : null,
				sin: clientUniq?.sinSecondaryCount ? sinMessage : null,
			}
		}
	}

	return (
		<>
			<Row
				gutter={[20, 20]}
				style={{ borderBottom: '1px solid #f3f3f3' }}
				align={'bottom'}>
				<Col
					xs={24}
					xl={6}>
					<Form.Item
						{...formItemProps}
						label='How many clients are there in the household?'
						name={'clientCount'}
						rules={[rules.requiredWhen(isRequired)]}>
						<Select options={generateNumValues(2)} />
					</Form.Item>
				</Col>
				{clientCount === 2 && (
					<Col
						xs={24}
						xl={6}>
						<Form.Item
							{...formItemProps}
							label='Number of Joint-Accounts'
							name={'jointAccountsNum'}
							rules={[rules.requiredWhen(isRequired)]}>
							<Select options={[{ value: 0 }, ...generateNumValues(3)]} />
						</Form.Item>
					</Col>
				)}

				<PmAndAdvisor {...props} />
			</Row>

			<Row gutter={[80, 0]}>
				{props.clientsData.map((clientData) => {
					const { clientKey, clientNum, clientInfo, individualAccounts } = clientData ?? {}

					const clientInfoPath = (keys: Paths<ClientInfoType>): Paths<OnboardingFormValues> => [
						clientKey,
						'clientInfo',
						...keys,
					]

					const accountTypes = individualAccounts?.map((acc) => acc?.accountType)
					const isSpousalAccount = isSpousalAccountExists(accountTypes)

					const isNeedSpouseInfo = () => {
						if (isSpouseClient2) {
							return false
						}

						if (isMarriedOrCommonLaw(clientInfo?.maritalStatus)) {
							return true
						}

						if (isSpousalAccount) {
							return true
						}
					}

					return (
						<Col
							key={clientNum}
							style={{ paddingTop: 20, ...(clientNum === 2 && { borderLeft: '1px solid #f3f3f3' }) }}
							xs={24}
							xl={12}>
							<Row>
								<Col>
									<h3>Client {clientNum}</h3>
								</Col>
							</Row>
							<Row gutter={[20, 0]}>
								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='Number of New Accounts'
										name={clientInfoPath(['accountsNum'])}
										rules={[rules.requiredWhen(isRequired)]}>
										<Select
											options={[{ value: 0 }, ...generateNumValues(9)]}
											allowClear
											listHeight={300}
										/>
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='Title'
										name={clientInfoPath(['title'])}
										rules={[rules.requiredWhen(isRequired), rules.selectedFromList(Object.values(ClientTitle))]}>
										<Select
											allowClear
											options={Object.values(ClientTitle).map((value) => ({
												value,
											}))}
										/>
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='First Name'
										name={clientInfoPath(['firstName'])}
										rules={[rules.requiredWhen(isRequired || clientKey === 'clientOne'), rules.minmax(2, 20)]}>
										<Input />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='Last Name'
										name={clientInfoPath(['lastName'])}
										rules={[rules.requiredWhen(isRequired || clientKey === 'clientOne'), rules.minmax(2, 20)]}>
										<Input />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='Email'
										name={clientInfoPath(['email'])}
										extra={clientKey === 'clientTwo' && getUniqMessages(clientKey).email}
										rules={[
											rules.requiredWhen(isRequired || clientKey === 'clientOne'),
											rules.email,

											...[
												clientKey === 'clientOne'
													? {
															validator: async (_: any, email: string): Promise<boolean> => {
																const result = await checkClientUniq({
																	variables: {
																		clientUniqInput: {
																			guid: guid || null,
																			email: email,
																		},
																	},
																})

																if (result?.data?.checkClientUniqueness?.emailCount) {
																	return Promise.reject('Email already exists')
																}

																return Promise.resolve(true)
															},
														}
													: {},
											],
										]}>
										<Input />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										label='Date of Birth'
										name={clientInfoPath(['dateOfBirth'])}
										rules={[rules.requiredWhen(isRequired), rules.minAge(18)]}>
										<BwDatePicker />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										validateFirst
										normalize={(value) => value?.replace(/\s+/g, '') || undefined}
										label='SIN'
										name={clientInfoPath(['socialInsuranceNumber'])}
										extra={getUniqMessages(clientKey).sin}
										rules={[rules.sin]}>
										<Input type='number' />
									</Form.Item>
								</Col>

								<Col
									xs={24}
									xl={12}>
									<Form.Item
										{...formItemProps}
										label='Marital Status'
										name={clientInfoPath(['maritalStatus'])}
										rules={[rules.requiredWhen(isRequired)]}>
										<Select
											allowClear
											options={[
												{ label: 'Common Law', value: MaritalStatusType.CommonLaw },
												{ label: 'Divorced', value: MaritalStatusType.Divorced },
												{ label: 'Married', value: MaritalStatusType.Married },
												{ label: 'Separated', value: MaritalStatusType.Separated },
												{ label: 'Single', value: MaritalStatusType.Single },
												{ label: 'Widowed', value: MaritalStatusType.Widowed },
											].filter((el) => {
												// When the spouse of 'Client 1' is 'Client 2', their Marital Status should match"
												if (isSpouseClient2 && clientKey === 'clientTwo') {
													return el.value === clientOneMaritalStatus
												}

												return true
											})}
										/>
									</Form.Item>
								</Col>

								{clientCount === 2 && clientKey === 'clientOne' && (
									<>
										<Col
											xs={24}
											xl={12}>
											<Form.Item
												{...formItemProps}
												label={'Relationship of Client 2 to Client 1'}
												name={clientInfoPath(['relationshipType'])}
												dependencies={[clientInfoPath(['maritalStatus'])]}
												rules={[
													rules.requiredWhen(isRequired),
													rules.selectedFromList(Object.values(RelationshipType)),
												]}>
												<Select
													allowClear
													options={Object.values(RelationshipType).map((value) => {
														const disabled =
															!isMarriedOrCommonLaw(clientInfo?.maritalStatus) && value === RelationshipType.Spouse

														return {
															value,
															disabled,
														}
													})}
												/>
											</Form.Item>
										</Col>

										{clientInfo?.relationshipType === RelationshipType.Other && (
											<Col
												xs={24}
												xl={12}>
												<Form.Item
													{...formItemProps}
													label='Other: Specify'
													name={clientInfoPath(['relationshipTypeOther'])}
													rules={[rules.requiredWhen(isRequired), rules.minmax(1, 20)]}>
													<Input />
												</Form.Item>
											</Col>
										)}
									</>
								)}

								{isNeedSpouseInfo() && (
									<Col>
										<Row gutter={[20, 0]}>
											<Col
												xs={24}
												xl={12}>
												<Form.Item
													{...formItemProps}
													label='Spouse First Name'
													name={clientInfoPath(['spouseFirstName'])}
													rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
													<Input />
												</Form.Item>
											</Col>

											<Col
												xs={24}
												xl={12}>
												<Form.Item
													{...formItemProps}
													label='Spouse Last Name'
													name={clientInfoPath(['spouseLastName'])}
													rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
													<Input />
												</Form.Item>
											</Col>
											<Col
												xs={24}
												xl={12}>
												<Form.Item
													label='Spouse Date of Birth'
													name={clientInfoPath(['spouseDateOfBirth'])}
													rules={[rules.requiredWhen(isRequired)]}>
													<BwDatePicker />
												</Form.Item>
											</Col>

											<Col
												xs={24}
												xl={12}>
												<Form.Item
													validateFirst
													normalize={(value) => value?.replace(/\s+/g, '') || undefined}
													label='Spouse SIN'
													name={clientInfoPath(['spouseSocialInsuranceNumber'])}
													rules={[rules.requiredWhen(isRequired && isSpousalAccount), rules.sin]}>
													<Input type='number' />
												</Form.Item>
											</Col>
										</Row>
									</Col>
								)}
							</Row>
						</Col>
					)
				})}
			</Row>
		</>
	)
}

export default ClientInformation
