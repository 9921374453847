import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ClientBaseInfoDocument } from 'graphql/generated/schema'
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg'
import { printError } from 'helpers/error'

const FormSentPage: FC = () => {
	const { guid = '' } = useParams()

	const client = useQuery(ClientBaseInfoDocument, { variables: { guid } })

	const { firstName, lastName, portfolioManager, advisor } = client?.data?.client || {}
	const clientFullName = firstName ? `${firstName} ${lastName}` : 'Client'
	const advisorFullName = advisor ? `${advisor?.firstName} ${advisor?.lastName}` : null

	useEffect(() => {
		if (client.error) {
			printError(client.error)
		}
	}, [client])

	return (
		<div className='bw-start'>
			<header className='bw-start__header'>
				<div className='bw-start__logo'>
					<LogoSVG />
				</div>
				<div className='bw-start__head'>
					<div className='bw-start__contacts'>
						<div>Bellwether Investment Management Inc.</div>
						<div>1295 Cornwall Road, Unit A3 Oakville ON L6J 7T5</div>
						<div>Tel: 905.337.2227 Fax: 905.337.3552</div>
					</div>
				</div>
			</header>

			<main className='bw-start__main'>
				<div className='bw-start__info'>
					<div>
						<div className='bw-start__client'>{clientFullName}</div>
						<h2 className='bw-start__title'>
							Congratulations, you’ve just taken the first step in what will be a long and fruitful business
							relationship.
						</h2>
						<div className='bw-start__thanks'>
							A secure DocuSign link has been sent to your email for your signature. Alongside {advisorFullName}, we
							look forward to strategically managing your portfolio to bring your goals to life. Thank you for making
							Bellwether your first choice. Now let’s make you ours.
						</div>
					</div>
					<div className='bw-manager'>
						{advisor && (
							<div className='bw-manager__item'>
								<div>Your Family Wealth Advisor</div>
								<div className='bw-manager__name'>{advisorFullName}</div>
								<address>
									<div>{advisor?.address}</div>
									<div>Tel: {advisor?.phone}</div>
								</address>
							</div>
						)}
						{portfolioManager && (
							<div className='bw-manager__item'>
								<div>Your Portfolio Manager</div>
								<div className='bw-manager__name'>
									{portfolioManager?.firstName} {portfolioManager?.lastName}
								</div>
								<address>
									<div>{portfolioManager?.address}</div>
									<div>Tel: {portfolioManager?.phone}</div>
								</address>
							</div>
						)}
					</div>
				</div>
			</main>
		</div>
	)
}
export default FormSentPage
