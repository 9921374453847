import { FC } from 'react'
import Counters from './Counters'
import Workspace from './Workspace'
import { Role } from 'graphql/generated/schema'
import { useAuth } from 'hooks/useContexts'
import moment from 'moment'
import { message } from 'antd'

const Dashboard: FC = () => {
	moment.updateLocale('en', {
		relativeTime: {
			future: 'in %s',
			past: '%s ago',
			s: 'seconds',
			ss: '%ss',
			m: 'a minute',
			mm: '%dm',
			h: 'an hour',
			hh: '%dh',
			d: 'a day',
			dd: '%dd',
			M: 'a month',
			MM: '%dM',
			y: 'a year',
			yy: '%dY',
		},
	})

	message.config({
		top: 15,
		duration: 5,
		maxCount: 3,
	})

	const auth = useAuth()

	return (
		<>
			{auth?.user?.role === Role.Admin && <Counters />}
			<Workspace />
		</>
	)
}

export default Dashboard
