import { FC, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { Drawer, Button, Space, Typography, Timeline, Collapse, Empty, Modal } from 'antd'
import { DataTypeClient } from './tabs/Clients'
import { ClientLogStatus, GetClientLogsDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'

interface Props {
	clientRecord: DataTypeClient
	showClientLog: boolean
	setShowClientLog: (showClientLog: boolean) => void
}

const LOG_DISPLAY_LENGTH = 75
const LOG_POOL_INTERVAL = 5000

const ClientLogDrawer: FC<Props> = (props) => {
	const clientLogs = useQuery(GetClientLogsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: {
			guid: props.clientRecord.guid,
		},
		onError: (err) => {
			printError(err)
		},
	})

	const onClose = () => {
		props.setShowClientLog(false)
	}

	useEffect(() => {
		clientLogs.startPolling(LOG_POOL_INTERVAL)
		return () => {
			clientLogs.stopPolling()
		}
	}, [clientLogs.startPolling, clientLogs.stopPolling])

	const logColorMap: {
		[key in ClientLogStatus]: string
	} = {
		SUCCESS: 'green',
		ERROR: 'red',
		INFO: 'blue',
		DEBUG: 'gray',
		WARNING: 'yellow',
	}

	const clientLogsData = clientLogs.data?.client?.clientLogs

	const getLogMessage = (message: string) => {
		if (message.startsWith('Fidelity')) {
			//1. Cut the XML for view in a new window
			const regexXML = /<\?xml[\s\S]*<\/Request>/
			const matchXML = message.match(regexXML)

			//2. Cut the Fidelity error json
			const regexJSON = /{[\s\S]*}/
			const matchJSON = message.match(regexJSON)

			if (matchXML || matchJSON) {
				const xmlString = matchXML?.[0]
				const jsonString = matchJSON?.[0]

				message = message.replace(regexXML, '')
				message = message.replace(regexJSON, '')

				return (
					<Collapse
						ghost
						expandIcon={() => null}>
						<Collapse.Panel
							header={message.slice(0, LOG_DISPLAY_LENGTH) + ' ...'}
							key='1'>
							<div>{message}</div>

							<Space style={{ marginTop: 15 }}>
								{xmlString && (
									<Button
										className='ant-btn-default-gold'
										onClick={() =>
											Modal.info({
												width: 1000,
												icon: false,
												content: <pre>{xmlString}</pre>,
												okButtonProps: {
													className: 'ant-btn-primary-black',
												},
												okText: 'Copy and close',
												afterClose: () => {
													navigator.clipboard.writeText(xmlString)
												},
											})
										}>
										View XML
									</Button>
								)}

								{jsonString && (
									<Button
										className='ant-btn-default-gold'
										onClick={() =>
											Modal.info({
												width: 1000,
												icon: false,
												content: (
													<pre style={{ whiteSpace: 'pre-wrap' }}>
														{JSON.stringify(JSON.parse(jsonString), null, 1)}
													</pre>
												),
												okButtonProps: {
													className: 'ant-btn-primary-black',
												},
												okText: 'Close',
											})
										}>
										View Fidelity Response
									</Button>
								)}
							</Space>
						</Collapse.Panel>
					</Collapse>
				)
			}
		}

		if (message.length > LOG_DISPLAY_LENGTH) {
			return (
				<Collapse
					ghost
					expandIcon={() => null}>
					<Collapse.Panel
						header={message.slice(0, LOG_DISPLAY_LENGTH) + ' ...'}
						key='1'>
						<p> {message}</p>
					</Collapse.Panel>
				</Collapse>
			)
		}

		return message
	}

	return (
		<Drawer
			placement='right'
			width={750}
			onClose={onClose}
			open={props.showClientLog}
			destroyOnClose={true}
			closeIcon={false}
			className='bw-log--drawer'
			title={
				<>
					<Space style={{ display: 'flex' }}>
						<Typography.Text
							strong
							style={{ fontSize: 16 }}>
							{props.clientRecord?.fullName}
						</Typography.Text>
					</Space>
				</>
			}
			extra={
				<Space size='large'>
					<Button
						className='ant-btn-default-gold'
						size='large'
						onClick={onClose}>
						Cancel
					</Button>
				</Space>
			}>
			{clientLogsData?.length ? (
				<Timeline
					mode='left'
					pending={false}>
					{clientLogsData?.map((log, idx) => {
						return (
							<Timeline.Item
								key={idx}
								label={moment(log.createdAt).format('YYYY-MM-DD HH:mm:ss')}
								color={logColorMap[log.status]}>
								{getLogMessage(log.message)}
							</Timeline.Item>
						)
					})}
				</Timeline>
			) : (
				<Empty />
			)}
		</Drawer>
	)
}

export default ClientLogDrawer
