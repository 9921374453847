import { FC } from 'react'
import { Col, Form, Row, Select, Tabs } from 'antd'
import { formItemProps, rules } from 'constants/props'
import BeneficiaryFields from './BeneficiaryFields'
import TransferFields from './TransferFields'
import PacSwpFields from './PacSwpFields'
import type { Paths } from 'types/ips'
import {
	ClientInfoType,
	CurrencyType,
	IndividualAccountInfoType,
	JurisdictionType,
	OnboardingChildProps,
	OnboardingFormValues,
} from 'types/client-onboarding'
import RrifPaymentsFields from './RrifPaymentsFields'
import { AccountType } from 'graphql/generated/schema'
import { isMarriedOrCommonLaw } from './ClientInformation'
import { useMatchQuery } from 'hooks/useMatchQuery'
import moment from 'moment'

const IndividualAccounts: FC<OnboardingChildProps> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')

	return (
		<Row gutter={[80, 0]}>
			{props.clientsData.map((clientData, clientIndx) => {
				const { clientInfo, individualAccounts = [], clientKey, clientNum } = clientData ?? {}

				const accountsNum = clientInfo?.accountsNum

				if (!accountsNum) {
					return (
						<Col
							xs={24}
							xl={12}
							key={clientNum}
						/>
					)
				}

				const accountFieldsJSX = [...Array(accountsNum)].map((_, accIndex) => {
					const selectedAccounts = individualAccounts.map((acc) => acc?.accountType)

					const currentAccount = individualAccounts[accIndex] ?? {}

					const fieldPath = (keys: Paths<IndividualAccountInfoType>): Paths<OnboardingFormValues> => [
						clientKey,
						'individualAccounts',
						accIndex,
						...keys,
					]

					const clientInfoPath = (keys: Paths<ClientInfoType>): Paths<OnboardingFormValues> => [
						clientKey,
						'clientInfo',
						...keys,
					]

					return (
						<Row
							gutter={[20, 0]}
							style={{ marginBottom: 30 }}
							align={'bottom'}
							key={accIndex}>
							<Col xs={24}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginBottom: 35,
										marginTop: 5,
									}}>
									<h4 style={{ color: '#A09575', margin: 0 }}>Account {accIndex + 1}</h4>
									{/* <IconCheck style={{ color: '#A09575', fontSize: 20 }} /> */}
								</div>
							</Col>
							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Account Type'
									name={fieldPath(['accountType'])}
									dependencies={[clientInfoPath(['maritalStatus'])]}
									rules={[
										rules.requiredWhen(isRequired),
										({ getFieldValue }) => ({
											validator: (_, value: AccountType | undefined) => {
												if (value !== AccountType.Itf) {
													const occurrences = selectedAccounts.filter(
														(accType) => value && accType && accType === value,
													).length

													if (occurrences > 1) {
														return Promise.reject(`Only one ${value} account per client is allowed`)
													}
												}

												if (value === AccountType.Fhsa) {
													const maxAge = 71
													const dateOfBirth = getFieldValue(clientInfoPath(['dateOfBirth']))
													const isValidAge = moment().diff(moment(dateOfBirth), 'years') <= maxAge

													if (dateOfBirth && !isValidAge) {
														return Promise.reject(`The maximum age allowed is ${maxAge}`)
													}
												}

												return Promise.resolve()
											},
										}),
									]}>
									<Select
										allowClear
										listHeight={300}
										options={[
											{ label: 'TFSA', value: AccountType.Tfsa },
											// { label: 'FHSA', value: AccountType.Fhsa }, //TODO: Temporary hidden
											{ label: 'Cash', value: AccountType.Cash },
											{ label: 'RRSP', value: AccountType.Rrsp },
											{ label: 'Spousal RRSP', value: AccountType.SpousalRrsp },
											{ label: 'LIRA/LRSP', value: AccountType.LiraLrsp },
											{ label: 'RRIF', value: AccountType.Rrif },
											{ label: 'Spousal RRIF', value: AccountType.SpousalRrif },
											{ label: 'LIF', value: AccountType.Lif },
											{ label: 'ITF', value: AccountType.Itf },
										]}
									/>
								</Form.Item>
							</Col>

							<Col
								xs={24}
								xl={12}>
								<Form.Item
									{...formItemProps}
									label='Account Currency'
									name={fieldPath(['accountCurrency'])}
									rules={[rules.requiredWhen(isRequired)]}>
									<Select
										allowClear
										options={[
											{ label: 'CAD', value: CurrencyType.Cad },
											{ label: 'CAD & USD', value: CurrencyType.CadUsd },
										]}
									/>
								</Form.Item>
							</Col>

							{/* Jurisdiction */}
							{currentAccount.accountType &&
								[AccountType.Lif, AccountType.LiraLrsp].includes(currentAccount.accountType) && (
									<Col
										xs={24}
										xl={12}>
										<Form.Item
											{...formItemProps}
											label='Jurisdiction'
											name={fieldPath(['jurisdiction'])}
											rules={[rules.requiredWhen(isRequired)]}>
											<Select
												allowClear
												options={getJurisdictions(currentAccount.accountType).map((value) => ({
													value,
												}))}
											/>
										</Form.Item>
									</Col>
								)}

							{/* Beneficiary */}
							{currentAccount.accountType &&
								[
									AccountType.Tfsa,
									AccountType.Rrsp,
									AccountType.SpousalRrsp,
									AccountType.LiraLrsp,
									AccountType.Rrif,
									AccountType.SpousalRrif,
									AccountType.Lif,
									AccountType.Itf,
									AccountType.Fhsa,
								].includes(currentAccount.accountType) && (
									<Col
										xs={24}
										style={{ marginTop: 20 }}>
										<Row
											gutter={[20, 0]}
											align={'bottom'}
											style={{ marginBottom: 1 }}>
											<Col xs={24}>
												<h4>Beneficiary Information</h4>
											</Col>

											{isMarriedOrCommonLaw(clientInfo?.maritalStatus) &&
												[
													AccountType.Tfsa,
													AccountType.Fhsa,
													AccountType.Rrif,
													AccountType.SpousalRrif,
													AccountType.Lif,
												].includes(currentAccount.accountType) && (
													<Col
														xs={24}
														xl={12}>
														<Form.Item
															{...formItemProps}
															label='Would you like to appoint a Successor?'
															name={fieldPath(['isSuccessor'])}
															rules={[rules.requiredWhen(isRequired)]}>
															<Select
																allowClear
																options={[
																	{ value: true, label: 'Yes' },
																	{ value: false, label: 'No' },
																]}
															/>
														</Form.Item>
													</Col>
												)}

											{!currentAccount.isSuccessor && currentAccount.accountType !== AccountType.Itf && (
												<Col
													xs={24}
													xl={12}>
													<Form.Item
														{...formItemProps}
														label='Would you like to appoint Beneficiaries?'
														name={fieldPath(['isBeneficiary'])}
														rules={[rules.requiredWhen(isRequired)]}>
														<Select
															allowClear
															options={[
																{ value: true, label: 'Yes' },
																{ value: false, label: 'No' },
															]}
														/>
													</Form.Item>
												</Col>
											)}

											{currentAccount.accountType === AccountType.Itf && (
												<BeneficiaryFields
													{...props}
													beneficiaryInfo={currentAccount.beneficiary}
													accountType={currentAccount.accountType}
													isSuccessor={currentAccount.isSuccessor}
													beneficiaryNum={1}
													fieldPath={fieldPath}
												/>
											)}

											{currentAccount.isBeneficiary && (
												<>
													<Col
														xs={24}
														xl={12}>
														<Form.Item
															{...formItemProps}
															label='Number of Beneficiary'
															name={fieldPath(['beneficiaryNum'])}
															rules={[rules.requiredWhen(isRequired)]}>
															<Select
																allowClear
																options={Array.from({ length: 4 }, (_, index) => ({ value: index + 1 }))}
															/>
														</Form.Item>
													</Col>

													<BeneficiaryFields
														{...props}
														beneficiaryInfo={currentAccount.beneficiary}
														accountType={currentAccount.accountType}
														beneficiaryNum={currentAccount.beneficiaryNum}
														isSuccessor={currentAccount.isSuccessor}
														fieldPath={fieldPath}
													/>
												</>
											)}
										</Row>
									</Col>
								)}

							{/* Transfer */}
							<TransferFields
								{...props}
								accountCurrency={currentAccount.accountCurrency}
								isTransfer={currentAccount.isTransfer}
								transferNum={currentAccount.transferNum}
								fieldPath={fieldPath}
							/>

							{/* PAC/SWP */}
							{currentAccount.accountType &&
								[
									AccountType.Tfsa,
									AccountType.Cash,
									AccountType.Rrsp,
									AccountType.SpousalRrsp,
									AccountType.Itf,
								].includes(currentAccount.accountType) && (
									<PacSwpFields
										{...props}
										isPacSwp={currentAccount.isPacSwp}
										pacSwp={currentAccount.pacSwp}
										accountType={currentAccount.accountType}
										fieldPath={fieldPath}
									/>
								)}

							{/* RIFF Payments */}
							{currentAccount.accountType &&
								[AccountType.Rrif, AccountType.SpousalRrif, AccountType.Lif].includes(currentAccount.accountType) && (
									<RrifPaymentsFields
										{...props}
										fieldPath={fieldPath}
										riffPayments={currentAccount?.riffPayments}
									/>
								)}
						</Row>
					)
				})

				return (
					<Col
						key={clientNum}
						style={clientNum === 2 ? { borderLeft: '1px solid #f3f3f3' } : {}}
						xs={24}
						xl={12}>
						<Row>
							<Col>
								<h3>Client {clientNum}</h3>
							</Col>
						</Row>

						<Tabs
							tabPosition='left'
							destroyInactiveTabPane={false}
							className='ant-tabs-bar-sticky'
							items={accountFieldsJSX.map((jsx, idx) => ({
								key: String(idx),
								label: idx + 1,
								children: jsx,
								forceRender: true,
							}))}
						/>
					</Col>
				)
			})}
		</Row>
	)
}

const getJurisdictions = (accountType: AccountType) => {
	if (accountType === AccountType.Lif) {
		return [
			JurisdictionType.BC,
			JurisdictionType.AB,
			JurisdictionType.SK_PRIF,
			JurisdictionType.MB,
			JurisdictionType.ON,
			JurisdictionType.QC,
			JurisdictionType.NB,
			JurisdictionType.PEI,
			JurisdictionType.NS,
			JurisdictionType.NF_LRIF,
			JurisdictionType.FED_LIF,
			JurisdictionType.FED_RLIF,
		]
	}

	if (accountType === AccountType.LiraLrsp) {
		return [
			JurisdictionType.BC,
			JurisdictionType.AB,
			JurisdictionType.SK,
			JurisdictionType.MB,
			JurisdictionType.ON,
			JurisdictionType.QC,
			JurisdictionType.NB,
			JurisdictionType.PEI,
			JurisdictionType.NS,
			JurisdictionType.NF,
			JurisdictionType.FED_LIRSP,
			JurisdictionType.FED_RLSP,
		]
	}

	return []
}

export default IndividualAccounts
