import { FC } from 'react'
import { Tag } from 'antd'
import { ClockCircleOutlined as IconClock, CheckCircleOutlined as IconCheck } from '@ant-design/icons'
import type { TagProps } from 'antd'
import type { FileStatus } from 'graphql/generated/schema'

type StyleMap = {
	[key in FileStatus]: {
		color: TagProps['color']
		icon: React.ReactNode
	}
}

interface Props {
	status: FileStatus
}

const STATUSES_STYLE_MAP: StyleMap = {
	UPLOADED: {
		color: 'default',
		icon: <IconClock />,
	},
	SENT: {
		color: 'green',
		icon: <IconCheck />,
	},
}

const FileStatusTag: FC<Props> = ({ status }) => {
	const statusStyle = STATUSES_STYLE_MAP[status]

	return (
		<Tag color={statusStyle?.color}>
			{statusStyle?.icon}
			<span>{status}</span>
		</Tag>
	)
}

export default FileStatusTag
