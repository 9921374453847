import { Badge } from 'antd'
import { FC } from 'react'
import { RequestStatus } from 'graphql/generated/schema'
import type { BadgeProps } from 'antd'

type StatusMap = { [key in RequestStatus]: BadgeProps['status'] }

interface Props {
	status: RequestStatus
}

const STATUS_MAP: StatusMap = {
	PENDING: 'processing',
	SUCCESS: 'success',
	ERROR: 'error',
}

const RequestStatusBadge: FC<Props> = ({ status }) => {
	const statusProp = STATUS_MAP[status]

	return (
		<Badge
			status={statusProp}
			text={status}
		/>
	)
}

export default RequestStatusBadge
