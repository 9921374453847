import { Tag } from 'antd'
import { FC } from 'react'
import type { TagProps } from 'antd'
import { ClientStatusType } from 'graphql/generated/schema'

type ColorMap = { [key in ClientStatusType]: TagProps['color'] }

interface Props {
	status: ClientStatusType
}

const STATUSES_COLOR_MAP: ColorMap = {
	CREATION: 'cyan',
	ERROR: 'red',
	UPLOADED: 'default',
	SENT: 'geekblue',
	CLIENT: 'purple',
	SIGNATURE: 'magenta',
	FIDELITY: 'orange',
	COMPLETED: 'green',
}

const StatusTag: FC<Props> = ({ status }) => {
	const statusColor = STATUSES_COLOR_MAP[status]

	return <Tag color={statusColor}>{status}</Tag>
}

export default StatusTag
