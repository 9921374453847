import { FC, useEffect, useState } from 'react'
import { Table, message } from 'antd'
import { EditableCell, EditableRow, getColumnSearchProps } from 'helpers/table'
import { useMutation, useQuery } from '@apollo/client'
import {
	AdminsDocument,
	UpdateAdminDocument,
	AdminsQueryVariables,
	FilterAdminsInput,
	SortingAdminsInput,
	Sort,
} from 'graphql/generated/schema'
import type { TableProps } from 'antd/lib/table'
import { ColumnTypeEditable, SORT_MAP } from 'types/admin'
import rfdc from 'rfdc'
import { printError } from 'helpers/error'
const deepClone = rfdc()

interface DataType {
	id: number
	fullName: string
	email: string
	password: string
}

interface SearchParams {
	pagination: {
		current: number
		pageSize: number
	}
	filters: FilterAdminsInput
	sorting: SortingAdminsInput
}

const Admins: FC = () => {
	const [tableData, setTableData] = useState<DataType[]>([])
	const [searchParams, setSearchParams] = useState<SearchParams>({
		pagination: {
			current: 1,
			pageSize: 10,
		},
		sorting: {
			updatedAt: Sort.Desc,
		},
		filters: {},
	})

	const [updateAdmin] = useMutation(UpdateAdminDocument)

	const getSearchVariables = (): AdminsQueryVariables => {
		const { pagination } = searchParams

		return {
			filters: searchParams.filters,
			sorting: searchParams.sorting,
			take: pagination.pageSize,
			skip: pagination.pageSize * pagination.current - pagination.pageSize,
		}
	}

	const adminsRes = useQuery(AdminsDocument, {
		notifyOnNetworkStatusChange: true,
		variables: getSearchVariables(),
		onError: (err) => {
			setTableData([])
			printError(err)
		},
		onCompleted: (data) => {
			const preparedData =
				data?.admins?.nodes?.map((admin): DataType => {
					return {
						id: admin.id,
						fullName: `${admin.firstName} ${admin.lastName}`,
						email: admin.email,
						password: '********',
					}
				}) ?? []

			setTableData(preparedData)
		},
	})

	useEffect(() => {
		adminsRes.refetch()
	}, [searchParams])

	const columns: ColumnTypeEditable<DataType>[] = [
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '50%',
			sorter: true,
			showSorterTooltip: false,
			...getColumnSearchProps<DataType>(),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '30%',
			editable: true,
			...getColumnSearchProps<DataType>(),
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			width: '20%',
			editable: true,
		},
	]

	const handleSave = async (row: DataType, col: ColumnTypeEditable<DataType>) => {
		const colKey = col.dataIndex as keyof DataType

		updateAdmin({
			variables: {
				updateAdminInput: {
					id: row.id,
					[colKey]: row[colKey],
				},
			},
		})
			.then(() => {
				message.success(`${col.title} changed`)
				adminsRes.refetch()
			})
			.catch(printError)
	}

	const columnsEditable = columns
		.filter((col) => !col.hidden)
		.map((col) => {
			if (col.editable) {
				return {
					...col,
					onCell: (record: DataType) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave: (row: DataType) => handleSave(row, col),
					}),
				}
			}

			return col
		})

	const onTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
		setSearchParams((prevState) => {
			const clonedParams: SearchParams = deepClone(prevState)

			// filters
			Object.keys(filters).forEach((key) => {
				if (!filters[key]) {
					delete filters[key]
				}
			})
			clonedParams.filters = filters

			// sorting
			if (!Array.isArray(sorter)) {
				const sortKey = sorter.columnKey as keyof SortingAdminsInput
				const sortOrder = sorter.order

				if (sortOrder) {
					clonedParams.sorting[sortKey] = SORT_MAP[sortOrder] as any
				} else {
					delete clonedParams.sorting[sortKey]
				}
			}

			// pagination
			clonedParams.pagination = {
				current: pagination.current as number,
				pageSize: pagination.pageSize as number,
			}

			return clonedParams
		})
	}

	return (
		<Table
			columns={columnsEditable as ColumnTypeEditable<DataType>[]}
			components={{
				body: {
					row: EditableRow,
					cell: EditableCell,
				},
			}}
			dataSource={tableData}
			pagination={{
				...searchParams.pagination,
				total: adminsRes.data?.admins.totalCount,
				showSizeChanger: true,
				showTotal: (total, range) => `${range[0]}-${[range[1]]} of ${total} items`,
			}}
			loading={adminsRes.loading}
			onChange={onTableChange}
			rowClassName='editable-row'
			rowKey={(record) => record.id}
		/>
	)
}

export default Admins
