import { FC } from 'react'

interface Props {
	children: React.ReactNode
}

const TabsActions: FC<Props> = ({ children }) => {
	return <div className='admin-tabs__actions'>{children}</div>
}

export default TabsActions
