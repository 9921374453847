export function isNumeric(value: any) {
	return /^\d+$/.test(value)
}

export function isObjectMatchTrue(obj: any): boolean {
	for (const key in obj) {
		const value = obj[key]
		if (value === true || (typeof value === 'object' && isObjectMatchTrue(value))) {
			return true
		}
	}

	return false
}

export function formatCash(n: number) {
	if (n < 1e3) return n
	if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
	if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
	if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
	if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
}

export function containsSubarray(source: (number | string)[][], compare: (number | string)[][]): boolean {
	let found = false

	for (const sourceSub of source) {
		for (const compareSub of compare) {
			if (sourceSub.length === compareSub.length && sourceSub.every((value, index) => value === compareSub[index])) {
				found = true
				break
			}
		}
		if (found) {
			break
		}
	}

	return found
}
