import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, DatePicker, Form, Input, InputNumber } from 'antd'
import { SearchOutlined as SearchIco } from '@ant-design/icons'
import type { InputRef } from 'antd'
import type { FormInstance, Rule } from 'antd/lib/form'
import type { ColumnType } from 'antd/lib/table'
import { rules } from 'constants/props'
import moment from 'moment'
import { FILTER_INPUT_TYPE } from 'types/admin'

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface EditableItem {
	phone: string
	email: string
	password: string
}

interface EditableCellProps {
	title: React.ReactNode
	editable: boolean
	children: React.ReactNode
	dataIndex: keyof EditableItem
	record: EditableItem
	handleSave: (record: EditableItem) => void
}

interface EditableRowProps {
	index: number
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form
			form={form}
			component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

export const EditableCell: React.FC<EditableCellProps> = (props) => {
	const { title, editable, children, dataIndex, record, handleSave, ...restProps } = props

	const [editing, setEditing] = useState(false)
	const inputRef = useRef<InputRef>(null)
	const form = useContext(EditableContext) as FormInstance

	useEffect(() => {
		if (editing) {
			inputRef?.current?.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		const value = dataIndex === 'password' ? '' : record[dataIndex]
		setEditing(!editing)
		form.setFieldsValue({ [dataIndex]: value })
	}

	const save = async () => {
		try {
			const values = await form.validateFields()
			toggleEdit()

			// if unchanged
			if (record[dataIndex] === values[dataIndex]) {
				return
			}

			handleSave({ ...record, ...values })
		} catch (err) {
			console.log('Save col valid error:', err)
		}
	}

	const rulesMap: {
		[key in keyof EditableItem]: Rule[]
	} = {
		phone: [rules.mobilePhone],
		email: [rules.email],
		password: [rules.minmax(2, 24)],
	}

	let childNode = children

	if (editable) {
		childNode = editing ? (
			<Form.Item
				data-colindex={dataIndex}
				style={{ margin: 0 }}
				name={dataIndex}
				validateTrigger={['onBlur']}
				rules={[rules.required, ...rulesMap[dataIndex]]}>
				<Input
					ref={inputRef}
					onPressEnter={save}
					onBlur={save}
					allowClear
				/>
			</Form.Item>
		) : (
			<div
				className='editable-cell-value-wrap'
				onClick={toggleEdit}>
				{children}
			</div>
		)
	}

	return <td {...restProps}>{childNode}</td>
}

export function getColumnSearchProps<T>(type?: FILTER_INPUT_TYPE): ColumnType<T> {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
			return (
				<div style={{ padding: 8 }}>
					{type === FILTER_INPUT_TYPE.DATE_RANGE ? (
						<DatePicker.RangePicker
							placement='topRight'
							value={
								selectedKeys.length
									? [moment(selectedKeys[0] as string), moment(selectedKeys[1] as string)]
									: [null, null]
							}
							ranges={{
								Today: [moment(), moment()],
								'This Month': [moment().startOf('month'), moment().endOf('month')],
							}}
							onChange={(e) => {
								const start = e?.[0]?.startOf('day').format('YYYY-MM-DD HH:mm:ss')
								const end = e?.[1]?.endOf('day').format('YYYY-MM-DD HH:mm:ss')
								setSelectedKeys(start && end ? [start, end] : [])
							}}
							style={{ marginBottom: 6 }}
						/>
					) : type === FILTER_INPUT_TYPE.NUMBER ? (
						<InputNumber
							placeholder='Search'
							type='number'
							controls={false}
							min={0}
							value={Number(selectedKeys[0])}
							onChange={(value) => {
								setSelectedKeys(Number(value) ? [Number(value)] : [])
							}}
							onPressEnter={() => confirm()}
							style={{ marginBottom: 6, display: 'block', width: `${100}%` }}
						/>
					) : (
						<Input
							placeholder='Search'
							value={selectedKeys[0]?.toString()}
							onChange={({ target: { value } }) => {
								setSelectedKeys(value ? [value] : [])
							}}
							onPressEnter={() => confirm()}
							style={{ marginBottom: 6, display: 'block' }}
						/>
					)}
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 1fr)',
							gridGap: '8px',
						}}>
						<Button
							block
							className='ant-btn-primary-black'
							onClick={() => confirm()}
							icon={<SearchIco />}>
							Search
						</Button>
						<Button
							block
							className='ant-btn-default-gold'
							onClick={() => clearFilters && clearFilters()}>
							Reset
						</Button>
					</div>
				</div>
			)
		},
		filterIcon: (filtered: boolean) => <SearchIco style={{ color: filtered ? '#A09575' : undefined }} />,
	}
}
