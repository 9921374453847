import { FC, useState } from 'react'
import { Button, Form, Input, Row, Col } from 'antd'
import { MailOutlined as MailIco } from '@ant-design/icons'
import { rules } from 'constants/props'
import Layout from '../layout/Layout'
import { useMutation } from '@apollo/client'
import { ForgotPasswordDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'

interface FormValues {
	email: string
}

const ForgotPassword: FC = () => {
	const [forgotPassword, { loading }] = useMutation(ForgotPasswordDocument)
	const [isSent, setIsSent] = useState(false)

	const onFinish = (values: FormValues) => {
		forgotPassword({
			variables: {
				forgotPasswordInput: {
					email: values.email,
				},
			},
		})
			.then(() => setIsSent(true))
			.catch(printError)
	}

	return (
		<Layout>
			<div className='admin-auth'>
				<div className='admin-auth__inner'>
					<Row justify='center'>
						<Col>
							<h2>Forgot password</h2>
						</Col>
					</Row>

					{isSent ? (
						<h3 className='text-center'>We've sent you a link to reset your password. Please check your inbox.</h3>
					) : (
						<Form
							size='large'
							layout='vertical'
							onFinish={onFinish}
							requiredMark={false}>
							<Form.Item
								extra='Enter your email and we will send you a link to reset your password'
								name='email'
								rules={[rules.required, rules.email]}>
								<Input
									prefix={<MailIco />}
									placeholder='Email'
								/>
							</Form.Item>

							<Form.Item>
								<Button
									block
									loading={loading}
									type='primary'
									htmlType='submit'
									className='ant-btn-primary-gold'>
									Continue
								</Button>
							</Form.Item>
						</Form>
					)}
				</div>
			</div>
		</Layout>
	)
}

export default ForgotPassword
