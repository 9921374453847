import { createContext, useState, useContext, useRef } from 'react'
import { CloseOutlined as IconClose } from '@ant-design/icons'

interface AudioPlayerContextType {
	currentAudio: string | undefined
	isPlaying: boolean
	playAudio: (audioSrc: string) => void
	pauseAudio: () => void
}

const AudioPlayerContext = createContext<AudioPlayerContextType | null>(null)

export const useAudioPlayer = () => {
	const context = useContext(AudioPlayerContext)

	if (!context) {
		throw new Error('useAudioPlayer must be used within an AudioPlayerProvider')
	}

	return context
}

const AudioPlayerProvider: React.FC<{ children: any }> = ({ children }) => {
	const [currentAudio, setCurrentAudio] = useState<string>()
	const [isPlaying, setIsPlaying] = useState(false)
	const audioRef = useRef<HTMLAudioElement>(null)
	const currentRef = audioRef.current

	const playAudio = (audioSrc: string) => {
		setIsPlaying(true)
		setCurrentAudio(audioSrc)

		currentRef?.play()
	}

	const pauseAudio = () => {
		setIsPlaying(false)

		currentRef?.pause()
	}

	const closePlayer = () => {
		setCurrentAudio(undefined)
		pauseAudio()
	}

	return (
		<AudioPlayerContext.Provider value={{ currentAudio, isPlaying, playAudio, pauseAudio }}>
			{children}

			<div
				className='audio-player'
				data-visible={Boolean(currentAudio)}>
				<audio
					ref={audioRef}
					src={currentAudio}
					onPlay={() => setIsPlaying(true)}
					onPause={() => setIsPlaying(false)}
					onEnded={() => closePlayer()}
					controls
					autoPlay
				/>
				<button
					type='button'
					className='audio-player__close'
					aria-label='Close player'
					onClick={closePlayer}>
					<IconClose />
				</button>
			</div>
		</AudioPlayerContext.Provider>
	)
}

export default AudioPlayerProvider
