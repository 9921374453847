import { FC, useState } from 'react'
import { message, Modal, notification, Upload } from 'antd'
import { InboxOutlined as IconInbox, LoadingOutlined as IconLoading } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { MODAL } from 'types/admin'
import { adminModalProps } from '../ModalProps'
import { FindFormsOfAdvisorsDocument, UploadAdvisorImaFormDocument } from 'graphql/generated/schema'
import { printError } from 'helpers/error'
import { useModal } from 'hooks/useContexts'
import type { UploadFile as UploadFileType } from 'antd/lib/upload/interface'

const ALLOWED_TYPES = ['application/pdf']

const ModalUploadIMA: FC = () => {
	const { activeModal, setActiveModal } = useModal()
	const [uploadIMA] = useMutation(UploadAdvisorImaFormDocument)
	const [fileList, setFileList] = useState<UploadFileType[]>([])
	const [loading, setLoading] = useState(false)

	const closeModal = () => {
		setFileList([])
		notification.destroy()
		setActiveModal(null)
	}

	const [file] = fileList

	const onUploadForm = async () => {
		setLoading(true)

		try {
			await uploadIMA({
				variables: {
					form: file.originFileObj,
				},
				refetchQueries: [FindFormsOfAdvisorsDocument],
			})

			closeModal()
			message.success('IMA Form successfully uploaded')
		} catch (err: any) {
			printError(err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Modal
			{...adminModalProps}
			className='admin-modal admin-modal--A'
			open={activeModal === MODAL.UPLOAD_IMA}
			okText='Upload'
			okButtonProps={{
				className: 'ant-btn-primary-black',
				disabled: !file,
				loading,
			}}
			cancelButtonProps={{
				className: 'ant-btn-default-gold',
				loading,
			}}
			onCancel={closeModal}
			onOk={onUploadForm}>
			<h2 className='text-center'>Upload IMA Form</h2>

			<Upload.Dragger
				name='file'
				multiple={false}
				maxCount={1}
				listType='picture'
				fileList={fileList}
				disabled={loading}
				customRequest={() => {}}
				beforeUpload={(file) => {
					const isAllowedType = ALLOWED_TYPES.includes(file.type)
					const isAllowedSize = file.size / 1024 / 1024 < 10

					if (!isAllowedType) {
						message.error('Allowed only .pdf file format')
						return Upload.LIST_IGNORE
					}

					if (!isAllowedSize) {
						message.error('File must be smaller than 10MB!')
						return Upload.LIST_IGNORE
					}
				}}
				accept={ALLOWED_TYPES.join()}
				onChange={(info) => {
					if (info.file.status === 'removed') {
						setFileList([])
					} else if (info.file.status === 'uploading') {
						setFileList([{ ...info.file, status: 'success' }])
					}
				}}>
				<p className='ant-upload-drag-icon'>{loading ? <IconLoading /> : <IconInbox />}</p>
				<p className='ant-upload-text'>Click or drag file to this area to upload</p>
			</Upload.Dragger>
		</Modal>
	)
}

export default ModalUploadIMA
