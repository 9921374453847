import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Table, UploadFile, notification } from 'antd'
import { useMutation } from '@apollo/client'
import { PreviewClientsBaseDocument, PreviewClientsBaseMutation } from 'graphql/generated/schema'
import { SelectUserOptionType } from '../AddFile/index'
import Initials from '../../common/Initials'
import { printError } from 'helpers/error'
import type { ColumnType } from 'antd/lib/table'

interface DataType {
	id: number
	fullName: string
	email: string
	pm: string
	advisor: string
}

interface Props {
	fileList: UploadFile[]
	setFileList: Dispatch<SetStateAction<UploadFile[]>>
	selectedPm: SelectUserOptionType
	selectedAdvisor: SelectUserOptionType
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ReviewTable: FC<Props> = (props) => {
	const [tableData, setTableData] = useState<DataType[]>([])
	const [previewClientsBase, { loading }] = useMutation(PreviewClientsBaseDocument)
	const [previewClientsData, setPreviewClientsData] = useState<PreviewClientsBaseMutation['previewClients']>([])

	const [file] = props.fileList

	const fetchData = async () => {
		if (!file) return

		try {
			props.setLoading(true)
			notification.destroy()
			setPreviewClientsData([])

			const clients = await previewClientsBase({
				variables: { file: file.originFileObj },
			})

			setPreviewClientsData(clients.data?.previewClients || [])
		} catch (err: any) {
			props.setFileList([])
			setPreviewClientsData([])
			printError(err)
		} finally {
			props.setLoading(false)
		}
	}

	const prepareTableData = () => {
		const preparedData = previewClientsData.map((client, idx: number): DataType => {
			return {
				id: idx + 1,
				fullName: `${client.firstName} ${client.lastName}`,
				email: client.email || '',
				pm: props.selectedPm.label,
				advisor: props.selectedAdvisor.label,
			}
		})

		setTableData(preparedData)
	}

	useEffect(() => {
		fetchData()
	}, [file])

	useEffect(() => {
		prepareTableData()
	}, [previewClientsData, props.selectedPm, props.selectedAdvisor])

	const columns: ColumnType<DataType>[] = [
		{
			title: '#',
			dataIndex: 'id',
			width: '7%',
		},
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			width: '23%',
			render: (fullName) => <b>{fullName}</b>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			ellipsis: true,
			width: '20%',
		},
		{
			title: 'Portfolio Manager',
			dataIndex: 'pm',
			width: '25%',
			render: (name) => (
				<Initials
					name={name}
					color='blue'
				/>
			),
		},
		{
			title: 'Advisor',
			dataIndex: 'advisor',
			width: '25%',
			render: (name) => (
				<Initials
					name={name}
					color='orange'
				/>
			),
		},
	]

	return (
		<Table
			columns={columns}
			dataSource={tableData}
			loading={loading}
			rowKey={(record) => record.id}
		/>
	)
}

export default ReviewTable
