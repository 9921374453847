import { FC, Fragment } from 'react'
import {
	IndividualAccountInfoType,
	JointAccountInfoType,
	OnboardingChildProps,
	OnboardingFormValues,
	TransferProvinceType,
	CurrencyType,
} from '../../../types/client-onboarding'
import { Col, Form, Input, InputNumber, Select } from 'antd'
import { formItemProps, rules } from 'constants/props'
import { Paths } from 'types/ips'
import { TransferType } from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'

type PathType = IndividualAccountInfoType | JointAccountInfoType

interface Props {
	transferNum?: 1 | 2
	isTransfer?: boolean
	accountCurrency?: CurrencyType
	fieldPath(keys: Paths<PathType>): Paths<OnboardingFormValues>
}

const TransferFields: FC<OnboardingChildProps & Props> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')
	const { fieldPath, isTransfer, transferNum, accountCurrency } = props

	const fieldsJSX =
		transferNum &&
		[...Array(transferNum)].map((_, idx) => {
			return (
				<Fragment key={idx}>
					<Col xs={24}>
						<h5>Transfer {idx + 1}</h5>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Institution Name'
							name={fieldPath(['transfers', idx, 'institutionName'])}
							rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
							<Input />
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Amount'
							name={fieldPath(['transfers', idx, 'transferAmount'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<InputNumber
								type='number'
								min={0}
								max={100_000_000}
								style={{ width: `${100}%` }}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Institution City'
							name={fieldPath(['transfers', idx, 'institutionCity'])}
							rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
							<Input />
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Institution Address'
							name={fieldPath(['transfers', idx, 'institutionAddress'])}
							rules={[rules.requiredWhen(isRequired), rules.minmax(2, 100)]}>
							<Input />
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Institution Province'
							name={fieldPath(['transfers', idx, 'institutionProvince'])}
							rules={[rules.requiredWhen(isRequired), rules.selectedFromList(Object.values(TransferProvinceType))]}>
							<Select
								options={Object.values(TransferProvinceType).map((value) => ({
									value,
								}))}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Institution Postal Code'
							normalize={(val?: string) => val?.toUpperCase()}
							name={fieldPath(['transfers', idx, 'institutionPostalCode'])}
							rules={[rules.requiredWhen(isRequired), rules.postalCA]}>
							<Input maxLength={7} />
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Currency'
							name={fieldPath(['transfers', idx, 'transferCurrency'])}
							dependencies={[fieldPath(['accountCurrency'])]}
							rules={[
								rules.requiredWhen(isRequired),
								{
									validator: (_, value: CurrencyType) => {
										if (value === CurrencyType.Usd && accountCurrency && accountCurrency !== CurrencyType.CadUsd) {
											return Promise.reject(`Allowed for account currency: ${CurrencyType.CadUsd}`)
										}

										return Promise.resolve()
									},
								},
							]}>
							<Select
								allowClear
								options={[
									{ label: 'CAD', value: CurrencyType.Cad },
									...(accountCurrency === CurrencyType.CadUsd
										? [
												{
													label: 'USD',
													value: CurrencyType.Usd,
												},
											]
										: []),
								]}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Type'
							name={fieldPath(['transfers', idx, 'transferType'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<Select
								allowClear
								options={[
									{
										label: 'All In Kind',
										value: TransferType.AllInKind,
									},
									{
										label: 'All In Cash',
										value: TransferType.AllInCash,
									},
								]}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Transfer Delivery Account #'
							name={fieldPath(['transfers', idx, 'deliveryNumber'])}
							rules={[rules.requiredWhen(isRequired), rules.minmax(2, 20)]}>
							<Input />
						</Form.Item>
					</Col>
				</Fragment>
			)
		})

	return (
		<>
			<Col xs={24}>
				<h4>Transfer Information</h4>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label='Would you like to initiate a transfer into this account?'
					name={fieldPath(['isTransfer'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						allowClear
						options={[
							{ value: true, label: 'Yes' },
							{ value: false, label: 'No' },
						]}
					/>
				</Form.Item>
			</Col>

			{isTransfer && (
				<>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='How many?'
							name={fieldPath(['transferNum'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<Select
								allowClear
								options={Array.from({ length: 2 }, (_, index) => ({ value: index + 1 }))}
							/>
						</Form.Item>
					</Col>

					{fieldsJSX}
				</>
			)}
		</>
	)
}

export default TransferFields
