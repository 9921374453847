import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Form, message, Modal, Select, Spin } from 'antd'
import { debounce } from 'throttle-debounce'
import { classNames } from 'helpers/classNames'
import { getAssetMixState } from 'helpers/ips-assetmix'
import { getIPSValues } from 'helpers/ips-get-values'
import { steps, stepGroups } from './steps'
import { ReactComponent as CheckmarkSVG } from '../../assets/images/checkmark.svg'
import { ReactComponent as LogoSVG } from '../../assets/images/logo.svg'
import {
	ClientFullInfoDocument,
	ClientStatusType,
	SendDocusignEnvelopeDocument,
	UpdateClientDocument,
} from 'graphql/generated/schema'
import { useStep } from 'hooks/useSteps'
import { useSaveStepValues } from 'hooks/useSaveStepValues'
import CompleteModal from './CompleteModal'
import { IDataIPS, IStateIPS } from '../../types/ips'
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { CLICK_ACTIONS } from 'types/common'
import { printError } from 'helpers/error'
import { useInactiveIPS } from 'hooks/useInactiveIPS'
import { INACTIVE_IPS_MESSAGE } from 'constants/ips'
import { config } from 'config'
import { useMatchQuery } from 'hooks/useMatchQuery'

interface Props {
	isAdmin?: boolean
	guid?: string
}

const IPSPage: FC<Props> = (props) => {
	const navigate = useNavigate()
	const [fromAdmin] = useMatchQuery('from', 'admin')
	const [form] = Form.useForm<IDataIPS>()
	const [state, setState] = useState<IStateIPS>({
		isLoading: false,
		isCompleteModal: false,
		isSecondaryClient: false,
		isSecondaryClientAccounts: false,
		completedSteps: [],
		assetMix: {
			totalPoints: 0,
			breakpoint: 0,
			breakpoints: [],
			value: 'Fixed Income',
		},
	})

	let { guid = '' } = useParams()

	if (props.isAdmin && props.guid) {
		guid = props.guid
	}

	const [saveStepValues] = useSaveStepValues()
	const [inactiveFields, isInactiveIPS, updateInactiveValues] = useInactiveIPS()
	const [updateClient] = useMutation(UpdateClientDocument)
	const [sendDocusignEnvelope] = useMutation(SendDocusignEnvelopeDocument)
	const [activeStep, stepController] = useStep(steps.length)
	const client = useQuery(ClientFullInfoDocument, {
		onCompleted: () => {
			if (state.isLoading) {
				setStateIPS({ isLoading: false })
			}
		},
		fetchPolicy: 'no-cache',
		variables: { guid },
	})

	const { title, stepKey } = steps[activeStep - 1]

	const setStateIPS = (newState: Partial<IStateIPS>) => {
		setState((prevState) => ({ ...prevState, ...newState }))
	}

	const validateCurrentStepFields = () => {
		const currentStepFields = form
			.getFieldsError()
			.filter((el) => el.name[0] === stepKey)
			.map((el) => el.name)

		return form.validateFields([...currentStepFields])
	}

	const saveFormValues = async () => {
		setState((prevState) => ({ ...prevState, isLoading: true }))

		const valuesWithoutErrors = form.getFieldsValue(true, (meta) => meta.errors.length === 0)

		try {
			await saveStepValues({
				guid,
				formValues: valuesWithoutErrors,
				state,
				client,
			})
		} finally {
			setState((prevState) => ({ ...prevState, isLoading: false }))
		}
	}

	const onNextStep = async () => {
		try {
			await validateCurrentStepFields()

			await saveFormValues()

			stepController.nextStep()
		} catch (error) {
			if ((error as ValidateErrorEntity)?.errorFields?.length) {
				setStateIPS({ isCompleteModal: true })

				const firstError = (error as ValidateErrorEntity).errorFields[0].name

				form.scrollToField(firstError, {
					behavior: 'smooth',
					block: 'center',
				})
			} else {
				printError(error)
			}
		}
	}

	useEffect(() => {
		if (!props.isAdmin && !fromAdmin && guid) {
			updateClient({
				variables: {
					input: {
						guid,
						status: ClientStatusType.Client,
					},
				},
			}).catch(printError)
		}
	}, [])

	const onSendForm = async () => {
		// 1. Check that all steps are completed
		if (state.completedSteps.length !== steps.length) {
			form.validateFields().catch((err: ValidateErrorEntity) => {
				const firstError = err.errorFields[0].name
				const stepKey = firstError[0]
				const stepIdx = steps.find((step) => step.stepKey === stepKey)?.id as number

				stepController.setStep(stepIdx)

				form.scrollToField(firstError, {
					behavior: 'smooth',
					block: 'center',
				})
			})

			message.warn('Please complete all required fields')
			return false
		}

		// 2. Check that IPS form are not blocked
		if (isInactiveIPS) {
			message.warn(INACTIVE_IPS_MESSAGE)
			return false
		}

		Modal.confirm({
			className: 'bw-modal-confirm',
			width: 630,
			icon: false,
			closable: true,
			title: <h2>Confirm sending application</h2>,
			content: (
				<div className='text-center'>
					Confirm that you have completed all application fields correctly and are ready to send to your advisor for
					review. After confirmation you will not able to edit the form.
				</div>
			),
			cancelText: 'Back to edit',
			okText: 'Confirm',
			okButtonProps: {
				className: 'ant-btn-primary-black ant-btn-primary-black--lg',
			},
			cancelButtonProps: {
				className: 'ant-btn-link-white ant-btn-link-white--lg',
				type: 'link',
			},
			async onOk() {
				try {
					setStateIPS({ isLoading: true })

					await saveFormValues()

					await updateClient({
						variables: {
							input: {
								guid,
								status: ClientStatusType.Signature,
							},
						},
					})

					sendDocusignEnvelope({
						variables: { guid },
					})

					navigate(`/sent/${guid}`, { replace: true })
				} catch (err: any) {
					printError(err)
				} finally {
					setStateIPS({ isLoading: false })
				}
			},
		})
	}

	const setAssetMixState = debounce(1000, () => {
		const assetMix = getAssetMixState(form)

		if (JSON.stringify(state.assetMix) !== JSON.stringify(assetMix)) {
			setState((prevState) => ({ ...prevState, assetMix }))
		}
	})

	const setStepsCompleteness = debounce(1000, () => {
		const stepErrorCounter: Partial<{ [key in keyof IDataIPS]: number }> = {}

		// get all fields without errors on view
		const fieldsToResetErrors = form
			.getFieldsError()
			.filter((el) => el.errors.length === 0)
			.map((el) => ({ name: el.name, errors: [] }))

		form
			.validateFields()
			.then(() => {
				const completedSteps: number[] = steps.map((step) => step.id)
				setState((prevState) => ({ ...prevState, completedSteps }))
			})
			.catch((err: ValidateErrorEntity) => {
				err.errorFields.forEach((el) => {
					const key = el.name[0] as keyof IDataIPS
					const errorCounter = el.errors.length ? 1 : 0
					stepErrorCounter[key] = stepErrorCounter[key] ? stepErrorCounter[key] || 0 + errorCounter : errorCounter
				})

				const completedSteps: number[] = steps
					.filter(({ stepKey }) => !stepErrorCounter[stepKey])
					.map((step) => step.id)

				if (JSON.stringify(completedSteps) !== JSON.stringify(state.completedSteps)) {
					setState((prevState) => ({ ...prevState, completedSteps }))
				}
			})
			.finally(() => form.setFields(fieldsToResetErrors))
	})

	const onFormValuesChange = (changedValue: IDataIPS, values: IDataIPS) => {
		setStepsCompleteness()
		setAssetMixState()
		updateInactiveValues(values)
	}

	const checkEvent = async (e: React.MouseEvent<HTMLElement>) => {
		const target = e.target as HTMLElement
		const action = target.getAttribute('data-action')

		if (action === CLICK_ACTIONS.ADMIN_ON_SAVE_FORM) {
			await saveFormValues()
				.then(() => message.success('Saved'))
				.catch(printError)
		}
	}

	useEffect(() => {
		document.addEventListener('click', checkEvent as any, { passive: true })
		return () => document.removeEventListener('click', checkEvent as any)
	})

	useEffect(() => {
		window?.scrollTo(0, 0)
		if (props.isAdmin) {
			document?.querySelector('.bw-ips--drawer .ant-drawer-body')?.scrollTo({ top: 0 })
		}
		document?.querySelector('.bw-ips__step--active')?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})
	}, [activeStep])

	useEffect(() => {
		setState((prevState) => ({ ...prevState, isLoading: client.loading }))
	}, [client.loading])

	useEffect(() => {
		if (!client.loading) {
			const ipsValues = getIPSValues(client)

			if (ipsValues.clientInfo.clientTwo) {
				setState((prevState) => ({ ...prevState, isSecondaryClient: true }))
			}

			const isSecondaryClientAccounts = ipsValues.clientAccounts?.some(
				(acc) => acc.clientType === 'Client 2' || acc.ownerType === 'JOINT',
			)

			if (isSecondaryClientAccounts) {
				setState((prevState) => ({ ...prevState, isSecondaryClientAccounts: true }))
			}

			form.setFieldsValue(ipsValues as any)

			setStepsCompleteness()
			setAssetMixState()
			updateInactiveValues(ipsValues)
		}
	}, [client])

	const stepFormsJSX = steps.map(({ Content, id, title }) => (
		<div
			key={id}
			className='bw-form__step'
			style={{ display: activeStep === id ? 'block' : 'none' }}>
			<Content
				setStateIPS={setStateIPS}
				stateIPS={state}
				form={form}
				stepController={stepController}
				inactiveFields={inactiveFields}
				guid={guid}
				stepData={{
					id,
					title,
					active: activeStep === id,
				}}
			/>
		</div>
	))

	return (
		<>
			<div className='bw-ips'>
				<div className='bw-ips__inner'>
					<div className='bw-ips__lside'>
						<div className='bw-ips__sidebar'>
							<div className='bw-ips__logo'>
								<LogoSVG />
							</div>
							<h3 className='bw-ips__speech'>Client Information and Investment Policy Statement</h3>
							<nav className='bw-ips__nav'>
								{stepGroups.map((group) => (
									<div
										className='bw-ips__nav-item'
										key={group.id}>
										<b>{group.title}</b>
										<ul className='bw-ips__steps'>
											{steps
												.filter((step) => step.groupKey === group.id)
												.map((step) => (
													<li
														key={step.id}
														className={classNames({
															'bw-ips__step': true,
															'bw-ips__step--active': activeStep === step.id,
															'bw-ips__step--completed': state.completedSteps.includes(step.id),
														})}
														onClick={async () => {
															if (activeStep !== step.id) {
																stepController.setStep(step.id)
																if (!props.isAdmin) {
																	await saveFormValues().catch(printError)
																}
															}
														}}>
														<i>
															{state.completedSteps.includes(step.id) ? (
																<CheckmarkSVG fill={activeStep === step.id ? '#fff' : '#A09575'} />
															) : (
																step.id
															)}
														</i>
														<div>{step.title}</div>
													</li>
												))}
										</ul>
									</div>
								))}
							</nav>
						</div>
					</div>
					<div className='bw-ips__rside'>
						{config.isDev && !props.isAdmin && (
							<div
								style={{
									position: 'fixed',
									top: '0',
									background: '#fff',
									zIndex: 999,
									padding: 5,
									border: '1px solid #A09575',
								}}>
								breakpoint: {state.assetMix.breakpoint} | breakpoints:{' '}
								{Array.from(state.assetMix.breakpoints).join(', ')} | totalPoints: {state.assetMix.totalPoints} | value:{' '}
								{state.assetMix.value}
							</div>
						)}

						<div className='bw-ips__main'>
							<div className='bw-ips__indicate'>
								<span>
									Step {activeStep} of {steps.length}
								</span>
								{activeStep < steps.length && (
									<span>
										Step {activeStep + 1}. {steps[activeStep].title}
										{' >'}
									</span>
								)}
							</div>
							<div className='bw-ips__head'>
								<h1 className='bw-ips__title'>{title}</h1>
								<Select
									placeholder='Font size'
									style={{ width: 120 }}
									options={[
										{ value: 14, label: <span style={{ fontSize: '14px' }}>14</span> },
										{ value: 16, label: <span style={{ fontSize: '16px' }}>16</span> },
										{ value: 18, label: <span style={{ fontSize: '18px' }}>18</span> },
										{ value: 20, label: <span style={{ fontSize: '20px' }}>20</span> },
										{ value: 22, label: <span style={{ fontSize: '22px' }}>22</span> },
									]}
									onChange={(value) => {
										document.documentElement.style.fontSize = `${value}px`
									}}
								/>
							</div>
							<Spin spinning={state.isLoading}>
								<Form
									form={form}
									validateTrigger='onBlur'
									scrollToFirstError
									onValuesChange={onFormValuesChange}
									requiredMark={false}
									size='large'
									layout='vertical'
									className={classNames({
										'bw-form': true,
										'bw-form--inactive': isInactiveIPS,
									})}>
									{stepFormsJSX}
								</Form>
							</Spin>
						</div>

						<div className='bw-ips__footer'>
							<div className='bw-ips__progressbar'>
								<div
									className='bw-ips__progressbar-indicator'
									style={{ width: (activeStep * (100 / steps.length)).toFixed(2) + '%' }}
								/>
							</div>
							<div className='bw-ips__footer-in'>
								{activeStep > 1 ? (
									<Button
										onClick={() => stepController.prevStep()}
										className='ant-btn-link-white ant-btn-link-white--lg'
										disabled={isInactiveIPS}
										type='link'>
										{'< Back'}
									</Button>
								) : (
									<div />
								)}
								<div>
									{activeStep < steps.length ? (
										<Button
											onClick={onNextStep}
											disabled={isInactiveIPS}
											className='ant-btn-primary-black ant-btn-primary-black--lg'>
											Next
										</Button>
									) : (
										<Button
											onClick={onSendForm}
											disabled={isInactiveIPS}
											className='ant-btn-primary-black ant-btn-primary-black--lg'>
											Send
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<CompleteModal
				isCompleteModal={state.isCompleteModal}
				setStateIPS={setStateIPS}
				stepController={stepController}
			/>
		</>
	)
}

export default IPSPage
