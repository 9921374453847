import { FC } from 'react'
import {
	IndividualAccountInfoType,
	JointAccountInfoType,
	OnboardingChildProps,
	OnboardingFormValues,
	PacSwpFieldsType,
} from 'types/client-onboarding'
import { Col, Form, InputNumber, Select } from 'antd'
import { formItemProps, rules } from 'constants/props'
import { DeepPartial, Paths } from 'types/ips'
import { BwDatePicker } from 'components/start/BwDatePicker'
import moment from 'moment'
import { AccPaymentFrequency, AccountType, AccountTypeJt, PacOrSwpType } from 'graphql/generated/schema'
import { useMatchQuery } from 'hooks/useMatchQuery'

type PathType = IndividualAccountInfoType | JointAccountInfoType

interface Props {
	isPacSwp?: boolean
	pacSwp?: DeepPartial<PacSwpFieldsType>
	isJointAccount?: boolean
	accountType?: AccountType | AccountTypeJt
	fieldPath(keys: Paths<PathType>): Paths<OnboardingFormValues>
}

const PacSwpFields: FC<OnboardingChildProps & Props> = (props) => {
	const [isRequired] = useMatchQuery('required', '1')

	const { fieldPath, isPacSwp, accountType } = props

	const isSwpAllowed =
		accountType && [AccountType.Tfsa, AccountType.Cash, AccountType.Itf, AccountTypeJt.Joint].includes(accountType)

	return (
		<>
			<Col xs={24}>
				<h4>PAC/SWP Information</h4>
			</Col>

			<Col
				xs={24}
				xl={12}>
				<Form.Item
					{...formItemProps}
					label={`Would you like to set-up a PAC ${isSwpAllowed ? '/SWP ' : ''} for this account?`}
					name={fieldPath(['isPacSwp'])}
					rules={[rules.requiredWhen(isRequired)]}>
					<Select
						allowClear
						options={[
							{ value: true, label: 'Yes' },
							{ value: false, label: 'No' },
						]}
					/>
				</Form.Item>
			</Col>

			{isPacSwp && (
				<>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Type'
							name={fieldPath(['pacSwp', 'type'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<Select
								allowClear
								options={[{ value: PacOrSwpType.Pac }, ...(isSwpAllowed ? [{ value: PacOrSwpType.Swp }] : [])]}
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Amount'
							name={fieldPath(['pacSwp', 'amount'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<InputNumber
								type='number'
								min={0}
								max={100_000_000}
								style={{ width: `${100}%` }}
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Start Date'
							dependencies={[fieldPath(['pacSwp', 'frequency'])]}
							name={fieldPath(['pacSwp', 'startDate'])}
							rules={[
								rules.requiredWhen(isRequired),
								rules.futureDate,
								({ getFieldValue }) => ({
									validator(_, value: moment.Moment | undefined) {
										if (!value) {
											return Promise.resolve()
										}

										const frequency = getFieldValue(fieldPath(['pacSwp', 'frequency']))
										const dayOfMonth = value.date()

										const isFirstHalf = dayOfMonth === 1 || dayOfMonth === 15
										const isSecondHalf = dayOfMonth === 15 || dayOfMonth === 30

										if (frequency && frequency === AccPaymentFrequency.Semimonthly_1_15 && !isFirstHalf) {
											return Promise.reject('The day of the month must be the 1st or the 15th')
										}

										if (frequency && frequency === AccPaymentFrequency.Semimonthly_15Last && !isSecondHalf) {
											return Promise.reject('The day of the month must be the 15th or the 30th')
										}

										return Promise.resolve()
									},
								}),
							]}>
							<BwDatePicker disabledDate={(inputDate) => inputDate && inputDate < moment().endOf('day')} />
						</Form.Item>
					</Col>

					<Col
						xs={24}
						xl={12}>
						<Form.Item
							{...formItemProps}
							label='Frequency'
							dependencies={[fieldPath(['pacSwp', 'startDate'])]}
							name={fieldPath(['pacSwp', 'frequency'])}
							rules={[rules.requiredWhen(isRequired)]}>
							<Select
								allowClear
								options={[
									{ label: 'Biweekly', value: AccPaymentFrequency.Biweekly },
									{ label: 'Semimonthly 1 & 15', value: AccPaymentFrequency.Semimonthly_1_15 },
									{ label: 'Semimonthly 15 & Last', value: AccPaymentFrequency.Semimonthly_15Last },
									{ label: 'Monthly', value: AccPaymentFrequency.Monthly },
									{ label: 'Quarterly', value: AccPaymentFrequency.Quarterly },
									{ label: 'Semi Annually', value: AccPaymentFrequency.SemiAnnually },
									{ label: 'Annually', value: AccPaymentFrequency.Annually },
								]}
							/>
						</Form.Item>
					</Col>

					{props.isJointAccount && (
						<Col
							xs={24}
							xl={12}>
							<Form.Item
								{...formItemProps}
								label='Bank Account'
								name={fieldPath(['pacSwp', 'clientType'])}
								rules={[rules.requiredWhen(isRequired)]}>
								<Select
									allowClear
									options={[{ value: 'Client 1' }, { value: 'Client 2' }]}
								/>
							</Form.Item>
						</Col>
					)}
				</>
			)}
		</>
	)
}

export default PacSwpFields
