import { FC, lazy, Suspense } from 'react'
import { Skeleton, Table, Form, Row, Col, Checkbox, Tooltip } from 'antd'
import FormSection from 'components/ips/FormSection'
import { IInvestmentGuidelinesFields, IDataIPS, IPropsSteps } from 'types/ips'
import { rules } from 'constants/props'
import { ASSET_MIX_DATA_MAP } from 'constants/ips'
import { QuestionCircleOutlined as IconHelp } from '@ant-design/icons'
const PieChart = lazy(() => import('components/ips/charts/PieChart'))

type AgreementsValue = 1 | 2 | 3 | 4

const Step8: FC<IPropsSteps> = (props) => {
	const keyPath = (key: keyof IInvestmentGuidelinesFields): [keyof IDataIPS, string] => ['investmentGuidelines', key]

	const { assetMix } = props.stateIPS

	const targetData = ASSET_MIX_DATA_MAP[assetMix.value]

	const addTooltip = (title: string) => {
		return (
			<Tooltip
				placement='topLeft'
				title={title}>
				<IconHelp style={{ marginLeft: '5px' }} />
			</Tooltip>
		)
	}

	const agreements = Form.useWatch(keyPath('agreements'), props.form)

	const getOptionError = (value: AgreementsValue) => {
		const valueIsNotSelected = Boolean(agreements?.length && agreements?.includes(value) === false)

		const equitesRange = targetData[2].range.split('-')
		const equitesMaxValue = Number(equitesRange?.[1])

		// option #1 is required when Equities >= 50
		// option #2,3,4 are required by default
		const optionErrors = {
			1: valueIsNotSelected && equitesMaxValue >= 50,
			2: valueIsNotSelected,
			3: valueIsNotSelected,
			4: valueIsNotSelected,
		}

		return optionErrors[value]
	}

	return (
		<>
			<FormSection title='Recommended Asset Mix Policy for Consolidated Portfolio'>
				<div className='bw-form__block'>
					<p className='bw-form__section-title'>
						<b>{assetMix.value}</b>
					</p>
					<Table
						pagination={false}
						bordered={true}
						style={{ marginBottom: '24px', width: '740px', maxWidth: 'initial' }}
						columns={[
							{
								title: 'Asset Class',
								dataIndex: 'asset',
								key: 'asset',
							},
							{
								title: '% Range',
								dataIndex: 'range',
								key: 'range',
							},
							{
								title: 'Benchmark %',
								dataIndex: 'benchmark',
								key: 'benchmark',
							},
							{
								title: 'Benchmark Index',
								key: 'benchmarkIdx',
								dataIndex: 'benchmarkIdx',
							},
						]}
						dataSource={[
							{
								key: '0',
								asset: (
									<>
										<span>Cash & Equivalents</span>
										{addTooltip(
											'Cash or money market securities issued by governments or corporations; or high-interest savings accounts.',
										)}
									</>
								),
								range: targetData[0].range,
								benchmark: targetData[0].benchmark,
								benchmarkIdx: 'FTSE TMX 91 Day Treasury Bill Index',
							},
							{
								key: '1',
								asset: (
									<>
										<span>Fixed Income</span>
										{addTooltip(
											'Bonds, debentures, preferred shares, notes or other debt investments of Canadian and/or US governments, government agencies or corporations, including mortgage or asset-backed securities (the credit quality must be rated BB or better by a recognized rating organization).',
										)}
									</>
								),
								range: targetData[1].range,
								benchmark: targetData[1].benchmark,
								benchmarkIdx: 'FTSE TMX Universe Bond Index',
							},
							{
								key: '2',
								asset: 'Equities',
								range: targetData[2].range,
								benchmark: targetData[2].benchmark,
								benchmarkIdx: '',
							},
							{
								key: '3',
								asset: (
									<>
										<span className='ml-20'>Canadian</span>
										{addTooltip(
											'Common shares, convertible debentures, share purchase warrants or rights, installment receipts, real estate trusts, or covered call options.',
										)}
									</>
								),
								range: targetData[3].range,
								benchmark: targetData[3].benchmark,
								benchmarkIdx: 'S&P/TSX Total Return Index',
							},
							{
								key: '4',
								asset: (
									<>
										<span className='ml-20'>Global</span>
										{addTooltip(
											'Common shares, American and Global Depository Receipts, real estate trusts, or covered call options.',
										)}
									</>
								),
								range: targetData[4].range,
								benchmark: targetData[4].benchmark,
								benchmarkIdx: 'S&P500 Total Return Index ($ Cdn)',
							},
						]}
					/>
				</div>

				<div className='bw-form__block'>
					<Suspense fallback={<Skeleton active />}>
						<PieChart chartData={targetData} />
					</Suspense>
				</div>

				<div className='bw-form__block'>
					<Row gutter={[20, 0]}>
						<Col
							xs={24}
							xl={24}>
							<Form.Item
								label={<b>Please check all that apply:</b>}
								name={keyPath('agreements')}
								rules={[
									rules.required,
									({ getFieldValue }) => ({
										validator(_, agreements: number[]) {
											const values: AgreementsValue[] = [1, 2, 3, 4]
											const errors = values.map((val) => getOptionError(val))

											if (errors.some((el) => el === true)) {
												return Promise.reject('Please select the required fields')
											}

											return Promise.resolve()
										},
									}),
								]}>
								<Checkbox.Group
									className='ant-checkboxes-noborder'
									options={[
										{
											label:
												'I/We  acknowledge  that  my/our  account(s)  with  Bellwether  are  invested  in  predominantly  equity  securities  (greater  than 50%) which primarily provide long term capital appreciation.',
											value: 1,
										},
										{
											label:
												'I/We  understand  that  a  balanced  (i.e.  a  mix  of  equities,  fixed  income  and  cash  investments)  and  diversified  approach  to investing is important to long term financial success.',
											value: 2,
										},
										{
											label:
												'I/We  acknowledge  that  all  of  my/our  investment  portfolio  may  not  be  with  Bellwether  and  therefore  it  is  my/our responsibility to ensure my/our total portfolio asset mix is correctly maintained to meet my/our investment objectives.',
											value: 3,
										},
										{
											label:
												'I/We certify that the information contained in this statement is true, complete, and accurately reflects my/our investment attitudes.',
											value: 4,
										},
									].map((option) => {
										const optionError = getOptionError(option.value as AgreementsValue)

										return {
											...option,
											style: optionError ? { borderColor: '#ff4d4f' } : {},
										}
									})}
								/>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</FormSection>
		</>
	)
}

export default Step8
