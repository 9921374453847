import { FormInstance } from 'antd'
import { DeepPartial, ExtractWithOptional } from './ips'
import {
	AccPaymentByType,
	AccPaymentFrequency,
	AccPaymentType,
	AccRrifAgeType,
	AccWtOnType,
	AccountType,
	ConfirmationType,
	MaritalStatusType,
	PacOrSwpType,
	TransferType,
	AccountTypeJt,
	JtwrosOrTicType,
	ClientAssetsAccountType,
	JointAssetsAccountType,
	EntityAssetsAccountType,
	RelationshipType,
} from 'graphql/generated/schema'

export type AccountsNum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export type ClientNum = 1 | 2
export type ClientKeyType = 'clientOne' | 'clientTwo'

export enum CurrencyType {
	Cad = 'CAD',
	CadUsd = 'CAD & USD',
	Usd = 'USD',
}

export enum JurisdictionType {
	AB = 'AB',
	BC = 'BC',
	SK = 'SK',
	SK_PRIF = 'SK PRIF',
	MB = 'MB',
	ON = 'ON',
	QC = 'QC',
	NB = 'NB',
	PEI = 'PEI',
	NS = 'NS',
	NF = 'NF',
	NF_LRIF = 'NF LRIF',
	FED_LIF = 'FED LIF',
	FED_LIRSP = 'FED LIRSP',
	FED_RLSP = 'FED RLSP',
	FED_RLIF = 'FED RLIF',
}

export enum BeneRelationshipType {
	SPOUSE = 'SPOUSE',
	CHILD = 'CHILD',
	BROTHER_SISTER = 'BROTHER/SISTER',
	PARENT = 'PARENT',
	ESTATE_SUCCESSION = 'ESTATE/SUCCESSION',
	COUSIN = 'COUSIN',
	GRANCHILD = 'GRANCHILD',
	GRANDPARENT = 'GRANDPARENT',
	NEPHEW_NEICE = 'NEPHEW/NEICE',
	UNCLE_AUNT = 'UNCLE/AUNT',
	NOT_RELATED = 'NOT RELATED',
	NOT_GIVEN = 'NOT GIVEN',
}

export enum TransferProvinceType {
	AB = 'AB',
	BC = 'BC',
	SK = 'SK',
	MB = 'MB',
	ON = 'ON',
	QC = 'QC',
	NB = 'NB',
	PEI = 'PEI',
	NS = 'NS',
	NF = 'NF',
}

export interface ClientInfoType {
	accountsNum: AccountsNum
	title: string
	firstName: string
	lastName: string
	email: string
	dateOfBirth: object
	socialInsuranceNumber: string
	maritalStatus: MaritalStatusType
	relationshipType: RelationshipType
	relationshipTypeOther: string
	spouseFirstName: string
	spouseLastName: string
	spouseDateOfBirth: object
	spouseSocialInsuranceNumber: string
}

export interface BeneficiaryFieldsType {
	firstName: string
	lastName: string
	middleInitial?: string
	sin: number
	dateOfBirth: object
	relationship: BeneRelationshipType
	allocation: number
}

export interface TransferFieldsType {
	transferAmount: number
	transferCurrency: CurrencyType
	transferType: TransferType
	deliveryNumber: string
	institutionName: string
	institutionAddress: string
	institutionCity: string
	institutionProvince: TransferProvinceType
	institutionPostalCode: string
}

export interface PacSwpFieldsType {
	type: PacOrSwpType
	amount: number
	startDate: object
	frequency: AccPaymentFrequency
	clientType: 'Client 1' | 'Client 2'
}

export interface IndividualAccountInfoType {
	accountType: AccountType
	accountCurrency: CurrencyType
	jurisdiction: JurisdictionType
	isSuccessor: boolean
	isBeneficiary: boolean
	beneficiaryNum: 1 | 2 | 3 | 4
	beneficiary: BeneficiaryFieldsType[]
	isTransfer: boolean
	transferNum: 1 | 2
	transfers: TransferFieldsType[]
	isPacSwp: boolean
	pacSwp: PacSwpFieldsType
	riffPayments: {
		age: AccRrifAgeType
		paymentType: AccPaymentType
		paymentFrequency: AccPaymentFrequency
		paymentStartDate: object
		paymentAmount: number
		paymentBlended: ConfirmationType
		paymentWithholdingTax: AccWtOnType
		paymentWithholdingTaxPercent: number
		paymentDestination: AccPaymentByType
	}
}

export interface JointAccountInfoType {
	accountType: AccountTypeJt
	accountCurrency: CurrencyType
	ownershipStructure: JtwrosOrTicType
	ticPercentage: number
	beneficiary: BeneficiaryFieldsType[]
	isTransfer: boolean
	transferNum: 1 | 2
	transfers: TransferFieldsType[]
	isPacSwp: boolean
	pacSwp: PacSwpFieldsType
}

interface ClientFieldsType {
	clientInfo: ClientInfoType
	individualAccounts: IndividualAccountInfoType[]
}

export interface ExistingAccountsFieldsType<T> {
	isExistingAccounts: boolean
	accountsNum: 1 | 2 | 3 | 4 | 5
	accounts?: {
		type: T
		value: number
		name?: string
	}[]
}

export type OnboardingFormValues = DeepPartial<{
	clientCount: ClientNum
	clientOne: ClientFieldsType
	clientTwo: ClientFieldsType
	jointAccountsNum: 0 | 1 | 2 | 3
	jointAccounts: JointAccountInfoType[]
	existingAccounts: {
		clientOne: ExistingAccountsFieldsType<ClientAssetsAccountType> & { isNonSolicitFormEnabled: boolean | null }
		clientTwo: ExistingAccountsFieldsType<ClientAssetsAccountType> & { isNonSolicitFormEnabled: boolean | null }
		jointAccounts: ExistingAccountsFieldsType<JointAssetsAccountType>
		entityAccounts: ExistingAccountsFieldsType<EntityAssetsAccountType>
	}
	advisorNotes: string
	pmId: number
	advisorId: number
}>

export type ExistingAccountsKeys = ExtractWithOptional<OnboardingFormValues['existingAccounts']>

export interface ClientDataType extends DeepPartial<ClientFieldsType> {
	clientNum: ClientNum
	clientKey: ClientKeyType
}

export interface OnboardingChildProps {
	guid?: string
	clientCount?: ClientNum
	form: FormInstance<OnboardingFormValues>
	clientsData: ClientDataType[]
}
