import { FC, memo } from 'react'
import { Button, Modal } from 'antd'
import type { IPropsSteps } from '../../types/ips'

type IProps = Pick<IPropsSteps, 'setStateIPS' | 'stepController'> & {
	isCompleteModal: boolean
}

const CompleteModal: FC<IProps> = ({ isCompleteModal, setStateIPS, stepController }) => {
	return (
		<Modal
			className='bw-modal-confirm'
			open={isCompleteModal}
			onCancel={() => setStateIPS({ isCompleteModal: false })}
			centered
			width={630}
			title={
				<h2>
					You have not complete <br /> client information
				</h2>
			}
			footer={
				<div>
					<Button
						onClick={() => {
							/* close the modal and go to next step */
							setStateIPS({ isCompleteModal: false })
							stepController.nextStep()
						}}
						className='ant-btn-link-white ant-btn-link-white--lg'
						type='link'>
						Skip for now
					</Button>
					<Button
						onClick={() => {
							/* close the modal and allow to fill invalid fields */
							setStateIPS({ isCompleteModal: false })
						}}
						className='ant-btn-primary-black ant-btn-primary-black--lg'>
						Continue
					</Button>
				</div>
			}>
			<p>
				You can skip the section for now and go back later. Or continue to complete all required fields to complete this
				section
			</p>
		</Modal>
	)
}

export default memo(CompleteModal)
