import { FC } from 'react'
import { IDataIPS, IPropsSteps } from 'types/ips'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'

const steps: {
	id: number
	groupKey: number
	title: string
	stepKey: keyof IDataIPS
	Content: FC<IPropsSteps>
}[] = [
	{
		id: 1,
		groupKey: 1,
		title: 'Personal Information',
		stepKey: 'clientInfo',
		Content: Step1,
	},
	{
		id: 2,
		groupKey: 1,
		title: 'Banking Information',
		stepKey: 'bankingInfo',
		Content: Step2,
	},
	{
		id: 3,
		groupKey: 1,
		title: 'Trusted Person',
		stepKey: 'trustedPerson',
		Content: Step3,
	},
	{
		id: 4,
		groupKey: 1,
		title: 'Additional Disclosures',
		stepKey: 'additionalDisclosures',
		Content: Step4,
	},
	{
		id: 5,
		groupKey: 2,
		title: 'Know Your Client',
		stepKey: 'investmentPolicy',
		Content: Step5,
	},
	{
		id: 6,
		groupKey: 2,
		title: 'Return Objective',
		stepKey: 'returnObjective',
		Content: Step6,
	},
	{
		id: 7,
		groupKey: 2,
		title: 'Risk Profile',
		stepKey: 'riskTolerance',
		Content: Step7,
	},
	{
		groupKey: 2,
		id: 8,
		title: 'Investment Guidelines',
		stepKey: 'investmentGuidelines',
		Content: Step8,
	},
	{
		id: 9,
		groupKey: 3,
		title: 'Client Accounts',
		stepKey: 'clientAccounts',
		Content: Step9,
	},
]

const stepGroups: {
	id: number
	title: string
}[] = [
	{
		id: 1,
		title: 'Client Information',
	},
	{
		id: 2,
		title: 'Investment Policy Information',
	},
	{
		id: 3,
		title: 'Account Information',
	},
]

export { steps, stepGroups }
