import { FC, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Form, Select } from 'antd'
import { AdvisorsDocument, FindAdvisorByUserDocument, PmsDocument, Role } from 'graphql/generated/schema'
import { formItemProps, rules } from 'constants/props'
import { OnboardingChildProps } from 'types/client-onboarding'
import { useAuth } from 'hooks/useContexts'

const PmAndAdvisor: FC<OnboardingChildProps> = (props) => {
	const { user } = useAuth()
	const { form } = props

	const selectedPmId = Form.useWatch(['pmId'], form)

	const pmsRes = useQuery(PmsDocument)
	const advisorsRes = useQuery(AdvisorsDocument)
	const advisorByUser = useQuery(FindAdvisorByUserDocument, { variables: { userId: user?.id ?? 0 } })

	const pmsList = pmsRes.data?.pms?.nodes ?? []
	const advisorsList = advisorsRes.data?.advisors?.nodes ?? []

	const pmsOpts: {
		label: string
		value: number
	}[] = pmsList.map((pm) => ({
		label: `${pm.firstName} ${pm.lastName}`,
		value: pm.id,
	}))

	const pmsAdvisorsOpts = advisorsList
		.filter((advisor) => advisor.pm.id === selectedPmId)
		.map((advisor) => ({
			label: `${advisor.firstName} ${advisor.lastName}`,
			value: advisor.id,
		}))

	const resetAdvisor = () => {
		form.resetFields([['advisorId']])
	}

	useEffect(() => {
		if (user?.role === Role.Pm) {
			form.setFieldsValue({ pmId: user.id })
		}

		if (user?.role === Role.Advisor) {
			const { id, pm } = advisorByUser.data?.findAdvisorByUser ?? {}

			form.setFieldsValue({ advisorId: id, pmId: pm?.id })
		}
	}, [user, advisorByUser])

	return (
		<>
			<Col
				hidden={user?.role && [Role.Pm, Role.Advisor].includes(user?.role)}
				xs={24}
				xl={6}>
				<Form.Item
					{...formItemProps}
					label='Portfolio Manager'
					name={'pmId'}
					rules={[rules.required]}>
					<Select
						allowClear
						style={{ width: '100%' }}
						size='large'
						showSearch
						onSelect={(_: any, option) => resetAdvisor()}
						onClear={() => resetAdvisor()}
						filterOption={(input, option) => {
							return (option?.label as string).toLowerCase().includes(input.toLowerCase())
						}}
						options={pmsOpts}
						loading={pmsRes.loading}
					/>
				</Form.Item>
			</Col>

			<Col
				hidden={user?.role && [Role.Advisor].includes(user?.role)}
				xs={24}
				xl={6}>
				<Form.Item
					{...formItemProps}
					label='Advisor'
					name={'advisorId'}
					rules={[rules.required]}>
					<Select
						allowClear
						style={{ width: '100%' }}
						size='large'
						showSearch
						disabled={!selectedPmId}
						filterOption={(input, option) => {
							return (option?.label as string).toLowerCase().includes(input.toLowerCase())
						}}
						options={pmsAdvisorsOpts}
						loading={pmsRes.loading}
					/>
				</Form.Item>
			</Col>
		</>
	)
}

export default PmAndAdvisor
