import { createContext } from 'react'
import { LoginUserDocument, LogoutUserDocument, WhoAmIDocument, WhoAmIQuery } from '../graphql/generated/schema'
import { useMutation, useQuery } from '@apollo/client'

type UserType = WhoAmIQuery['whoAmI'] | undefined

interface LoginValuesType {
	email: string
	password: string
}

interface AuthContextType {
	login: (values: LoginValuesType) => Promise<void>
	logout: () => Promise<void>
	user: UserType
	loading: boolean
}

const AuthContext = createContext<AuthContextType>({
	user: undefined,
	loading: true,
	login: async (values) => {},
	logout: async () => {},
})

function AuthProvider(props: any) {
	const [loginUser, { loading: loginLoading }] = useMutation(LoginUserDocument)
	const [logoutUser, { loading: logoutLoading }] = useMutation(LogoutUserDocument)
	const checkUser = useQuery(WhoAmIDocument)

	const login = async (values: LoginValuesType) => {
		await loginUser({
			variables: {
				loginUserInput: {
					email: values.email,
					password: values.password,
				},
			},
		})

		await checkUser.refetch()
	}

	const logout = () => {
		return logoutUser()
	}

	const loading = loginLoading || logoutLoading || checkUser.loading

	return (
		<AuthContext.Provider
			value={{
				user: checkUser.data?.whoAmI,
				loading,
				login,
				logout,
			}}
			{...props}
		/>
	)
}

export { AuthContext, AuthProvider }
