import { useLocation } from 'react-router-dom'

type QueryParamKey = 'required' | 'from'
type QueryParamValue = '1' | 'admin'

export function useMatchQuery(param: QueryParamKey, value: QueryParamValue): [boolean] {
	const location = useLocation()

	const searchParams = new URLSearchParams(location.search)
	const isMatch = searchParams.get(param) === value

	return [isMatch]
}
