import type { IRiskToleranceFields, StepFieldsDisabled, IReturnObjectiveFields } from 'types/ips'

export class OptionsDependency<K extends keyof O, O extends object> {
	private readonly obj: O

	constructor(obj: O) {
		this.obj = obj
	}

	getDisabledOption(key: K) {
		const fields: any = this.obj[key] ?? {}
		const reason = Object.keys(fields).filter((key) => fields[key])
		const disabled = Object.values(fields).some((v) => v)

		return { disabled, reason }
	}

	get disabledOptions() {
		return Object.keys(this.obj)
			.map((key) => this.getDisabledOption(key as K))
			.filter((el) => el.disabled)
	}

	get blockReasons() {
		const reasons = this.disabledOptions.flatMap((el) => el.reason)
		const reasonsUniq = reasons.filter((el, i, arr) => arr.indexOf(el) === i)
		return reasonsUniq
	}
}

type DownturnSumValues = IRiskToleranceFields['downturnSum']
type ExpectAverageValues = IReturnObjectiveFields['expectAverage']
type RiskProfileValues = IRiskToleranceFields['riskProfile']

export const downturnSumDisabled = ({
	expectAverage,
	riskProfile,
}: {
	expectAverage: ExpectAverageValues
	riskProfile: RiskProfileValues
}) => {
	const expectAverageInt = expectAverage ? +expectAverage : 0
	const riskProfileInt = riskProfile ? +riskProfile : 0
	const returnMap: {
		[key in IRiskToleranceFields['downturnSum'] as string]: StepFieldsDisabled
	} = {
		'1': {
			expectAverage: [3, 4, 5].includes(expectAverageInt),
			riskProfile: [3, 4, 5].includes(riskProfileInt),
		},
		'2': {
			expectAverage: [3, 4, 5].includes(expectAverageInt),
			riskProfile: [4, 5].includes(riskProfileInt),
		},
		'3': {
			expectAverage: [3, 4, 5].includes(expectAverageInt),
			riskProfile: [4, 5].includes(riskProfileInt),
		},
		'4': {},
		'5': {},
		'': {},
	}

	return returnMap
}

export const riskProfileDisabled = ({
	expectAverage,
	downturnSum,
}: {
	expectAverage: ExpectAverageValues
	downturnSum: DownturnSumValues
}) => {
	const expectAverageInt = expectAverage ? +expectAverage : 0
	const downturnSumInt = downturnSum ? +downturnSum : 0
	const returnMap: {
		[key in IRiskToleranceFields['riskProfile'] as string]: StepFieldsDisabled
	} = {
		'1': {
			expectAverage: [3, 4, 5].includes(expectAverageInt),
		},
		'2': {
			expectAverage: [3, 4, 5].includes(expectAverageInt),
		},
		'3': {
			expectAverage: [4, 5].includes(expectAverageInt),
			downturnSum: [1].includes(downturnSumInt),
		},
		'4': {
			expectAverage: [5].includes(expectAverageInt),
			downturnSum: [1, 2].includes(downturnSumInt),
		},
		'5': {
			downturnSum: [1, 2, 3].includes(downturnSumInt),
		},
		'': {},
	}

	return returnMap
}
