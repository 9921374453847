import { Avatar, Space } from 'antd'
import { FC } from 'react'

type ColorType = 'blue' | 'orange'
type ColorMap = { [key in ColorType]: React.CSSProperties }

interface Props {
	name: string
	color: ColorType
}

const COLOR_MAP_STYLE: ColorMap = {
	blue: {
		backgroundColor: '#D6E4FF',
		color: '#2F54EB',
	},
	orange: {
		backgroundColor: '#FFE7BA',
		color: '#FA541C',
	},
}

const StatusTag: FC<Props> = ({ name, color }) => {
	if (typeof name !== 'string') {
		return null
	}

	const nameStatusTag = name
		.split(' ')
		.filter((name) => name.length > 0)
		.map((n) => n[0].toUpperCase())
		.join('')

	const avaStyle = COLOR_MAP_STYLE[color]

	return (
		<Space>
			<Avatar
				size={24}
				style={{ fontSize: 12, ...avaStyle }}>
				{nameStatusTag}
			</Avatar>
			<span>{name}</span>
		</Space>
	)
}

export default StatusTag
