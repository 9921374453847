import { useCallback, useState } from 'react'

export interface StepController {
	nextStep: () => void
	prevStep: () => void
	setStep: (step: number) => void
}

export function useStep(maxStep: number): [number, StepController] {
	const [activeStep, setActiveStep] = useState(1)

	const setStep = useCallback(
		(step: number) => {
			if (step < 1) step = 1
			if (step > maxStep) step = maxStep

			return setActiveStep(step)
		},
		[maxStep, activeStep],
	)

	const nextStep = useCallback(() => {
		setStep(activeStep + 1)
	}, [activeStep])

	const prevStep = useCallback(() => {
		setStep(activeStep - 1)
	}, [activeStep])

	return [
		activeStep,
		{
			nextStep,
			prevStep,
			setStep,
		},
	]
}
